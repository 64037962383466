import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Typography from '../../../components/Typography';

interface IHBOHireProps extends WithStyles<typeof styles> {
    onClick: Function;
}

const NortonBanner = (props: IHBOHireProps) => {
    const { classes, onClick } = props;

    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={12} sm={12} md={12} lg={6} style={{ textAlign: 'center' }}>
                    <img alt="" src={"../Norton-logo-black.svg"} className={classes.logoLarge} />
                    <p style={{ color: '#020202', fontSize: '1.875em', margin: '10px 0px 10px 0px', lineHeight: '1' }}>
                        <strong style={{ color: '#fbbd38', paddingRight: '5px' }}>
                            CONTRATA YA
                        </strong>
                        tu servicio
                    </p>
                    <Typography style={{ color: '#020202', fontSize: '2.25em', margin: '10px 0px', whiteSpace: 'pre', }} type="h2" text={"¡Protege todos tus dispositivos!"} />
                    <hr className={classes.hr} />
                    <Typography style={{ color: '#020202', fontSize: '1em', margin: '0' }} type="p" text={"Los productos Norton son multidispositivo y multiplataforma"} />
                    <img alt="" src={"../logos_os.svg"} className={classes.logoOs} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <div className={classes.div2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} style={{ textAlign: 'center' }}>
                            <Typography style={{ color: '#020202', fontSize: '2.8125em', margin: '0', textAlign: 'center' }} type="h2" text={"Security"} />
                            <img alt="" src={"../Norton-logo-black.svg"} className={classes.logoSmall} />
                            <img alt="" src={"../norton-security-precios.svg"} className={classes.precios} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} style={{ textAlign: 'center' }}>
                            <Typography style={{ color: '#020202', fontSize: '2.8125em', margin: '0' }} type="h2" text={"Family"} />
                            <Typography style={{ color: '#020202', fontSize: '1.125em', margin: '-7px 0px 2px 0px' }} type="p" text={"(Control Parental)"} />
                            <img alt="" src={"../Norton-logo-black.svg"} className={classes.logoSmall2} />
                            <img alt="" src={"../norton-family-precios.svg"} className={classes.precios} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} style={{ textAlign: 'center' }}>
                            <Typography style={{ color: '#020202', fontSize: '2.8125em', margin: '0' }} type="h2" text={"VPN"} />
                            <img alt="" src={"../Norton-logo-black.svg"} className={classes.logoSmall} />
                            <img alt="" src={"../norton-vpn-precios.svg"} className={classes.precios} />
                        </Grid>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button className={classes.mainButton} onClick={() => onClick()} >
                            Contratar
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
    },
    container: {
        height: '513px',
        backgroundColor: "#FFFFFF",
    },
    div2: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '125px',
        alignItems: 'center'
    },
    hr: {
        borderTop: '2px solid #fbbd38',
        width: '70%'
    },
    logoSmall: {
        height: 'auto',
        width: '100px',
        marginBottom: '31px'
    },
    logoSmall2: {
        height: 'auto',
        width: '100px',
        marginBottom: '14px'
    },
    logoLarge: {
        height: 'auto',
        width: '450px',
        paddingTop: '75px'
    },
    logoOs: {
        paddingTop: '10px',
        height: 'auto',
        width: '190px'
    },
    precios: {
        width: '98%'
    },
    buttonContainer: {
        paddingTop: '15px',
        display: 'flex',
        justifyContent: 'center',
    },
    mainButton: {
        backgroundColor: '#252f3a',
        borderColor: '#ecb941',
        borderWidth: '2px',
        borderStyle: 'solid',
        padding: '13px',
        width: "280px",
        fontFamily: "Sarabun",
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#FFFFFF',
        textAlign: 'center',
        textDecoration: 'none',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#252f3a',
            cursor: 'pointer',
        }
    },
});

export default withStyles(styles)(NortonBanner);
