import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { TextField, InputAdornment, Theme, Tooltip, Fade } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

interface ITextFieldCardCVV extends WithStyles<typeof styles> {
    cardCvvValue: string,
    cardCvvFormat?: string,
    cardCvvError?: string,
    otherStyles?: any,
    onKeyDown?: (event: React.KeyboardEvent) => void,
    onFocus?: (typeData: "number" | "date" | "cvv") => void,
    addPaymentData: (value: string, typeData: "number" | "date" | "cvv") => void,
}

const TextFieldCardCVV = (props: ITextFieldCardCVV) => {
    const {classes, cardCvvValue, cardCvvError, otherStyles, onKeyDown, onFocus, addPaymentData} = props;

    return (
        <div className={classes.root} style={otherStyles}>
             <TextField
                value={cardCvvValue} 
                placeholder="CVV"
                autoComplete="off"
                error={cardCvvError ? true : false}
                helperText={cardCvvError ? cardCvvError : ' '}
                inputProps={{style: { color: "#005ca7" }, maxLength: 3}}
                InputProps={{
                    endAdornment: (
                        <Tooltip 
                            className={classes.tooltip}
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }} 
                            title="Código de 3 digitos ubicado al reverso de tu tarjeta de crédito.">
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faQuestionCircle} style={{color: "#cccccc"}} />
                            </InputAdornment>
                        </Tooltip>
                    ),
                }}
                onKeyDown={(event: React.KeyboardEvent) => {onKeyDown && onKeyDown(event)}}
                onChange={(event) => addPaymentData(event.target.value.replace(/[^0-9]/g, ''), 'cvv')} 
                onFocus={() => {onFocus && onFocus("cvv")}} 
            />
        </div>
    )
}

const styles = ({ palette, breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    tooltip: {
        backgroundColor: "transparent",
        color: palette.common.black
    }
});

export default withStyles(styles)(TextFieldCardCVV);
