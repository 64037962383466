import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormLabel, Grid, Link, makeStyles, MenuItem, TextField, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import moment from 'moment';
import { estadoCiudadData } from './components/EstadoCiudad';
import states from './components/states.json';
import { Dispatch } from 'redux';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import _ from 'lodash';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import { AlertRedux } from 'megacable-mobile-library';
import SignaturePad from 'react-signature-canvas';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Help from '../../components/Help';
import checkToken from '../../utils/checKToken';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface municipalitiesValue {
    name: string;
    value: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>

type FormPortabilityProps = PropsFromRedux & {

}

const FormPortability = (props: FormPortabilityProps) => {
    const classes = styles();
    const { currentContract, login } = props;
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [createdDate, setCreatedDate] = React.useState("");
    const [personType, setPersonType] = React.useState('');
    const [businessName, setBusinessName] = React.useState("");
    const [paternalSurname, setPaternalSurname] = React.useState("");
    const [maternalSurname, setMaternalSurname] = React.useState("");
    const [curp, setCurp] = React.useState("");
    const [birthDate, setbirthDate] = React.useState("");
    const [state, setState] = React.useState("");
    const [municipality, setMunicipality] = React.useState("");
    const [typeFijoPospago, setTypeFijoPospago] = React.useState(false);
    const [typeFijoPrepago, setTypeFijoPrepago] = React.useState(false);
    const [typeMovilPospago, setTypeMovilPospago] = React.useState(false);
    const [typeMovilPrepago, setTypeMovilPrepago] = React.useState(false);
    const [typeNoGeographic, setTypeNoGeographic] = React.useState(false);
    const [portingDate, setPortingDate] = React.useState('');
    const [serviceType, setServiceType] = React.useState("");
    const [digitosIDD, setDigitosIDD] = React.useState("");
    const [otherName, setOtherName] = React.useState("");
    const [nip, setNip] = React.useState("");
    const [signatureEncoded, setSignatureEncoded] = React.useState("");
    const [phone1, setPhone1] = React.useState("");
    const [municipalitiesList, setMunicipalitiesList] = React.useState<municipalitiesValue[]>([]);
    const [token, setToken] = React.useState<string>('');
    const dispatch = useDispatch();
    const [imageURL, setImageURL] = React.useState('');
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const signCanvas = React.useRef() as React.MutableRefObject<any>;
    const [portabilityData, setPortabilityData] = React.useState<any>();
    const [hasRequest, setHasRequest] = React.useState(false);
    const [isDisabled, setisDisabled] = React.useState(false);
    const [isSignatureEmpty, setIsSignatureEmpty] = React.useState(false);

    const [alertMessage, setAlertMessage] = React.useState<string>('');
    const [alertTitle, setAlertTitle] = React.useState<string>('');
    const [showAlert, setShowAlert] = React.useState<boolean>(false);

    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract', 'idCliente'])) {
                setIdCliente(currentContract.currentContract.idCliente);
            }
        }
    }, [currentContract]);

    useEffect(() => {
        let createdDate = moment().format("YY/MM/DD");
        setCreatedDate(createdDate);
    }, []);

    useEffect(() => {
        if (login) {
            if (_.hasIn(login, ['auth', 'token'])) {
                setToken(login.auth.token);
            }
        }

    }, [login]);

    useEffect(() => {
        if (token) {
            consultarPortabilidad();
        }
    }, [token]);

    useEffect(() => {
        if (portabilityData) {
            setPersonType(portabilityData.tipo_persona);
            setBusinessName(portabilityData.nombre);
            setPaternalSurname(portabilityData.apaterno);
            setMaternalSurname(portabilityData.amaterno);
            setCurp(portabilityData.curp);
            setbirthDate(portabilityData.fecha_nacimiento);
            setState(portabilityData.estado);
            changeState(portabilityData.estado);
            setEmail(portabilityData.email);
            setMunicipality(portabilityData.ciudad);
            setPortingDate(portabilityData.fecha_portacion);
            if (portabilityData.proveedor_donador_id === '125' || portabilityData.proveedor_donador_id === '144' || portabilityData.proveedor_donador_id === '155') {
                setServiceType(portabilityData.proveedor_donador_id)
            } else {
                setServiceType("Otro");
                setOtherName(portabilityData.proveedor_donador);
                setDigitosIDD(portabilityData.proveedor_donador_id);
            }
            setNip(portabilityData.nip_personal)
            setImageURL(portabilityData.firma)
            setSignatureEncoded(portabilityData.firma)
            setPhone1(portabilityData.telefono_portabilidad)
            if (portabilityData.tipo_servicio === 'FIJO' && portabilityData.descripcion_tipo_servicio === 'POSPAGO (FACTURA MENSUAL)') {
                setTypeFijoPospago(true)
            }
            if (portabilityData.tipo_servicio === 'FIJO' && portabilityData.descripcion_tipo_servicio === 'PREPAGO (TARJETA)') {
                setTypeFijoPrepago(true)
            }
            if (portabilityData.tipo_servicio === 'MOVIL' && portabilityData.descripcion_tipo_servicio === 'POSPAGO (PLAN, CONTRATO)') {
                setTypeMovilPospago(true)
            }
            if (portabilityData.tipo_servicio === 'MOVIL' && portabilityData.descripcion_tipo_servicio === 'PREPAGO') {
                setTypeMovilPrepago(true)
            }
            if (portabilityData.tipo_servicio === 'NUMERO NO GEOGRAFICO' && portabilityData.descripcion_tipo_servicio === '01 800 + 7 DIGITOS / 01 900 + 7 DIGITOS, ETC.') {
                setTypeNoGeographic(true)
            }
        }
    }, [portabilityData]);

    const consultarPortabilidad = () => {
        if (token) {
            let sucursal = idCliente.substring(0, 3);
            let suscriptor = idCliente.substring(3, 10);
            checkToken(() => {
                apiV2.getApiV2ValidaPortabilidadSucursalSuscriptor(sucursal, suscriptor, { headers: { "Authorization": 'Bearer: ' + token } })
                    .then(response => {
                        if (response.data && (response.data.estatus === ("POR") || response.data.estatus === ("PRO"))) {
                            setPortabilityData(response.data.datos)
                            setisDisabled(true);
                        } else if (response.data.estatus === "ACT") {
                            setHasRequest(true);
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
            })
        }
    }

    const personTypes = [
        {
            value: 'Fisica',
            label: 'Persona Física'
        },
        {
            value: 'Moral',
            label: 'Persona Moral'
        },
    ];

    const currentServiceType: { value: string, label: string }[] = [
        { value: "125", label: "Teléfonos de México S.A.B. de C.V." },
        { value: "144", label: "Maxcom Telecomunicaciones, S.A. de C.V." },
        { value: "155", label: "Axtel, S.A.B. de C.V." },
        { value: "Otro", label: "Otro" }
    ];

    const disclaimer1 = "1.- “El Usuario acepta que con la firma de la presente Solicitud de Portabilidad manifiesta su consentimiento de terminar la relación contractual con su actual proveedor de servicios, únicamente en lo que respecta a los servicios de telecomunicaciones cuya prestación requiere de los números telefónicos a ser portados, a partir de la fecha efectiva en que se realice la portabilidad de los mismos”.";
    const disclaimer2 = "2.- “El Usuario acepta que el portar sus(s) números, no lo exime del cumplimiento de las obligaciones que haya contraído por la relación contractual con su actual proveedor de servicios y en su caso con su proveedor de larga distancia internacional, por lo que de manera enunciativa, mas no limitativa, se compromete a pagar los adeudos pendientes, devolver los equipos de telecomunicaciones que sean propiedad del Proveedor Donador y pagar las penalizaciones por terminaciones anticipadas que, en su caso, se hubieran convenido”.";
    const disclaimer3 = "3.- “El Usuario reconoce que la Portabilidad de(los) número(s) solicitada está sujeta al cumplimiento de todos los requisitos establecidos de las Reglas de Portabilidad”.";
    const disclaimer4 = "4.- “El firmante declara bajo protesta de decir la verdad que los datos asentados en la presente solicitud y, en su caso, los documentos que la acompañan son verdaderos”.";
    const disclaimer5 = "5.- “El Usuario reconoce que la Portabilidad se aplica únicamente del Servicio Fijo al Servicio Fijo; del Servicio Móvil al Servicio Móvil bajo la misma Modalidad de Contratación (“El Que Llama Paga” a “El Que Llama Paga” o, en su caso, de “El Que Recibe Paga” a “El Que Recibe Paga”); de un Servicio No Geográfico al mismo Servicio No Geográfico, y del Servicio de Acceso a la Red Pública Telefónica que proporcionan las redes del Servicio Móvil de Radiocomunicación Especializada de Flotillas, al Servicio Móvil bajo la modalidad de contratación “El Que Recibe Paga”.";
    const disclaimer6 = "6.- “El horario para que el Usuario solicite el trámite de Portabilidad presentando al efecto el Formato de Solicitud de Portabilidad respectivo, será de las 11:00 horas a las 17:00 horas, de lunes a sábado. En caso de que la solicitud de portabilidad se presente después de las 17:00 horas del día respectivo o en día domingo, la solicitud se considerará como ingresada formalmente al Día Hábil siguiente”.";
    const disclaimer7 = "7.- “Los Datos Personales del Usuario contenidos o que acompañen el Formato de Solicitud de Portabilidad, serán tratados con base en lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de Particulares y exclusivamente podrán ser utilizados para la realización del trámite de portabilidad respectivo”.";
    const disclaimer8 = "8.- “El consentimiento que en su caso hubiere otorgado previamente el Usuario al Proveedor Donador u Concesionario Donador, para la recepción de llamadas de promoción de servicios o paquetes, en términos de lo establecido por el artículo 191 fracción XIX de la Ley, se tendrá por revocado con la presentación de esta solicitud”.Para mayor información sobre la Portabilidad Numérica ingrese a la página www.ift.org.mx o marque al número 01-800-2000120";

    const handleServiceType = (type: string) => {
        setTypeFijoPospago(false);
        setTypeFijoPrepago(false);
        setTypeMovilPospago(false);
        setTypeMovilPrepago(false);
        setTypeNoGeographic(false);
        if (type === 'typeFijoPospago') {
            setTypeFijoPospago(true)
        }
        if (type === 'typeFijoPrepago') {
            setTypeFijoPrepago(true)
        }
        if (type === 'typeMovilPospago') {
            setTypeMovilPospago(true)
        }
        if (type === 'typeMovilPrepago') {
            setTypeMovilPrepago(true)
        }
        if (type === 'typeNoGeographic') {
            setTypeNoGeographic(true)
        }
    };

    const changeState = (text: string) => {
        const filteredCities = estadoCiudadData().filter((data) => data.state === text);
        const municipalitiesList: Array<municipalitiesValue> = [];
        filteredCities.forEach(city => {
            municipalitiesList.push({
                name: city.state,
                value: city.city,
            })
        });
        setMunicipalitiesList(municipalitiesList);
    };

    const updateFormatDate = (date: string) => {
        var numberRaw = date.replace(/[^0-9]/g, '')
        var numberFormated = numberRaw.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")
        return numberFormated
    };

    const validateDate = (inputString: any) => {
        let valid = false;
        let reg = new RegExp("^(?:[0-9]{2})?[0-9]{2}/(1[0-2]|0?[1-9])/(3[01]|[12][0-9]|0[1-9])$");
        let result = reg.test(inputString)
        if (result) {
            let splitResult = inputString.split("/");
            if (splitResult[1] <= 12 && splitResult[1] >= 1) {
                if (splitResult[1] === 2) {
                    let febDays = (splitResult[0] % 4 === 0) ? 29 : 28;
                    if (splitResult[2] <= febDays && splitResult[2] >= 1) {
                        valid = true;
                    }
                } else if (splitResult[1] === 1 || splitResult[1] === 3 || splitResult[1] === 5 || splitResult[1] === 7 || splitResult[1] === 8 || splitResult[1] === 10 || splitResult[1] === 12) {
                    if (splitResult[2] <= 31 && splitResult[2] >= 1) {
                        valid = true;
                    }
                } else {
                    if (splitResult[2] <= 30 && splitResult[2] >= 1) {
                        valid = true;
                    }
                }
            }
        }
        return valid;
    }

    const hideAlert = () => {
        dispatch(AlertRedux.hideAlert());
        setShowAlert(false);
    }

    const AlertDialog = () => {
        return (
            <div className={classes.root}>
                <Dialog
                    open={showAlert}
                    onClose={hideAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {alertTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ whiteSpace: 'pre-line' }} id="alert-dialog-description">
                            {alertMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className={classes.buttonFinish} onClick={hideAlert} autoFocus>
                            <span style={{ margin: '0px 0px 0.35em', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: 'normal', fontSize: '1rem', lineHeight: '1.5', letterSpacing: '0.00938em' }}>
                                <Box fontWeight={700} color="white">OK</Box>
                            </span>
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    const validateCurp = (inputString: string) => {
        let valid = false;
        let reg = new RegExp("^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$")
        let result = reg.test(inputString)
        if (result)
            valid = true
        else
            valid = false
        return valid;
    }

    const clear = () => signCanvas.current.clear();
    const save = () => {
        setImageURL(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        if (signCanvas.current.isEmpty()) {
            setIsSignatureEmpty(true);
        } else {
            setIsSignatureEmpty(false);
        }
    }

    const handleClose = () => {
        save();
        let codeTmp = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        let codeSplit = codeTmp.split(',')
        setSignatureEncoded(codeSplit[1])
        setShowModal(false);
    };

    const validate = async () => {
        let sucursal = idCliente.substring(0, 3);
        let suscriptor = idCliente.substring(3, 10);
        let error = "";
        let errorUpdate = "";
        let allValidated = false;
        let validadoPhone = false;
        let validadoCurp = false;
        let validadoBirthDate = false;
        let validadoPortingDate = false;
        let validadoSignature = false;
        let validadoRazonSocial = false;
        let validadoPortabilityType = false;
        let validadoServiceType = false;
        let validadoNip = false;
        let validadoEmail = false;

        if (personType === "") {
            error += "No se seleccionó el tipo de persona.\n";
        }
        if (businessName === "") {
            error += "El nombre está vacío.\n";
        }
        if (paternalSurname === "") {
            error += "El apellido paterno está vacío.\n";
        }
        if (maternalSurname === "") {
            error += "El apellido materno está vacío.\n";
        }
        if (curp === "") {
            error += "La CURP está vacía.\n";
        } else {
            let result = validateCurp(curp);
            if (result) {
                validadoCurp = true;
            } else {
                error += "El formato de la CURP es incorrecto.\n";
            }
        }
        if (birthDate === "") {
            error += "La fecha de nacimiento está vacía.\n";
        } else {
            let result = validateDate(birthDate);
            if (result) {
                validadoBirthDate = true;
            } else {
                error += "La fecha de nacimiento es incorrecta.\n";
            }
        }
        if (state === "") {
            error += "No se seleccionó un estado.\n";
        }
        if (municipality === "") {
            error += "No se seleccionó una ciudad.\n";
        }
        if (typeFijoPospago || typeFijoPrepago || typeMovilPospago || typeMovilPrepago || typeNoGeographic) {
            validadoPortabilityType = true;
        } else {
            error += "Debe estar marcado al menos un tipo de servicio.\n";
        }
        if (_.isEmpty(portingDate)) {
            error += "La fecha de portación está vacía.\n";
        } else {
            let result = validateDate(portingDate);
            if (result) {
                validadoPortingDate = true;
            } else {
                error += "La fecha de portación es incorrecta.\n";
            }
        }
        if (serviceType) {
            if (serviceType === "Otro") {
                // let reg = RegExp("^[0-9]{3}$");
                // let regexValidate = reg.test(digitosIDD);
                if (otherName === "") {
                    error += "El campo Nombre del servicio actual está vacío.\n";
                } else {
                    validadoServiceType = true;
                }
                // if (digitosIDD === "") {
                //     error += "El campo 3 dígitos IDD del servicio actual está vacío.\n";
                // } else {
                //     if (!regexValidate) {
                //         error += "El campo 3 dígitos IDD debe ser de 3 dígitos.\n";
                //     }
                // }
                // if (!_.isEmpty(otherName) && !_.isEmpty(digitosIDD) && regexValidate) {
                //     validadoServiceType = true;
                // }
            } else {
                validadoServiceType = true;
            }
        } else {
            error += "No se ha seleccionado la empresa prestadora de servicios actual.\n";
        }
        var reg = RegExp("^[0-9]{4}$");
        let regexValidate = reg.test(nip);
        if (nip === "") {
            error += "El nip está vacío.\n";
            errorUpdate += "El nip está vacío.\n";
        } else {
            if (!regexValidate) {
                error += "El campo Nip debe ser de 4 dígitos.\n";
                errorUpdate += "El campo Nip debe ser de 4 dígitos.\n";
            }
        }
        if (!_.isEmpty(nip) && regexValidate) {
            validadoNip = true;
        }
        if (_.isEmpty(signatureEncoded)) {
            error += "La firma está vacía.\n";
        } else {
            if (isSignatureEmpty) {
                error += "La firma está vacía.\n";
            } else {
                validadoSignature = true;
            }
            // let emptySignatureFireFox = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQIW2NgAAIAAAUAAR4f7BQAAAAASUVORK5CYII=";
            // let emptySignatureOperaChrome = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
            // if ((signatureEncoded !== emptySignatureFireFox) || (signatureEncoded !== emptySignatureOperaChrome)) {
            //     validadoSignature = true;
            // } else {
            //     error += "La firma está vacía.\n";
            // }
        }
        if (_.isEmpty(phone1)) {
            error += "El teléfono está vacío.\n";
            errorUpdate += "El teléfono está vacío.\n";
        } else {
            if (phone1.length === 10) {
                validadoPhone = true;
            } else {
                error += "El número telefónico debe contener 10 dígitos.\n";
                errorUpdate += "El número telefónico debe contener 10 dígitos.\n";
            }
        }
        if (_.isEmpty(email)) {
            error += "El correo electrónico está vacío.\n";
            errorUpdate += "El correo electrónico está vacío.\n";
        } else {
            let isEmailValid = regexEmail.test(email)
            if (!isEmailValid) {
                error += "El correo electrónico no es válido.\n";
                errorUpdate += "El correo electrónico no es válido.\n";
            } else {
                validadoEmail = true;
            }
        }
        if (error !== "" && !isDisabled) {
            setAlertMessage(error);
            setAlertTitle('Portabilidad');
            setShowAlert(true)
        }
        if ((validadoCurp || validadoRazonSocial) && validadoPhone && validadoBirthDate && validadoPortingDate && validadoSignature && validadoServiceType && validadoNip && validadoPortabilityType && validadoEmail) {
            allValidated = true;
        }
        if (isDisabled) {
            if (validadoNip && validadoPhone && validadoEmail) {
                let objActualizaDatos = {
                    sucursal: sucursal,
                    suscriptor: suscriptor,
                    origen: 'WEB',
                    email: email,
                    nip_personal: nip,
                    telefono_portabilidad: phone1
                }
                checkToken(() => {
                    apiV2.postApiV2ActualizaDatosPortarLinea(objActualizaDatos, { headers: { "Authorization": 'Bearer: ' + token } })
                        .then(response => {
                            if (response.data && response.data.mensaje) {
                                dispatch(AlertRedux.showAlert(response.data.mensaje, "Portabilidad"))
                            } else {
                                dispatch(AlertRedux.showAlert('Hubo un error al intentar procesar su solicitud, intente más tarde.', "Portabilidad"))
                            }
                        })
                        .catch(error => {
                            error = _.get(error, 'response', error);
                            dispatch(AlertRedux.showAlert('Hubo un error al intentar procesar su solicitud, intente más tarde.', "Portabilidad"))
                        })
                })
            } else {
                dispatch(AlertRedux.showAlert(errorUpdate, "Portabilidad"));
            }
        }
        else if (allValidated) {
            let completeName;
            if (personType === "legalEntity") {
                completeName = businessName;
            } else {
                completeName = businessName + " " + paternalSurname + " " + maternalSurname;
            }

            let objPortabilityData = {
                idCliente: idCliente,
                createdDate: createdDate,
                personType: personType,
                fullName: completeName,
                businessName: businessName,
                paternalSurname: paternalSurname,
                maternalSurname: maternalSurname,
                curp: curp,
                birthDate: birthDate,
                state: state,
                municipality: municipality,
                typeFijoPospago: typeFijoPospago,
                typeFijoPrepago: typeFijoPrepago,
                typeMovilPospago: typeMovilPospago,
                typeMovilPrepago: typeMovilPrepago,
                typeNoGeographic: typeNoGeographic,
                portingDate: portingDate,
                serviceType: serviceType,
                iddDigits: digitosIDD,
                otherName: otherName,
                nip: nip,
                signatureEncoded: signatureEncoded,
                phone1: phone1
            }

            let tipoServicio = '';
            let descripcionTipoServicio = '';
            let proveedorDonador = '';
            // let proveedorDonadorId = '';

            if (serviceType !== "Otro") {
                if (serviceType === '125') {
                    proveedorDonador = 'Teléfonos de México S.A.B. de C.V.';
                    // proveedorDonadorId = '125';
                }
                if (serviceType === '144') {
                    proveedorDonador = 'Maxcom Telecomunicaciones, S.A. de C.V.';
                    // proveedorDonadorId = '144';
                }
                if (serviceType === '155') {
                    proveedorDonador = 'Axtel, S.A.B. de C.V.';
                    // proveedorDonadorId = '155';
                }
            } else {
                proveedorDonador = otherName;
                // proveedorDonadorId = digitosIDD;
            }

            if (typeFijoPospago || typeFijoPrepago) {
                tipoServicio = 'FIJO';
                if (typeFijoPospago) {
                    descripcionTipoServicio = 'POSPAGO (FACTURA MENSUAL)';
                } else {
                    descripcionTipoServicio = 'PREPAGO (TARJETA)';
                }
            }
            if (typeMovilPospago || typeMovilPrepago) {
                tipoServicio = 'MOVIL';
                if (typeMovilPospago) {
                    descripcionTipoServicio = 'POSPAGO (PLAN, CONTRATO)';
                } else {
                    descripcionTipoServicio = 'PREPAGO';
                }
            }
            if (typeNoGeographic) {
                tipoServicio = 'NUMERO NO GEOGRAFICO';
                descripcionTipoServicio = '01 800 + 7 DIGITOS / 01 900 + 7 DIGITOS, ETC.';
            }

            let objPortarLinea = {
                sucursal: sucursal,
                suscriptor: suscriptor,
                origen: 'WEB',
                tipo_persona: personType,
                nombre: businessName,
                apaterno: paternalSurname,
                amaterno: maternalSurname,
                curp: curp,
                fecha_nacimiento: birthDate,
                estado: state,
                ciudad: municipality,
                tipo_servicio: tipoServicio,
                descripcion_tipo_servicio: descripcionTipoServicio,
                fecha_portacion: portingDate,
                proveedor_donador: proveedorDonador,
                // proveedor_donador_id: proveedorDonadorId,
                proveedor_donador_id: "",
                proveedor_receptor: 'Megacable, S.A. de C.V. 199',
                email: email,
                nip_personal: nip,
                firma: signatureEncoded,
                telefono_portabilidad: phone1
            }

            checkToken(() => {
                apiV2.postApiV2PortarLinea(objPortarLinea, { headers: { "Authorization": 'Bearer: ' + token } })
                    .then(response => {
                        if (response.data && response.data.mensaje) {
                            dispatch(AlertRedux.showAlert(response.data.mensaje, "Portabilidad"))
                            apiV2.postApiV2ObtPdfPortability(objPortabilityData, { headers: { "Authorization": 'Bearer: ' + token } })
                            consultarPortabilidad();
                        } else {
                            dispatch(AlertRedux.showAlert('Hubo un error al intentar procesar su solicitud, intente más tarde.', "Portabilidad"))
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                        dispatch(AlertRedux.showAlert('Hubo un error al intentar procesar su solicitud, intente más tarde.', "Portabilidad"))
                    })
            })
        }
    }

    return (
        <div className={clsx(classes.animated)}>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '70px 0px', paddingRight: '10%' }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 40 }}>
                    <img style={{ maxHeight: '140px', maxWidth: '140px', marginBottom: 16 }} alt={'Actualizar datos'} src={'../icon-portabilidad@4.png'} />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={9} style={{ padding: 16 }}>
                    {
                        hasRequest ?
                            <Help type={"attention"} helpStyle={"outline"} text1="¡Atención!" text2='Por el momento no es posible generar una solicitud nueva, ya que tiene una activa.' additionalStyle={{ margin: "10px 10px" }} />
                            :
                            <>
                                <p className={classes.textName}>ESTIMADO SUSCRIPTOR</p>
                                <p style={{ textAlign: 'justify', color: "gray" }}>Antes de comenzar a llenar el formulario de portabilidad, puede consultar los beneficios y requisitos en:</p>
                                <Link href={"https://www.megacable.com.mx/telefonia"} style={{ color: "#0797fd" }}>
                                    {"https://www.megacable.com.mx/telefonia"}
                                </Link>
                                <div style={{ marginTop: '30px' }}>
                                    <p className={classes.textName}>DATOS DEL SUSCRIPTOR</p>
                                    <FormLabel className={classes.label} component="legend">Tipo de persona</FormLabel>
                                    <TextField
                                        id="option"
                                        select
                                        value={personType}
                                        onChange={(event) => setPersonType(event.target.value)}
                                        className={classes.textfields}
                                        disabled={isDisabled}
                                        variant='filled'
                                    >
                                        {personTypes.map((option) => (
                                            option.value && option.label ?
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                :
                                                null
                                        ))}
                                    </TextField>
                                    <FormLabel className={classes.label} component="legend">Nombre / Razón social</FormLabel>
                                    <TextField
                                        value={businessName}
                                        name="businessName"
                                        placeholder='Nombre / Razón social'
                                        variant="outlined"
                                        autoComplete='notAutoComplete'
                                        fullWidth
                                        disabled={isDisabled}
                                        style={{ marginBottom: 16 }}
                                        onChange={(event) => setBusinessName(event.target.value)}
                                    />
                                    <FormLabel className={classes.label} component="legend">Apellido paterno</FormLabel>
                                    <TextField
                                        value={paternalSurname}
                                        name="paternalSurname"
                                        placeholder='Apellido paterno'
                                        variant="outlined"
                                        autoComplete='notAutoComplete'
                                        fullWidth
                                        disabled={isDisabled}
                                        style={{ marginBottom: 16 }}
                                        onChange={(event) => setPaternalSurname(event.target.value)}
                                    />
                                    <FormLabel className={classes.label} component="legend">Apellido materno</FormLabel>
                                    <TextField
                                        value={maternalSurname}
                                        name="maternalSurname"
                                        placeholder='Apellido materno'
                                        variant="outlined"
                                        autoComplete='notAutoComplete'
                                        fullWidth
                                        disabled={isDisabled}
                                        style={{ marginBottom: 16 }}
                                        onChange={(event) => setMaternalSurname(event.target.value)}
                                    />
                                    <FormLabel className={classes.label} component="legend">CURP</FormLabel>
                                    <TextField
                                        value={curp}
                                        name="curp"
                                        placeholder='CURP'
                                        variant="outlined"
                                        autoComplete='notAutoComplete'
                                        fullWidth
                                        disabled={isDisabled}
                                        inputProps={{ maxLength: 18 }}
                                        style={{ marginBottom: 16 }}
                                        onChange={(event) => setCurp(event.target.value)}
                                    />
                                    <FormLabel className={classes.label} component="legend">Fecha de nacimiento</FormLabel>
                                    <TextField
                                        value={birthDate}
                                        name="birthDate"
                                        placeholder="AAAA/MM/DD"
                                        variant="outlined"
                                        autoComplete='notAutoComplete'
                                        fullWidth
                                        disabled={isDisabled}
                                        inputProps={{ maxLength: 8 }}
                                        style={{ marginBottom: 16 }}
                                        onChange={(event) => setbirthDate(updateFormatDate(event.target.value))}
                                    />
                                    <FormLabel className={classes.label} component="legend">Estado</FormLabel>
                                    <TextField
                                        select
                                        value={state}
                                        onChange={(e) => {
                                            setState(e.target.value as string)
                                            changeState(e.target.value as string)
                                        }}
                                        className={classes.textfields}
                                        variant='filled'
                                        disabled={isDisabled}
                                    >
                                        {states.map((option: any) => (
                                            option.value ?
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                                :
                                                null
                                        ))}
                                    </TextField>
                                    {
                                        state ?
                                            <>
                                                <FormLabel className={classes.label} component="legend">Ciudad</FormLabel>
                                                <TextField
                                                    select
                                                    value={municipality}
                                                    onChange={(e) => setMunicipality(e.target.value as string)}
                                                    className={classes.textfields}
                                                    variant='filled'
                                                    disabled={isDisabled}
                                                >
                                                    {municipalitiesList.map((option) => (
                                                        option.value ?
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.value}
                                                            </MenuItem>
                                                            :
                                                            null
                                                    ))}
                                                </TextField>
                                            </>
                                            :
                                            null
                                    }
                                </div>
                                <p className={classes.textName}>TIPO DE SERVICIO EN EL QUE DESEA REALIZAR LA PORTABILIDAD</p>
                                <p className={classes.textName}>A) FIJO</p>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={typeFijoPospago}
                                            onChange={() => handleServiceType("typeFijoPospago")}
                                            name="generateBill"
                                            color="primary"
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={"POSPAGO (FACTURA MENSUAL)"}
                                    style={{ fontWeight: 'bold', color: "#004fc0" }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={typeFijoPrepago}
                                            onChange={() => handleServiceType("typeFijoPrepago")}
                                            name="generateBill"
                                            color="primary"
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={"PREPAGO (TARJETA)"}
                                    style={{ fontWeight: 'bold', color: "#004fc0" }}
                                />
                                <p className={classes.textName}>B) MÓVIL</p>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={typeMovilPospago}
                                            onChange={() => handleServiceType("typeMovilPospago")}
                                            name="generateBill"
                                            color="primary"
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={"POSPAGO (PLAN, CONTRATO)"}
                                    style={{ fontWeight: 'bold', color: "#004fc0" }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={typeMovilPrepago}
                                            onChange={() => handleServiceType("typeMovilPrepago")}
                                            name="generateBill"
                                            color="primary"
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={"PREPAGO"}
                                    style={{ fontWeight: 'bold', color: "#004fc0" }}
                                />
                                <p className={classes.textName}>C) NÚMERO NO GEOGRÁFICO</p>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={typeNoGeographic}
                                            onChange={() => handleServiceType("typeNoGeographic")}
                                            name="generateBill"
                                            color="primary"
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={"01 800 + 7 DÍGITOS / 01 900 + 7 DÍGITOS, ETC."}
                                    style={{ fontWeight: 'bold', color: "#004fc0", marginBottom: '30px' }}
                                />
                                <FormLabel className={classes.label} component="legend">Fecha en que se solicita se ejecute la portación</FormLabel>
                                <TextField
                                    value={portingDate}
                                    name="portingDate"
                                    placeholder='AAAA/MM/DD'
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    disabled={isDisabled}
                                    inputProps={{ maxLength: 8 }}
                                    style={{ marginBottom: 16 }}
                                    onChange={(event) => setPortingDate(updateFormatDate(event.target.value))}
                                />
                                <p className={classes.textName}>DATOS DEL PROVEEDOR DE SERVICIOS DE TELECOMUNICACIONES</p>
                                <FormLabel className={classes.label} component="legend">Empresa prestadora de servicios actual (PROVEEDOR DONADOR)</FormLabel>
                                <TextField
                                    id="option"
                                    select
                                    value={serviceType}
                                    onChange={(event) => setServiceType(event.target.value)}
                                    className={classes.textfields}
                                    variant='filled'
                                    disabled={isDisabled}
                                >
                                    {currentServiceType.map((option) => (
                                        option.value && option.label ?
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                            :
                                            null
                                    ))}
                                </TextField>
                                {
                                    serviceType === "Otro" ?
                                        <>
                                            <FormLabel className={classes.label} component="legend">Nombre del servicio actual</FormLabel>
                                            <TextField
                                                value={otherName}
                                                name="Nombre"
                                                placeholder='Nombre'
                                                variant="outlined"
                                                autoComplete='notAutoComplete'
                                                fullWidth
                                                disabled={isDisabled}
                                                style={{ marginBottom: 16 }}
                                                onChange={(event) => setOtherName(event.target.value)}
                                            />
                                            {/* <FormLabel className={classes.label} component="legend">3 dígitos IDD</FormLabel>
                                            <TextField
                                                value={digitosIDD}
                                                name="idd"
                                                placeholder='IDD del servicio actual'
                                                variant="outlined"
                                                autoComplete='notAutoComplete'
                                                fullWidth
                                                disabled={isDisabled}
                                                inputProps={{ maxLength: 3 }}
                                                style={{ marginBottom: 16 }}
                                                onChange={(event) => setDigitosIDD(event.target.value.replace(/[^0-9]/g, ''))}
                                            /> */}
                                        </>
                                        :
                                        null
                                }
                                <FormLabel className={classes.label} component="legend">Empresa prestadora de servicios actual (PROVEEDOR RECEPTOR)</FormLabel>
                                <TextField
                                    value={"Megacable, S.A. de C.V. 199"}
                                    name="newProvider"
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    disabled={isDisabled}
                                    style={{ marginBottom: 16 }}
                                />
                                <FormLabel className={classes.label} component="legend">Número de identificación personal (NIP)</FormLabel>
                                <TextField
                                    value={nip}
                                    name="NIP"
                                    placeholder='NIP'
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    inputProps={{ maxLength: 4 }}
                                    style={{ marginBottom: 16 }}
                                    onChange={(event) => setNip(event.target.value.replace(/[^0-9]/g, ''))}
                                />
                                {
                                    isDisabled ? null :
                                        <>
                                            <p className={classes.textName}>AGREGAR FIRMA</p>
                                            <img
                                                src={imageURL}
                                                alt=""
                                                style={{
                                                    display: "block",
                                                    margin: "0 auto",
                                                    border: "1px solid black",
                                                    width: "100%",
                                                    minHeight: '300px',
                                                    maxHeight: '400px',
                                                    maxWidth: '600px'
                                                }}
                                            />
                                            <Dialog onClose={() => { }} aria-labelledby="customized-dialog-title" open={showModal}>
                                                <SignaturePad
                                                    ref={signCanvas}
                                                    canvasProps={{
                                                        className: classes.signatureCanvas
                                                    }}
                                                />
                                                <MuiDialogActions className={classes.dialogActionsContent}>
                                                    <Button type={'primary'} text="Guardar" size="large" onClick={handleClose} />
                                                    <Button type={'primary'} text="Editar" size="large" onClick={() => clear()} />
                                                </MuiDialogActions>
                                            </Dialog>
                                            <Button disabled={isDisabled} type={isDisabled ? 'disabled' : 'primary'} text="Firmar" size="large" onClick={() => setShowModal(true)} />
                                        </>
                                }
                                <p className={classes.textName}>NÚMERO TELEFÓNICO A SER PORTADO</p>
                                <FormLabel className={classes.label} component="legend">Teléfono</FormLabel>
                                <TextField
                                    value={phone1}
                                    type='tel'
                                    name="phone"
                                    placeholder='Teléfono'
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    inputProps={{ maxLength: 10 }}
                                    onChange={(event) => setPhone1(event.target.value.replace(/[^0-9]/g, ''))}
                                />
                                <FormLabel className={classes.label} component="legend">Correo electrónico</FormLabel>
                                <TextField
                                    value={email}
                                    type='email'
                                    name="email"
                                    placeholder='Correo electrónico'
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                                <p className={classes.textName}>NOTAS</p>
                                <Typography type="p" text={disclaimer1} />
                                <Typography type="p" text={disclaimer2} />
                                <Typography type="p" text={disclaimer3} />
                                <Typography type="p" text={disclaimer4} />
                                <Typography type="p" text={disclaimer5} />
                                <Typography type="p" text={disclaimer6} />
                                <Typography type="p" text={disclaimer7} />
                                <Typography type="p" text={disclaimer8} />
                                <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: '20px' }}>
                                    <Button disabled={false} type={'primary'} text="GUARDAR" size="large" onClick={() => validate()} />
                                </div>
                            </>
                    }
                </Grid>
                <AlertDialog />
            </Grid>
        </div>
    )
}

const styles = makeStyles(({ palette, breakpoints }: Theme) => createStyles({
    animated: {
        position: 'relative',
        left: '5%',
        animationName: '$fadein',
        animationDuration: '.5s',
        animationFillMode: 'forwards',
        transition: 'All 500ms ease-in-out',
        Webkittransition: 'All 500ms ease-in-out',
        MozTransition: 'All 500ms ease-in-out',
        OTransition: 'All 500ms ease-in-out',
        backgroundColor: 'white'
    },
    '@keyframes fadein': {
        '0%': {
            display: 'none',
            position: 'relative',
            left: '2%',
        },
        '100%': {
            position: 'relative',
            left: '0%',
        },
    },
    label: {
        color: 'inherit',
        paddingLeft: '5px',
        paddingBottom: '10px'
    },
    textName: {
        fontWeight: "bold",
        display: "block",
        fontSize: "18px",
        fontFamily: "Sarabun",
        height: '1.09em',
        color: "#002A4F",
        textTransform: 'uppercase',
        lineHeight: 1
    },
    textfields: {
        width: '100%',
        paddingBottom: '20px',
        [breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    signatureCanvas: {
        border: '1px solid gray',
        width: '100%',
        minHeight: '400px',
    },
    dialogActionsContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        flex: 1
    },
    root: {
        display: "flex",
        flexDirection: "column",
        borderColor: "#b7b7b7",
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        cursor: "pointer",
    },
    buttonFinish: {
        backgroundColor: "#0797fd",
        borderRadius: 5,
        border: 0,
        padding: 8,
        width: '60px',
        "&:hover": {
            backgroundColor: palette.primary.main,
            color: "#fff"
        },
        "& .MuiTouchRipple-root span": {
            backgroundColor: "#6fc3fc",
        },
    },
}));

const mapState = (state: any) => ({
    login: state.login,
    currentContract: state.currentContract,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({

});

const connector = connect(mapState, mapDispatch)
export default (connector(FormPortability));