import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Input from '../../components/Input';
import Button from '../../components/Button';
import AlertDialog from '../../components/AlertDialog';
import { useParams } from 'react-router';
// import { useHistory } from 'react-router-dom'
import awsmobile from '../../aws-exports';
import * as api from 'megacable-mobile-api';
import _ from 'lodash';
import { ResetPassword } from 'megacable-mobile-api/models';
import { useDispatch } from 'react-redux';
import { AlertRedux } from 'megacable-mobile-library';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

const apiV2 = new api.V2Api({}, baseUrl);

const styles = () => createStyles({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(90deg, #0078D0 0%, #004FC0 100%)'
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        height: '60px',
        width: '320px',
    },
    title: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '25px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: '10px'
    },
    text: {
        color: 'white',
        display: 'flex',
        justifyContent: 'space-around'
    },
    text2: {
        color: 'white',
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '25px'
    },
    inputContainer: {
        paddingTop: '25px'
    }
});

interface ResetPasswordProps extends WithStyles<typeof styles> {

}

type TParams = { resetToken: string };

export const ResetPasswordScreen = ({ classes }: ResetPasswordProps) => {

    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");
    const [display, setDisplay] = React.useState(0);
    const dispatch = useDispatch();
    // const history = useHistory();
    const reg = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')

    let params = useParams<TParams>();

    useEffect(() => {
        if (params && params.resetToken) {
            apiV2.getApiV2ValidateResetToken(params.resetToken)
                .then(response => { })
                .catch(error => {
                    error = _.get(error, 'response', error);
                    if (error && _.has(error, "data.message")) {
                        dispatch(AlertRedux.showAlert(error.data.message, "Recuperar contraseña"))
                    } else {
                        dispatch(AlertRedux.showAlert("Hubo un error al intentar procesar su solicitud, solicite ayuda con el área de soporte a suscriptores.", "Recuperar contraseña"))
                    }
                })
        }
    }, [params])

    function resetPassword() {
        let obj: ResetPassword = {
            newPassword: password,
            resetLink: params.resetToken
        }
        let validPassword = reg.test(password);
        if (password && password2) {
            if (password === password2) {
                if (validPassword) {
                    apiV2.postApiV2ResetPassword(obj)
                        .then(response => {
                            if (response && _.has(response, ['data', "message"])) {
                                dispatch(AlertRedux.showAlert(response.data.message, "Recuperar contraseña"))
                            }
                            setDisplay(1);
                        })
                        .catch(error => {
                            error = _.get(error, 'response', error);
                            if (error && _.has(error, "data.message")) {
                                dispatch(AlertRedux.showAlert(error.data.message, "Recuperar contraseña"))
                            } else {
                                dispatch(AlertRedux.showAlert("Hubo un error al intentar procesar su solicitud, solicite ayuda con el área de soporte a suscriptores.", "Recuperar contraseña"))
                            }
                            setDisplay(2);
                        })
                } else {
                    dispatch(AlertRedux.showAlert("La contraseña debe tener al menos 8 caracteres, un número, una letra mayúscula y un caracter especiales.", "Recuperar contraseña"))
                }
            } else {
                dispatch(AlertRedux.showAlert("La contraseña no coincide", "Recuperar contraseña"))
            }
        } else {
            dispatch(AlertRedux.showAlert("Ambos campos son requeridos", "Recuperar contraseña"))
        }
    }

    return (
        <div className={classes.container}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '100px' }}>
                <div className={classes.logoContainer}>
                    <img src="../megacable-logo.png" alt="" className={classes.logo} />
                </div>
                <p className={classes.title}>Estimado usuario</p>
                {
                    display === 0
                        ?
                        <>
                            <p className={classes.text}>Ha solicitado restablecer su contraseña para</p>
                            <p className={classes.text}>ingresar a servicios en línea.</p>
                            <p className={classes.text2}>La contraseña debe tener al menos 8 caracteres,</p>
                            <p className={classes.text}>un número, una letra mayúscula y un caracter especiales.</p>
                            <p className={classes.text2}>Ingrese y confirme su nueva contraseña</p>
                            <div className={classes.inputContainer}>
                                <Input title="Nueva contraseña*" colorTitle={'white'} type={'password'} placeholder="" value={password} onChange={(e: any) => setPassword(e.target.value)}
                                    onKeyUp={(e) => {
                                        e.persist();
                                        let pressedEnter = (_.find(e, ['key', "Enter"]))
                                        if (pressedEnter) {
                                            resetPassword();
                                        }
                                    }} />
                                <Input title="Repetir nueva contraseña*" colorTitle={'white'} type={'password'} placeholder="" value={password2} onChange={(e: any) => setPassword2(e.target.value)}
                                    onKeyUp={(e) => {
                                        e.persist();
                                        let pressedEnter = (_.find(e, ['key', "Enter"]))
                                        if (pressedEnter) {
                                            resetPassword();
                                        }
                                    }} />
                            </div>
                            <div className={classes.buttonContainer}>
                                <Button type="secondary" text="CONFIRMAR" size="medium" onClick={() => resetPassword()} />
                            </div>
                        </>
                        :
                        display === 1 ?
                            <p className={classes.text}>Su contraseña ha cambiado.</p>
                            :
                            display === 2 ?
                                <p className={classes.text}>Hubo un error al intentar procesar su solicitud, solicite ayuda con el área de soporte a suscriptores.</p>
                                :
                                null
                }
            </Grid>
            <AlertDialog /*onPressHide={() => history.replace("/")}*/ />
        </div>
    )
}

export default withStyles(styles)(ResetPasswordScreen);