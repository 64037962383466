import React, { useEffect } from 'react';
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import { createStyles, Theme } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles } from '@material-ui/core';
import { Col } from 'react-flexbox-grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OptionsButton from '../../components/OptionsButton';
import GoBack from '../../components/GoBack';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { connect, ConnectedProps } from "react-redux";
import { IContract } from '../../storeV2/ducks/types';
import ContractData from '../../components/ContractData';
import { DatosPago } from 'megacable-mobile-api/models';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import moment from 'moment';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import { keycloak } from '../../App';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

type MenuOption = {
    icon: string,
    label: string,
    path: string,
}

type PropsFromRedux = ConnectedProps<typeof connector>

type IPaymentsCenters = PropsFromRedux & {

}


const PaymentsCenters = (props: IPaymentsCenters) => {
    const classes = styles();
    const { pagosData, currentContract } = props;
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [fullName, setFullName] = React.useState<string>('');
    const [paymentData, setPaymentData] = React.useState<DatosPago>();
    const [limitDay, setLimitDay] = React.useState<string>("");
    const [banksArray, setBanksArray] = React.useState<Array<any>>();
    const [pharmacyArray, setPharmacyArray] = React.useState<Array<any>>();
    const [othersArray, setOthersArray] = React.useState<Array<any>>();
    const [superArray, setSuperArray] = React.useState<Array<any>>();
    const [convenienceArray, setConvenienceArray] = React.useState<Array<any>>();
    const [arrayListCenterPayments, setArrayListCenterPayments] = React.useState<Array<any>>();
    let match = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        startAnalytics('cons_pays_center_id');
        return () => {
            logAnalytics('cons_pays_center_id')
        }
    }, []);

    useEffect(() => {
        apiV2.getApiV2PaymentCenters({ headers: { 'Authorization': `Bearer ${keycloak.token}` } })
            .then(response => {
                let arrayCenterPayments = []
                if (response.data) {
                    for (let key in response.data) {
                        if (key === "bancos")
                            arrayCenterPayments.push({ "title": "BANCOS" });
                        if (key === "supermercados")
                            arrayCenterPayments.push({ "title": "SUPERMERCADOS" });
                        if (key === "farmacias")
                            arrayCenterPayments.push({ "title": "FARMACIAS" });
                        if (key === "tiendaConveniencia")
                            arrayCenterPayments.push({ "title": "TIENDAS DE CONVENIENCIA" });
                        if (key === "otrosCentros")
                            arrayCenterPayments.push({ "title": "OTROS" });
                    }
                    setArrayListCenterPayments(arrayCenterPayments);
                    if (response.data.bancos) {
                        setBanksArray(response.data.bancos)
                    }
                    if (response.data.farmacias) {
                        setPharmacyArray(response.data.farmacias)
                    }
                    if (response.data.otrosCentros) {
                        setOthersArray(response.data.otrosCentros)
                    }
                    if (response.data.supermercados) {
                        setSuperArray(response.data.supermercados)
                    }
                    if (response.data.tiendaConveniencia) {
                        setConvenienceArray(response.data.tiendaConveniencia)
                    }
                }
            })
            .catch(err => {
                err = _.get(err, 'response', err);
            })
    }, []);

    useEffect(() => {
        if (paymentData) {
            if (_.hasIn(paymentData, ['datos', 'paymentLimitDate'])) {
                const limitDateTmp = paymentData.datos.paymentLimitDate;
                var check = moment(limitDateTmp, 'DD/MM/YYYY');
                var day = check.format('D');
                if (day) {
                    setLimitDay(day);
                }
            }
        }
        return () => { }
    }, [paymentData])

    useEffect(() => {
        if (contractSelected) {
            if (pagosData && _.hasIn(pagosData, ['datosPago'])) {
                const paymentList: any = pagosData.datosPago;
                if (paymentList && paymentList.suscriptor === contractSelected.idCliente) {
                    setPaymentData(paymentList)
                }
            }
        }
        return () => { }
    }, [contractSelected, pagosData]);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract'])) {
                setContractSelected(currentContract.currentContract);

            }
        }
    }, [currentContract]);

    useEffect(() => {
        if (contractSelected) {
            if (_.hasIn(contractSelected, ['idCliente']) && _.hasIn(contractSelected, ['nombreSuscriptor'])
                && _.hasIn(contractSelected, ['apellidoPaterno']) && _.hasIn(contractSelected, ['apellidoMaterno'])) {
                setIdCliente(contractSelected.idCliente);
                setFullName(`${contractSelected.nombreSuscriptor} ${contractSelected.apellidoPaterno} ${contractSelected.apellidoMaterno}`);
            }
        }
        return () => { }
    }, [contractSelected])

    const menuComponent = (element: any) => {
        let banksArrayTmp: Array<any> = [];
        let pharmacyArrayTmp: Array<any> = [];
        let othersArrayTmp: Array<any> = [];
        let superArrayTmp: Array<any> = [];
        let convenienceArrayTmp: Array<any> = [];
        _.forEach(banksArray, (element) => {
            if (element.imgName === 'citibanamex') {
                banksArrayTmp.push({
                    icon: element.link,
                    label: "",
                    path: `${match.path}/banamex`
                })
            } else if (element.imgName === 'bbva') {
                banksArrayTmp.push({
                    icon: element.link,
                    label: "",
                    path: `${match.path}/bbva`
                })
            } else if (element.imgName === 'banco-santander') {
                banksArrayTmp.push({
                    icon: element.link,
                    label: "",
                    path: `${match.path}/santander`
                })
            } else if (element.imgName === 'scotiabank') {
                banksArrayTmp.push({
                    icon: element.link,
                    label: "",
                    path: `${match.path}/scotiabank`
                })
            } else {
                banksArrayTmp.push({
                    icon: element.link,
                    label: "",
                    path: `${match.path}/oxxo`
                })
            }
        })
        _.forEach(pharmacyArray, (element) => {
            pharmacyArrayTmp.push({
                icon: element.link,
                label: "",
                path: `${match.path}/oxxo`
            })
        })
        _.forEach(othersArray, (element) => {
            othersArrayTmp.push({
                icon: element.link,
                label: "",
                path: `${match.path}/oxxo`
            })
        })
        _.forEach(superArray, (element) => {
            superArrayTmp.push({
                icon: element.link,
                label: "",
                path: `${match.path}/oxxo`
            })
        })
        _.forEach(convenienceArray, (element) => {
            convenienceArrayTmp.push({
                icon: element.link,
                label: "",
                path: `${match.path}/oxxo`
            })
        })
        let arrayRender: Array<any> = []
        if (element === "BANCOS") {
            arrayRender = banksArrayTmp;
        } else if (element === "SUPERMERCADOS") {
            arrayRender = superArrayTmp;
        }
        else if (element === "FARMACIAS") {
            arrayRender = pharmacyArrayTmp;
        }
        else if (element === "TIENDAS DE CONVENIENCIA") {
            arrayRender = convenienceArrayTmp;
        }
        else if (element === "OTROS") {
            arrayRender = othersArrayTmp;
        }
        return arrayRender.map((paymentMenu, index) => (
            <OptionsButton
                noLabel={true}
                key={index}
                icon={paymentMenu.icon}
                label={paymentMenu.label}
                path={paymentMenu.path} />
        ));
    }

    const getLineaBank = (linea: "banamex" | "bbva" | "santander" | "scotiabank" | "oxxo") => {
        if (contractSelected && fullName && idCliente) {
            const idClienteTmp = _.cloneDeep(idCliente);
            const contractSelectedTmp = _.cloneDeep(contractSelected);
            const fullNameTmp = _.cloneDeep(fullName);
            let currentLineaBank = "";
            let convenio = "";

            if (linea === "banamex") {
                currentLineaBank = contractSelectedTmp.lineaBanamex;
            } else if (linea === "bbva") {
                currentLineaBank = contractSelectedTmp.lineaBancomer;
                convenio = "801720";
            } else if (linea === "santander") {
                currentLineaBank = contractSelectedTmp.lineaSantander;
                convenio = "1332";
            } else if (linea === "scotiabank") {
                currentLineaBank = contractSelectedTmp.lineaScotiabank;
                convenio = "3356";
            }
            return (
                <div>
                    <div style={{ backgroundColor: "#002A4F" }}>
                        <GoBack onClick={() => history.push('/payments/paymentsCenters')} />
                    </div>
                    <ContractData
                        suscriptor={idClienteTmp}
                        fullName={fullNameTmp}
                        limitDay={limitDay}
                        linea={currentLineaBank ? currentLineaBank : "Sin información"}
                        convenio={convenio} />
                </div>
            )
        }
    }

    return (
        <div className={classes.root}>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: "#333333" }}>
                    <Switch>
                        <Route
                            exact
                            path={`${match.path}`}
                            render={() => (
                                <div>
                                    <div style={{ backgroundColor: "#002A4F" }}>
                                        <GoBack onClick={() => history.push('/payments')} />
                                    </div>
                                    {/* <div style={{ width: "100%", borderBottom: '1px #d4d4d4 solid', }}></div> */}
                                    {/* {menuComponent()} */}
                                    <div>
                                        {
                                            arrayListCenterPayments && arrayListCenterPayments.map((ob: any, key: any) => {
                                                return (
                                                    <Accordion style={{margin: '0px'}}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon fontSize="large" fontWeight={700} style={{ color: "#00CCFF" }} />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            className={classes.accordion}>
                                                            < Col xs={12} style={{ background: '#333333', color: 'white', padding: '10px', fontSize: 16, display: 'flex', alignItems: 'left', justifyContent: 'left' }}>{ob.title}</Col>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {menuComponent(ob.title)}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })}
                                    </div>
                                </div>
                            )} />
                        <Route
                            exact
                            path={`${match.path}/banamex`}
                            render={() => getLineaBank('banamex')} />
                        <Route
                            exact
                            path={`${match.path}/bbva`}
                            render={() => getLineaBank('bbva')} />
                        <Route
                            exact
                            path={`${match.path}/santander`}
                            render={() => getLineaBank('santander')} />
                        <Route
                            exact
                            path={`${match.path}/scotiabank`}
                            render={() => getLineaBank('scotiabank')} />
                        {
                            contractSelected && fullName && contractSelected.codigoBarras && (
                                <Route
                                    exact
                                    path={`${match.path}/oxxo`}
                                    render={() =>
                                    (
                                        <div>
                                            <div style={{ backgroundColor: "#002A4F" }}>
                                                <GoBack onClick={() => history.push('/payments/paymentsCenters')} />
                                            </div>
                                            <ContractData
                                                suscriptor={idCliente}
                                                fullName={fullName}
                                                limitDay={limitDay}
                                                barCode={contractSelected.codigoBarras} />
                                        </div>
                                    )
                                    } />
                            )
                        }
                        <Redirect from={`${match.path}/`} to={`/payments`} />
                    </Switch>
                </Grid>
            </Grid>
        </div>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
    },
    accordion: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#333333',
        height: '40px'
    },
}));

const mapState = (state: any) => ({
    login: state.login,
    banners: state.banners,
    estadoCuenta: state.estadoCuenta,
    pagosData: state.pagosData,
    loading: state.loading,
    currentContract: state.currentContract,
});

const connector = connect(mapState);

export default (connector(PaymentsCenters));
