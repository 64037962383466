import React, { useEffect } from 'react';
import { Grid, Hidden, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { Dispatch } from "redux";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import Spinner from '../../components/Spinner';
import _ from 'lodash';
import ComponentHeaderSecondary from '../../components/ComponentHeaderSecondary';
import awsmobile from '../../aws-exports';
import * as api from 'megacable-mobile-api';
import { AlertRedux } from 'megacable-mobile-library';

import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import Typography from '../../components/Typography';
// import Button from '../../components/Button';
import SwipeableButton from '../myServices/components/SwipeableButton';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles(() => createStyles({
    container: {
        display: "flex",
        flexDirection: 'column',
    },
    animated: {
        position: 'relative',
        left: '5%',
        animationName: '$fadein',
        animationDuration: '.5s',
        animationFillMode: 'forwards',
        transition: 'All 500ms ease-in-out',
        Webkittransition: 'All 500ms ease-in-out',
        MozTransition: 'All 500ms ease-in-out',
        OTransition: 'All 500ms ease-in-out',
    },
    swipeButton: {
        width: "300px",
    }
}));

type PropsFromRedux = ConnectedProps<typeof connector>

type TicketsProps = PropsFromRedux & {
    button?: any;
    showHeader: boolean;
}

const UpdateSignal = (props: TicketsProps) => {
    const { currentContract, button, showHeader } = props;
    const classes = styles();
    const [idCliente, setIdCliente] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract', 'idCliente']) && currentContract.currentContract.idCliente) {
                setIdCliente(currentContract.currentContract.idCliente);
            }
        }
    }, [currentContract])

    const resetEquipment = () => {
        const idClienteTmp = _.cloneDeep(idCliente);
        const tokenTmp = _.cloneDeep(keycloak.token);

        if (idClienteTmp && tokenTmp) {
            setLoading(true);
            checkToken(() => {
                const headers = { "Authorization": `Bearer ${keycloak.token}` };
                apiV2.getApiV2ReiniciarEquipoIdCliente(idClienteTmp, { headers }).then(response => {
                    if (response.data.mensaje) {
                        dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
                    }
                    setLoading(false);
                }).catch(err => {
                    err = _.get(err, 'response', err);
                    dispatch(AlertRedux.showAlert("Lamentamos las molestias, intente más tarde.", "Estimado(a) suscriptor"));
                    setLoading(false);
                })
            })
        }
    }

    const onSuccess = () => {
        resetEquipment();
    }

    return (
        <div className={classes.container}>
            {
                showHeader ?
                    <Hidden mdUp>
                        <ComponentHeaderSecondary type="H2" title="ACTUALIZAR SEÑAL" button={button} />
                    </Hidden>
                    :
                    null
            }
            {
                loading ? <Spinner /> :
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.animated} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', }}>
                            <Typography type="h3" text={'Actualizar señal'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '8px 35px 35px 35px', textAlign: 'center' }}>
                            <Typography type="p" text={"Estimado usuario, puede reestablecer su servicio de internet de manera rápida si presenta alguno de los siguientes casos:"} />
                            <Typography type="p" text={"1. Si usted tenía suspendido el servicio, realizó el pago y aún no tiene señal."} />
                            <Typography type="p" text={"2. Si no cuenta con servicio de internet."} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '8px 35px 35px 35px', display: 'flex', justifyContent: 'center' }}>
                            <div className={classes.swipeButton}>
                                <SwipeableButton onSuccess={onSuccess} color='#1693d2' text='Reestablecer el servicio' />
                            </div>
                        </Grid>
                    </Grid>
            }
        </div>
    )
}

const mapState = (state: any) => ({
    currentContract: state.currentContract,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({


})
const connector = connect(mapState, mapDispatch);

export default (connector(UpdateSignal));