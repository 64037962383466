import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../../aws-exports';
import _ from 'lodash';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);
interface NBAActivateProps extends WithStyles<typeof styles> {
    idCliente: string;
    token: string;
    packages: any;
    parentRef: any;
};

interface imgCollection {
    first: string;
};

const BREAKPOINTS = {
    small: 580,
    large: 1200,
};

const NBAActivate = (props: NBAActivateProps) => {
    const { classes, idCliente, token, packages, parentRef } = props;
    const [widthCategory, setWidthCategory] = React.useState('');
    const divRef = React.useRef<HTMLDivElement | null>(null);
    const [imageCollection, setImageCollection] = React.useState<imgCollection>({
        first: "../nba-sm-3.png",
    });

    const openLink = () => {
        window.open('https://www.nba.com/account/sign-in')

        let objBitacora = {
            sucursal: idCliente.substring(0, 3),
            suscriptor: idCliente.substring(3, idCliente.length),
            origen: "WEB",
            tipoServicio: "",
            servicio: "",
            promocion: "",
            accion: "Activacion",
            correo: ""
        }

        if (packages && packages.length > 0) {
            packages.forEach((products: any) => {
                if (products.promocion === "4322") {
                    objBitacora.tipoServicio = products.tipoServicio;
                    objBitacora.servicio = products.servicio;
                    objBitacora.promocion = products.promocion;
                }
            })
        }
        
        apiV2.postApiV2BitacoraEvento(objBitacora, { headers: { 'Authorization': `Bearer ${token}` } })
    };

    useEffect(() => {
        const handleResize = () => {
            if (divRef.current) {
                const divWidth = divRef.current.getBoundingClientRect().width;

                if (divWidth <= BREAKPOINTS.small) {
                    setWidthCategory('small');
                } else if (divWidth < BREAKPOINTS.large) {
                    setWidthCategory('medium');
                } else {
                    setWidthCategory('large');
                }
            }
        };

        handleResize(); // Initial calculation
        window.addEventListener('resize', handleResize);
        window.addEventListener('DOMContentLoaded', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('DOMContentLoaded', handleResize);
        };
    }, [divRef, parentRef.current]);

    useEffect(() => {
        if (!_.isEmpty(widthCategory)) {
            if (widthCategory === "small") {
                setImageCollection({
                    first: "../nba-sm-3.png",
                })
            } else if (widthCategory === "medium") {
                setImageCollection({
                    first: "../nba-md-3.png",
                })
            } else if (widthCategory === "large") {
                setImageCollection({
                    first: "../nba-lg-3.png",
                })
            }
        }
    }, [widthCategory]);

    return (
        <div className={classes.root} ref={divRef}>
            <div className={classes.container}>
                <img
                    src={imageCollection.first}
                    alt="nba"
                    className={classes.image}
                />
                <div className={classes.buttonContainerFirst}>
                    <Button className={classes.nbaButton}
                        onClick={() => openLink()}
                    >
                        Activa aquí
                    </Button>
                </div>
            </div>
        </div>
    );
}

const styles = ({ breakpoints }: any) =>
    createStyles({
        root: {
            width: "100%",
        },
        container: {
            position: "relative"
        },
        image: {
            width: "100%",
            marginBottom: "-4px"
        },
        buttonContainerFirst: {
            position: "absolute",
            width: "100%",
            bottom: "4.6vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            [breakpoints.down('md')]: {
                bottom: "4.2vh",
            },
            [breakpoints.down('sm')]: {
                bottom: "3.6vh",
            },
            [breakpoints.down('xs')]: {
                bottom: "2.8vh",
            },
        },
        nbaButton: {
            marginTop: "15px",
            backgroundColor: "#fff",
            color: "#1b386c",
            width: "160px",
            textTransform: "uppercase",
            fontSize: "18px",
            padding: "6px",
            borderRadius: "30px",
            "&:hover": {
                backgroundColor: "#fff",
                cursor: "pointer",
            },
            [breakpoints.down('sm')]: {
                width: "150px",
                padding: "4px",
                fontSize: "16px"
            },
        },
    });

export default withStyles(styles)(NBAActivate);
