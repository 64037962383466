import React from 'react';
import { createStyles, withStyles } from '@material-ui/styles';

import Button from './Button';
import Input from './Input';
import { AlertRedux, LoadingRedux } from 'megacable-mobile-library';
import { useHistory } from 'react-router-dom';
import awsmobile from '../aws-exports';
import * as api from 'megacable-mobile-api';
import _ from 'lodash';
import { RetrievePasswordRequest } from 'megacable-mobile-api/dist/models';
import Spinner from '../components/Spinner';
import { useSelector, useDispatch } from 'react-redux';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

const apiV2 = new api.V2Api({}, baseUrl);

const styles = ({ palette }: any) => createStyles({
    titleContainer: {
        width: '100%',
        height: '75px',
        padding: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        backgroundColor: '#044c9b',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formContainer: {
        width: '100%',
        height: '85%',
        padding: '20px',
        boxSizing: 'border-box',
    },
    buttonsContainerRegistration: {
        display: 'inline-flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '8px'
    },
    chatContainer: {
        width: '100%',
        flex: 1,
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        backgroundColor: '#002A4F',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '29px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    chatIcon: {
        verticalAlign: '-33%',
        width: '55px',
        height: '45px',
        marginRight: '15px'
    },
    alertStyle: {
        border: "2px solid #044c9b",
        borderRadius: 10,
        padding: 14,
        marginBottom: 16,
    },
    warning: {
        color: '#044C9B',
        fontSize: 14,
        fontWeight: 'bold'
    },
    warning2: {
        color: '#044C9B',
        fontSize: 14,
        marginLeft: '5px'
    },
});

interface ForgotDataProps {
    classes: any;
}

export const ForgotData = ({ classes }: ForgotDataProps) => {
    const [suscriptor, setSuscriptor] = React.useState("");
    const [cellphone, setCellphone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const loading = useSelector((state: any) => state.loading.showLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    function verifyData() {
        dispatch(LoadingRedux.Creators.showLoading());
        if (suscriptor && cellphone && email) {
            let obj: RetrievePasswordRequest = {
                email: email,
                idCliente: suscriptor,
                telefonoCelular: cellphone
            }
            apiV2.postApiV2ForgotPassword(obj)
                .then(response => {
                    if (response && _.has(response, ['data', "message"])) {
                        dispatch(AlertRedux.showAlert(response.data.message, "Recuperar contraseña"))
                        dispatch(LoadingRedux.Creators.hideLoading());
                    }
                })
                .catch(error => {
                    error = _.get(error, 'response', error);
                    dispatch(AlertRedux.showAlert(_.has(error, ['data', "message"]) ? error.data.message : "Hubo un error al realizar la petición", "Recuperar contraseña"))
                    dispatch(LoadingRedux.Creators.hideLoading());
                })
        } else {
            dispatch(AlertRedux.showAlert("Hay campos obligatorios sin datos o con datos incompletos.", "Recuperar Contraseña"))
            dispatch(LoadingRedux.Creators.hideLoading());
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.titleContainer}>
                {"RECUPERAR CONTRASEÑA"}
            </div>
            <div style={{ height: 'calc(100vh - 75px)', display: 'flex', flexDirection: 'column' }}>
                <div className={classes.formContainer}>
                    <div className={classes.alertStyle}>
                        <span className={classes.warning}>{'¡Atención! '}</span>
                        <span className={classes.warning2}>{' Estimado usuario, el número de suscriptor debe coincidir con los datos que aparecen en el Estado de Cuenta.'}</span>
                    </div>
                    <Input title="Número de suscriptor*" placeholder="" description="Consulte el número en su estado de cuenta. A continuación, ingrese los 10 dígitos sin guión." value={suscriptor} onChange={(e: any) => setSuscriptor(e.target.value.replace(/[^0-9]/g, ''))} maxLength={10} />
                    <Input title="Número celular*" type='tel' placeholder="" value={cellphone} onChange={(e: any) => setCellphone(e.target.value.replace(/[^0-9]/g, ''))} maxLength={10} />
                    <Input title="Correo electrónico*" type="email" placeholder="" value={email} onChange={(e: any) => setEmail(e.target.value)} />
                    <div style={{ margin: "8px 0px" }}>
                        {loading && <Spinner />}
                    </div>
                    <div className={classes.buttonsContainerRegistration}>
                        <div style={{ marginRight: '10px' }}>
                            <Button type="secondary" text="REGRESAR" size="medium" onClick={() => history.push('/')} />
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <Button type="primary" text="RECUPERAR" size="medium" onClick={() => verifyData()} />
                        </div>
                    </div>
                </div>
                {/* <div className={classes.chatContainer}>
                                        <img src="../chatbox-dialog.png" className={classes.chatIcon} alt="" />CHAT EN LÍNEA
                                </div> */}
            </div>
        </div>
    )
}

export default withStyles(styles)(ForgotData);
