import * as React from 'react';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import WhiteSpinner from '../../../components/WhiteSpinner';

export default function ProcessingDialog(props) {

  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          backgroundImage: 'linear-gradient(90deg, rgba(213,232,247,1) 0%, rgba(236,243,249,1) 100%)',
          borderRadius: 8,
          width: 400
        },
      }}
    >
      <div style={{
        backgroundImage: 'linear-gradient(90deg, rgba(4,127,230,1) 0%, rgba(124,187,240,1) 100%)',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: 8,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}>
        <WhiteSpinner />
      </div>
      <DialogContent>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
          <Typography style={{
            fontSize: 22,
            color: '#107CE1',
            textAlign: "center",
            fontWeight: 'bold',
            marginBottom: 15
          }}>
            PROCESANDO SOLICITUD
          </Typography>
          <Typography style={{
            fontSize: 14,
            marginTop: 3,
          }}>
            Estimado Suscriptor.
          </Typography>
          <Typography style={{
            fontSize: 14,
            fontWeight: 'bold',
            color: '#474747'
          }}>
            Espere un momento.
          </Typography>
          <Typography style={{
            fontSize: 14,
          }}>
            Su solictud de contratación está siendo
          </Typography>
          <Typography style={{
            fontSize: 14,
          }}>
            procesada
          </Typography>
          <Typography style={{
            fontSize: 16,
            textAlign: "center",
            fontWeight: 'bold',
            color: '#107CE1',
            lineHeight: 1.5,
            marginTop: 15
          }}>
            No recargue la página
          </Typography>
          <Typography style={{
            fontSize: 16,
            textAlign: "center",
            fontWeight: 'bold',
            color: '#107CE1',
            marginBottom: 15,
            marginTop: -5
          }}>
            o perderá su progreso
          </Typography>
        </div>
      </DialogContent>
    </Dialog >
  );
}