import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import Typography from './Typography';

const styles = ({ palette, breakpoints }: any) => createStyles({
    mobileDetailHeaderContainer: {
        backgroundColor: `#282828`,
        fontFamily: 'Sarabun',
        color: 'white',
        width: '100%',
        display: 'block'
    },
    container1: {
        width: '100%',
        display: 'flex'
    },
    div1: {
        /* marginLeft: '15px', */
        position: 'relative',
        width: '60%',
        height: '45px'
    },
    div2: {
        position: 'relative',
        width: '40%',
        display: 'flex',
        height: '45px',
        whiteSpace: 'pre-wrap',
        direction: 'rtl',
        /* marginRight: '15px' */
    },
    textContainer: {
        position: 'relative',
        height: 'fit-content',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        direction: 'ltr'
    },
});

interface MobileDetailHeaderProps extends WithStyles<typeof styles> {
    type: string;
    title?: string;
}

export const MobileDetailHeader = ({ type, classes, title }: MobileDetailHeaderProps) => {
    return (
        <div className={classes.mobileDetailHeaderContainer}>
            <div className={classes.container1}>
                <div className={classes.div1}>
                    <div className={classes.textContainer}>
                        <Typography type="h4" text={title ? title : "Detalles"}style={{ margin: '0px 10px 0px 15px' }} />
                    </div>
                </div>
                <div className={classes.div2}>
                    <div className={classes.textContainer}>
                        <Typography type="h4" text={type}  weight='normal' style={{ margin: '0px 15px 0px 10px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(MobileDetailHeader);
