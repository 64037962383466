import React, { useEffect, useState } from 'react';
import { createStyles } from '@material-ui/styles';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { CisRedux } from 'megacable-mobile-library';
import CISList from '../../components/CISList';
import Map from './components/Map4';
import { Dispatch } from "redux";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import Spinner from '../../components/Spinner';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import { useHistory } from 'react-router-dom';
import GoBack from '../../components/GoBack';
import _ from 'lodash';

const geolib = require('geolib');

declare global {
    interface Window {
        readonly innerHeight: number;
        readonly innerWidth: number;
    }
}

type PropsFromRedux = ConnectedProps<typeof connector>

type CISProps = PropsFromRedux & {

}

export const Cis = (props: CISProps) => {
    const classes = styles();
    const elementsByPage: number = 15;
    const [geolocationPermissionStatus, setGeolocationPermissionStatus] = useState("");
    const [newCisArray, setNewCisArray] = useState([]);
    const [arrayCisToShow, setArrayCisToShow] = useState([]);
    const [arrayOrderedCis, setArrayOrderedCis] = useState([]);
    const [cisSelected, setCisSelected] = useState({});
    const [/*loading*/, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const cisListTmp = useSelector((state: any) => state.cis.cis);
    const dispatch = useDispatch();
    const history = useHistory();
    navigator.permissions.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
            setGeolocationPermissionStatus(permissionStatus.state);

            permissionStatus.onchange = function () {
                setGeolocationPermissionStatus(this.state);
            };
        });

    useEffect(() => {
        startAnalytics('cons_pay_cis_id');
        dispatch(CisRedux.getCIS());
        return () => {
            logAnalytics('cons_pay_cis_id')
        }
    }, [dispatch])

    useEffect(() => {
        let arrayTmp: any = [];
        if (cisListTmp && cisListTmp.length > 0) {
            _.forEach(cisListTmp, (cis, key) => {
                arrayTmp.push({
                    name: cis.nombre,
                    latitude: cis.latitud,
                    longitude: cis.longitud,
                    address: cis.direccion,
                    suburb: cis.colonia,
                    id: cis.id_cis
                })
            })
            arrayTmp = _.sortBy(arrayTmp, ['name']);
            if (arrayTmp.length > 0) {
                setCisSelected(arrayTmp[0]);
            }
        }
        setNewCisArray(arrayTmp);
    }, [cisListTmp])

    useEffect(() => {
        setLoading(true);
        let newPage = 1;
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                let arrayCisTmp = [];
                if (newCisArray && newCisArray.length > 0) {
                    arrayCisTmp = geolib.orderByDistance({ latitude: position.coords.latitude, longitude: position.coords.longitude }, newCisArray);
                    _.forEach(arrayCisTmp, (cis, key) => {
                        let distance = null;
                        distance = geolib.getDistance({ latitude: position.coords.latitude, longitude: position.coords.longitude }, cis, 1);
                        if (distance >= 1000) {
                            cis.distance = _.round(geolib.convertDistance(distance, 'km')).toString() + " Km"
                        } else {
                            cis.distance = distance.toString() + " m"
                        }
                    })
                    setArrayCisToShow(_.take(arrayCisTmp, newPage * elementsByPage));
                }
                setArrayOrderedCis(arrayCisTmp);
                setLoading(false);
            }, (error) => {
                setArrayCisToShow(_.take(newCisArray, newPage * elementsByPage));
                setPage(newPage);
                setArrayOrderedCis(newCisArray);
                setLoading(false);
            })
        } else {
            setArrayCisToShow(_.take(newCisArray, newPage * elementsByPage));
            setPage(newPage);
            setArrayOrderedCis(newCisArray);
            setLoading(false);
        }
    }, [geolocationPermissionStatus, newCisArray]);

    const onScrollEvent = (e: any) => {
        let target = e.target;
        let difference = Math.round(target.scrollHeight - target.scrollTop) - target.clientHeight;
        if (difference >= -1 && difference <= 1) {
            if (arrayOrderedCis) {
                let newPage = page + 1;
                let cisTmp = _.take(arrayOrderedCis, newPage * elementsByPage);
                setPage(newPage);
                setArrayCisToShow(cisTmp);
            }

        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.menuContainer}>
                {/* <ComponentHeader type="H2" title="Ubicar CIS más cercano" /> */}
                <div className={classes.titleContainer}>
                    <Typography component={'span'} variant="h6">
                        <Box color="white" fontStyle={700}>Ubicar CIS más cercano</Box>
                    </Typography>
                    <GoBack onClick={() => history.push('/payments/cisLocation')} />
                </div>
                {
                    arrayCisToShow && arrayCisToShow.length > 0 ?
                        (
                            <div className={classes.menuCis} onScroll={(e) => onScrollEvent(e)}>
                                <CISList
                                    cisElements={arrayCisToShow}
                                    onPress={(e: any) => setCisSelected(e)}
                                    permission={geolocationPermissionStatus} />
                            </div>
                        ) : <Spinner />
                }
            </div>
            <div className={classes.mapContainer}>
                <Map cis={cisSelected} />
            </div>
        </div>

        // <Grid fluid className={classes.container}>
        //     {loading ?
        //         <Spinner />
        //         :
        //         <Row className={classes.divRow}>
        //             <Col xs={12} sm={12} md={4} lg={4} className={classes.divCol}>
        //                 <Row>
        //                     <ComponentHeader type="H2" title="Ubicar CIS más cercano" />
        //                 </Row>
        //                 <Row className={classes.divList} onScroll={(e) => onScrollEvent(e)}>
        //                     {   
        //                         arrayCisToShow && arrayCisToShow.length > 0 
        //                         ? <CISList cisElements={arrayCisToShow} 
        //                         onPress={(e: any) => setCisSelected(e)} 
        //                         permission={geolocationPermissionStatus} /> : <Spinner />
        //                     }
        //                 </Row>
        //             </Col >
        //             <Col xs={12} sm={12} md={8} lg={8} className={classes.divCol} style={{ paddingLeft: 0, border: "3px solid red"}}>
        //                 <Map cis={cisSelected} />
        //             </Col>
        //         </Row>
        //     }
        // </Grid>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: "93vh",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('md')]: {
            height: "auto",
            flexDirection: "column",
        }
    },

    menuContainer: {
        flex: 4,
        display: "flex",
        flexDirection: "column",
        order: 1,
        [theme.breakpoints.down('md')]: {
            order: 2,
            height: 350,
        }
    },

    titleContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundImage: 'linear-gradient(90deg, #0078D0 0%, #004FC0 100%)',
        padding: 16,
    },

    menuCis: {
        height: "100%",
        overflowY: 'scroll',
        '&::-webkit-scrollbar-track': {
            background: '#CCCCCC'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0078D0'
        },
        '&::-webkit-scrollbar': {
            width: 'auto'
        }
    },

    mapContainer: {
        flex: 6,
        display: "flex",
        flexDirection: "column",
        order: 2,
        [theme.breakpoints.down('md')]: {
            height: 450,
            order: 1,
        }
    },

    // container: {
    //     height: '100%',
    //     padding: 0,
    //     paddingLeft: '8px',
    // },

    // divRow: {
    //     height: '100%'
    // },
    // divCol: {
    //     height: '600px',
    //     background: 'white',
    // },
    // divList: {
    //     height: '1080px',
    //     padding: 0,
    //     overflowY: 'scroll',
    //     '&::-webkit-scrollbar-track': {
    //         background: '#CCCCCC'
    //     },
    //     '&::-webkit-scrollbar-thumb': {
    //         background: '#0078D0'
    //     },
    //     '&::-webkit-scrollbar': {
    //         width: 'auto'
    //     }
    // },
    // mapDiv: {
    //     height: `100%`
    // }
}));

const mapState = (state: any) => ({
    cis: state.cis
})

const mapDispatch = (dispatch: Dispatch<any>) => ({

});


const connector = connect(mapState, mapDispatch);

export default (connector(Cis));