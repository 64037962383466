import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface IButtonPayment extends WithStyles<typeof styles> {
    onClick: () => void,
    onKeyPress?: (event: React.KeyboardEvent) => void,
}

const ButtonPayment = (props: IButtonPayment) => {
    const { classes, onClick } = props;
    return (
        <div className={classes.root} onClick={onClick}>
            <div className={classes.customButton}>
                <Typography component={'span'} variant="h6" color="primary"><Box fontWeight={700}>PAGAR CON ESTA TARJETA</Box></Typography>
                <FontAwesomeIcon icon={faChevronRight} className={`${classes.chevronIcon}`} />
            </div>
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        borderColor: "#b7b7b7",
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        cursor: "pointer",
    },

    customButton: {
        display: "flex",
        flexDirection: "row",
        padding: 40,
        justifyContent: "center",
        alignItems: "center",
        transitionDuration: "300ms",
        '&:hover': {
            opacity: 0.3,
        },
    },
    chevronIcon: {
        fontSize: '20px',
        color: "#276ba6",
        marginLeft: "auto",
    }
});

export default withStyles(styles)(ButtonPayment);
