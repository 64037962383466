import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '../../../components/Typography';
import { Button } from '@material-ui/core';

interface INFLActivationProps extends WithStyles<typeof styles> {
    nflLink: string;
    code: string;
}

const NFLActivation = (props: INFLActivationProps) => {
    const { classes, nflLink, code } = props;

    return (
        <div className={classes.root}>
            <div className={classes.imageBackground}>
                <Typography style={{ color: 'white', fontSize: '28px', paddingBottom: '10px' }} type="h2" text="ACTIVA TU CUENTA DE" />
                <img src="../NflLayer 1.png" alt="NFL" />
                <Typography style={{ color: 'white', fontSize: '18px', fontWeight: 'lighter', marginTop: 15, marginBottom: 5 }} type="h3" text={`Código de activación`} />
                <Typography style={{ color: 'white', fontSize: '22px', paddingBottom: '10px', margin: 0 }} type="h3" text={code} />
                <div style={{ paddingTop: '20px' }}>
                    <Button className={classes.nflButton}
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(nflLink);
                        }}>
                        Click aquí
                    </Button>
                </div>
            </div>
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
    },
    imageBackground: {
        width: '100%',
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundImage: 'url(NFL-bg.jpg)',
        backgroundPositionX: "center",
        backgroundPositionY: "center",
    },
    nflButton: {
        backgroundColor: '#D50A0A',
        width: "200px",
        border: 'none',
        fontFamily: "Sarabun",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#FFFFFF',
        textAlign: 'center',
        textDecoration: 'none',
        margin: '5px 5px',
        padding: '10px',
        borderRadius: '5px',
        direction: 'ltr',
        '&:hover': {
            backgroundColor: '#AC0101',
            cursor: 'pointer',
        }
    }
});

export default withStyles(styles)(NFLActivation);
