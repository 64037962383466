import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, Box, Button, Paper, Grid } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';

interface ICardResponse extends WithStyles<typeof styles> {
    titleText: string,
    descriptionText: string,
    descriptionText2?: string,
    descriptionText3?: string,
    descriptionText4?: string,
    infoText: string,
    typeResponse: "success" | "error",
    buttonText: string,
    onClick: () => void,
}

const CardResponse = (props: ICardResponse) => {
    const { classes, titleText, descriptionText, descriptionText2, descriptionText3, descriptionText4, infoText, typeResponse, buttonText, onClick } = props;
    return (
        <Paper className={classes.root} elevation={0}>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>

                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={
                        clsx(
                            classes.imgContainer,
                            typeResponse === "success" ? classes.backgroundSuccessful : classes.backgroundError
                        )}>
                        <img
                            className={classes.img}
                            src={typeResponse === "success" ? "../../exito.png" : "../../alerta.png"}
                            alt="payment-response" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={clsx(classes.detailContainer)}>
                        <Typography component={'span'} variant="h6" color="textPrimary">
                            <Box fontWeight={700} mb={1}>{titleText}</Box>
                        </Typography>
                        <Typography component={'span'}>
                            <Box mb={1}>{descriptionText}</Box>
                        </Typography>
                        {
                            !_.isEmpty(descriptionText2) ?
                                <Typography component={'span'}>
                                    <Box mb={1}>{descriptionText2}</Box>
                                </Typography>
                                : null
                        }
                        {
                            !_.isEmpty(descriptionText3) ?
                                <Typography component={'span'}>
                                    <Box mb={1}>{descriptionText3}</Box>
                                </Typography>
                                : null
                        }
                        {
                            !_.isEmpty(descriptionText4) ?
                                <Typography component={'span'}>
                                    <Box mb={1}>{descriptionText4}</Box>
                                </Typography>
                                : null
                        }
                        <Typography component={'span'}>
                            <Box>{infoText}</Box>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className={classes.actionContainer}>
                        <Button className={classes.buttonFinish} onClick={onClick}>
                            <Typography component={'span'} variant="body1">
                                <Box fontWeight={700} color="white">{buttonText}</Box>
                            </Typography>
                        </Button>
                    </div>
                </Grid>

            </Grid>
        </Paper>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },

    imgContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 24,
    },

    backgroundSuccessful: {
        backgroundColor: "#bdeedd",
    },

    backgroundError: {
        backgroundColor: "#ffe6e6",
    },

    img: {

    },

    detailContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 24,
    },

    actionContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 24
    },

    buttonFinish: {
        width: "65%",
        height: 60,
        backgroundColor: "#0797fd",
        borderRadius: 10,
        border: 0,
        padding: '0 30px',
        "& .MuiTouchRipple-root span": {
            backgroundColor: "#6fc3fc",
        },
    },
});

export default withStyles(styles)(CardResponse);
