import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface PrivateRouteProps {
    component?: any;
    path?: any;
    exact?: any;
}
const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
    return (
        <Route
            {...rest}
            render={props => (
                localStorage.getItem('token') ?
                    <Component {...props} />
                    :
                    <Redirect to="/" />
            )}
        />
    )
}

export default PrivateRoute;