import React, { useEffect } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import Slider from '../../components/Slider';
import ContractInfoBanner from '../../components/ContractInfoBanner';
import OptionsButton from '../../components/OptionsButton';
import LastUpdate from '../../components/LastUpdate';
import CardPayments from '../cardPayments/CardPayments';
import PaymentsCenters from '../paymentsCenters/PaymentsCenters';
import CisLocation from '../cisLocation/CisLocation';
import Help from '../../components/Help';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Dispatch } from "redux";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { IContract } from '../../storeV2/ducks/types';
import { DatosPago, FullApiResponse, SuscriptorYServicios } from 'megacable-mobile-api/models';
import { PagosDataRedux } from 'megacable-mobile-library';
import IsValidToken from '../../utils/IsValidToken';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import _ from 'lodash';
import moment from 'moment';
import DataNotLoading from '../../components/DataNotLoading';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import PaypalPayment from '../cardPayments/PaypalPayment';
import CardUpdateModal from '../../components/CardUpdateModal';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles((theme: Theme) => createStyles({
    bannerContainer: {
        width: "100%",
    },
    cardMenuOption: {
        height: '120px',
        display: "flex",
        flexDirection: "row",
        cursor: 'pointer',
        alignItems: "center",
        borderBottom: '1px #d4d4d4 solid',
        paddingLeft: 24,
        paddingRight: 24,
        transitionDuration: "300ms",
        borderRadius: '0px',
        '&:hover': {
            opacity: 0.3,
        },

        [theme.breakpoints.down('xs')]: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        marginRight: 15,
        [theme.breakpoints.down('xs')]: {
            marginRight: 8,
        }
    },
    icon: {
        height: 65,
    },
    labelContainer: {
        display: "flex",
        flexDirection: "column",
        marginRight: 16,
        [theme.breakpoints.down('md')]: {
            maxWidth: 299,
        }
    },
    div3: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto"
    },
    chevronIcon: {
        fontSize: '20px',
        color: "#276ba6",
    },
    textStyle: {
        fontSize: 16,
        fontWeight: "bolder",
        fontFamily: "Sarabun",
        lineHeight: '0.9em',
        color: "#002A4F"
    },
    generalContainer: {
        backgroundColor: "white"
    },
    loadingData: {
        display: "flex",
        flexDirection: "column",
        backgroundImage: "linear-gradient(90deg, #0078D0 0%, #004FC0 100%)",
        padding: 16,
        alignItems: "center"
    },
}));

type MenuOption = {
    icon: string,
    label: string,
    path: string,
}

type PropsFromRedux = ConnectedProps<typeof connector>

type OnlinePaymentsProps = PropsFromRedux & {

}

export const OnlinePayments = (props: OnlinePaymentsProps) => {
    const classes = styles();
    const { currentContract, pagosData, banners, login } = props;
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    const [paymentData, setPaymentData] = React.useState<DatosPago>();
    const [dateRefresh, setDateRefresh] = React.useState<string>('');
    const [user, setUser] = React.useState<SuscriptorYServicios>();
    const [cardExpired, setCardExpiered] = React.useState<FullApiResponse>()
    const [bTieneConvenio, setBTieneConvenio] = React.useState(false);
    const [paymentList, setPaymentList] = React.useState(false);
    const [clientId, setClientId] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    let history = useHistory();
    let match = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(() => {
        startAnalytics('cons_pay_target_id');
        return () => {
            logAnalytics('cons_pay_target_id')
        }
    }, []);

    useEffect(() => {
        if (login && _.hasIn(login, ['auth']) && _.hasIn(login, ['user'])) {
            const auth = login.auth;
            const user = login.user;
            setUser(user);

            if (_.hasIn(auth, ['token'])) {
                setToken(auth.token);
            }
        }
        return () => { }
    }, [login])

    useEffect(() => {
        if (currentContract && currentContract.currentContract) {
            setIdCliente(currentContract.currentContract.idCliente);
        }
    }, [currentContract]);

    useEffect(() => {
        if (contractSelected) {
            checkToken(() => {
                apiV2.getApiV2GetDatosPago(contractSelected.idCliente, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (_.has(response, 'data.datos')) {
                            setPaymentData(response.data)
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })

                if (pagosData && _.hasIn(pagosData, ['lastEdoCuentaRefresh'])) {
                    const refreshData = pagosData.lastEdoCuentaRefresh;
                    const dateRefresh = moment(refreshData).format('DD MMMM YYYY, h:mm a');
                    setDateRefresh(dateRefresh);
                }
            })
        }
        return () => { }
    }, [contractSelected, pagosData, token]);

    useEffect(() => {
        if (contractSelected) {
            checkToken(() => {
                apiV2.getApiV2ListadoPagosIdCliente(contractSelected.idCliente, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (response.data) {
                            setPaymentList(true)
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
            })
        }
    }, [contractSelected]);

    useEffect(() => {
        if (paymentData)
            setLoading(false);
        return () => { }
    }, [paymentData])

    useEffect(() => {
        if (contractSelected) {
            onRefresh();
            checkToken(() => {
                apiV2.getApiV2IsCardExpired({ headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (_.has(response, 'data.tarjeta')) {
                            setBTieneConvenio(true);
                            setCardExpiered(response.data);
                        } else if (response.data.codigo === '902') {
                            setBTieneConvenio(false);
                            setCardExpiered(response.data)
                        }
                    })
                    .catch(err => {
                        err = _.get(err, 'response', err);
                        if (_.has(err, 'data') && err.data === "invalid token")
                            IsValidToken(err.data);
                    })
            })
            return (() => {

            })
        }
        return () => { }
    }, [contractSelected, login, token])

    useEffect(() => {
        if (currentContract) {
            setContractSelected(currentContract.currentContract);
        }
    }, [currentContract]);

    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    useEffect(() => {
        if (!_.isEmpty(idCliente)) {
            let sucursal = idCliente.substring(0, 3);
            let suscriptor = idCliente.substring(3, 10);
            let objData = {
                sucursal: sucursal,
                suscriptor: suscriptor
            }
            apiV2.postApiV2PaypalClient(objData, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                .then((response) => {
                    if (response.data.respuesta === "OK") {
                        setClientId(response.data.mensaje);
                    }
                })
                .catch((error) => {
                    error = _.get(error, 'response', error);
                })
        }
    }, [idCliente]);

    const paymentsMenuList: Array<MenuOption> = [
        { icon: "../pay-card.png", label: "PAGO CON TARJETA CRÉDITO O DÉBITO", path: "/payments/cardPayment" },
        { icon: "../icon-paypal@4.png", label: "PAGO CON PAYPAL", path: "/payments/paypalPayment" },
        { icon: "../pay-cis.png", label: "PAGO EN CENTRO INTEGRAL DE SERVICIOS", path: "/payments/cisLocation" },
        { icon: "../pay-store.png", label: "PAGO EN CENTRO DE COBRO", path: "/payments/paymentsCenters" },
    ];

    const menuComponent = () => {
        return paymentsMenuList.map((paymentMenu, index) => (
            <OptionsButton
                key={index}
                icon={paymentMenu.icon}
                label={paymentMenu.label}
                path={paymentMenu.path} />
        ));
    }

    const domiciliarPago = () => {
        return (
            <Paper elevation={0} className={classes.cardMenuOption} onClick={goToAddConvenio}>
                <div className={classes.iconContainer}>
                    <img src={'../pago-recurrente.png'} alt={"DOMICILIAR PAGO"} className={classes.icon} />
                </div>
                <div className={classes.labelContainer}>
                    <Typography component={'span'} style={{ marginTop: '0px', marginBottom: '0px', }}>
                        <Box className={classes.textStyle}>DOMICILIAR PAGO</Box>
                    </Typography>
                </div>
                <div className={classes.div3}>
                    <FontAwesomeIcon icon={faChevronRight} className={`${classes.chevronIcon}`} />
                </div>
            </Paper>
        )
    }

    const paymentHistory = () => {
        return (
            <Paper elevation={0} className={classes.cardMenuOption} onClick={goToHistory}>
                <div className={classes.iconContainer}>
                    <img src={'../icon-last-payment@4.png'} alt={"ÚLTIMOS PAGOS"} className={classes.icon} />
                </div>
                <div className={classes.labelContainer}>
                    <Typography component={'span'} style={{ marginTop: '0px', marginBottom: '0px', }}>
                        <Box className={classes.textStyle}>ÚLTIMOS PAGOS</Box>
                    </Typography>
                </div>
                <div className={classes.div3}>
                    <FontAwesomeIcon icon={faChevronRight} className={`${classes.chevronIcon}`} />
                </div>
            </Paper>
        )
    }

    const onRefresh = () => {
        checkToken(() => {
            const idClienteTmp = idCliente;
            const tokenTmp = keycloak.token;
            if (idClienteTmp && tokenTmp) {
                checkToken(() => {
                    if (keycloak.token)
                        dispatch(PagosDataRedux.getDatosPago(idClienteTmp, keycloak.token, () => { }));
                })
            }
        })
    };

    const goToAddConvenio = () => {
        if (cardExpired && user) {
            history.push({
                pathname: '/addCardConvenio',
                state: {
                    bTieneConvenio: bTieneConvenio,
                    cardExpired: cardExpired,
                    user: user,
                }
            });
        }
    }

    const goToHistory = () => {
        if (paymentList) {
            history.push('/listPayments');
        }
    }

    return (
        <div className={classes.generalContainer}>
            <div className={classes.bannerContainer}>
                {(bannerList && !_.isEmpty(bannerList)) ? <Slider size='large' images={bannerList} /> : <div />}
            </div>
            {
                contractSelected ? (
                    (paymentData && pagosData.datosPagoOnRefresh) || loading ?
                        <>
                            <div className={classes.loadingData}>
                                <Typography component={'span'} variant="h4">
                                    <Box fontWeight={700} color="#fff">{'Obteniendo Saldo...'}</Box>
                                </Typography>
                            </div>
                            <LastUpdate date={dateRefresh} />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Switch>
                                    <Route exact path={match.path} render={() => (
                                        <>
                                            {menuComponent()}
                                            {domiciliarPago()}
                                            {paymentList ? paymentHistory() : null}
                                        </>
                                    )} />
                                    <Route exact path={`${match.path}/cisLocation`} component={CisLocation} />
                                    <Route path={`${match.path}/paymentsCenters`} component={PaymentsCenters} />
                                    <Redirect from={`${match.path}/paypalPayment/`} to={`${match.path}/paypalPayment`} />
                                    <Redirect from={`${match.path}/cardPayment/`} to={`${match.path}/cardPayment`} />
                                    <Redirect from="/" to={match.path} />
                                </Switch>
                            </div>
                        </>
                        : (paymentData && paymentData.datos && paymentData.datos.amount) ? (
                            <>
                                <ContractInfoBanner
                                    contract={contractSelected}
                                    paymentData={paymentData}
                                    reload
                                    reloadAction={onRefresh}
                                />
                                <LastUpdate date={dateRefresh} />
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Switch>
                                        <Route exact path={match.path} render={() => (
                                            <>
                                                {menuComponent()}
                                                {domiciliarPago()}
                                                {paymentList ? paymentHistory() : null}
                                            </>
                                        )} />
                                        <Route
                                            exact
                                            path={`${match.path}/cardPayment`}
                                            render={() => (
                                                <CardPayments
                                                    idCliente={idCliente}
                                                    contract={contractSelected}
                                                    payment={paymentData}
                                                />
                                            )} />
                                        <Route
                                            exact
                                            path={`${match.path}/paypalPayment`}
                                            render={() => (
                                                <PaypalPayment
                                                    idCliente={idCliente}
                                                    contract={contractSelected}
                                                    payment={paymentData}
                                                    reloadAction={() => onRefresh()}
                                                    clientId={clientId}
                                                />
                                            )} />
                                        <Route exact path={`${match.path}/cisLocation`} component={CisLocation} />
                                        <Route path={`${match.path}/paymentsCenters`} component={PaymentsCenters} />
                                        <Redirect from={`${match.path}/paypalPayment/`} to={`${match.path}/paypalPayment`} />
                                        <Redirect from={`${match.path}/cardPayment/`} to={`${match.path}/cardPayment`} />
                                        <Redirect from="/" to={match.path} />
                                    </Switch>
                                </div>
                            </>
                        ) :
                            <>
                                <ContractInfoBanner
                                    contract={contractSelected}
                                    reload
                                    reloadAction={onRefresh}
                                />
                                <DataNotLoading />
                            </>
                ) : (
                    <Help
                        type={"error"}
                        helpStyle={"outline"}
                        text1="¡Por favor!"
                        text2='Seleccione algún contrato.'
                        additionalStyle={{ margin: "10px 10px" }}
                    />
                )
            }
            {localStorage.getItem('rejectedSaveCard') !== "rejected" && user && <CardUpdateModal user={user} />}
        </div>
    )
}

const mapState = (state: any) => ({
    currentContract: state.currentContract,
    login: state.login,
    banners: state.banners,
    pagosData: state.pagosData,
    alias: state.alias,
    estadoCuenta: state.estadoCuenta,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({

});

const connector = connect(mapState, mapDispatch);

export default connector(OnlinePayments);