import React, { useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { createStyles, makeStyles } from '@material-ui/styles';
import Slider from '../../components/Slider';
import ContractList from '../../components/ContractList';
import Button from '../../components/Button';
import Expansion from '../../components/Expansion';
import { bindActionCreators, Dispatch } from "redux";
import { BannersRedux, EstadoCuentaRedux, } from 'megacable-mobile-library';
import { connect, ConnectedProps, useSelector, useDispatch } from "react-redux";
import AddContractHeader from '../../components/AddContractHeader';
import { IContract } from '../../storeV2/ducks/types';
import Help from '../../components/Help';
import _ from 'lodash';
import Spinner from '../../components/Spinner';
import awsmobile from '../../aws-exports';
import * as api from 'megacable-mobile-api';
import { AlertRedux } from 'megacable-mobile-library';
import { Creators as ContractActions } from '../../storeV2/ducks/currentContract';
import getIdCliente from '../../utils/getIdCliente';
import { Fade, FormLabel, InputAdornment, TextField } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles(({ palette }: any) => createStyles({
    info: {
        borderColor: '#00CCFF',
        border: 'solid 1px',
        fontSize: '10px',
        color: '#00CCFF',
        padding: '5px',
        borderRadius: '5px'
    },
    boxButtonRegister: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: "10px 0px 10px 0px"
    },
    infoText1: {
        fontWeight: 'bold'
    },
    infoText2: {
        fontWeight: 'normal',
        marginLeft: '5px'
    },
    rowCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    rowEnd: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    boxNewTarget: {
        width: '100%',
        padding: '10px 0px 40px',
        borderBottom: '#c5c5c5 solid 1px'
    },
    bannerContainer: {
        width: "100%",
    },
    label: {
        color: 'inherit',
        paddingLeft: '5px',
        paddingBottom: '10px',
    },
    tooltip: {
        backgroundColor: "transparent",
    },
    icon: {
        width: "100%",
        height: "100%",
        backgroundSize: "contain",
    },
}));

type PropsFromRedux = ConnectedProps<typeof connector>

type AditionalServicesProps = PropsFromRedux & {

}

export const AddContracts = (props: AditionalServicesProps) => {
    const { banners, currentContract, login } = props;
    const dispatch = useDispatch();
    const classes = styles();
    const [bannerList, setBannerList] = React.useState<Array<string>>();
    const [contracts, setContracts] = React.useState<Array<any>>([]);
    const accountStatus = useSelector((state: any) => state.estadoCuenta);
    const [contractSelected, setContractSelected] = React.useState<IContract | undefined>();
    const [contractNumber, setContractNumber] = React.useState("");
    const [contractName, setContractName] = React.useState("");
    const [suscriptorName, setSuscriptorName] = React.useState("");
    const [extNumber, setExtNumber] = React.useState("");
    const [isEditing, setIsEditing] = React.useState(false);
    const [newContractNumber, setNewContractNumber] = React.useState("");
    const [newSuscriptorName, setNewSuscriptorName] = React.useState("");
    const [newExtNumber, setNewExtNumber] = React.useState("");
    const [idCliente, setIdCliente] = React.useState<string>("");
    const [branch, setBranch] = React.useState("");
    const [newBranch, setNewBranch] = React.useState("");
    const [currentContractNumber, setCurrentContractNumber] = React.useState("");
    const [newContract, setNewContract] = React.useState("");
    const [token, setToken] = React.useState<string>('');
    const [loading, setLoading] = React.useState(false);
    const [idClienteCurrent, setIdClienteCurrent] = React.useState<string>("");

    useEffect(() => {
        const idCliente = getIdCliente();
        if (idCliente && login && login.auth && login.auth.idCliente) {
            setIdCliente(login.auth.idCliente);
        }
    }, [login]);

    useEffect(() => {
        if (login) {
            if (_.hasIn(login, ['auth', 'token'])) {
                setToken(login.auth.token);
            }
        }
    }, [login]);

    useEffect(() => {
        if (banners) {
            setBannerList(banners.banners);
        }
        return () => { }
    }, [banners]);

    useEffect(() => {
        setContractSelected(currentContract.currentContract);
    }, [currentContract]);

    useEffect(() => {
        if (accountStatus && accountStatus.contratos) {
            setContracts(accountStatus.contratos)
        }
    }, [accountStatus]);

    useEffect(() => {
        if (idCliente) {
            let branchTmp = idCliente.substring(0, 3)
            let contractNumberTmp = idCliente.substring(3, idCliente.length)
            setBranch(branchTmp);
            setCurrentContractNumber(contractNumberTmp);
        }
    }, [idCliente]);

    useEffect(() => {
        if (newContractNumber) {
            let newBranchTmp = newContractNumber.substring(0, 3)
            let newContractNumberTmp = newContractNumber.substring(3, newContractNumber.length)
            setNewContract(newContractNumberTmp)
            setNewBranch(newBranchTmp);
        }
    }, [newContractNumber]);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract', 'idCliente'])) {
                setIdClienteCurrent(currentContract.currentContract.idCliente);
            }
        }
    }, [currentContract]);

    function contractInfo(contractInfo?: any) {
        if (contractInfo && contractInfo.idCliente !== contractNumber) {
            setContractName(contractInfo.nombre);
            setContractNumber(contractInfo.idCliente)
            setSuscriptorName(contractInfo.nombreSuscriptor)
            setExtNumber(contractInfo.domicilio.numeroexterior)
            setIsEditing(true)
        }

        return (
            <div>
                <Grid className={classes.boxNewTarget}>
                    <Row>
                        <Col sm={12} md={6} >
                            <div style={{ margin: '10px 5px 10px 5px' }}>
                                <FormLabel className={classes.label} component="legend">Número de suscriptor</FormLabel>
                                <TextField
                                    value={contractNumber}
                                    disabled={isEditing}
                                    name="contractNumber"
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    inputProps={{ maxLength: 10 }}
                                    onChange={(e: any) => setContractNumber(e.target.value)} />
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div style={{ margin: '10px 5px 10px 5px' }}>
                                <FormLabel className={classes.label} component="legend">Nombre para contrato</FormLabel>
                                <TextField
                                    value={contractName}
                                    name="contractName"
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    onChange={(e: any) => setContractName(e.target.value)} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6} >
                            <div style={{ margin: '10px 5px 10px 5px' }}>
                                <FormLabel className={classes.label} component="legend">Nombre del suscriptor</FormLabel>
                                <TextField
                                    value={suscriptorName}
                                    disabled={isEditing}
                                    name="suscriptorName"
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    onChange={(e: any) => setSuscriptorName(e.target.value)} />
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div style={{ margin: '10px 5px 10px 5px' }}>
                                <FormLabel className={classes.label} component="legend">Número exterior</FormLabel>
                                <TextField
                                    value={extNumber}
                                    disabled={isEditing}
                                    name="extNumber"
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    onChange={(e: any) => setExtNumber(e.target.value)} />
                            </div>
                        </Col>
                    </Row>
                </Grid>
                <Grid style={{ width: '100%' }}>
                    <Row className={classes.rowCenter} />
                </Grid>
            </div>
        )
    }

    function registerContract() {
        if (idCliente !== newContractNumber) {
            let objData = {
                nombre: newSuscriptorName,
                numeroExterior: newExtNumber,
                sucursal: branch,
                sucursalAsociar: newBranch,
                suscriptor: currentContractNumber,
                suscriptorAsociar: newContract
            }
            if (newContractNumber && newContractNumber.length === 10 && newSuscriptorName && newExtNumber) {
                setLoading(true);
                checkToken(() => {
                    apiV2.postApiV2AsociarContrato(objData, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                        .then(response => {
                            if (response && _.has(response, ['data', "mensaje"])) {
                                dispatch(AlertRedux.showAlert(response.data.mensaje, "Contratos asociados"))
                            }
                            apiV2.getApiV2GetContratos(idCliente, true, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                                .then(responseContratos => {
                                    dispatch(EstadoCuentaRedux.Creators.setContratos(responseContratos.data.contratos))
                                })
                            setLoading(false);
                        })
                        .catch(error => {
                            error = _.get(error, 'response', error);
                            if (error && _.has(error, "data.mensaje")) {
                                dispatch(AlertRedux.showAlert(error.data.mensaje, "Contratos asociados"))
                            } else {
                                dispatch(AlertRedux.showAlert("Hubo un error al intentar procesar su solicitud, intente más tarde.", "Contratos asociados"))
                            }
                            setLoading(false);
                        })
                })
            } else {
                dispatch(AlertRedux.showAlert("Hay campos obligatorios sin datos o con datos incompletos.", "Contratos asociados"))
            }
        } else {
            dispatch(AlertRedux.showAlert("No es posible asociar el mismo contrato", "Contratos asociados"))
        }
    }

    function updateAlias() {
        if (contractName && contractNumber) {
            let obj = {
                alias: contractName,
                idCliente: contractNumber
            }
            setLoading(true);
            checkToken(() => {
                apiV2.postApiV2Alias(obj, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        apiV2.getApiV2GetContratos(idCliente, true, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                            .then(responseContratos => {
                                let newContract = _.find(responseContratos.data.contratos, (contractTmp) => contractTmp.idCliente === idClienteCurrent);
                                dispatch(ContractActions.setCurrentContract(newContract));
                            })
                        setLoading(false);
                    })
                    .catch(error => {
                        dispatch(AlertRedux.showAlert("Hubo un error al intentar cambiar el alias.", "Editar alias"))
                        setLoading(false);
                    })
            })
        } else {
            dispatch(AlertRedux.showAlert("El nombre del contrato es obligatorio", "Editar alias"))
        }
    }

    function newContractForm() {

        return (
            <div>
                <Grid className={classes.boxNewTarget}>
                    <Grid style={{ width: '100%' }}>
                        <Row className={classes.rowCenter}>
                            <Col xs={12} sm={12} md={12}>
                                <Help type={"information"} helpStyle={"outline"} text1="¡INFORMACIÓN!" text2='Aquí puede vincular a su cuenta principal contratos adicionales - Nombre y número exterior deben coincidir con los datos que aparecen en el Estado de Cuenta' additionalStyle={{ margin: "10px 0px 10px 0px" }} />
                            </Col>
                        </Row>
                    </Grid>
                    <Row>
                        <Col sm={12} md={6} >
                            <div style={{ margin: '10px 5px 10px 5px' }}>
                                <FormLabel className={classes.label} component="legend">Número de suscriptor</FormLabel>
                                <TextField
                                    value={newContractNumber}
                                    name="newContractNumber"
                                    placeholder='A continuación, ingrese los 10 dígitos sin guión.'
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    inputProps={{ maxLength: 10 }}
                                    onChange={(e: any) => setNewContractNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip
                                                className={classes.tooltip}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                title={
                                                    <img src="../suscriptor.jpeg" alt={"Suscriptor"} className={classes.icon} />
                                                }>
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "#cccccc" }} />
                                                </InputAdornment>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={12} md={6} >
                            <div style={{ margin: '10px 5px 10px 5px' }}>
                                <FormLabel className={classes.label} component="legend">Nombre del suscriptor</FormLabel>
                                <TextField
                                    value={newSuscriptorName}
                                    name="newSuscriptorName"
                                    placeholder='Ingrese el nombre del suscriptor tal como aparece en el estado de cuenta.'
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    onChange={(e: any) => setNewSuscriptorName(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip
                                                className={classes.tooltip}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                title={
                                                    <img src="../nombre.jpeg" alt={"Nombre"} className={classes.icon} />
                                                }>
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "#cccccc" }} />
                                                </InputAdornment>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <div style={{ margin: '10px 5px 10px 5px' }}>
                                <FormLabel className={classes.label} component="legend">Número exterior</FormLabel>
                                <TextField
                                    value={newExtNumber}
                                    name="newExtNumber"
                                    placeholder='Número exterior'
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    onChange={(e: any) => setNewExtNumber(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip
                                                className={classes.tooltip}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                title={
                                                    <img src="../datos-suscriptor.jpg" alt={"Número exterior"} className={classes.icon} />
                                                }>
                                                <InputAdornment position="start">
                                                    <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "#cccccc" }} />
                                                </InputAdornment>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Grid>
                <Grid style={{ width: '100%' }}>
                    <Row className={classes.rowEnd}>
                        <Col xs={12} sm={12} md={12} className={classes.boxButtonRegister}>
                            <Button type="primary" text="Registrar" size="large" onClick={() => registerContract()} />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Grid style={{ height: '100%', background: 'white', width: '100%', padding: 0 }}>
                <Col md={12} style={{ padding: 0 }}>
                    <div className={classes.bannerContainer}>
                        {
                            !bannerList ?
                                <Spinner />
                                :
                                bannerList !== null && bannerList.length < 1 ?
                                    null
                                    :
                                    <Slider size='large' images={bannerList} />
                        }
                    </div>
                </Col>
                {
                    contractSelected ? (
                        contracts ?
                            loading ?
                                <Spinner />
                                :
                                <>
                                    <Col md={12} style={{ padding: 0 }}>
                                        <AddContractHeader title={'Suscriptor'} number={contractSelected ? contractSelected.suscriptor : ""} />
                                    </Col>
                                    <Col md={12} style={{ padding: 0 }}>
                                        <ContractList idClienteCurrent={idClienteCurrent} token={token} contracts={contracts} form={contractInfo} onClickSave={() => updateAlias()} />
                                    </Col>
                                    <Col xs={12} style={{ padding: 0 }}>
                                        <Expansion title="AÑADIR CONTRATO" content={newContractForm()} />
                                    </Col>
                                </>
                            : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor recarga la página!" text2='Ocurrió un error al recuperar la información' additionalStyle={{ margin: "10px 10px" }} />
                    )
                        : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
                }
            </Grid>
        </div>
    )
}

const mapState = (state: any) => ({
    banners: state.banners,
    currentContract: state.currentContract,
    login: state.login,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({
    ...bindActionCreators({ BannersActions: BannersRedux.Creators } as any, dispatch),

})
const connector = connect(mapState, mapDispatch)

export default connector(AddContracts);