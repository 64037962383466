import React, { Component } from 'react'
import './SwipeableButton.css'

const slider = React.createRef();
const container = React.createRef();

export default class DisabledSwipeableButton extends Component {

  state = {}

  render() {
    return (
      <div className='ReactSwipeButton'>
        <div className={'rsbContainer ' + (this.state.unlocked ? 'rsbContainerUnlocked' : '')} ref={container}>
          <div className='rsbcSlider'
            ref={slider}
            onMouseDown={this.startDrag}
            style={{ background: this.props.color }}
            onTouchStart={this.startDrag}>
            <span className='rsbcSliderText'>{'Arrastra para aceptar'}</span>
            <span className='rsbcSliderArrow'></span>
            <span className='rsbcSliderCircle' style={{ background: this.props.color }}></span>
          </div>
          <div className='rsbcText'>{this.props.text || "Arrastra para aceptar"}</div>
        </div>
      </div>
    )
  }
}