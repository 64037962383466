import { saveAs } from 'file-saver';

export function saveFile(base64str: string, typeFile: string, title: string) {
    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    // create the blob object with content-type               
    var blob = new Blob([view], { type: typeFile });

    saveAs(blob, title);
}