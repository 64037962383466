

import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

interface SpinnerProps extends WithStyles<typeof styles> {

}


const WhiteSpinner = (props: SpinnerProps) => {
    const { classes } = props;
    return (
        <div className={classes.container}>
            <img src={'/Spinner-white.svg'} alt={"Loading..."} className={classes.spinner} />
        </div>
    )
}

const styles = () => createStyles({
    container: {
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        padding: "5%",
    },
    spinner: {
        width: 100,
        height: 100,
    }
});

export default withStyles(styles)(WhiteSpinner);
