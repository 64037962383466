import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

const styles = ({ palette }: any) => createStyles({
    addIcon: {
        verticalAlign: 'middle',
        margin: '-4px 30px 0px 25px',
        fontSize: '40px'
    },
    addContainer: {
        display: 'flex',
    },
    chevronIcon: {
        verticalAlign: 'middle',
        margin: '0px 45px 0px 30px',
        fontSize: '20px',
        color: '#002A4F',
        transition: 'all .3s linear'
    },
    chevronContainer: {
        marginLeft: "auto",
    },
    chevronIconUp: {
        transform: 'rotate(-180deg)'
    },
    contentContainer: {
        transition: 'margin 5s',
        width: '100%'
    },
    expansionContainer: {
        borderBottom: '#c5c5c5 solid 1px',
        borderTop: '#c5c5c5 solid 1px',
        height: '105px',
        width: '100%',
        cursor: 'pointer',
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    component: {
        width: '100%',
    },
    text: {
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '20px',
        color: '#002A4F',
        // verticalAlign: 'middle',
        // margin: '0px 30px 0px 0px',
    },
    expansionShadow: {
        // boxShadow: '0px 3px rgba(175, 175, 175, 0.5)'
    }
});

interface ExpansionProps extends WithStyles<typeof styles> {
    title: string;
    content: React.ReactNode;
    /* classes: any; */
}

export const Expansion = ({ title, content, classes }: ExpansionProps) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div className={classes.component}>
            <div className={`${classes.expansionContainer} ${open && classes.expansionShadow}`} onClick={() => setOpen(!open)}>
                <div>
                    <img src="../add-button.png" className={classes.addIcon} alt="" />
                    {/* <FontAwesomeIcon className={classes.addIcon} icon={faPlusCircle} /> */}
                </div>
                <p className={classes.text}>{title}</p>
                <div className={classes.chevronContainer}>
                    <FontAwesomeIcon className={`${classes.chevronIcon} ${!open && classes.chevronIconUp}`} icon={faChevronUp} />
                </div>
            </div>
            {
                open && 
                <div className={classes.contentContainer}>
                    {content}
                </div>}
        </div>
    )
}

export default withStyles(styles)(Expansion);
