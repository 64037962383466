import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { createStyles, styled, withStyles, WithStyles } from '@material-ui/styles';
import * as React from 'react';

const styles = () => createStyles({

});

interface CallsTableProps extends WithStyles<typeof styles> {
    callsList?: Array<any>;
}

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#D1E3FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface Column {
    id: 'fechaHora' | 'destino' | 'ciudad' | 'estado' | 'pais' | 'tipoLlamadaDescripcion' | 'duracion' | 'importe';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const CallsTable = ({ classes, callsList }: CallsTableProps) => {
    const [page, /*setPage*/] = React.useState(0);
    const [rowsPerPage, /*setRowsPerPage*/] = React.useState(1000);

    const columns: readonly Column[] = [
        { id: 'fechaHora', label: 'Fecha', minWidth: 100 },
        { id: 'destino', label: 'Teléfono', minWidth: 100 },
        { id: 'ciudad', label: 'Ciudad', minWidth: 100 },
        { id: 'estado', label: 'Estado', minWidth: 100 },
        { id: 'pais', label: 'País', minWidth: 100 },
        { id: 'tipoLlamadaDescripcion', label: 'Tipo de llamada', minWidth: 100 },
        { id: 'duracion', label: 'Duración', minWidth: 100 },
        { id: 'importe', label: 'Importe', minWidth: 100 },
    ];

    const rows: any = callsList;

    return (
        <Paper style={{ width: '100%', overflow: 'hidden', borderRadius: '0' }}>
            <TableContainer style={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: '#0051C1', color: '#FFFFFF' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any) => {
                                return (
                                    <StyledTableRow key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : column.id === 'importe' && !value
                                                            ? '$0.00'
                                                            : column.id === 'importe' && value
                                                                ? '$' + value
                                                                : value}
                                                </TableCell>
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default withStyles(styles)(CallsTable);