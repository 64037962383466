import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import _ from 'lodash';

interface IDisneyActivationProps extends WithStyles<typeof styles> {
    onClick: Function;
    parentRef: any;
};

interface imgCollection {
    first: string;
};

const BREAKPOINTS = {
    small: 580,
    large: 1200,
};

const DisneyActivation = (props: IDisneyActivationProps) => {
    const { classes, onClick, parentRef } = props;
    const [widthCategory, setWidthCategory] = React.useState('');
    const divRef = React.useRef<HTMLDivElement | null>(null);
    const [imageCollection, setImageCollection] = React.useState<imgCollection>({
        first: "../disney-sm-4.png",
    });

    useEffect(() => {
        const handleResize = () => {
            if (divRef.current) {
                const divWidth = divRef.current.getBoundingClientRect().width;

                if (divWidth <= BREAKPOINTS.small) {
                    setWidthCategory('small');
                } else if (divWidth < BREAKPOINTS.large) {
                    setWidthCategory('medium');
                } else {
                    setWidthCategory('large');
                }
            }
        };

        handleResize(); // Initial calculation
        window.addEventListener('resize', handleResize);
        window.addEventListener('DOMContentLoaded', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('DOMContentLoaded', handleResize);
        };
    }, [divRef, parentRef.current]);

    useEffect(() => {
        if (!_.isEmpty(widthCategory)) {
            if (widthCategory === "small") {
                setImageCollection({
                    first: "../disney-sm-4.png",
                })
            } else if (widthCategory === "medium") {
                setImageCollection({
                    first: "../disney-md-4.png",
                })
            } else if (widthCategory === "large") {
                setImageCollection({
                    first: "../disney-lg-4.png",
                })
            }
        }
    }, [widthCategory]);

    return (
        <div className={classes.root} ref={divRef}>
            <div className={classes.container}>
                <img
                    src={imageCollection.first}
                    alt="disneyAct"
                    className={classes.image}
                />
                <div className={classes.buttonContainerFirst}>
                    <Button
                        className={classes.disneyButton}
                        onClick={() => onClick()}
                    >
                        Activa aquí
                    </Button>
                </div>
            </div>
        </div>
    );
}

const styles = ({ breakpoints }: any) => createStyles({
    root: {
        width: "100%",
    },
    container: {
        position: "relative"
    },
    image: {
        width: "100%",
        marginBottom: "-4px"
    },
    buttonContainerFirst: {
        position: "absolute",
        width: "100%",
        bottom: "4.6vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        [breakpoints.down('md')]: {
            bottom: "4.2vh",
        },
        [breakpoints.down('sm')]: {
            bottom: "3.6vh",
        },
        [breakpoints.down('xs')]: {
            bottom: "2.8vh",
        },
    },
    disneyButton: {
        backgroundColor: "#fff",
        color: "#01147b",
        width: "160px",
        textTransform: "uppercase",
        fontSize: "18px",
        padding: "6px",
        borderRadius: "30px",
        "&:hover": {
            backgroundColor: "#fff",
            cursor: "pointer",
        },
        [breakpoints.down('sm')]: {
            width: "150px",
            padding: "4px",
            fontSize: "16px"
        },
    },
});

export default withStyles(styles)(DisneyActivation);
