import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, Box, Button, Grid } from '@material-ui/core';
import { useBarcode } from 'react-barcodes';

interface IAddresses {
    calle: string,
    ciudad: string,
    codigopostal: string,
    colonia: string,
    departamento: string,
    entrecalle1: string,
    entrecalle2: string,
    estado: string,
    numeroexterior: string,
}

interface IContractData extends WithStyles<typeof styles> {
    isButtonEnable?: boolean,
    suscriptor: string,
    fullName: string,
    addresses?: IAddresses,
    limitDay: string,
    barCode?: string,
    linea?: string,
    convenio?: string,
    onClick?: () => void
}

function BarCode(value: { textobarcode: string }) {
    const { inputRef } = useBarcode({
        value: value.textobarcode === "" ? " " : value.textobarcode,
        options: { font: "Sarabun", fontSize: 16, }
    });

    return <svg ref={inputRef} />;
};

const ContractData = (props: IContractData) => {
    const { classes, suscriptor, /*addresses,*/ fullName, limitDay, barCode, isButtonEnable, linea, convenio, onClick } = props;

    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classes.contractInfo}>
                        <Typography component={'span'} variant="h6">
                            <Box>Suscriptor</Box>
                        </Typography>
                        <Typography component={'span'} variant="h5" color="primary">
                            <Box fontWeight={700} mb={4}>{suscriptor}</Box>
                        </Typography>
                        <Typography component={'span'} variant="h4" color="primary">
                            <Box fontWeight={700} mb={4}>{fullName}</Box>
                        </Typography>
                        <Typography component={'span'} variant="h6">
                            <Box fontWeight={700}>Fecha limite de pago es:</Box>
                        </Typography>
                        <Typography component={'span'} variant="h5">
                            <Box fontWeight={700}>Día <span className={classes.dayNumber}>{limitDay}</span> del mes en curso</Box>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classes.barCodeContainer}>
                        {
                            barCode ? <BarCode textobarcode={barCode} /> : null
                        }

                        {
                            isButtonEnable && (
                                <Button className={classes.buttonFinish} onClick={onClick}>
                                    <Typography component={'span'} variant="body1">
                                        <Box fontWeight={700} color="white">UBICAR CIS MÁS CERCANO</Box>
                                    </Typography>
                                </Button>
                            )
                        }

                        {
                            linea &&
                            <>
                                <Typography component={'span'} variant="h6">
                                    <Box>
                                        Linea de captura:
                                    </Box>
                                </Typography>
                                <Typography component={'span'} color="primary">
                                    <Box fontWeight={700} fontSize={20} mb={2}>
                                        {linea}
                                    </Box>
                                </Typography>

                                {
                                    linea !== "Sin información" && convenio && (
                                        <>
                                            <Typography component={'span'} variant="h6">
                                                <Box>
                                                    Convenio:
                                                </Box>
                                            </Typography>
                                            <Typography component={'span'} variant="h5" color="primary">
                                                <Box fontWeight={700}>
                                                    {convenio}
                                                </Box>
                                            </Typography>
                                        </>
                                    )
                                }
                            </>
                        }
                    </div>

                </Grid>
            </Grid>
        </div>
    )
}

const styles = ({ palette, breakpoints, }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    bannerContainer: {
        width: "100%",
    },
    userInfoContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundImage: "linear-gradient(90deg, #0078D0 0%, #004FC0 100%)",
        padding: 16,
    },
    container: {
        alignItems: "center",
        backgroundColor: "#fff"
    },
    contractInfo: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: 40,
        [breakpoints.down('sm')]: {
            justifyContent: "center",
            textAlign: "center"
        },
        [breakpoints.down('xs')]: {
            padding: 16,
        }
    },
    barCodeContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [breakpoints.down('xs')]: {
            paddingTop: 16,
            paddingRight: 0,
            paddingLeft: 0,
            paddingBotom: 16
        }
    },
    dayNumber: {
        color: "#36c1ff",
    },

    buttonFinish: {
        height: 48,
        backgroundColor: "#0797fd",
        borderRadius: 10,
        border: 0,
        padding: '0 30px',
        margin: '24px 0',
        "&:hover": {
            backgroundColor: palette.primary.main,
            color: "#fff"
        },
        "& .MuiTouchRipple-root span": {
            backgroundColor: "#6fc3fc",
        },
    },
});

export default withStyles(styles)(ContractData);
