import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
// import { Typography, Box, Button, Paper, Grid } from '@material-ui/core';
// import clsx from 'clsx';

interface ISocialNetworkIcon extends WithStyles<typeof styles>{

}

const SocialNetworkIcon = (props: ISocialNetworkIcon) => {
    const { classes } = props;
    
    return (
        <div className={classes.root}>
            <img src="../twitter.png" alt={"twitter"} className={classes.icon} />
            <img src="../facebook.png" alt={"facebook"} className={classes.icon} />
            <img src="../facebook-messenger.png" alt={"facebook"} className={classes.icon} />
            <img src="../whatsapp.png" alt={"facebook"} className={classes.icon} />
        </div> 
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        display:"flex", 
        flexDirection:"row",
        justifyContent: "center",
    },
    icon: {
        width: 30,
        height: 30,
        backgroundSize: "contain",
        marginRight: 8,
    },
});

export default withStyles(styles)(SocialNetworkIcon);
