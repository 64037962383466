import React, { useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '../../../components/Typography';
import { Button, Grid, TextField } from '@material-ui/core';
import SwipeableButton from './SwipeableButton';
import { useDispatch } from "react-redux";
import { AlertRedux } from 'megacable-mobile-library';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../../aws-exports';
import _ from 'lodash';
import ProcessingDialog from './ProccessingDialog';
import checkToken from '../../../utils/checKToken';
import { useHistory } from 'react-router-dom';
import { removeToken } from '../../../utils';
import BlockedDialog from './BlockedDialog';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface NortonHireAlternativesProps extends WithStyles<typeof styles> {
    packages: any;
    idCliente: string;
    token: string;
    onClickHire: Function;
}

const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const NortonHireAlternatives = (props: NortonHireAlternativesProps) => {
    const { classes, packages, idCliente, token, onClickHire } = props;
    const [packageFamily, setPackageFamily] = React.useState<any>();
    const [packageVPN, setPackageVPN] = React.useState<any>();
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [isHiringFamily, setIsHiringFamily] = React.useState<boolean>(false);
    const [isHiringVPN, setIsHiringVPN] = React.useState<boolean>(false);
    const [emailFamily, setEmailFamily] = React.useState<string>('');
    const [emailVPN, setEmailVPN] = React.useState<string>('');
    const [isEmailCorrectFamily, setIsEmailCorrectFamily] = React.useState<boolean>(false);
    const [isEmailCorrectVPN, setIsEmailCorrectVPN] = React.useState<boolean>(false);
    const [verificationVPN, setVerificationVPN] = React.useState<boolean>(false);
    const [verificationFamily, setVerificationFamily] = React.useState<boolean>(false);
    const [sendingMessage, setSendingMessage] = React.useState<boolean>(false);
    const [verificationCodeFamily, setVerificationCodeFamily] = React.useState<string>("");
    const [verificationCodeVPN, setVerificationCodeVPN] = React.useState<string>("");
    const [verificationMessage, setVerificationMessage] = React.useState<string>("");
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [blockedDialog, setBlockedDialog] = React.useState<boolean>(false);
    const [chosenService, setChosenService] = React.useState<string>("");
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (packages) {
            packages.forEach((element: any) => {
                if (element.IDPaquete === "B9160FC9-0BCD-492B-ACAE-DA64A6D07382" && element.estado !== "CON") {
                    setPackageFamily(element);
                }
                if (element.IDPaquete === "DFC56D7E-13EC-458F-809E-DA2793A3EF37" && element.estado !== "CON") {
                    setPackageVPN(element);
                }
            });
        }
    }, [packages]);

    useEffect(() => {
        if (regexEmail.test(emailFamily)) {
            setIsEmailCorrectFamily(true)
        } else {
            setIsEmailCorrectFamily(false)
        }
        if (regexEmail.test(emailVPN)) {
            setIsEmailCorrectVPN(true)
        } else {
            setIsEmailCorrectVPN(false)
        }
    }, [emailFamily, emailVPN]);

    const addService = (promotion: any, verificationCode: string, email: any,) => {
        if (idCliente) {
            let sucursal = idCliente.substring(0, 3);
            let suscriptor = idCliente.substring(3, 10);
            let objData = {
                suscriptor: suscriptor,
                id_paquete: promotion.IDPaquete,
                promocion: parseInt(promotion.promocion),
                origen: "WEB",
                nombrePaquete: promotion.paquete,
                codigo_validacion: verificationCode
            }
            let objDataEmailNorton = {
                idCliente: idCliente,
                email: email
            }
            let objBitacora = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length),
                origen: "WEB",
                tipoServicio: promotion.tipoServicio,
                servicio: promotion.servicio,
                promocion: promotion.promocion,
                accion: "Contratacion",
                correo: email
            }

            setIsReset(false);
            setChosenService(promotion.tipoServicio)

            apiV2.postApiV2EmailNorton(objDataEmailNorton, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(response => { })
                .catch(error => { })

            let objDataValidate = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length),
                id_paquete: promotion.IDPaquete,
                promocion: parseInt(promotion.promocion),
                origen: "WEB",
            }

            let dataToStore = {
                idPaquete: promotion.IDPaquete,
                lastAttempt: new Date().getTime(),
                idCliente: idCliente,
                codigo: promotion.codigo,
                codigoTipo: promotion.codigoTipo
            }

            const dataReviewTmp = localStorage.getItem('salesAttempts');
            let proceedWithSale = true;
            let dataReview: any = [];

            if (dataReviewTmp && !_.isEmpty(dataReviewTmp)) {
                dataReview = JSON.parse(dataReviewTmp);
                if (promotion.codigoTipo === "47") {
                    dataReview.forEach((element: any) => {
                        if ((element.codigo === promotion.codigo) && (Math.abs(new Date().getTime() - element.lastAttempt) / 1000) <= 1800) {
                            proceedWithSale = false;
                        }
                    })
                } else {
                    dataReview.forEach((element: any) => {
                        if ((element.idPaquete === promotion.IDPaquete) && (Math.abs(new Date().getTime() - element.lastAttempt) / 1000) <= 1800) {
                            proceedWithSale = false;
                        }
                    })
                }
            }

            if (proceedWithSale) {
                setDialogOpen(true);
                checkToken(() => {
                    apiV2.postApiV2ValidaContrataciones(objDataValidate, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response: any) => {
                            if (response.data && response.data.resultado === "1") {
                                apiV2.postApiV2VentasOperacionesVerifiedSucursal(sucursal, objData, { headers: { 'Authorization': `Bearer ${token}` } })
                                    .then((response) => {
                                        dataReview.push(dataToStore)
                                        localStorage.setItem('salesAttempts', JSON.stringify(dataReview));
                                        if (response && response.data && response.data.id && response.data.id.id && !_.isEmpty(response.data.id.id)) {
                                            onClickHire();
                                            apiV2.postApiV2BitacoraEvento(objBitacora, { headers: { 'Authorization': `Bearer ${token}` } })
                                        } else if (response.data.mensaje === "Registro duplicado.") {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, ya cuenta con una en proceso.", "Contratar " + promotion.tipoServicio));
                                        } else if (response.data.mensaje === "código incorrecto.") {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, el código de validación es incorrecto.", "Contratar " + promotion.tipoServicio));
                                        } else {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, intentalo más tarde.", "Contratar " + promotion.tipoServicio))
                                        }
                                    })
                                    .catch((error) => {
                                        // setIsReset(true);
                                        dispatch(AlertRedux.showAlert("No es posible realizar la contratación, intentalo más tarde.", "Contratar " + promotion.tipoServicio))
                                    })
                            } else if (response.data.codigo === "2") {
                                dispatch(AlertRedux.showAlert("No es posible continuar con la contratación, ya cuenta con una en proceso.", "Contratar " + promotion.tipoServicio))
                            } else {
                                dispatch(AlertRedux.showAlert("Ha ocurrido un error al validar su contratación, intentarlo más tarde.", "Contratar " + promotion.tipoServicio))
                            }
                            setDialogOpen(false);
                        })
                        .catch((err: any) => {
                            // setIsReset(true);
                            setDialogOpen(false);
                            err = _.get(err, 'response', err);
                            if (err && err.data && err.data.err && err.data.err.message && err.data.err.message === "jwt expired") {
                                dispatch(AlertRedux.showAlert("La sesión ha caducado.", "Sesión"));
                                setTimeout(function () {
                                    removeToken();
                                    history.push("/");
                                }, 3000);
                            } else {
                                dispatch(AlertRedux.showAlert("Ha ocurrido un error al validar su contratación, intentarlo más tarde.", "Contratar " + promotion.tipoServicio))
                            }
                        })
                })
            } else {
                setBlockedDialog(true);
            }
        }
    };

    const onSuccessFamily = () => {
        if (verificationCodeFamily.length !== 6) {
            setIsReset(true);
            dispatch(AlertRedux.showAlert("¡Estimado Usuario! El código de verificación debe ser de 6 dígitos.", "Contratar servicio"))
        } else {
            addService(packageFamily, verificationCodeFamily, emailFamily);
        }
    }

    const onSuccessVPN = () => {
        if (verificationCodeVPN.length !== 6) {
            setIsReset(true);
            dispatch(AlertRedux.showAlert("¡Estimado Usuario! El código de verificación debe ser de 6 dígitos.", "Contratar servicio"))
        } else {
            addService(packageVPN, verificationCodeVPN, emailVPN);
        }
    }

    const sendCodeFamily = () => {
        setSendingMessage(true);
        let objData = {
            idCliente: idCliente,
            payload: packageFamily
        }

        checkToken(() => {
            apiV2.postApiV2SendVerificationCode(objData, { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    if (response.data.estatus === "true") {
                        setVerificationFamily(true);
                        setVerificationMessage("Ha sido enviado un código de verificación para la contratación del servicio vía SMS.")
                    } else if (response.data.codigo === "2") {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error en el envío de su código de verificación vía SMS, intentarlo más tarde.", "Contratar servicio"))
                    } else if (response.data.codigo === "3") {
                        dispatch(AlertRedux.showAlert("Ha llegado a su límite de intentos de contratación en el día, intentarlo el día de mañana.", "Contratar servicio"))
                    } else {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error al generar su código de verificación, intentarlo más tarde.", "Contratar servicio"))
                    }
                    setSendingMessage(false);
                })
                .catch((err) => {
                    err = _.get(err, 'response', err);
                    if (err && err.data && err.data.err && err.data.err.message && err.data.err.message === "jwt expired") {
                        dispatch(AlertRedux.showAlert("La sesión ha caducado.", "Sesión"));
                        setTimeout(function () {
                            removeToken();
                            history.push("/");
                        }, 3000);
                    } else {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error al generar su código de verificación, intentarlo más tarde.", "Contratar servicio"))
                    }
                    setSendingMessage(false);
                })
        })
    }

    const sendCodeVPN = () => {
        setSendingMessage(true);
        let objData = {
            idCliente: idCliente,
            payload: packageVPN
        }

        checkToken(() => {
            apiV2.postApiV2SendVerificationCode(objData, { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    if (response.data.estatus === "true") {
                        setVerificationVPN(true);
                        setVerificationMessage("Ha sido enviado un código de verificación para la contratación del servicio vía SMS.")
                    } else if (response.data.codigo === "2") {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error en el envío de su código de verificación vía SMS, intentarlo más tarde.", "Contratar servicio"))
                    } else if (response.data.codigo === "3") {
                        dispatch(AlertRedux.showAlert("Ha llegado a su límite de intentos de contratación en el día, intentarlo el día de mañana.", "Contratar servicio"))
                    } else {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error al generar su código de verificación, intentarlo más tarde.", "Contratar servicio"))
                    }
                    setSendingMessage(false);
                })
                .catch((err) => {
                    err = _.get(err, 'response', err);
                    if (err && err.data && err.data.err && err.data.err.message && err.data.err.message === "jwt expired") {
                        dispatch(AlertRedux.showAlert("La sesión ha caducado.", "Sesión"));
                        setTimeout(function () {
                            removeToken();
                            history.push("/");
                        }, 3000);
                    } else {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error al generar su código de verificación, intentarlo más tarde.", "Contratar servicio"))
                    }
                    setSendingMessage(false);
                })
        })
    }

    return (
        <div className={classes.root}>
            <Grid container style={{ height: '100%' }}>
                {
                    packageFamily ?
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.container}>
                            <Grid item xs={12} sm={12} md={7} lg={7} className={classes.backgroundFamily}>
                                <hr className={classes.hr2} />
                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backgroundFamily}>
                                    <img alt="" src={"../norton-logo-family.png"} style={{ paddingRight: '5%' }} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backgroundFamily3}>
                                {
                                    verificationFamily ?
                                        <>
                                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', margin: '0px', textAlign: 'center' }} type="p" text={verificationMessage ? verificationMessage : ""} />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', fontWeight: "bold", margin: '0px', textAlign: 'center', paddingTop: '25px' }} type="p" text="Código de verificación" />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                <TextField
                                                    value={verificationCodeFamily}
                                                    name="verificationCode"
                                                    placeholder='123456'
                                                    variant="outlined"
                                                    autoComplete='notAutoComplete'
                                                    style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383', borderRadius: '5px' }}
                                                    InputProps={{ className: classes.textfield }}
                                                    inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
                                                    onChange={(event) => setVerificationCodeFamily(event.target.value)} />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                <div className={classes.swipeButton}>
                                                    <SwipeableButton resetStatus={isReset} onSuccess={() => onSuccessFamily()} color='#FFC300' text='Arrastra para aceptar' />
                                                </div>
                                            </Grid>
                                        </>
                                        : isHiringFamily ?
                                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.hrContainer}>
                                                <hr className={classes.hr} />
                                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backgroundFamily3}>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px', margin: '-20px 15px 15px 30px', textAlign: 'center' }} type="h2" text={"¿Está seguro que desea contratar el paquete " + packageFamily.paquete + "? Ingrese su correo y deslice el botón para contratar."} />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <TextField
                                                            value={emailFamily}
                                                            name="emailFamily"
                                                            placeholder='Correo electrónico'
                                                            type='email'
                                                            variant="outlined"
                                                            autoComplete='notAutoComplete'
                                                            style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383' }}
                                                            InputProps={{ className: classes.textfield }}
                                                            onChange={(event) => setEmailFamily(event.target.value)} />
                                                    </Grid>
                                                    {
                                                        isEmailCorrectFamily ?
                                                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                <div className={classes.mainButtonContainer}>
                                                                    <Button disabled={sendingMessage} className={sendingMessage ? classes.nortonButtonDisabled : classes.nortonButton}
                                                                        onClick={() => sendCodeFamily()}>
                                                                        Confirmar
                                                                    </Button>
                                                                </div>
                                                            </Grid>
                                                            :
                                                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                <div className={classes.mainButtonContainer}>
                                                                    <Button disabled className={classes.nortonButtonDisabled}>
                                                                        Confirmar
                                                                    </Button>
                                                                </div>
                                                            </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.hrContainer}>
                                                <hr className={classes.hr} />
                                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backgroundFamily2}>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '2em', margin: '0px' }} type="h2" text={packageFamily.descripcion ? packageFamily.descripcion : ""} />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <Typography style={{ color: '#fecb16', fontSize: '3em', margin: '10px 0px 10px 0px', paddingLeft: '10px' }} type="h2" text={packageFamily.precio ? "$" + packageFamily.precio : ""} />
                                                        <Typography style={{ color: '#fecb16', fontSize: '22px', margin: '0px', paddingLeft: '10px' }} type="h2" text="al mes" />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <Button className={classes.nortonButton} onClick={() => setIsHiringFamily(true)}>
                                                            Contrata ahora
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                }
                            </Grid>
                        </Grid>
                        :
                        null
                }
                {
                    packageVPN ?
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.container}>
                            <Grid item xs={12} sm={12} md={7} lg={7} className={classes.backgroundVPN}>
                                <hr className={classes.hr2} />
                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backgroundVPN}>
                                    <img alt="" src={"../norton-logo-vpn.png"} style={{ paddingRight: '5%' }} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backgroundVPN3}>
                                {
                                    verificationVPN ?
                                        <>
                                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', margin: '0px', textAlign: 'center' }} type="p" text={verificationMessage ? verificationMessage : ""} />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', fontWeight: "bold", margin: '0px', textAlign: 'center', paddingTop: '25px' }} type="p" text="Código de verificación" />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                <TextField
                                                    value={verificationCodeVPN}
                                                    name="verificationCode"
                                                    placeholder='123456'
                                                    variant="outlined"
                                                    autoComplete='notAutoComplete'
                                                    style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383', borderRadius: '5px' }}
                                                    InputProps={{ className: classes.textfield }}
                                                    inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
                                                    onChange={(event) => setVerificationCodeVPN(event.target.value)} />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                <div className={classes.swipeButton}>
                                                    <SwipeableButton resetStatus={isReset} onSuccess={() => onSuccessVPN()} color='#FFC300' text='Arrastra para aceptar' />
                                                </div>
                                            </Grid>
                                        </>
                                        : isHiringVPN ?
                                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.hrContainer}>
                                                <hr className={classes.hr} />
                                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backgroundVPN3}>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px', margin: '-20px 15px 15px 30px', textAlign: 'center' }} type="h2" text={"¿Está seguro que desea contratar el paquete " + packageVPN.paquete + "? Ingrese su correo y deslice el botón para contratar."} />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <TextField
                                                            value={emailVPN}
                                                            name="emailVPN"
                                                            placeholder='Correo electrónico'
                                                            type='email'
                                                            variant="outlined"
                                                            autoComplete='notAutoComplete'
                                                            style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383' }}
                                                            InputProps={{ className: classes.textfield }}
                                                            onChange={(event) => setEmailVPN(event.target.value)} />
                                                    </Grid>
                                                    {
                                                        isEmailCorrectVPN ?
                                                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                <div className={classes.mainButtonContainer}>
                                                                    <Button disabled={sendingMessage} className={sendingMessage ? classes.nortonButtonDisabled : classes.nortonButton}
                                                                        onClick={() => sendCodeVPN()}>
                                                                        Confirmar
                                                                    </Button>
                                                                </div>
                                                            </Grid>
                                                            :
                                                            <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                <div className={classes.mainButtonContainer}>
                                                                    <Button disabled className={classes.nortonButtonDisabled}>
                                                                        Confirmar
                                                                    </Button>
                                                                </div>
                                                            </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.hrContainer}>
                                                <hr className={classes.hr} />
                                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backgroundVPN2}>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '2em', margin: '0px' }} type="h2" text={packageVPN.descripcion ? packageVPN.descripcion : ""} />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <Typography style={{ color: '#fecb16', fontSize: '3em', margin: '10px 0px 10px 0px', paddingLeft: '10px' }} type="h2" text={packageVPN.precio ? "$" + packageVPN.precio : ""} />
                                                        <Typography style={{ color: '#fecb16', fontSize: '22px', margin: '0px', paddingLeft: '10px' }} type="h2" text="al mes" />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                        <Button className={classes.nortonButton} onClick={() => setIsHiringVPN(true)}>
                                                            Contrata ahora
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                }
                            </Grid>
                        </Grid>
                        :
                        null
                }
            </Grid>
            <ProcessingDialog open={dialogOpen} />
            <BlockedDialog open={blockedDialog} servicio={chosenService} onExit={() => setBlockedDialog(false)} />
        </div >
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
        height: "400px"
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
    },
    backgroundFamily: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#2a3845'
    },
    backgroundFamily2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: "#141f35"
    },
    backgroundFamily3: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        backgroundColor: "#141f35"
    },
    backgroundVPN: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#2a3845'
    },
    backgroundVPN2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: "#141f35",
    },
    backgroundVPN3: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        backgroundColor: "#141f35",
    },
    nortonButton: {
        backgroundImage: "linear-gradient(#fcbd24, #ec770b)",
        width: "200px",
        border: 'none',
        fontFamily: "Sarabun",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#FFFFFF',
        textAlign: 'center',
        textDecoration: 'none',
        margin: '5px 5px',
        padding: '10px',
        borderRadius: '50px',
        direction: 'ltr',
        '&:hover': {
            backgroundImage: "linear-gradient(#ec770b, #fcbd24)",
            cursor: 'pointer',
        }
    }, swipeButton: {
        width: "300px",
    },
    nortonButtonDisabled: {
        backgroundImage: "linear-gradient(#444, #444)",
        width: "200px",
        border: 'none',
        fontFamily: "Sarabun",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#FFFFFF',
        textAlign: 'center',
        textDecoration: 'none',
        margin: '5px 5px',
        padding: '10px',
        borderRadius: '50px',
        direction: 'ltr',
        '&:hover': {
            backgroundImage: "linear-gradient(#444, #444)",
            cursor: 'pointer',
        }
    },
    text: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textfield: {
        color: '#FFFFFF'
    },
    hr: {
        borderTop: '2px solid #FFFFFF',
        width: '70%'
    },
    hr2: {
        borderTop: '2px solid #FFFFFF',
        width: '50%'
    },
    hrContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#141f35'
    },
    mainButtonContainer: {
        paddingTop: '15px'
    },
});

export default withStyles(styles)(NortonHireAlternatives);
