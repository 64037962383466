import React, { Component } from "react";
import "./SwipeableButton.css";

export default class SwipeableButton extends Component {
  state = {};
  slider = React.createRef();
  container = React.createRef();
  isTouchDevice = "ontouchstart" in document.documentElement;

  componentDidMount() {
    if (this.isTouchDevice) {
      document.addEventListener("touchmove", this.onDrag);
      document.addEventListener("touchend", this.stopDrag);
    } else {
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    }
    this.containerWidth = this.container.current.clientWidth - 50;
  }

  onDrag = (e) => {
    if (this.unmounted || this.state.unlocked) return;
    if (this.isDragging) {
      if (this.isTouchDevice) {
        this.sliderLeft = Math.min(
          Math.max(0, e.touches[0].clientX - this.startX),
          this.containerWidth
        );
      } else {
        this.sliderLeft = Math.min(
          Math.max(0, e.clientX - this.startX),
          this.containerWidth
        );
      }
      this.updateSliderStyle();
    }
  };

  updateSliderStyle = () => {
    if (this.unmounted || this.state.unlocked) return;
    this.slider.current.style.left = this.sliderLeft + 50 + "px";
  };

  stopDrag = () => {
    if (this.unmounted || this.state.unlocked) return;
    if (this.isDragging) {
      this.isDragging = false;
      if (this.sliderLeft > this.containerWidth * 0.9) {
        this.sliderLeft = this.containerWidth;
        if (this.props.onSuccess) {
          this.props.onSuccess();
          this.onSuccess();
        }
      } else {
        this.sliderLeft = 0;
        if (this.props.onFailure) {
          this.props.onFailure();
        }
      }
      this.updateSliderStyle();
    }
  };

  startDrag = (e) => {
    if (this.unmounted || this.state.unlocked) return;
    this.isDragging = true;
    if (this.isTouchDevice) {
      this.startX = e.touches[0].clientX;
    } else {
      this.startX = e.clientX;
    }
  };

  onSuccess = () => {
    if (this.container && this.container.current) {
      this.container.current.style.width =
        this.container.current.clientWidth + "px";
      this.setState({
        unlocked: true,
      });
    }
    if (this.props.resetStatus === true) {
      this.reset();
    }
  };

  getText = () => {
    return this.state.unlocked
      ? this.props.text_unlocked || "Contratar"
      : this.props.text || "Arrastra para aceptar";
  };

  reset = () => {
    if (this.unmounted) return;
    this.setState({ unlocked: false }, () => {
      this.sliderLeft = 0;
      this.updateSliderStyle();
    });
  };

  componentDidUpdate(previousProps) {
    if (previousProps.resetStatus !== this.props.resetStatus) {
      this.reset()
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    return (
      <div id={this.props.id} className="ReactSwipeButton">
        <div
          className={
            "rsbContainer " +
            (this.state.unlocked ? "rsbContainerUnlocked" : "")
          }
          ref={this.container}
        >
          <div
            className="rsbcSlider"
            ref={this.slider}
            onMouseDown={this.startDrag}
            style={{ background: this.props.color }}
            onTouchStart={this.startDrag}
          >
            <span className="rsbcSliderText">{this.getText()}</span>
            <span className="rsbcSliderArrow"></span>
            <span
              className="rsbcSliderCircle"
              style={{ background: this.props.color }}
            ></span>
          </div>
          <div className="rsbcText">{this.getText()}</div>
        </div>
      </div>
    );
  }
}
