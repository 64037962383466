import React, { useEffect, useState, Fragment } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import _ from 'lodash';
import ComponentHeader from '../../components/ComponentHeader';
import Map from './components/Map';

import { useSelector, useDispatch } from 'react-redux';
import { getWifiPoints } from '../../store/actions/WifiPointsAction';
import Help from '../../components/Help';
import Spinner  from '../../components/Spinner';

const geolib = require('geolib');

const styles = () => createStyles({
    container: {
        height: '100%',
        paddingRight: 0
    },
    divRow: {
        height: '100%'
    },
    divCol: {
        height: '100%',
        background: 'white',
    },
    divList: {
        height: '1080px',
        padding: 0,
        overflowY: 'scroll',
        '&::-webkit-scrollbar-track': {
            background: '#CCCCCC'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0078D0'
        },
        '&::-webkit-scrollbar': {
            width: 'auto'
        }
    },
    mapDiv: {
        height: `100%`
    }
});

interface WifiPointsProps extends WithStyles<typeof styles> {

}

const WifiPoints = ({ classes }: WifiPointsProps) => {
    const [geolocationPermissionStatus, setGeolocationPermissionStatus] = useState("denied");
    const [loading, setLoading] = useState(true);
    const [wifiPointsWithinRadius, setWifiPointsWithinRadius] = useState<any>([]);
    const [position, setPosition] = useState<any>({ latitude: 0, longitude: 0 });
    const wifiPoints = useSelector((store: any) => store.wifi.wifiPoints);

    const dispatch = useDispatch();

    navigator.permissions.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
            setGeolocationPermissionStatus(permissionStatus.state);

            permissionStatus.onchange = function () {
                setGeolocationPermissionStatus(this.state);
            };
        });

    useEffect(() => {
        dispatch(getWifiPoints());
    }, [dispatch])

    useEffect(() => {
        setLoading(true);
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPosition({ latitude: position.coords.latitude, longitude: position.coords.longitude })
                let arrayTmp: any[] = [];
                let cont = 0;
                _.forEach(wifiPoints, (wifiPoint, key) => {
                    let radio = geolib.isPointWithinRadius(
                        { latitude: Number(wifiPoint.Latitude), longitude: Number(wifiPoint.Longitude) },
                        { latitude: Number(position.coords.latitude), longitude: Number(position.coords.longitude) },
                        7000
                    );
                    if (radio) {
                        arrayTmp.push(wifiPoint);
                    }
                    cont++;
                })
                if (wifiPoints && cont === wifiPoints.length) {
                    setWifiPointsWithinRadius(arrayTmp);
                    setLoading(false);
                }
            },
                (error) => {})
        } else {
            setLoading(false);
        }
    }, [geolocationPermissionStatus, wifiPoints]);

    return (
        <Grid fluid style={{ padding: 0 }}>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} style={{ height: geolocationPermissionStatus === "denied" ? '100%' : '900px' }}>
                    {
                        geolocationPermissionStatus === "denied" ?
                            <Help type={"information"} helpStyle={"outline"} text1="¡Estimado usuario!" text2='Para poderle brindar información más precisa de los puntos WiFi cercanos, es necesario que habilite en el navegador su ubicación.' additionalStyle={{ margin: '10px 10px' }} />
                            :
                            loading ?
                                <Spinner />
                                :
                                <Fragment>
                                    <ComponentHeader type="H2" title="Puntos WiFi" />
                                    <Map wifiPoints={wifiPointsWithinRadius} position={position} mapZoom={16} />
                                </Fragment>
                    }
                </Col>
            </Row>
        </Grid>
    )
}

export default withStyles(styles)(WifiPoints);