import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// import Typography from './Typography';

const styles = ({ palette, breakpoints }: any) => createStyles({
    onlineSupportContainer: {
        fontFamily: 'Sarabun',
        width: '100%',
        borderBottom: "1px solid #dddddd"
    },
    contractContainer: {
        display: 'flex',
        fontFamily: 'Sarabun',
        height: '100px',
        width: '100%',
        color: palette.secondary,
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.3,
        },
    },
    contractContainerBorder: {
        borderBottom: '1px #d4d4d4 solid',
    },
    firstContractContainer: {
        borderTop: '1px #d4d4d4 solid'
    },
    div1: {
        position: 'relative',
        textAlign: 'center',
    },
    div2: {
        position: 'relative',
        whiteSpace: 'pre-wrap',
        width: 'inherit'
    },
    div3: {
        position: 'relative',
        margin: '0px 0px 0px 10px'
    },
    iconContainer: {
        margin: '0px 15px 0px 25px',
        top: '50%',
        position: 'relative',
        transform: 'translateY(-50%)'
    },
    textContainer: {
        position: 'relative',
        minWidth: '100px',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    buttonContainer: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex'
    },
    chevronIcon: {
        margin: '0px 30px 0px 0px',
        fontSize: '20px',
        verticalAlign: 'middle',
        color: "#276ba6",
    },
    textName: {
        fontWeight: "bold",
        marginTop: '0px',
        marginBottom: '0px',
        display: "block",
        fontSize: "16px",
        marginLeft: "0",
        marginRight: "0",
        fontFamily: "Sarabun",
        height: '1.09em',
        color: "#002A4F"
    },

    selectedElement: {
        backgroundColor: "#00CCFF",
        color: "white"
    }
});

export interface ContractData {
    number: string;
    contractName: string;
    subscriberName: string;
    externalNumber: string;
}

interface Contract {
    name: string;
    icon?: string;
    iconWhite?: string;
    onClick?: Function;
    extra?: any;
}


interface ContractProps extends WithStyles<typeof styles> {
    options: Array<Contract>;
    autoSelection?: boolean;
    onClick?: () => void,
}

export const OnlineSupport = ({ classes, options, autoSelection, onClick }: ContractProps) => {
    const [selectedElement, setSelectedElement] = React.useState(0);

    useEffect(() => {
        if (autoSelection) {
            setSelectedElement(autoSelection === true ? 1 : 0)
        }
    }, [autoSelection]);

    function doClick(key: number, c: Contract) {
        setSelectedElement(key + 1)
        c.onClick && c.onClick(c)
    }

    const contractDetail = (contractInfo: Contract, key: number) => {
        return (
            <div key={`Contract-${key}`} className={`${classes.contractContainer} ${key + 1 === selectedElement && classes.selectedElement}`} onClick={() => doClick(key, contractInfo)}>
                <div className={classes.div1}>
                    <div className={`${classes.iconContainer}`}>
                        {(contractInfo.icon) && <img style={{ maxHeight: '55px', maxWidth: '55px' }} src={key + 1 === selectedElement ? contractInfo.iconWhite : contractInfo.icon} alt="" />}
                    </div>
                </div>
                <div className={classes.div2}>
                    <div className={classes.textContainer}>
                        {/* <Typography type="h3" text={contractInfo.name} weight={'bold'} style={{ marginTop: '0px', marginBottom: '0px' }} /> */}
                        <p className={`${classes.textName} ${key + 1 === selectedElement && classes.selectedElement}`}>{contractInfo.name}</p>
                    </div>
                </div>
                {
                    <div className={classes.div3}>
                        <div className={classes.buttonContainer}>
                            <FontAwesomeIcon icon={faChevronRight} className={`${classes.chevronIcon} ${key + 1 === selectedElement && classes.selectedElement}`} />
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className={classes.onlineSupportContainer}>
            {options.map((contractInfo, key) => {
                return (
                    <div className={`${classes.contractContainerBorder} ${key === 0 && classes.firstContractContainer} `} onClick={onClick} key={`Contract-Container-${key}`}>
                        {contractDetail(contractInfo, key)}
                    </div>
                )
            })}
        </div>
    )
}

export default withStyles(styles)(OnlineSupport);
