import React, { useEffect } from 'react';
import { Theme, createStyles } from '@material-ui/core/styles';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Slider from '../../components/Slider';
import Spinner from '../../components/Spinner';
import Help from '../../components/Help';
import { useBarcode } from 'react-barcodes';
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { BannersRedux, } from 'megacable-mobile-library';
import { IContract } from '../../storeV2/ducks/types';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import _ from 'lodash';

const styles = makeStyles(({ palette, breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    bannerContainer: {
        width: "100%",
    },
    userInfoContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundImage: "linear-gradient(90deg, #0078D0 0%, #004FC0 100%)",
        padding: 16,
    },
    container: {
        alignItems: "center",
        backgroundColor: "#fff"
    },
    contractInfo: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: 40,
        [breakpoints.down('sm')]: {
            justifyContent: "center",
            textAlign: "center"
        },
        [breakpoints.down('xs')]: {
            padding: 16,
        }
    },
    barCodeContainer: {
        display: "flex",
        justifyContent: "center",
        padding: 40,
        [breakpoints.down('xs')]: {
            paddingTop: 16,
            paddingRight: 0,
            paddingLeft: 0,
            paddingBotom: 16
        }
    },
    dayNumber: {
        color: "#36c1ff",
    }
})
);

function BarCode(value: { textobarcode: string }) {
    const { inputRef } = useBarcode({
        value: value.textobarcode === "" ? " " : value.textobarcode,
        options: { font: "Sarabun", fontSize: 16, }
    });

    return <svg ref={inputRef} />;
};

type PropsFromRedux = ConnectedProps<typeof connector>

type MyIdProps = PropsFromRedux & {

}

export const MyId = (props: MyIdProps) => {
    const classes = styles();
    const { banners, currentContract } = props;
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [name, setName] = React.useState<string>("");
    const [clientId, setClientId] = React.useState<string>("");
    const [suscriptor, setSuscriptor] = React.useState<string>("");
    const [fullName, setfullName] = React.useState<string>("");
    const [limitDay, setLimitDay] = React.useState<string>("");
    const [barCode, setBarCode] = React.useState<string>("");
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        startAnalytics('my_id');
        return () => {
            logAnalytics('my_id');
        }
    }, []);

    useEffect(() => {
        setContractSelected(currentContract.currentContract);
    }, [currentContract]);

    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    useEffect(() => {
        if (contractSelected) {
            setLimitDay(contractSelected.diaDeCorte)
            setName(contractSelected.nombre);
            setfullName(`${contractSelected.nombreSuscriptor} ${contractSelected.apellidoPaterno} ${contractSelected.apellidoMaterno}`)
            setClientId(contractSelected.idCliente);
            setSuscriptor(contractSelected.suscriptor);
            setBarCode(contractSelected.codigoBarras);
            setLoading(false);
        }
    }, [contractSelected]);

    return (
        <div className={classes.root}>
            <div className={classes.bannerContainer}>
                {bannerList && !_.isEmpty(bannerList) ? <Slider size='large' images={bannerList} /> : null}
            </div>
            {
                contractSelected ? (
                    <>
                        {loading ? <Spinner /> : suscriptor && fullName && clientId && limitDay ? (
                            <>
                                <div className={classes.userInfoContainer}>
                                    <Typography component={'span'} variant="h4">
                                        <Box fontWeight={700} color="#fff">{name}</Box>
                                    </Typography>
                                    <Typography component={'span'} variant="h6">
                                        <Box color="#fff">{clientId}</Box>
                                    </Typography>
                                </div>

                                <Grid container className={classes.container}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <div className={classes.contractInfo}>
                                            <Typography component={'span'} variant="h6">
                                                <Box color="">Suscriptor</Box>
                                            </Typography>
                                            <Typography component={'span'} variant="h5" color="primary">
                                                <Box fontWeight={700} mb={4}>{suscriptor}</Box>
                                            </Typography>
                                            <Typography component={'span'} variant="h4" color="primary">
                                                <Box fontWeight={700} mb={4}>{fullName}</Box>
                                            </Typography>
                                            {
                                                limitDay && (
                                                    <>
                                                        <Typography component={'span'} variant="h6">
                                                            <Box fontWeight={700}>Fecha limite de pago es:</Box>
                                                        </Typography>
                                                        <Typography component={'span'} variant="h5">
                                                            <Box fontWeight={700}>Día <span className={classes.dayNumber}>{limitDay}</span> del mes en curso</Box>
                                                        </Typography>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <div className={classes.barCodeContainer}>
                                            {
                                                barCode ? <BarCode textobarcode={barCode} /> : null
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        ) : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor recarga la página!" text2='Ocurrió un error al recuperar la información' additionalStyle={{ margin: "10px 10px" }} />}
                    </>
                ) : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
            }
        </div>
    )
}

const mapState = (state: any) => ({
    login: state.login,
    banners: state.banners,
    pagosData: state.pagosData,
    currentContract: state.currentContract,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({
    ...bindActionCreators({ BannersActions: BannersRedux.Creators } as any, dispatch),
    getBanners: () => dispatch(BannersRedux.getBanners()),

})
const connector = connect(mapState, mapDispatch)

export default (connector(MyId));
