import { combineReducers } from 'redux';
// import { resettableReducer } from 'reduxsauce';

import reducers from 'megacable-mobile-library';
import currentContract from './currentContract';

// const resettable = resettableReducer('RESET');

export default combineReducers({
    ...reducers,
    currentContract
})