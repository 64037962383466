import React, { useEffect } from 'react';
import { createStyles, withStyles } from '@material-ui/styles';

import Button from './Button';
import Input from './Input';
import { AlertRedux, LoginRedux } from 'megacable-mobile-library';
import { saveToken } from '../utils';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { startAnalytics, logAnalytics } from '../analitycsV2';
import Spinner from '../components/Spinner';
import { useSelector, useDispatch } from 'react-redux';

import { LoadCanvasTemplate, validateCaptcha } from '../utils/react-simple-captcha/react-simple-captcha';

const styles = ({ palette }: any) => createStyles({
    titleContainer: {
        width: '100%',
        height: '75px',
        padding: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        backgroundColor: '#044c9b',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formContainer: {
        width: '100%',
        height: '85%',
        padding: '20px',
        boxSizing: 'border-box',
    },
    buttonsContainer: {
        textAlign: 'center',
        justifyContent: 'center',
        width: '100%',
        // marginTop: '30px'
    },
    chatContainer: {
        width: '100%',
        flex: 1,
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        backgroundColor: '#002A4F',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '29px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    chatIcon: {
        verticalAlign: '-33%',
        width: '55px',
        height: '45px',
        marginRight: '15px'
    },
    link: {
        fontWeight: 'normal',
        textTransform: 'initial',
        textDecoration: 'underline',
        color: '#002A4F',
        verticalAlign: 'middle',
        fontSize: '16px',
        cursor: 'pointer',
    },
});

interface LoginProps {
    classes: any;
}

export const Login = ({ classes }: LoginProps) => {

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [captcha, setCaptcha] = React.useState("");
    const loading = useSelector((state: any) => state.loading.showLoading);
    const dispatch = useDispatch();
    const history = useHistory();


    useEffect(() => {
        startAnalytics('login_id');
        return () => { }
    }, []);

    useEffect(() => {
        history.push('/myId');
    }, []);

    const addToken = (e: string) => {
        saveToken(e);
        history.push('/myId');
    }

    function signIn() {
        if (username && password && captcha) {
            let user_captcha = captcha;
            if (validateCaptcha(user_captcha) === true) {
                logAnalytics('login_id');
                dispatch(LoginRedux.signInSel(username, password, addToken, false));
            } else {
                dispatch(AlertRedux.showAlert("El captcha es incorrecto, intente de nuevo.", "Iniciar sesión"));
                setCaptcha("");
            }
        } else {
            dispatch(AlertRedux.showAlert("Todos los campos son requeridos.", "Iniciar sesión"));
        }
    }

    return (
        <div style={{ height: '100vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.titleContainer}>
                    {"SERVICIOS EN LÍNEA"}
                </div>
                <div style={{ height: 'calc(100vh - 75px)', backgroundColor: '#CECECE', display: 'flex', flexDirection: 'column' }}>
                    <div className={classes.formContainer}>
                        <form id="login-form" onSubmit={signIn} style={{ marginBottom: 8 }}>
                            <Input title="Email / Número de Teléfono*" placeholder="" value={username} onChange={(e: any) => setUsername(e.target.value.replace(/[^a-zA-Z0-9._@-]/g, ''))}
                                onKeyUp={(e) => {
                                    e.persist();
                                    let pressedEnter = (_.find(e, ['key', "Enter"]))
                                    if (pressedEnter) {
                                        signIn();
                                    }
                                }} />
                            <Input title="Contraseña*" type={'password'} placeholder="" value={password} onChange={(e: any) => setPassword(e.target.value)}
                                onKeyUp={(e) => {
                                    e.persist();
                                    let pressedEnter = (_.find(e, ['key', "Enter"]))
                                    if (pressedEnter) {
                                        signIn();
                                    }
                                }} />

                        </form>
                        <div style={{ margin: "8px 0px" }}>
                            {loading && <Spinner />}
                        </div>
                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <div className={classes.buttonsContainer}>
                                <LoadCanvasTemplate />
                            </div>
                            <div>
                                <Input title="Verifique la imagen*" placeholder="" maxLength={6} value={captcha} onChange={(e: any) => setCaptcha(e.target.value.replace(/[^a-zA-Z0-9]/g, ''))}
                                    onKeyUp={(e) => {
                                        e.persist();
                                        let pressedEnter = (_.find(e, ['key', "Enter"]))
                                        if (pressedEnter) {
                                            signIn();
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className={classes.buttonsContainer}>
                            <div>
                                <Button type="secondary" text="INICIAR SESIÓN" size="large" onClick={() => signIn()} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <Button type="primary" text="REGÍSTRATE AQUÍ" size="large" onClick={() => history.push('/register')} />
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <p className={classes.link} onClick={() => history.push('/forgotData')}>¿Olvidaste tus datos?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(Login);
