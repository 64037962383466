import React, { useEffect } from 'react';
// import { Grid, Row, Col } from 'react-flexbox-grid';
import { createStyles } from '@material-ui/styles';
import { TextField, FormLabel, makeStyles, Grid, Paper, FormControlLabel, Checkbox, Link, Hidden, MenuItem } from '@material-ui/core';
import OnlineSupport from '../../components/OnlineSupport';
import ComponentHeader from '../../components/ComponentHeader';
import ComponentHeaderSecondary from '../../components/ComponentHeaderSecondary';
import Button from '../../components/Button';
import Slider from '../../components/Slider';
import _ from 'lodash';
// import { UpdateSuscriptorData } from 'megacable-mobile-api/models';
import { Dispatch } from "redux";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { AlertRedux } from 'megacable-mobile-library';
import { IContract } from '../../storeV2/ducks/types';
// import IsValidToken from '../../utils/IsValidToken';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import clsx from 'clsx';
// import { removeToken } from '../../utils';
// import { useHistory } from 'react-router-dom';
import UpdatePassword from '../UpdatePassword/UpdatePassword';
// import LogOutDialog from '../../components/LogOutDialog';
import Help from '../../components/Help';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
// import FormPortability from '../formPortability/FormPortability';
import { RetrievePasswordRequest } from 'megacable-mobile-api/dist/models';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles(({ breakpoints }: any) => createStyles({
    noMarginRow: {
        marginRight: 0,
        marginLeft: 0
    },
    containerInfo: {
        height: '100%'
    },
    animated: {
        position: 'relative',
        left: '5%',
        animationName: '$fadein',
        animationDuration: '.5s',
        animationFillMode: 'forwards',
        transition: 'All 500ms ease-in-out',
        Webkittransition: 'All 500ms ease-in-out',
        MozTransition: 'All 500ms ease-in-out',
        OTransition: 'All 500ms ease-in-out',
    },
    '@keyframes fadein': {
        '0%': {
            display: 'none',
            position: 'relative',
            left: '2%',
        },
        '100%': {
            position: 'relative',
            left: '0%',
        },
    },
    label: {
        color: 'inherit',
        paddingLeft: '5px',
        paddingBottom: '10px'
    },
    container: {
        display: 'flex',
        width: '100%',
        visibility: 'visible'
    },

    FormUpdateUsercontainer: {
        display: "flex",
        flexDirection: "column",
    },

    warning: {
        color: '#044C9B',
        fontSize: 14,
        fontWeight: 'bold'
    },
    warning2: {
        color: '#044C9B',
        fontSize: 14,
        marginLeft: '5px'
    },
    snackBar: {
        padding: '10px 24px',
        lineHeight: '1.5em',
        borderRadius: '10px',
        width: '90%',
        boxSizing: 'border-box',
        margin: '10px 0px',
        fontFamily: 'Sarabun',
        border: '#044C9B',
        borderStyle: 'solid',
        backgroundColor: "#FFFFFF",
        alignItems: 'center',
        justifyContent: 'center'
    },

    alertStyle: {
        border: "2px solid #044c9b",
        borderRadius: 10,
        padding: 14,
        marginBottom: 16,
    },
    textfields: {
        backgroundColor: 'white',
        width: '100%',
        paddingBottom: '20px',
        [breakpoints.down('sm')]: {
            width: '100%'
        }
    },
}));

type PropsFromRedux = ConnectedProps<typeof connector>

type SettingsProps = PropsFromRedux & {

}

interface IUpdateUser {
    suscriptor: string,
    sucursal: string,
    usuario: string,
    origen: string
}

interface IUpdateSuscriptor {
    telefono: string,
    telefonoAdicional: string,
    email: string,
    telefonoWhatsapp: string
}

interface IUpdateBilling {
    rfc: string,
    businessName: string,
    address: string,
    suburb: string,
    city: string,
    state: string,
    zipCode: string,
    emailBilling: string,
    id_usoCFDI: string,
    id_regfiscal: string,
}

interface IUpdatePassword {
    nip: string,
    repeatNip: string,
}
interface Option {
    name: string;
    icon?: string;
    iconWhite?: string;
    onClick?: Function;
    extra?: any;
}

export const Settings = (props: SettingsProps) => {
    // const history = useHistory();
    const { currentContract, login, banners } = props;
    const classes = styles();
    const [autoSelectionMenu, setAutoSelectionMenu] = React.useState<boolean>(false);
    // const [loading, setLoading] = React.useState<boolean>(false);
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [titleInfo, setTitleInfo] = React.useState("ACTUALIZAR MIS DATOS");
    const [settingsOption, setSettingsOption] = React.useState(2);
    const regCorreo = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/);
    const [contract, setContract] = React.useState<IContract>();
    const [token, setToken] = React.useState<string>('');
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    // const [isOpenLogoutDialog, setIsOpenLogoutDialog] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<boolean>(false);
    const [generateBill, setGenerateBill] = React.useState<boolean>(false);
    const [sendBill, setSendBill] = React.useState<boolean>(false);
    const [billingData, setBillingData] = React.useState<any>();
    const [isUpdate, setIsUpdate] = React.useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    // const [selectedValue, setSelectedValue] = React.useState('Correo');
    // const [userCellphone, setUserCellphone] = React.useState('');
    // const [userCellphoneError, setUserCellphoneError] = React.useState<boolean>(false);
    // const [userCellphoneMsg, setUserCellphoneMsg] = React.useState('');
    // const [userEmail, setUserEmail] = React.useState('');
    // const [userEmailMsg, setUserEmailMsg] = React.useState('');
    // const [userEmailError, setUserEmailError] = React.useState<boolean>(false);
    const [useCFDI, setUseCFDI] = React.useState('');
    const [regFiscal, setRegFiscal] = React.useState('');
    const [cfdiData, setCfdiData] = React.useState<any>();
    const [regFiscalData, setRegFiscalData] = React.useState<any>();
    const [cfdiList, setCfdiList] = React.useState<Array<any>>([]);
    const [regFiscalList, setRegFiscalList] = React.useState<Array<any>>([]);

    const [userData, setUserData] = React.useState<IUpdateSuscriptor>({
        telefono: "",
        telefonoAdicional: "",
        email: "",
        telefonoWhatsapp: ""
    })

    const [userBillingData, setUserBillingData] = React.useState<IUpdateBilling>({
        rfc: "",
        businessName: "",
        address: "",
        suburb: "",
        city: "",
        state: "",
        zipCode: "",
        emailBilling: "",
        id_usoCFDI: "",
        id_regfiscal: ""
    })

    const [userPassword, setUserPassword] = React.useState<IUpdatePassword>({
        nip: '',
        repeatNip: '',
    })

    const [userPasswordErrors, /*setUserPasswordErrors*/] = React.useState<IUpdatePassword>({
        nip: '',
        repeatNip: '',
    })

    // const [userDataError, setUserDataError] = React.useState<IUpdateSuscriptor>({
    //     telefono: "",
    //     telefonoAdicional: "",
    //     email: "",
    //     telefonoWhatsapp: ""
    // })

    const [userBillingDataError, setUserBillingDataError] = React.useState<IUpdateBilling>({
        rfc: "",
        businessName: "",
        address: "",
        suburb: "",
        city: "",
        state: "",
        zipCode: "",
        emailBilling: "",
        id_usoCFDI: "",
        id_regfiscal: ""
    })

    const dispatch = useDispatch();

    useEffect(() => {
        if (login) {
            if (_.hasIn(login, ['auth', 'token'])) {
                setToken(login.auth.token);
            }
        }

    }, [login]);

    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    useEffect(() => {
        if (currentContract) {
            setContract(currentContract.currentContract);
        }
    }, [currentContract]);

    useEffect(() => {
        if (currentContract) {

            if (_.hasIn(currentContract, ['currentContract', 'idCliente'])) {
                setIdCliente(currentContract.currentContract.idCliente);
                setAutoSelectionMenu(true);
                setSettingsOption(settingsOption);
            }
        } else {
            setAutoSelectionMenu(false);
        }
        return () => { }
    }, [currentContract, settingsOption]);

    useEffect(() => {
        if (contract) {
            let userDataTmp: IUpdateSuscriptor = {
                telefono: "",
                telefonoAdicional: "",
                email: _.get(contract, 'email', ""),
                telefonoWhatsapp: _.get(contract, 'telefonoWhatsapp', "")
            };
            setUserData(userDataTmp)
        }
    }, [contract])

    useEffect(() => {
        if (idCliente) {
            checkToken(() => {
                apiV2.postApiV2ConsultarDatosFacturacionIdCliente(idCliente, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (response && _.has(response, ['data', "rfc"])) {
                            setBillingData(response.data);
                        } else if (response && _.has(response, ['data', "estatus"])) {
                            setBillingData(null)
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
                apiV2.postApiV2ConsultarCatCfdi()
                    .then(response => {
                        setCfdiData(response.data);
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
                apiV2.postApiV2ConsultarCatRegFiscal()
                    .then(response => {
                        setRegFiscalData(response.data);
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
            })
        }
    }, [idCliente, token])

    useEffect(() => {
        if (cfdiData) {
            if (cfdiData.catalogo_uso)
                setCfdiList(cfdiData.catalogo_uso)
        }
    }, [cfdiData])

    useEffect(() => {
        if (regFiscalData) {
            if (regFiscalData.catalogo_regimen)
                setRegFiscalList(regFiscalData.catalogo_regimen)
        }
    }, [regFiscalData])

    useEffect(() => {
        if (billingData && cfdiList) {
            setUseCFDI(billingData.id_usoCFDI.toString())
        }
    }, [billingData, cfdiList])

    useEffect(() => {
        if (billingData && regFiscalList) {
            setRegFiscal(billingData.id_regfiscal.toString())
        }
    }, [billingData, regFiscalList])

    useEffect(() => {
        if (billingData) {
            let userBillingDataTmp: IUpdateBilling = {
                rfc: billingData.rfc.trim(),
                businessName: billingData.razonSocial.trim(),
                address: billingData.domicilio.trim(),
                suburb: billingData.colonia.trim(),
                city: billingData.ciudad.trim(),
                state: billingData.estado.trim(),
                zipCode: billingData.codigoPostal.trim(),
                emailBilling: billingData.correo.trim(),
                id_usoCFDI: billingData.id_usoCFDI.toString(),
                id_regfiscal: billingData.id_regfiscal.toString()
            };
            if (billingData.generarFactura === 1) {
                setGenerateBill(true);
            }
            if (billingData.enviarFactura === 1) {
                setSendBill(true);
            }
            setIsUpdate(true);
            setUserBillingData(userBillingDataTmp);
        } else {
            let userBillingDataEmpty: IUpdateBilling = {
                rfc: "",
                businessName: "",
                address: "",
                suburb: "",
                city: "",
                state: "",
                zipCode: "",
                emailBilling: "",
                id_usoCFDI: "",
                id_regfiscal: ""
            };
            setUserBillingData(userBillingDataEmpty);
        }
    }, [billingData])

    // const validateData = () => {
    //     let error = false;
    //     let hasData = false;
    //     let newDataSuscriptor: UpdateSuscriptorData = {
    //         telefono: "",
    //         telefonoAdicional: "",
    //         email: "",
    //         telefonoWhatsapp: "",
    //         sucursal: idCliente.substring(0, 3),
    //         suscriptor: idCliente.substring(3, 10)
    //     };

    //     let userDataErrorTmp: IUpdateSuscriptor = {
    //         telefono: "",
    //         telefonoAdicional: "",
    //         email: "",
    //         telefonoWhatsapp: ""
    //     };

    //     // setLoading(true);

    //     if (userData.telefono) {
    //         hasData = true;
    //         if (userData.telefono.length < 10) {
    //             userDataErrorTmp.telefono = "El número de telefono debe tener 10 digítos.";
    //             error = true;
    //         }
    //     }
    //     if (userData.telefonoAdicional) {
    //         hasData = true;
    //         if (userData.telefonoAdicional.length < 10) {
    //             userDataErrorTmp.telefonoAdicional = "El número de telefono adicional debe tener 10 digítos.";
    //             error = true;
    //         }
    //     }
    //     if (userData.telefonoWhatsapp) {
    //         hasData = true;
    //         if (userData.telefonoWhatsapp.length < 10) {
    //             userDataErrorTmp.telefonoWhatsapp = "El número de telefono de whatsapp debe tener 10 digítos.";
    //             error = true;
    //         }
    //     }
    //     if (userData.email) {
    //         hasData = true;
    //         if (regCorreo.test(userData.email) === false) {
    //             userDataErrorTmp.email = "El correo eletrónico no es válido.";
    //             error = true;
    //         }
    //     }

    //     if (error) {
    //         setUserDataError(userDataErrorTmp);
    //     } else {
    //         setUserDataError(userDataErrorTmp);
    //         if (hasData) {
    //             newDataSuscriptor.telefono = userData.telefono;
    //             newDataSuscriptor.telefonoAdicional = userData.telefonoAdicional;
    //             newDataSuscriptor.telefonoWhatsapp = userData.telefonoWhatsapp;
    //             newDataSuscriptor.email = userData.email;

    //             checkToken(() => {
    //                 apiV2.postApiV2UpdateSuscriptor(newDataSuscriptor, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
    //                     .then(response => {
    //                         // setLoading(false);
    //                         dispatch(AlertRedux.showAlert(response.data.mensaje, "Actualizar Suscriptor"));
    //                     })
    //                     .catch(err => {
    //                         // setLoading(false);
    //                         err = _.get(err, 'response', err);
    //                         if (_.has(err, 'data') && err.data === "invalid token") {
    //                             IsValidToken(err.data);
    //                         } else {
    //                             dispatch(AlertRedux.showAlert("Hubo un error al efectuar la operación.", "Actualizar Suscriptor"));
    //                         }
    //                     })
    //             })
    //         } else {
    //             dispatch(AlertRedux.showAlert("Al menos un campo debe contener información.", "Actualizar Suscriptor"));
    //         }
    //     }
    // }

    const validateBillingData = () => {
        let error = false;
        let hasData = false;

        let newDataBilling = {
            sucursal: idCliente.substring(0, 3),
            suscriptor: idCliente.substring(3, 10),
            razonSocial: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: "",
            codigoPostal: "",
            rfc: "",
            correo: "",
            id_usoCFDI: "0",
            id_regfiscal: "0"
        };

        let updateDataBilling = {
            sucursal: idCliente.substring(0, 3),
            suscriptor: idCliente.substring(3, 10),
            razonSocial: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: "",
            codigoPostal: "",
            rfc: "",
            generarFactura: "",
            enviarFactura: "",
            correo: "",
            id_usoCFDI: "0",
            id_regfiscal: "0"
        };

        let billingDataErrorTmp: IUpdateBilling = {
            rfc: "",
            businessName: "",
            address: "",
            suburb: "",
            city: "",
            state: "",
            zipCode: "",
            emailBilling: "",
            id_usoCFDI: "",
            id_regfiscal: ""
        };

        // setLoading(true);

        if (userBillingData.rfc.length === 0) {
            billingDataErrorTmp.rfc = "El campo RFC es requerido.";
            error = true;
        }

        if (userBillingData.businessName.length === 0) {
            billingDataErrorTmp.businessName = "El campo Razón Social es requerido.";
            error = true;
        }

        if (userBillingData.emailBilling.length === 0) {
            billingDataErrorTmp.emailBilling = "El campo Correo Electrónico es requerido.";
            error = true;
        }

        if (userBillingData.rfc) {
            hasData = true;
            const regexRFC = "^[A-Z]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$";
            if (!userBillingData.rfc.match(regexRFC)) {
                billingDataErrorTmp.rfc = "El RFC no es válido.";
                error = true;
            }
        }

        if (userBillingData.emailBilling) {
            hasData = true;
            if (regCorreo.test(userBillingData.emailBilling) === false) {
                billingDataErrorTmp.emailBilling = "El correo eletrónico no es válido.";
                error = true;
            }
        }

        if (useCFDI === '0' || useCFDI === '') {
            billingDataErrorTmp.id_usoCFDI = "El campo Uso de comprobantes es requerido.";
            error = true;
        }

        if (regFiscal === '0' || regFiscal === '') {
            billingDataErrorTmp.id_regfiscal = "El campo Regimen Fiscal es requerido.";
            error = true;
        }

        if (!generateBill && !sendBill) {
            error = true;
        }

        if (error) {
            setUserBillingDataError(billingDataErrorTmp);
            dispatch(AlertRedux.showAlert("Hay campos obligatorios sin datos o con datos incompletos.", "Datos de facturación"));
        } else {
            setUserBillingDataError(billingDataErrorTmp);
            if (hasData) {
                if (billingData) {
                    updateDataBilling.sucursal = idCliente.substring(0, 3);
                    updateDataBilling.suscriptor = idCliente.substring(3, 10);
                    updateDataBilling.razonSocial = userBillingData.businessName;
                    updateDataBilling.domicilio = userBillingData.address;
                    updateDataBilling.colonia = userBillingData.suburb;
                    updateDataBilling.ciudad = userBillingData.city;
                    updateDataBilling.estado = userBillingData.state;
                    updateDataBilling.codigoPostal = userBillingData.zipCode;
                    updateDataBilling.rfc = userBillingData.rfc;
                    updateDataBilling.generarFactura = generateBill ? "1" : "0";
                    updateDataBilling.enviarFactura = sendBill ? "1" : "0";
                    updateDataBilling.correo = userBillingData.emailBilling;
                    updateDataBilling.id_usoCFDI = useCFDI.toString();
                    updateDataBilling.id_regfiscal = regFiscal.toString();

                    checkToken(() => {
                        apiV2.postApiV2ActualizarDatosFacturacion(updateDataBilling, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                            .then(response => {
                                if (response && _.has(response, ['data', "mensaje"])) {
                                    dispatch(AlertRedux.showAlert("Tu factura se enviará mensualmente.", "Actualización de datos EXITOSA."));
                                }
                            })
                            .catch(error => {
                                dispatch(AlertRedux.showAlert("Hubo un error al efectuar la operación.", "Datos de facturación"));
                            })
                    })
                } else {
                    newDataBilling.sucursal = idCliente.substring(0, 3);
                    newDataBilling.suscriptor = idCliente.substring(3, 10);
                    newDataBilling.razonSocial = userBillingData.businessName;
                    newDataBilling.domicilio = userBillingData.address;
                    newDataBilling.colonia = userBillingData.suburb;
                    newDataBilling.ciudad = userBillingData.city;
                    newDataBilling.estado = userBillingData.state;
                    newDataBilling.codigoPostal = userBillingData.zipCode;
                    newDataBilling.rfc = userBillingData.rfc;
                    newDataBilling.correo = userBillingData.emailBilling;
                    newDataBilling.id_usoCFDI = useCFDI.toString();
                    newDataBilling.id_regfiscal = regFiscal.toString();

                    checkToken(() => {
                        apiV2.postApiV2AgregarDatosFacturacion(newDataBilling, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                            .then(response => {
                                if (response && _.has(response, ['data', "mensaje"])) {
                                    dispatch(AlertRedux.showAlert("Tu factura se enviará mensualmente.", "Registro de Datos Exitoso."));
                                }
                            })
                            .catch(error => {
                                dispatch(AlertRedux.showAlert("Hubo un error al efectuar la operación.", "Datos de facturación"));
                            })
                    })
                }
            }
        }
    }

    // const checkTextInput = () => {
    //     if (userPassword && idCliente) {

    //         let { nip, repeatNip } = _.cloneDeep(userPassword);
    //         let errorsTmp: IUpdatePassword = { nip: "", repeatNip: "" };

    //         if (!nip || !repeatNip || nip !== repeatNip) {
    //             if (!nip) errorsTmp = { ...errorsTmp, nip: 'Ingresa tu contraseña' }
    //             if (!repeatNip) errorsTmp = { ...errorsTmp, repeatNip: 'Ingresa tu contraseña de nuevo' }
    //             if (nip !== repeatNip) errorsTmp = { ...errorsTmp, repeatNip: 'La contraseñas no coinciden' }

    //         }
    //         return errorsTmp;
    //     }
    // };

    // const checkTextInputUser = () => {
    //     let user = selectedValue === 'Correo' ? userEmail : selectedValue === 'Celular' ? userCellphone : '';
    //     let obj: IUpdateUser = {
    //         suscriptor: idCliente.substring(3, 10),
    //         sucursal: idCliente.substring(0, 3),
    //         usuario: user,
    //         origen: 'web'
    //     }

    //     if (selectedValue === 'Correo') {
    //         if (userEmail === '') {
    //             setUserEmailError(true);
    //             setUserEmailMsg('Campo requerido');
    //         } else if (regCorreo.test(userEmail) === false) {
    //             setUserEmailError(true);
    //             setUserEmailMsg('El correo eletrónico no es válido');
    //         } else {
    //             setUserEmailError(false);
    //             setUserEmailMsg('');
    //             changeUser(obj)
    //         }
    //     } else if (selectedValue === 'Celular') {
    //         if (userCellphone === '') {
    //             setUserCellphoneError(true);
    //             setUserCellphoneMsg('Campo requerido');
    //         } else if (userCellphone.length < 10) {
    //             setUserCellphoneError(true);
    //             setUserCellphoneMsg('El número de telefono debe tener 10 digítos');
    //         } else {
    //             setUserCellphoneError(false);
    //             setUserCellphoneMsg('');
    //             changeUser(obj)
    //         }
    //     }
    // };

    // const changeUser = (obj: IUpdateUser) => {
    //     apiV2.postUpdateUsuario(obj, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
    //         .then(response => {
    //             if (response && _.has(response, ['data', "mensaje"])) {
    //                 if (response.data.estatus === 'true') {
    //                     dispatch(AlertRedux.showAlert(response.data.mensaje + ' Deberá iniciar sesión la próxima vez con el nuevo usuario.', "Cambiar datos de sesión"));
    //                 } else {
    //                     dispatch(AlertRedux.showAlert(response.data.mensaje, "Cambiar datos de sesión"));
    //                 }
    //             }
    //         })
    //         .catch(err => {
    //             err = _.get(err, 'response', err);
    //             dispatch(AlertRedux.showAlert("Hubo un error al efectuar la operación.", "Cambiar datos de sesión"));
    //         })
    // };

    const changePassword = () => {
        let obj: RetrievePasswordRequest = {
            email: _.get(contract, 'email', ""),
            idCliente: idCliente,
            telefonoCelular: _.get(contract, 'telefonoCelular', ""),
            origen: "WEB"
        }
        apiV2.postApiV2ForgotPassword(obj)
            .then(response => {
                if (response && _.has(response, ['data', "message"])) {
                    dispatch(AlertRedux.showAlert(response.data.message, "Cambiar contraseña"))
                } else {
                    dispatch(AlertRedux.showAlert("Hubo un error al realizar la petición", "Cambiar contraseña"))
                }
            })
            .catch(error => {
                error = _.get(error, 'response', error);
                dispatch(AlertRedux.showAlert(_.has(error, ['data', "message"]) ? error.data.message : "Hubo un error al realizar la petición.", "Cambiar contraseña"))
            })
        // const errorsTmp = checkTextInput();
        // if (errorsTmp) {
        //     setUserPasswordErrors(errorsTmp);
        //     if (errorsTmp.nip || errorsTmp.repeatNip) {
        //         //ERROR
        //     } else {
        //         let idClienteTmp = _.cloneDeep(idCliente);
        //         let { nip } = _.cloneDeep(userPassword);
        //         let obj = {
        //             sucursal: idClienteTmp.substring(0, 3),
        //             suscriptor: idClienteTmp.substring(3, 10),
        //             nip
        //         }
        //         rest_API(obj);
        //     }
        // }
    };

    // const rest_API = (objPassword: { sucursal: string, suscriptor: string, nip: string }) => {
    //     apiV2.postApiV2CambiarContrasena(objPassword, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
    //         .then(response => {
    //             setUserPassword({ ...userPassword, nip: '', repeatNip: '' });
    //             if (response.data.statusMessage) {
    //                 dispatch(AlertRedux.showAlert(response.data.statusMessage, "Cambiar Contraseña"));
    //             }
    //         })
    //         .catch(err => {
    //             err = _.get(err, 'response', err);
    //             if (_.has(err, 'data') && err.data === "invalid token") {
    //                 IsValidToken(err.data);
    //             }
    //         })
    // }

    function changeViewInfo(a: Option) {
        switch (a.name) {
            // case 'ACTUALIZAR MIS DATOS':
            //     setSettingsOption(1)
            //     setTitleInfo('ACTUALIZAR MIS DATOS')
            //     break;
            case 'DATOS DE FACTURACIÓN':
                setSettingsOption(2)
                setTitleInfo('DATOS DE FACTURACIÓN');
                break;
            case 'CAMBIAR DATOS DE SESIÓN':
                setSettingsOption(3)
                setTitleInfo('CAMBIAR CONTRASEÑA')
                break;
            // case 'CREAR TRÁMITE DE PORTABILIDAD':
            //     setSettingsOption(4)
            //     setTitleInfo('CREAR TRÁMITE DE PORTABILIDAD')
            //     break;
            // case 'CERRAR SESIÓN':
            //     setSettingsOption(5)
            //     openDialog()
            //     setTitleInfo('CERRAR SESIÓN')
            //     break;
            default:
                break;
        }
    }

    // function openDialog() {
    //     setIsOpenLogoutDialog(true);
    // }

    const handleValueChange = (value: string, type: string) => {
        switch (type) {
            case 'email':
                setUserData({ ...userData, email: value })
                break;
            case 'telefonoWhatsapp':
                setUserData({ ...userData, telefonoWhatsapp: value.replace(/[^0-9]/g, '') })
                break;
            case 'nip':
                setUserPassword({ ...userPassword, nip: value.trim() })
                break;
            case 'repeatNip':
                setUserPassword({ ...userPassword, repeatNip: value.trim() })
                break;
            case 'rfc':
                setUserBillingData({ ...userBillingData, rfc: value })
                break;
            case 'businessName':
                setUserBillingData({ ...userBillingData, businessName: value })
                break;
            case 'address':
                setUserBillingData({ ...userBillingData, address: value })
                break;
            case 'suburb':
                setUserBillingData({ ...userBillingData, suburb: value })
                break;
            case 'city':
                setUserBillingData({ ...userBillingData, city: value })
                break;
            case 'state':
                setUserBillingData({ ...userBillingData, state: value })
                break;
            case 'zipCode':
                setUserBillingData({ ...userBillingData, zipCode: value })
                break;
            case 'emailBilling':
                setUserBillingData({ ...userBillingData, emailBilling: value })
                break;
            default:
                break;
        }
    }

    // const valueOptions = [
    //     {
    //         value: 'Correo',
    //         label: 'Correo'
    //     },
    //     {
    //         value: 'Celular',
    //         label: 'Celular'
    //     },
    // ]

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleChangeGenerateBill = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGenerateBill(event.target.checked);
    };

    const handleChangeSendBill = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendBill(event.target.checked);
    };

    const options: Array<Option> = [
        // { name: "ACTUALIZAR MIS DATOS", icon: "../actualizar-datos-listado.png", iconWhite: "../actualizar-datos-listado-white.png", onClick: (a: Option) => changeViewInfo(a) },
        { name: "DATOS DE FACTURACIÓN", icon: "../icon-datos-facturacion@4.png", iconWhite: "../icon-datos-facturacion@4-white.png", onClick: (a: Option) => changeViewInfo(a) },
        { name: "CAMBIAR DATOS DE SESIÓN", icon: "../cambiar-contraseña-listado.png", iconWhite: "../cambiar-contraseña-listado-white.png", onClick: (a: Option) => changeViewInfo(a) },
        // { name: "CREAR TRÁMITE DE PORTABILIDAD", icon: "../icon-portabilidad@4.png", iconWhite: "../icon-portabilidad@5-white.png", onClick: (a: Option) => changeViewInfo(a) },
        // { name: "CERRAR SESIÓN", icon: "../cerrar-sesión-listado.png", iconWhite: "../cerrar-sesión-listado-white.png", onClick: (a: Option) => changeViewInfo(a) },
    ]

    const disclaimer = "Acepto términos y condiciones*";
    const privacy = "La aceptación de la presente implica su consentimiento y autorización expresa para el tratamiento de sus datos personales según lo contenido en el aviso de privacidad de Megacable publicado en";
    const link = "https://www.megacable.com.mx/privacidad";

    // const FormUpdateUser = (
    //     <div className={clsx(classes.animated,)}>
    //         <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
    //             <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    //                 <Help type={"attention"} helpStyle={"outline"} text1="¡Atención!" text2='Para actualizar sus datos de suscriptor, edite los campos y de click en "ACTUALIZAR"' additionalStyle={{ margin: "10px 10px" }} />
    //             </Grid>
    //             <Grid item xs={12} sm={12} md={4} lg={4} xl={3} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 24 }}>
    //                 <img style={{ maxHeight: '140px', maxWidth: '140px', marginBottom: 16 }} alt={'Actualizar datos'} src={'../actualizar-datos-big.png'} />
    //             </Grid>
    //             <Grid item xs={12} sm={12} md={8} lg={8} xl={9} style={{ padding: 16 }}>
    //                 <FormLabel className={classes.label} component="legend">Número de teléfono Whatsapp</FormLabel>
    //                 <TextField
    //                     value={userData.telefonoWhatsapp}
    //                     name="phone"
    //                     placeholder='Número de teléfono Whatsapp'
    //                     type='tel'
    //                     variant="outlined"
    //                     autoComplete='notAutoComplete'
    //                     fullWidth
    //                     style={{ marginBottom: 16 }}
    //                     inputProps={{ maxLength: 10 }}
    //                     error={userDataError && userDataError.telefonoWhatsapp ? true : false}
    //                     helperText={userDataError && userDataError.telefonoWhatsapp}
    //                     onFocus={() => setUserDataError({ ...userDataError, telefonoWhatsapp: "" })}
    //                     onChange={(event) => handleValueChange(event.target.value, 'telefonoWhatsapp')} />

    //                 <FormLabel className={classes.label} component="legend">Correo electrónico</FormLabel>
    //                 <TextField
    //                     value={userData.email}
    //                     name="email"
    //                     placeholder='Correo electrónico'
    //                     type='email'
    //                     variant="outlined"
    //                     autoComplete='notAutoComplete'
    //                     fullWidth
    //                     style={{ marginBottom: 24 }}
    //                     error={userDataError && userDataError.email ? true : false}
    //                     helperText={userDataError && userDataError.email}
    //                     onFocus={() => setUserDataError({ ...userDataError, email: "" })}
    //                     onChange={(event) => handleValueChange(event.target.value, 'email')} />
    //                 <div>
    //                     <div style={{ display: 'flex', }}>
    //                         <FormControlLabel
    //                             control={
    //                                 <Checkbox
    //                                     checked={checked}
    //                                     onChange={handleChange}
    //                                     name="termsConditions"
    //                                     color="primary"
    //                                     required={true}
    //                                 />
    //                             }
    //                             label={disclaimer}
    //                             style={{ fontWeight: 'bold', color: "#004fc0" }}
    //                         />
    //                     </div>
    //                     <p style={{ textAlign: 'justify', color: "gray" }}>{privacy}</p>
    //                     <Link href={link} style={{ color: "#0797fd" }}>
    //                         {link}
    //                     </Link>
    //                 </div>
    //                 <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: '20px' }}>
    //                     <Button disabled={checked ? false : true} type={checked ? 'primary' : "disabled"} text="ACTUALIZAR" size="large" onClick={validateData} />
    //                 </div>
    //             </Grid>
    //         </Grid>
    //     </div>
    // )

    // const FormChangeUser = (
    //     <>
    //         <div className={clsx(classes.animated,)}>
    //             <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
    //                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    //                     <Help type={"attention"} helpStyle={"outline"} text1="¡Atención!" text2='Para cambiar su usuario, puede ingresar su correo o celular y de click en "CAMBIAR USUARIO"' additionalStyle={{ margin: "10px 10px" }} />
    //                 </Grid>
    //                 <Grid item xs={12} sm={12} md={4} lg={4} xl={3} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 24 }}>
    //                     <img style={{ maxHeight: '140px', maxWidth: '140px', marginBottom: 16 }} alt={'Actualizar datos'} src={'../actualizar-datos-big.png'} />
    //                 </Grid>
    //                 <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ padding: 16 }}>
    //                     <FormLabel className={classes.label} component="legend">Seleccione una opción</FormLabel>
    //                     <TextField
    //                         id="option"
    //                         select
    //                         value={selectedValue}
    //                         onChange={(event) => setSelectedValue(event.target.value)}
    //                         className={classes.textfields}
    //                         variant='filled'
    //                     >
    //                         {valueOptions.map((option) => (
    //                             option.value && option.label ?
    //                                 <MenuItem key={option.value} value={option.value}>
    //                                     {option.label}
    //                                 </MenuItem>
    //                                 :
    //                                 null
    //                         ))}
    //                     </TextField>
    //                     {
    //                         selectedValue === 'Celular' ?
    //                             <>
    //                                 <FormLabel className={classes.label} component="legend">Número de celular</FormLabel>
    //                                 <TextField
    //                                     value={userCellphone}
    //                                     name="phone"
    //                                     placeholder='Número de celular'
    //                                     type='tel'
    //                                     variant="outlined"
    //                                     autoComplete='notAutoComplete'
    //                                     helperText={userCellphoneMsg}
    //                                     error={userCellphoneError ? true : false}
    //                                     fullWidth
    //                                     style={{ marginBottom: 16 }}
    //                                     inputProps={{ maxLength: 10 }}
    //                                     onChange={(e: any) => setUserCellphone(e.target.value.replace(/[^0-9]/g, ''))} />
    //                             </>
    //                             : selectedValue === 'Correo' ?
    //                                 <>
    //                                     <FormLabel className={classes.label} component="legend">Correo electrónico</FormLabel>
    //                                     <TextField
    //                                         value={userEmail}
    //                                         name="email"
    //                                         placeholder='Correo electrónico'
    //                                         type='email'
    //                                         variant="outlined"
    //                                         autoComplete='notAutoComplete'
    //                                         helperText={userEmailMsg}
    //                                         error={userEmailError ? true : false}
    //                                         fullWidth
    //                                         style={{ marginBottom: 16 }}
    //                                         onChange={(e: any) => setUserEmail(e.target.value)} />
    //                                 </>
    //                                 :
    //                                 null
    //                     }
    //                     <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: '20px' }}>
    //                         <Button disabled={selectedValue ? false : true} type={selectedValue ? 'primary' : "disabled"} text="CAMBIAR USUARIO" size="large" onClick={checkTextInputUser} />
    //                     </div>
    //                 </Grid>
    //             </Grid>
    //         </div>
    //     </>
    // )

    const FormUpdateBilling = (
        <div className={clsx(classes.animated,)}>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Help type={"attention"} helpStyle={"outline"} text1="¡Atención!" text2='Para actualizar sus datos de facturación, edite los campos y 
                    "ACTUALIZAR"' additionalStyle={{ margin: "10px 10px" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={3} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 24 }}>
                    <img style={{ maxHeight: '140px', maxWidth: '140px', marginBottom: 16 }} alt={'Actualizar datos'} src={'../icon-datos-facturacion@4.png'} />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ padding: 16 }}>
                    <FormLabel className={classes.label} component="legend">RFC</FormLabel>
                    <TextField
                        value={userBillingData.rfc}
                        name="rfc"
                        placeholder='RFC'
                        variant="outlined"
                        autoComplete='notAutoComplete'
                        fullWidth
                        style={{ marginBottom: 16 }}
                        error={userBillingDataError && userBillingDataError.rfc ? true : false}
                        helperText={userBillingDataError && userBillingDataError.rfc}
                        onFocus={() => setUserBillingDataError({ ...userBillingDataError, rfc: "" })}
                        onChange={(event) => handleValueChange(event.target.value.toUpperCase(), 'rfc')} />
                    <FormLabel className={classes.label} component="legend">Razón Social</FormLabel>
                    <TextField
                        value={userBillingData.businessName}
                        name="businessName"
                        placeholder='Razón Social'
                        variant="outlined"
                        autoComplete='notAutoComplete'
                        fullWidth
                        style={{ marginBottom: 16 }}
                        error={userBillingDataError && userBillingDataError.businessName ? true : false}
                        helperText={userBillingDataError && userBillingDataError.businessName}
                        onFocus={() => setUserBillingDataError({ ...userBillingDataError, businessName: "" })}
                        onChange={(event) => handleValueChange(event.target.value, 'businessName')} />
                    <FormLabel className={classes.label} component="legend">Calle y Número</FormLabel>
                    <TextField
                        value={userBillingData.address}
                        name="address"
                        placeholder='Calle y Número'
                        variant="outlined"
                        autoComplete='notAutoComplete'
                        fullWidth
                        style={{ marginBottom: 16 }}
                        error={userBillingDataError && userBillingDataError.address ? true : false}
                        helperText={userBillingDataError && userBillingDataError.address}
                        onFocus={() => setUserBillingDataError({ ...userBillingDataError, address: "" })}
                        onChange={(event) => handleValueChange(event.target.value, 'address')} />
                    <FormLabel className={classes.label} component="legend">Colonia</FormLabel>
                    <TextField
                        value={userBillingData.suburb}
                        name="suburb"
                        placeholder='Colonia'
                        variant="outlined"
                        autoComplete='notAutoComplete'
                        fullWidth
                        style={{ marginBottom: 16 }}
                        error={userBillingDataError && userBillingDataError.suburb ? true : false}
                        helperText={userBillingDataError && userBillingDataError.suburb}
                        onFocus={() => setUserBillingDataError({ ...userBillingDataError, suburb: "" })}
                        onChange={(event) => handleValueChange(event.target.value, 'suburb')} />
                    <FormLabel className={classes.label} component="legend">Ciudad</FormLabel>
                    <TextField
                        value={userBillingData.city}
                        name="city"
                        placeholder='Ciudad'
                        variant="outlined"
                        autoComplete='notAutoComplete'
                        fullWidth
                        style={{ marginBottom: 16 }}
                        error={userBillingDataError && userBillingDataError.city ? true : false}
                        helperText={userBillingDataError && userBillingDataError.city}
                        onFocus={() => setUserBillingDataError({ ...userBillingDataError, city: "" })}
                        onChange={(event) => handleValueChange(event.target.value, 'city')} />
                    <FormLabel className={classes.label} component="legend">Estado</FormLabel>
                    <TextField
                        value={userBillingData.state}
                        name="state"
                        placeholder='Estado'
                        variant="outlined"
                        autoComplete='notAutoComplete'
                        fullWidth
                        style={{ marginBottom: 16 }}
                        error={userBillingDataError && userBillingDataError.state ? true : false}
                        helperText={userBillingDataError && userBillingDataError.state}
                        onFocus={() => setUserBillingDataError({ ...userBillingDataError, state: "" })}
                        onChange={(event) => handleValueChange(event.target.value, 'state')} />
                    <FormLabel className={classes.label} component="legend">Código Postal</FormLabel>
                    <TextField
                        value={userBillingData.zipCode}
                        name="zipCode"
                        placeholder='Código Postal'
                        variant="outlined"
                        autoComplete='notAutoComplete'
                        fullWidth
                        style={{ marginBottom: 16 }}
                        inputProps={{ maxLength: 6 }}
                        error={userBillingDataError && userBillingDataError.zipCode ? true : false}
                        helperText={userBillingDataError && userBillingDataError.zipCode}
                        onFocus={() => setUserBillingDataError({ ...userBillingDataError, zipCode: "" })}
                        onChange={(event) => handleValueChange(event.target.value, 'zipCode')} />
                    <FormLabel className={classes.label} component="legend">Correo Electrónico</FormLabel>
                    <TextField
                        value={userBillingData.emailBilling}
                        name="emailBilling"
                        placeholder='Correo Electrónico'
                        type='email'
                        variant="outlined"
                        autoComplete='notAutoComplete'
                        fullWidth
                        style={{ marginBottom: 16 }}
                        error={userBillingDataError && userBillingDataError.emailBilling ? true : false}
                        helperText={userBillingDataError && userBillingDataError.emailBilling}
                        onFocus={() => setUserBillingDataError({ ...userBillingDataError, emailBilling: "" })}
                        onChange={(event) => handleValueChange(event.target.value, 'emailBilling')} />
                    <FormLabel className={classes.label} component="legend">Uso de comprobantes</FormLabel>
                    <TextField
                        id="option"
                        select
                        value={useCFDI}
                        onChange={(event) => setUseCFDI(event.target.value)}
                        className={classes.textfields}
                        variant='filled'
                        error={userBillingDataError && userBillingDataError.id_usoCFDI ? true : false}
                        helperText={userBillingDataError && userBillingDataError.id_usoCFDI}
                    >
                        {cfdiList.map((option) => (
                            option.id_usoCFDI && option.descripcion ?
                                <MenuItem key={option.id_usoCFDI} value={option.id_usoCFDI}>
                                    {option.descripcion}
                                </MenuItem>
                                :
                                null
                        ))}
                    </TextField>
                    <FormLabel className={classes.label} component="legend">Regimen Fiscal</FormLabel>
                    <TextField
                        id="option"
                        select
                        value={regFiscal}
                        onChange={(event) => setRegFiscal(event.target.value)}
                        className={classes.textfields}
                        variant='filled'
                        error={userBillingDataError && userBillingDataError.id_regfiscal ? true : false}
                        helperText={userBillingDataError && userBillingDataError.id_regfiscal}
                    >
                        {regFiscalList.map((option) => (
                            option.id_regfiscal && option.descripcion ?
                                <MenuItem key={option.id_regfiscal} value={option.id_regfiscal}>
                                    {option.descripcion}
                                </MenuItem>
                                :
                                null
                        ))}
                    </TextField>
                    <div>
                        {isUpdate ?
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateBill}
                                            onChange={handleChangeGenerateBill}
                                            name="generateBill"
                                            color="primary"
                                        />
                                    }
                                    label={"Generar Factura"}
                                    style={{ fontWeight: 'bold', color: "#004fc0" }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sendBill}
                                            onChange={handleChangeSendBill}
                                            name="sendBill"
                                            color="primary"
                                        />
                                    }
                                    label={"Enviar Factura"}
                                    style={{ fontWeight: 'bold', color: "#004fc0" }}
                                />
                            </div>
                            : null}
                        <div style={{ display: 'flex' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        name="termsConditions"
                                        color="primary"
                                        required={true}
                                    />
                                }
                                label={disclaimer}
                                style={{ fontWeight: 'bold', color: "#004fc0" }}
                            />
                        </div>
                        <p style={{ textAlign: 'justify', color: "gray" }}>{privacy}</p>
                        <Link href={link} style={{ color: "#0797fd" }}>
                            {link}
                        </Link>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: '20px' }}>
                        <Button disabled={checked ? false : true} type={checked ? 'primary' : "disabled"} text="ACTUALIZAR" size="large" onClick={validateBillingData} />
                    </div>
                </Grid>
            </Grid>
        </div>
    )

    // const handleCloseDialog = (isAgree: boolean) => {
    //     if (isAgree) {
    //         removeToken();
    //         history.push("/");
    //     } else {
    //         setAutoSelectionMenu(false);
    //         setIsOpenLogoutDialog(false);
    //     }
    // }

    const getSettingsOption = () => {
        switch (settingsOption) {
            // case 1:
            //     return FormUpdateUser;
            case 2:
                return FormUpdateBilling;
            case 3:
                return (
                    <>
                        {/* {FormChangeUser} */}
                        < UpdatePassword
                            userPassword={userPassword}
                            userPasswordErrors={userPasswordErrors}
                            handleValueChange={handleValueChange}
                            onClick={changePassword} />
                    </>
                )
            // case 4:
            // return <FormPortability />
            // case 5:
            //     return <LogOutDialog
            //         isOpen={isOpenLogoutDialog}
            //         handleCloseDialog={handleCloseDialog} />
            default:
                break;
        }
    }

    return (
        <div style={{ width: "100%", minHeight: contract ? '93vh' : '100%', display: "flex", flexDirection: "column", backgroundColor: "white", }}>
            <div>
                {bannerList && !_.isEmpty(bannerList) ? <Slider size='large' images={bannerList} /> : null}
            </div>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} style={{ flex: 1 }}>
                {
                    contract ?
                        <>
                            <Hidden mdUp>
                                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                                    <Paper style={{ height: "100%" }}>
                                        <ComponentHeader type="H2" title="Configuración" />
                                        {
                                            isOpen ?
                                                <>
                                                    <ComponentHeaderSecondary type='H2'
                                                        button={
                                                            { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setIsOpen(false) }
                                                        } title={titleInfo} />
                                                    {getSettingsOption()}
                                                </>
                                                :
                                                <OnlineSupport onClick={() => setIsOpen(true)} options={options} autoSelection={autoSelectionMenu} />
                                        }
                                    </Paper>
                                </Grid>
                            </Hidden>
                            <Hidden smDown>
                                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                                    <Paper style={{ height: "100%" }}>
                                        <ComponentHeader type="H2" title="Configuración" />
                                        <OnlineSupport options={options} autoSelection={autoSelectionMenu} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                                    <ComponentHeaderSecondary type='H2' title={titleInfo} />
                                    {getSettingsOption()}
                                </Grid>
                            </Hidden>
                        </>
                        :
                        <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
                }
            </Grid>
        </div>
    )
}


const mapState = (state: any) => ({
    login: state.login,
    banners: state.banners,
    loading: state.loading,
    pagosData: state.pagosData,
    currentContract: state.currentContract,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({

});

const connector = connect(mapState, mapDispatch)
export default (connector(Settings));