import React, { useEffect } from 'react';
import { /*useRouteMatch,*/ useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Box, TextField, Button, Typography, InputAdornment, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import clsx from 'clsx';
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { SuscriptorYServicios } from 'megacable-mobile-api/models';
import { BannersRedux } from 'megacable-mobile-library';
import IsValidToken from '../utils/IsValidToken';
import moment from 'moment';
import _ from 'lodash';
import * as api from 'megacable-mobile-api';
import awsmobile from '../aws-exports';
import { logAnalytics, startAnalytics } from '../analitycsV2';
import { keycloak } from '../App';
import checkToken from '../utils/checKToken';


const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

type PropsFromRedux = ConnectedProps<typeof connector>

type ICardUpdateModal = PropsFromRedux & {
    user: SuscriptorYServicios,
}

const CardUpdateModal = (props: ICardUpdateModal) => {
    const classes = styles();
    const { user, login } = props;
    const [token, setToken] = React.useState<string>('');
    // const [tarjeta, setTarjeta] = React.useState<string>("");
    // const [loading, setLoading] = React.useState(false);
    // const [finish, setFinish] = React.useState(false);

    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [cardExpired, setCardExpired] = React.useState<any>();
    const [bTieneConvenio, setBTieneConvenio] = React.useState<boolean>(false);
    const convenioBanners = useSelector((state: any) => state.banners.convenioBanners);
    const [imgSinConvenio, setImgSinConvenio] = React.useState<string>('');
    const [imgConvenio, setImgConvenio] = React.useState<string>('');
    const [mmaa, setMmaa] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    // const [successMessage, setSuccessMessage] = React.useState<string>("");
    // let match = useRouteMatch();
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(BannersRedux.getConvenioBanners());
    }, [])

    useEffect(() => {
        if (login) {
            if (_.has(login, ['auth', 'token'])) {
                setToken(login.auth.token);
            }
        }

        return () => { }
    }, [login])

    useEffect(() => {
        startAnalytics('cosult_convenio_id');
        if (token) {
            checkToken(() => {
                apiV2.getApiV2IsCardExpired({ headers: { "Authorization": `Bearer ${keycloak.token}` } })
                    .then(response => {
                        let result = response.data;
                        logAnalytics('cosult_convenio_id');

                        if (_.has(result, 'tarjeta') && result.actualizar === true) {
                            setShowModal(true);
                            setBTieneConvenio(true);
                            setCardExpired(result);
                        } else if (result.codigo === '902') {
                            setShowModal(true);
                            setBTieneConvenio(false);
                            setCardExpired(result);
                        }
                    })
                    .catch(err => {
                        err = _.get(err, 'response', err);
                        if (_.has(err, 'data') && err.data === "invalid token") {
                            IsValidToken(err.data);
                        }
                    })
            })
        }

    }, [token]);

    useEffect(() => {
        if (convenioBanners) {
            const bannerTmp = _.orderBy(convenioBanners, 'id', 'asc');
            let imgSinConvenio = bannerTmp && bannerTmp.length > 0 ? bannerTmp[0].imgName : "";
            let imgConvenio = bannerTmp && bannerTmp.length > 0 ? bannerTmp[1].imgName : "";
            if (imgSinConvenio) { setImgSinConvenio(imgSinConvenio); }
            if (imgConvenio) { setImgConvenio(imgConvenio); }
        }
        return () => { }
    }, [convenioBanners]);

    const handleClose = () => {
        setShowModal(false);
        localStorage.setItem('rejectedSaveCard', 'rejected');
    };

    const addCardConvenio = () => {
        setShowModal(false);
        history.replace({
            pathname: '/addCardConvenio',
            state: {
                bTieneConvenio: bTieneConvenio,
                cardExpired: cardExpired,
                user: user,
            }
        });
    }

    const addMMAA = (value: string) => {
        let valueTmp: string = _.cloneDeep(value);
        let onlyNums = valueTmp.replace(/[^0-9]/g, '');
        if (onlyNums) {
            onlyNums = onlyNums.split("/").join("")
            if (onlyNums.length <= 4) {
                let cardFormatArray: Array<string> | null = onlyNums.match(/.{1,2}/g);
                if (cardFormatArray) {
                    let newCardFormat: string = cardFormatArray.join("/");
                    setMmaa(newCardFormat);
                }
            }
        } else {
            setMmaa('');
        }
    }

    const validateExpirationDate = () => {
        let mmaaTmp = _.cloneDeep(mmaa);
        let cardExpiredTmp = _.cloneDeep(cardExpired);
        let lastExpirationDate = moment(`${cardExpiredTmp.tarjeta.vencimiento.mes}/${cardExpiredTmp.tarjeta.vencimiento.anio}`, 'MM/YY');
        let date = moment(mmaaTmp, 'MM/YY');
        if (!date.isValid()) {
            setErrorMessage("Fecha Inválida");
            return false;
        } else if (date < lastExpirationDate) {
            setErrorMessage("La tarjeta tiene fecha menor que la ya registrada");
            return false;
        } else if (date < moment()) {
            setErrorMessage("La tarjeta ha expirado");
            return false;
        }
        setErrorMessage('');
        return true;
    }

    const updateExpirationDate = () => {
        let errorMessage = "No se ha podido actualizar el vencimiento de la tarjeta";
        let bTieneConvenioTmp = _.cloneDeep(bTieneConvenio);

        if (bTieneConvenioTmp) {
            if (validateExpirationDate()) {
                let mmaaTmp = _.cloneDeep(mmaa);
                // let tokenTmp = _.cloneDeep(token);
                let date = moment(mmaaTmp, 'MM/YY');
                let cardExpiredTmp = _.cloneDeep(cardExpired);
                let request = {
                    "terminacion": cardExpiredTmp.tarjeta.terminacion,
                    "tarjeta": {
                        "numero": "",
                        "vencimiento": {
                            "anio": date.format('YYYY'),
                            "mes": date.format('MM')
                        }
                    }
                }
                // setLoading(true);
                checkToken(() => {
                    apiV2.postApiV2UpdateExpirationCard(request, { headers: { "Authorization": `Bearer ${keycloak.token}` } }).then(response => {
                        let result = response.data;
                        if (_.isEmpty(result)) {
                            setErrorMessage(errorMessage);
                            // setLoading(false);
                        } else if (!_.has(result, 'codigo')) {
                            setErrorMessage(errorMessage);
                            // setLoading(false);
                        } else if (_.has(result, 'mensaje') && result.mensaje) {
                            // setLoading(false);
                            setShowModal(false);
                            // setFinish(true);
                            // setSuccessMessage(result.mensaje); 
                        } else if (result.codigo === 0) {
                            // setFinish(true);
                            // setLoading(false);
                            // setSuccessMessage(result.mensaje); 
                        } else if (result.codigo > 0) {
                            setErrorMessage(result.mensaje);
                            // setLoading(false);
                        } else {
                            setErrorMessage(errorMessage);
                            // setLoading(false);
                        }
                    }).catch(err => {
                        err = _.get(err, 'response', err);
                        if (_.has(err, 'data') && err.data === "invalid token") {
                            IsValidToken(err.data);
                        } else {
                            errorMessage = "No se ha podido actualizar";
                            setErrorMessage(errorMessage);
                            //   setLoading(false);
                        }
                    })
                })
            }
        }
    }

    return (
        <Dialog onClose={() => { }} aria-labelledby="customized-dialog-title" open={showModal}>
            <MuiDialogTitle disableTypography className={classes.dialogTitleContainer}>
                <Typography component={'span'} variant="h6">
                    <Box fontWeight={700} style={{ textAlign: "center" }}>
                        Estimado Suscriptor
                    </Box>
                </Typography>
            </MuiDialogTitle>
            <DialogContent dividers>
                {
                    imgSinConvenio && imgConvenio && bTieneConvenio && cardExpired ?
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography component={'span'} variant="body1" color="textSecondary">
                                <Box style={{ textAlign: "center", marginTop: 8, marginBottom: 8 }}>
                                    Detectamos que ha vencido la tarjeta con terminación {cardExpired.tarjeta.terminacion}
                                </Box>
                            </Typography>
                            <img src={imgConvenio} alt='Convenio Megacable' className={classes.convenioImg} />
                            <TextField
                                value={mmaa}
                                placeholder="MM/AA"
                                error={errorMessage ? true : false}
                                helperText={errorMessage ? errorMessage : ''}
                                className={clsx(classes.inputDateAndCvv)}
                                onChange={(event) => addMMAA(event.target.value)}
                                inputProps={{ style: { color: "#005ca7" }, maxLength: 5 }}
                                InputProps={{
                                    endAdornment: (
                                        <Tooltip
                                            className={classes.tooltip}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title="Fecha ubicada al frente de tu tarjeta, debajo del número de tarjeta.">
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "#cccccc" }} />
                                            </InputAdornment>
                                        </Tooltip>
                                    ),
                                }}
                            />
                        </div>
                        : <>
                            <img src={imgSinConvenio} alt='Sin convenio Megacable' className={classes.convenioImg} />
                            <div style={{ marginBottom: 8, cursor: "pointer" }} onClick={() => { }}>
                                <a href="https://megacable.com.mx/legales" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                    <Typography component={'span'} variant="body1" color="textSecondary">
                                        <Box style={{ textAlign: "center" }}>
                                            Aplica términos y condiciones.
                                        </Box>
                                    </Typography>
                                </a>
                            </div>
                        </>
                }
            </DialogContent>
            <MuiDialogActions className={bTieneConvenio ? classes.dialogActionsContentVert : classes.dialogActionsContentHoriz}>
                {
                    bTieneConvenio &&
                    <Button onClick={updateExpirationDate} color="primary" className={classes.button}>
                        <Typography component={'span'} variant="h6">
                            <Box fontWeight={700} color="#0069bf">
                                Actualizar
                            </Box>
                        </Typography>
                    </Button>
                }
                <Button onClick={addCardConvenio} color="primary" className={clsx(classes.button, !bTieneConvenio && classes.buttonBorderRight)}>
                    <Typography component={'span'} variant="h6">
                        <Box fontWeight={700} color="#0069bf">
                            Agregar tarjeta
                        </Box>
                    </Typography>
                </Button>
                <Button onClick={handleClose} color="primary" className={classes.button}>
                    <Typography component={'span'} variant="h6">
                        <Box fontWeight={700} color="#0069bf">
                            Ahora no
                        </Box>
                    </Typography>
                </Button>
            </MuiDialogActions>
        </Dialog>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    convenioImg: {
        width: "100%",
        backgroundSize: "contain",
        // marginBottom: 16,
        [theme.breakpoints.down('md')]: {

        },
        [theme.breakpoints.down('sm')]: {

        },
        [theme.breakpoints.down('xs')]: {

        }
    },

    dialogTitleContainer: {
        display: "flex",
        flexDirection: "column",
    },

    dialogActionsContentHoriz: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    dialogActionsContentVert: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    button: {
        flex: 1
    },

    buttonBorderRight: {
        borderRight: "1px solid #e0e0e0",
    },

    inputDateAndCvv: {
        alignSelf: "center",
        marginTop: 16,
        marginBottom: 16,
    },

    tooltip: {
        backgroundColor: "transparent",
        color: theme.palette.common.black
    }
}));

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: 0,
    },
}))(MuiDialogContent);


const mapState = (state: any) => ({
    login: state.login,
    currentContract: state.currentContract,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({

});

const connector = connect(mapState, mapDispatch);

export default (connector(CardUpdateModal));
