import React, { useEffect, useRef, useState } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import moment, { Moment } from 'moment';
import SocialNetworkIcon from '../components/SocialNetworkIcon';

// import Typography from './Typography';
import { Grid, Divider, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { ConsumosResponse } from 'megacable-mobile-api/models';
import ProgressBarData from './ProgressBarData';

const styles = ({ palette, breakpoints }: any) => createStyles({
    expirationContainer: {
        width: '175px',
        height: '45px',
        backgroundImage: 'linear-gradient(90deg, #0078D0 0%, #004FC0 100%)',
        /* backgroundColor: '#006dcc', */
        margin: 'auto',
        marginTop: '12px',
        borderRadius: '8px',
        position: 'relative'
    },
    textContainer: {
        display: 'flex',
        position: 'relative',
        height: 'fit-content',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    expiration: {
        color: '#ffffff',
        textAlign: 'center',
        fontWeight: 600,
        lineHeight: '12px',
        margin: 'auto'
    },
    expiresIn: {
        margin: '0px',
        color: '#282828',
        textAlign: 'left',
        marginLeft: '15px',
        fontWeight: 700
    },
    days: {
        margin: '0px',
        color: '#0797fd;',
        textAlign: 'left',
        fontWeight: 700
    },

    root: {
        width: 450,
        display: "flex",
        borderRadius: 10,
        backgroundColor: "#fff",
        [breakpoints.down('xs')]: {
            width: "90%",
        },
        boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    },

    flexRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 8,
    },

    flexColumn: {
        display: "flex",
        flexDirection: "column",
    },

    phoneIcon: {
        height: '24px',
        width: '17px',
        marginRight: 8,
    },

    phone: {
        color: '#0797fd;',
        fontWeight: 700,
    },

    mgbsAvailableContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 8
    },

    canvasContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    },

    canvasText: {
        position: "absolute",
        marginTop: 50,
    },

    validityConainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 8
    },

    unlimited: {
        fontWeight: 700,
        textAlign: "center",
        color: '#207ce5',
        fontSize: '32px',
        margin: '3px 0px',
        lineHeight: '27px',
    },
});

interface MobileDetail extends WithStyles<typeof styles> {
    totalMB: number;
    usedMB: number;
    phoneNumber: string;
    expiration: Moment;
    consumos: null | ConsumosResponse;
}

export const MobileDetail = ({ expiration, consumos, phoneNumber, totalMB, usedMB, classes }: MobileDetail) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current?.getContext('2d')

            if (ctx) {
                const pi = Math.PI;

                const percentage = ((usedMB / totalMB) * 100).toFixed(0);
                const result = parseFloat(percentage) / 100.0;
                const arcEnd = result > 1 ? 1 : result;

                const x = 100,
                    y = 100,
                    radius = 70,
                    angleStart = 1 * pi,
                    angleEnd = (2 - arcEnd) * pi;


                //Create gradient
                const gradient = ctx.createLinearGradient(30, 30, 110, 0);
                gradient.addColorStop(0, '#2d3591');
                gradient.addColorStop(0.3, '#24af4e');
                gradient.addColorStop(0.6, '#85c752');
                gradient.addColorStop(1, '#f1e660');


                //Draw circle
                ctx.beginPath();
                ctx.arc(x, x, radius, 1 * pi, 0 * pi, false);
                ctx.lineWidth = 20;
                ctx.strokeStyle = '#cccccc'
                ctx.stroke();

                //Draw arc
                ctx.beginPath();
                ctx.arc(x, y, radius, angleStart, angleEnd);
                ctx.strokeStyle = gradient;
                ctx.lineWidth = 20;
                ctx.stroke();
            }
        }
    }, [totalMB, usedMB]);

    const currentDate = moment().startOf('day');
    const expirationDate = expiration.startOf('day');
    const difference = expirationDate.diff(currentDate, 'days')
    const [percentageShow, setPercentageShow] = useState(0);
    const [percentageShowPromo, setPercentageShowPromo] = useState(0);

    const parseStorageUnitsMeasure = (measure: string) => {
        let newMeasure = measure.toUpperCase();
        let number = newMeasure.match(/[.\d]+/g);
        let cadena = newMeasure.match(/[A-Za-zñÑ]+/g);

        switch (cadena && cadena[0]) {
            case 'KB':
                return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) / 1024 : 0;
            case 'MB':
                return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) : 0;
            case 'GB':
                return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) * 1024 : 0;
            default:
                return;
        }
    }

    useEffect(() => {
        if (consumos && consumos.consumos) {
            let saldoProcess = parseStorageUnitsMeasure(consumos.consumos.saldo);
            let ofertaOriginalProcess = parseStorageUnitsMeasure(consumos.consumos.ofertaOriginal);
            let saldoPromoProcess = parseStorageUnitsMeasure(consumos.consumos.saldoPromo);
            let ofertaPromoProcess = parseStorageUnitsMeasure(consumos.consumos.ofertaPromo);

            let percentageShowTmp = (saldoProcess ? saldoProcess : 0) * 100 / (ofertaOriginalProcess ? ofertaOriginalProcess : 0);
            if (percentageShowTmp) {
                setPercentageShow(percentageShowTmp);
            }
            let percentageShowPromoTmp = (saldoPromoProcess ? saldoPromoProcess : 0) * 100 / (ofertaPromoProcess ? ofertaPromoProcess : 0);
            if (percentageShowPromoTmp) {
                setPercentageShowPromo(percentageShowPromoTmp)
            }
        }
    }, [consumos])

    return (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.root}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={clsx(classes.flexRow,)}>
                <Typography component={'span'} variant="h6">
                    <Box fontWeight={700} mr={1}>
                        Vence en:
                    </Box>
                </Typography>
                <Typography component={'span'} variant="h6">
                    <Box fontWeight={700} color="#0797fd">
                        {`${difference} dias`}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={clsx(classes.flexRow,)}>
                <img src="../celphone.png" className={classes.phoneIcon} alt="celphone" />
                <Typography component={'span'} variant="h5">
                    <Box fontWeight={700} color="#0797fd">{phoneNumber}</Box>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider style={{ backgroundColor: "#0797fd", borderWidth: 4, }} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={clsx(classes.mgbsAvailableContainer)}>
                <Typography component={'span'} variant="body1" >
                    <Box textAlign="center">MB DISPONIBLES</Box>
                </Typography>
                <div className={classes.canvasContainer}>
                    <canvas width="210px" height="120px" ref={canvasRef}></canvas>
                    <div className={classes.canvasText}>
                        <Typography component={'span'} variant="body2">
                            <Box fontWeight={500} textAlign="center" color="#61617d">Quedan</Box>
                        </Typography>
                        <Typography component={'span'} variant="body1">
                            <Box fontWeight={700} textAlign="center" color="#070797">{consumos ? consumos.consumos.saldo : `${(totalMB - usedMB).toFixed(2)} MB`}</Box>
                        </Typography>
                        <Typography component={'span'} variant="body2">
                            <Box fontWeight={500} mr={1} textAlign="center" color="#61617d">de {consumos && consumos.consumos.ofertaOriginal ? consumos.consumos.ofertaOriginal : ''}</Box>
                        </Typography>
                    </div>
                </div>
                {consumos && consumos.consumos ?
                    <>
                        <ProgressBarData completed={percentageShow} progressBarTitle={"CONTRATADO"} bgColor={'linear-gradient(to right, #0600B1, #1173D7, #58E031, #BBE800)'} leftText={consumos.consumos.saldo ? consumos.consumos.saldo : ''} rightText={consumos.consumos.ofertaOriginal ? consumos.consumos.ofertaOriginal : ''} />
                        <ProgressBarData completed={percentageShowPromo} progressBarTitle={"PROMO"} bgColor={'linear-gradient(to right, #340799, #FE584B, #FCC231)'} leftText={consumos.consumos.saldoPromo ? consumos.consumos.saldoPromo : ''} rightText={consumos.consumos.ofertaPromo ? consumos.consumos.ofertaPromo : ''} />
                    </>
                    :
                    null
                }
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={clsx(classes.validityConainer)}>
                <div>
                    <Typography component={'span'} variant="body1" >
                        <Box mb={3} textAlign="center">Minutos | SMS | Redes</Box>
                    </Typography>
                    <Box mb={3}>
                        <SocialNetworkIcon />
                    </Box>
                    <Box mb={3}>
                        <Typography component={'span'} variant="h6">
                            <Box className={classes.unlimited}>ILIMITADOS</Box>
                        </Typography>
                    </Box>
                    <div className={classes.expirationContainer}>
                        <div className={classes.textContainer}>
                            <h3 className={classes.expiration}>Vigencia <span >{expiration.format('DD/MM/YYYY')}</span></h3>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(MobileDetail);
