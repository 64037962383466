import React, { useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider, Grid } from '@material-ui/core';
import moment from 'moment';

interface IService {
    servicio: string
    tipo: string
    estado: string
    fechaultimopago: string
}

interface IServicesItemList extends WithStyles<typeof styles> {
    service: IService,
    showDate?: boolean,
}

const ServicesItemList = (props: IServicesItemList) => {
    const { classes, service, showDate } = props;
    const { servicio, tipo, estado, fechaultimopago } = service;
    const [gridNumber, setGridNumber] = React.useState<any>(12);

    useEffect(() => {
        if (showDate) {
            setGridNumber(7);
        } else {
            setGridNumber(12);
        }
        return () => { }
    }, [showDate])

    return (
        <div className={classes.root}>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} spacing={1}>

                <Grid item xs={gridNumber} sm={gridNumber} md={gridNumber} lg={gridNumber} xl={gridNumber}>
                    <div className={classes.container}>
                        <Typography component={'span'} variant="body1">
                            <Box fontWeight={700} color="#2a5179">
                                {servicio}
                            </Box>
                        </Typography>
                        <Typography component={'span'} variant="body2" color="textPrimary">
                            <Box>
                                {tipo}
                            </Box>
                        </Typography>
                    </div>
                </Grid>


                {
                    showDate && (
                        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <div className={classes.container}>
                                <Typography component={'span'} variant="body2">
                                    <Box color="#2a5179">
                                        {estado}
                                    </Box>
                                </Typography>
                                <Typography component={'span'} variant="body2" color="textPrimary">
                                    <Box>
                                        Último pago: {moment(fechaultimopago).format('DD/MM/YY')}
                                    </Box>
                                </Typography>
                            </div>
                        </Grid>
                    )
                }


            </Grid>
            <Divider className={classes.divider} />
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },

    container: {
        display: "flex",
        flexDirection: "column",
    },

    divider: {
        marginTop: 16,
        marginBottom: 16,
    }

});

export default withStyles(styles)(ServicesItemList);
