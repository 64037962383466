import React, { Component } from 'react';
import _ from 'lodash';

declare global {
    interface Window {
        google: any;
    }
}

export interface Props {
    wifiPoints: Array<any>,
    position: any,
    mapZoom: number
}

export interface State {
    location?: any,
    wifiPointsWithinRadius? : any,
    map?: any,
    activeMaker?: any,
    activeInfoWindow?: any
}

const iconPath = "M23.1,48.6C9.2,28.4,6.6,26.3,6.6,18.9C6.6,8.7,14.8,0.4,25,0.4s18.4,8.2,18.4,18.4c0,7.4-2.6,9.5-16.5,29.7C26,49.9,24,49.9,23.1,48.6L23.1,48.6z M25,26.5c4.2,0,7.7-3.4,7.7-7.7s-3.4-7.7-7.7-7.7s-7.7,3.4-7.7,7.7S20.8,26.5,25,26.5z";

const iconBase = {
    path: iconPath,
    fillOpacity: 1,
    strokeWeight: 1,
    scale: .7,
    anchor: {}
}

let normalMarkerIcon = {
    ...iconBase,
    fillColor: '#006DCC',
    strokeColor: '#044C9B',
}

let activeMakerIcon = {
    ...iconBase,
    fillColor: '#985CA6',
    strokeColor: '#844797'
}

class WifiPoints extends Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            activeMaker: null,
            activeInfoWindow: null
        }
    }

    componentDidMount() {

        let { position, wifiPoints } = this.props;
        let location = {
            latitude: _.has(position, 'latitude') ? position.latitude : 20.588023900000003,
            longitude: _.has(position, 'longitude') ? position.longitude : -100.34682409999999
        }

        this.setState({ location, wifiPointsWithinRadius: wifiPoints}, () => {
            this.createMap()
        })
    }

    createMap = () => {
        const { google } = window;
        const { location } = this.state;

        const latitude = location.latitude !== null || location.latitude === 0 ? location.latitude : 20.588023900000003;
        const longitude = location.longitude !== null || location.longitude === 0 ? location.longitude : -100.34682409999999;

        const position = { lat: latitude, lng: longitude };

        let map = null;

        if (google) {
            map = new google.maps.Map(document.getElementById('map'), { zoom: this.props.mapZoom ? this.props.mapZoom : 14, center: position });
        }

        this.setState({ map }, () => {
            if (!_.isEmpty(position))
                this.generateCircle()
        })
    }

    generateCircle = () => {
        const { location, map } = this.state;
        const { google } = window;
        const { wifiPointsWithinRadius } = this.state;

        const latitude = location.latitude !== null || location.latitude === 0 ? location.latitude : 20.588023900000003;
        const longitude = location.longitude !== null || location.longitude === 0 ? location.longitude : -100.34682409999999;

        const position = { lat: latitude, lng: longitude };

        if (google && wifiPointsWithinRadius.length > 0) {
            normalMarkerIcon.anchor = new google.maps.Point(50, 50);

            new google.maps.Circle({
                center: position,
                map: map,
                radius: 7000,
                strokeWidth: 1,
                strokeColor: 'rgba(101, 170, 213, 0.20)',
                fillColor: 'rgba(101, 170, 213, 0.20)'
            });

            new google.maps.Marker({
                position: position,
                map: map,
                title: 'UBICACIÓN ACTUAL',
                zIndex: 2000
            })

            _.forEach(wifiPointsWithinRadius, (wifiPoint, key) => {

                let marker = new google.maps.Marker({
                    position: { lat: Number(wifiPoint.Latitude), lng: Number(wifiPoint.Longitude) },
                    map: map,
                    title: wifiPoint.Nombre_Comercial,
                    icon: normalMarkerIcon
                })

                new google.maps.event.addListener(marker, 'click', () => {
                    let { activeMaker, activeInfoWindow } = this.state;
                    if (activeMaker && activeInfoWindow) {
                        if (activeMaker === marker) {
                            normalMarkerIcon.anchor = new google.maps.Point(50, 50);
                            activeMaker.setIcon(normalMarkerIcon)
                            activeInfoWindow.close();
                        } else {
                            normalMarkerIcon.anchor = new google.maps.Point(50, 50);
                            activeMaker.setIcon(normalMarkerIcon);
                            activeInfoWindow.close();
                            activeMakerIcon.anchor = new google.maps.Point(50, 50);
                            marker.setIcon(activeMakerIcon);
                            let infoWindow = new google.maps.InfoWindow({
                                content: marker.title
                            })
                            infoWindow.open(map, marker);
                            this.setState({ activeMaker: marker, activeInfoWindow: infoWindow })
                        }
                    } else {
                        activeMakerIcon.anchor =  new google.maps.Point(50, 50);
                        marker.setIcon(activeMakerIcon);
                        let infoWindow = new google.maps.InfoWindow({
                            content: marker.title
                        })
                        infoWindow.open(map, marker);
                        this.setState({ activeMaker: marker, activeInfoWindow: infoWindow })
                    }
                })
            })
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        const { position, wifiPoints } = this.props;

        let location = {
            latitude: position && position.latitude ? Number(position.latitude) : 20.588023900000003,
            longitude: position && position.longitude ? Number(position.longitude) : -100.34682409999999
        }

        if (!_.isEqual(location, this.state.location) || (!_.isEqual(wifiPoints, this.state.wifiPointsWithinRadius)&& wifiPoints.length > 0 )) {
            this.setState({ location, wifiPointsWithinRadius: wifiPoints}, () => {
                this.createMap()
            })
        }
    }

    render() {
        const { google } = window;
        if (google) {
            return <div id="map" style={{ height: 'calc( 100% - 50px)', width: '100%' }} />
        }
        return (
            <div>NO Mapa</div>
        )
    }
}

export default WifiPoints;