import * as React from 'react';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export default function BlockedDialog(props) {

    const handleClose = () => {
        props.onExit();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={props.open}
            PaperProps={{
                style: {
                    backgroundImage: 'linear-gradient(90deg, rgba(213,232,247,1) 0%, rgba(236,243,249,1) 100%)',
                    borderRadius: 8,
                    width: 400
                },
            }}
        >
            <div style={{
                backgroundImage: 'linear-gradient(90deg, rgba(4,127,230,1) 0%, rgba(124,187,240,1) 100%)',
                width: '100%',
                fontSize: 70,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderRadius: 8,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            }}>
                <FontAwesomeIcon icon={faExclamationTriangle} color="white" solid style={{ marginTop: 30, marginBottom: 30 }} />
            </div>
            <DialogContent>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: 26,
                        color: '#107CE1',
                        textAlign: "center",
                        fontWeight: 'bold',
                        marginBottom: 15
                    }}>
                        INTENTO DE CONTRATACIÓN
                    </Typography>
                    <Typography style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: '#474747'
                    }}>
                        Cuenta con un intento de contratación
                    </Typography>
                    <Typography style={{
                        fontSize: 16,
                        marginTop: 3,
                    }}>
                        {props.servicio ? `del servicio de ${props.servicios} en los últimos` : "del servicio en los últimos"}
                    </Typography>
                    <Typography style={{
                        fontSize: 16,
                    }}>
                        30 minutos
                    </Typography>
                    <Typography style={{
                        fontSize: 18,
                        textAlign: "center",
                        fontWeight: 'bold',
                        color: '#107CE1',
                        lineHeight: 1.5,
                        marginTop: 15
                    }}>
                        Debe esperar para volver a intentarlo
                    </Typography>
                    <Button style={{
                        height: 35,
                        width: 120,
                        alignSelf: 'center',
                        borderRadius: 20,
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundImage: 'linear-gradient(90deg, rgba(4,127,230,1) 0%, rgba(124,187,240,1) 100%)',
                        color: 'white',
                        fontWeight: 'bold'
                    }}
                        onClick={handleClose}
                    >
                        Aceptar
                    </Button>
                </div>
            </DialogContent>
        </Dialog >
    );
}