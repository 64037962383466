import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from "react-router-dom";
import Keycloak from 'keycloak-js';
import 'moment/locale/es';

import Routes from './Routes';
import storev2 from './storeV2';
import { setStoreV2 } from './storeV2/store';
import { createMuiTheme } from '@material-ui/core';
import LoginScreen from './views/loginScreen/LoginScreen';
import PublicRoute from './components/PublicRoute';
import ResetPassword from './views/resetPassword/ResetPassword';
import log from './analitycsV2';
// import Register from './components/Register';
// import ForgotData from './components/ForgotData';
import { saveToken } from './utils';

const megacableTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#006DCC',
      dark: '#044C9B'
    },
    secondary: {
      main: '#002A4F',
      dark: '#01192B',
    },
  },
  typography: {
    h1: {
      fontFamily: "Sarabun"
    },
    h2: {
      fontFamily: "Sarabun"
    },
    h3: {
      fontFamily: "Sarabun"
    },
    h4: {
      fontFamily: "Sarabun"
    },
    h5: {
      fontFamily: "Sarabun"
    },
    h6: {
      fontFamily: "Sarabun",
    },
    body1: {
      fontFamily: "Sarabun",
    },
    body2: {
      fontFamily: "Sarabun",
    },
    button: {
      fontFamily: "Sarabun"
    },
    subtitle1: {
      fontFamily: "Sarabun"
    },
    subtitle2: {
      fontFamily: "Sarabun"
    },
    caption: {
      fontFamily: "Sarabun"
    },
    overline: {
      fontFamily: "Sarabun"
    }
  }
});

export const keycloak = Keycloak('/keycloak.json');

function App() {
  const [date, setDate] = useState(new Date());
  const [checkAuth, setCheckAuth] = useState(false);

  useEffect(() => {
    setDate(new Date());

    if (!keycloak.authenticated) {
      keycloak.init({ onLoad: 'login-required', "checkLoginIframe": false })
        .then((authenticated) => {
          // logAnalytics('login_id');
          keycloak.loadUserInfo().then((user) => {
            keycloak.loadUserProfile().then((user) => {
              // dispatch({ type: AppActions.KEYCLOAK_SUCCESS, keycloak, authenticated });
              addToken(keycloak.token ? keycloak.token : "");
              setCheckAuth(true);
            })
          })

          // addToken(JSON.stringify(keycloak.token));
          // setCheckAuth(true);
        })
    }
  }, [])

  const addToken = (e: string) => {
    saveToken(e);
    // history.push('/myId');
  }

  window.onbeforeunload = () => {
    log('app', new Date().getTime() - date.getTime());
  }

  setStoreV2(storev2)
  return (
    <ThemeProvider theme={megacableTheme}>
      <div className="App" style={{ height: '100%' }}>
        <Provider store={storev2}>
          <BrowserRouter>
            <Switch>
              <PublicRoute path="/" restricted={true} component={LoginScreen} exact />
              {/* <PublicRoute path="/register" restricted={true} component={LoginScreen} exact />
              <PublicRoute path="/forgotData" restricted={true} component={LoginScreen} exact /> */}
              <PublicRoute path="/resetPassword/:resetToken" restricted={false} component={ResetPassword} exact />
              {checkAuth && <Routes />}
            </Switch>
          </BrowserRouter>
        </Provider>
      </div>
    </ThemeProvider>
  );
}

export default App;
