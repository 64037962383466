// import { bindActionCreators, Dispatch } from "redux";
import { /*connect, ConnectedProps, useSelector,*/ useDispatch } from "react-redux";
import { LoginRedux, AlertRedux } from 'megacable-mobile-library';


const IsValidToken = (message: string) => {
    const dispatch = useDispatch();
    if (message && message === "invalid token") {
        dispatch(AlertRedux.showAlert("La sesión ha caducado.", "SESION"));
        dispatch(LoginRedux.logOut(()=>{}));
    }
}

export default IsValidToken;