import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Box, Grid, IconButton, Theme, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { DatosPago } from 'megacable-mobile-api/models';
import { IContract, IServicios } from '../storeV2/ducks/types';
import _ from 'lodash';
import moment from 'moment';


const styles = ({ palette, breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundImage: `linear-gradient(90deg, #0078D0 0%, #004FC0 100%)`
    },

    rootDebt: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: '#FC0022'
    },

    gridContainer: {
        flex: 1,
    },

    cardInfo: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
        paddingTop: 8,
        paddingLeft: 24,
        paddingBottom: 8,
        // [breakpoints.down('md')]: {
        //     alignItems: "left",
        // }
    },

    cardInfoReloadIcon: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        padding: 16
    },

    cardPrice: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        color: "white",
        paddingTop: 8,
        paddingRight: 24,
        paddingBottom: 8,
        backgroundColor: "#002d4d",
        // [breakpoints.down('md')]: {
        //     alignItems: "center",
        // }
    },

    cardPriceDebt: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        color: "#FC0022",
        paddingTop: 8,
        paddingRight: 24,
        paddingBottom: 8,
        backgroundColor: "white",
        // [breakpoints.down('md')]: {
        //     alignItems: "center",
        // }
    },

    reloadIcon: {
        fontSize: 40,
        color: "white",
    },
});

interface ContractInfoBannerProps extends WithStyles<typeof styles> {
    contract?: IContract,
    reload?: boolean,
    description?: string,
    paymentData?: DatosPago,
    reloadAction?: () => void,
}

export const ContractInfoBanner = ({ classes, contract, reload, description, paymentData, reloadAction }: ContractInfoBannerProps) => {

    const [payment, setPayment] = React.useState<DatosPago>();
    const [paymentLimitDate, setPaymentLimitDate] = React.useState<string>('');
    const [amount, setAmount] = React.useState<string>('');
    const [hasDebt, setHasDebt] = React.useState<boolean>(false);
    const [services, setServices] = React.useState<IServicios | undefined>();

    useEffect(() => {
        setPayment(paymentData);
        return () => { }
    }, [paymentData]);

    useEffect(() => {
        if (payment && contract && contract.idCliente) {
            if (payment.suscriptor === contract.idCliente) {
                if (_.hasIn(payment, ['datos', 'paymentLimitDate'])) {
                    const dateTmp = payment.datos.paymentLimitDate;
                    if (dateTmp) {
                        const dateNewFormat = moment(dateTmp, "DD/MM/YYYY").format('DD-MMM-YYYY');
                        setPaymentLimitDate(dateNewFormat);
                    }
                }
                if (_.hasIn(payment, ['datos', 'amount'])) {
                    setAmount(payment.datos.amount);
                }
            }
        }
        return () => { }
    }, [payment, contract])

    useEffect(() => {
        if (contract) {
            if (_.has(contract, ['servicios'])) {
                setServices(contract.servicios)
            }
        }
    }, [contract]);

    useEffect(() => {
        if (services && services.servicios) {
            services.servicios.forEach((element: any) => {
                if ((element.tiposervicio === '1') || (element.tiposervicio === '4') || (element.tiposervicio === '9')) {
                    if (((element.estado === 'COR') || (element.estado === 'STB') || (element.estado === 'PCN')) && Number(amount) > 0)
                        setHasDebt(true);
                }
            })
        }
    }, [services, amount]);

    return (
        <div className={hasDebt ? classes.rootDebt : classes.root}>
            <Grid container className={classes.gridContainer}>
                {payment ?
                    <>
                        <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                            <div className={classes.cardInfo}>
                                {
                                    description && (
                                        <Typography component={'span'} variant="body1">
                                            <Box>{description}</Box>
                                        </Typography>
                                    )
                                }
                                <Typography component={'span'} variant="h5">
                                    <Box fontWeight={700} >
                                        {contract ? contract.nombre : ""}
                                    </Box>
                                </Typography>

                                <Typography component={'span'} variant="body1">
                                    <Box>{contract ? contract.suscriptor : ""}</Box>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                            <div className={classes.cardInfo}>
                                <Typography component={'span'}>
                                    Pagar antes de:
                                </Typography>
                                <Typography component={'span'} variant="h6">
                                    <Box fontWeight={700} color="#fff">
                                        {paymentLimitDate ? paymentLimitDate : ""}
                                    </Box>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={3} xl={3}>
                            {
                                reload && (
                                    <div className={classes.cardInfoReloadIcon} onClick={reloadAction}>
                                        <IconButton aria-label="reload">
                                            <FontAwesomeIcon icon={faSyncAlt} className={classes.reloadIcon} />
                                        </IconButton>
                                    </div>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                            <div className={hasDebt ? classes.cardPriceDebt : classes.cardPrice}>
                                <Typography component={'span'}>
                                    {hasDebt ? 'Tiene un saldo vencido de:' : 'Total a pagar'}
                                </Typography>
                                <Typography component={'span'} variant="h3">
                                    <Box fontWeight={700}>
                                        {amount ? `$${amount}` : ""}
                                    </Box>
                                </Typography>
                            </div>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className={classes.cardInfo}>
                                {
                                    description && (
                                        <Typography component={'span'} variant="body1">
                                            <Box>{description}</Box>
                                        </Typography>
                                    )
                                }
                                <Typography component={'span'} variant="h5">
                                    <Box fontWeight={700} >
                                        {contract ? contract.nombre : ""}
                                    </Box>
                                </Typography>

                                <Typography component={'span'} variant="body1">
                                    <Box>{contract ? contract.suscriptor : ""}</Box>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                            {
                                reload && (
                                    <div className={classes.cardInfoReloadIcon} onClick={reloadAction}>
                                        <IconButton aria-label="reload">
                                            <FontAwesomeIcon icon={faSyncAlt} className={classes.reloadIcon} />
                                        </IconButton>
                                    </div>
                                )
                            }
                        </Grid>
                    </>
                }
            </Grid>
        </div>
    )
}

export default withStyles(styles)(ContractInfoBanner);
