import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import Button from './Button';
import Typography from './Typography';
import GoBack from './GoBack';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

const styles = ({ palette, breakpoints }: any) => createStyles({
    accountStatusHeaderContainer: {
        backgroundImage: `linear-gradient(90deg, #0078D0 0%, #004FC0 100%)`,
        fontFamily: 'Sarabun',
        color: 'white',
        width: '100%',
        display: 'block'
    },
    container1: {
        width: '100%',
        display: 'flex'
    },
    container2: {
        width: '100%',
        backgroundColor: palette.secondary.main
    },
    div1: {
        marginLeft: '15px',
        position: 'relative',
        width: '70%',
        height: '90px'
    },
    div2: {
        position: 'relative',
        width: '30%',
        display: 'flex',
        height: '90px',
        whiteSpace: 'pre-wrap',
        direction: 'rtl',
        marginRight: '15px',
    },
    div3: {
        width: '100%',
        height: '50px',
        direction: 'rtl',
        paddingRight: '5px'
    },
    textContainer: {
        position: 'relative',
        height: 'fit-content',
        top: '50%',
        transform: 'translateY(-50%)'
    }
});

interface AccountStatusHeaderProps extends WithStyles<typeof styles> {
    name: string;
    number: string;
    type: string;
    onClickPay: Function;
    onClickSend: Function;
}

interface State {
    sendFactura: string;
}

export const AccountStatusHeader = ({ name, number, type, classes, onClickPay, onClickSend }: AccountStatusHeaderProps) => {

    let history = useHistory();

    const location = useLocation<State>();
    const [sendFactura, setSendFactura] = React.useState(false);

    useEffect(() => {
        if (location && _.hasIn(location.state, ['sendFactura'])) {
            if (location.state.sendFactura === "true") {
                setSendFactura(true)
            } else {
                setSendFactura(false)
            }
        }
    }, [location]);

    return (
        <div className={classes.accountStatusHeaderContainer}>
            <div className={classes.container1}>
                <div className={classes.div1}>
                    <div className={classes.textContainer}>
                        <Typography type="h1" text={name} style={{ marginBottom: '0px', marginTop: '0px' }} />
                        <Typography type="h4" text={number} weight='normal' style={{ marginBottom: '0px', marginTop: '0px' }} />
                    </div>
                </div>
                <div className={classes.div2}>
                    <div className={classes.textContainer}>
                        <Typography type="h2" weight='normal' text={type} style={{ marginBottom: '0px', marginTop: '0px' }} />
                    </div>
                </div>
            </div>
            <div className={classes.container2} >
                <div className={classes.div3}>
                    {
                        sendFactura ?
                            <div style={{display: "flex", flexDirection: "row", }}>
                                <Button type="primary" text="PAGAR" size="medium" onClick={() => onClickPay()} />
                                {/* <Button type="send" text="ENVIAR" size="medium" onClick={() => onClickSend()} /> */}
                                <GoBack onClick={() => history.push('/statement')} />
                            </div>
                            :
                            <div style={{display: "flex", flexDirection: "row", }}>
                                <GoBack onClick={() => history.push('/statement')} />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(AccountStatusHeader);
