import React, { useEffect } from 'react';
import { Theme, createStyles } from '@material-ui/core/styles';
import { Box, Grid, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import Slider from '../../components/Slider';
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { BannersRedux } from 'megacable-mobile-library';
import _ from 'lodash';
import CallsTable from './components/CallsTable';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import Spinner from '../../components/Spinner';
import Help from '../../components/Help';
import XLSX from "xlsx";

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles(({ breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        marginBottom: '15px',
        backgroundColor: 'white'
    },
    bannerContainer: {
        width: "100%",
    },
    topContainer: {
        display: "flex",
        backgroundColor: '#002A4F',
        padding: 16,
    },
    grids: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [breakpoints.down('sm')]: {
            paddingBottom: 15,
        }
    },
    textfields: {
        backgroundColor: 'white',
        width: '250px',
        [breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    titles: {
        marginRight: '30px',
        fontSize: '15px',
        fontWeight: 'bold',
        lineHeight: 2
    },
    downloadButton: {
        width: '95px',
        backgroundColor: '#00CCFF',
        color: '#FFFFFF',
        border: 'none',
        margin: '5px 5px',
        padding: '10px',
        direction: 'ltr',
        fontSize: '14px',
        textAlign: 'center',
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        borderRadius: '5px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        marginLeft: '30px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#1364C9',
        },
        [breakpoints.down('sm')]: {
            marginLeft: '0px',
            marginTop: '10px'
        }
    }
})
);

type PropsFromRedux = ConnectedProps<typeof connector>

type CallsListProps = PropsFromRedux & {

}

const typesOfCalls = [
    {
        value: 'AllCalls',
        label: 'Todas las Llamadas',
    },
    {
        value: 'LMF',
        label: 'Locales megafón',
    },
    {
        value: 'LOC',
        label: 'Locales otras compañías',
    },
    {
        value: 'CPP',
        label: 'Celular',
    },
    {
        value: 'NLD',
        label: 'Larga distancia nacional',
    },
    {
        value: 'NEA',
        label: 'Larga distancia nacional NEA',
    },
    {
        value: 'ILD',
        label: 'Larga distancia a E.U. y CAN',
    },
    {
        value: 'ROW',
        label: 'Larga distancia otros paises',
    },
    {
        value: '800',
        label: 'Larga distancia sin costo',
    },
    {
        value: 'CNP',
        label: 'Larga distancia celulares',
    },
    {
        value: '???',
        label: 'Desconocidas',
    },
];

export const CallsList = (props: CallsListProps) => {
    const classes = styles();
    const { banners, currentContract } = props;
    const [idCliente, setIdCliente] = React.useState('');
    const [contrato, setContrato] = React.useState<any>({});
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [callsList, setCallsList] = React.useState<Array<any>>([]);
    const [callType, setCallType] = React.useState('AllCalls');
    const [periodList, setPeriodList] = React.useState<any>();
    const [firstPeriod, setFirstPeriod] = React.useState<any>();
    const [secondPeriod, setSecondPeriod] = React.useState<any>();
    const [thirdPeriod, setThirdPeriod] = React.useState<any>();
    const [fourthPeriod, setFourthPeriod] = React.useState<any>();
    const [fifthPeriod, setFifthPeriod] = React.useState<any>();
    const [sixthPeriod, setSixthPeriod] = React.useState<any>();
    const [seventhPeriod, setSeventhPeriod] = React.useState<any>();
    const [eigthPeriod, setEigthPeriod] = React.useState<any>();
    const [callPeriod, setCallPeriod] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    useEffect(() => {
        if (currentContract && currentContract.currentContract) {
            setIdCliente(currentContract.currentContract.idCliente);
        }
    }, [currentContract]);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract'])) {
                setContrato(currentContract.currentContract);
            }
        }
    }, [currentContract]);

    useEffect(() => {
        if (idCliente) {
            checkToken(() => {
                apiV2.getApiV2PeriodosLlamadasIdCliente(idCliente, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (response && response.data && response.data.length > 0) {
                            setError(false);
                            setPeriodList(response.data);
                        } else {
                            setError(true);
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                        setError(true);
                    })
            })
        }
    }, [idCliente]);

    useEffect(() => {
        setLoading(true);
        if (periodList) {
            if (periodList[0]) {
                setFirstPeriod(periodList[0].fecha_inicio)
                setSecondPeriod(periodList[0].fecha_fin)
            }
            if (periodList[1]) {
                setThirdPeriod(periodList[1].fecha_inicio)
                setFourthPeriod(periodList[1].fecha_fin)
            }
            if (periodList[2]) {
                setFifthPeriod(periodList[2].fecha_inicio)
                setSixthPeriod(periodList[2].fecha_fin)
            }
            if (periodList[3]) {
                setSeventhPeriod(periodList[3].fecha_inicio)
                setEigthPeriod(periodList[3].fecha_fin)
            }
            setLoading(false);
        }
    }, [periodList]);


    useEffect(() => {
        if (idCliente && callType && callPeriod) {
            let startDate = callPeriod.slice(0, 10);
            let endDate = callPeriod.slice(13, 23);
            checkToken(() => {
                apiV2.getApiV2ListadoLlamadasIdCliente(idCliente, startDate, endDate, callType, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (response && response.data && response.data.length > 0) {
                            setCallsList(response.data)
                        } else {
                            setCallsList([])
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
            })
        }
    }, [idCliente, callType, callPeriod]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCallType(event.target.value);
    };

    const handleChangePeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCallPeriod(event.target.value);
    };

    const exportCsv = () => {
        let Heading = [
            ["Número teléfonico", "Ciudad", "Destino", "Duración", "Estado", "Fecha y hora", "País", "Sucursal", "Suscriptor", "Descripción tipo de llamada", "Importe"]
        ];

        let arrData = callsList;
        arrData.forEach((data) => {
            delete data.callID;
            delete data.fecha;
            delete data.tipoLlamada;
        });

        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, Heading);
        XLSX.utils.sheet_add_json(ws, arrData, { origin: 'A2', skipHeader: true });
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        let exportFileName = 'Lista_llamadas.xlsx';
        XLSX.writeFile(wb, exportFileName);
    }

    const periods = [
        {
            value: firstPeriod && secondPeriod ? firstPeriod + " - " + secondPeriod : '',
            label: firstPeriod && secondPeriod ? firstPeriod + " al " + secondPeriod : ''
        },
        {
            value: thirdPeriod && fourthPeriod ? thirdPeriod + " - " + fourthPeriod : '',
            label: thirdPeriod && fourthPeriod ? thirdPeriod + " al " + fourthPeriod : ''
        },
        {
            value: fifthPeriod && sixthPeriod ? fifthPeriod + " - " + sixthPeriod : '',
            label: fifthPeriod && sixthPeriod ? fifthPeriod + " al " + sixthPeriod : ''
        },
        {
            value: seventhPeriod && eigthPeriod ? seventhPeriod + " - " + eigthPeriod : '',
            label: seventhPeriod && eigthPeriod ? seventhPeriod + " al " + eigthPeriod : ''
        }
    ];

    return (
        <div className={classes.root}>
            <div className={classes.bannerContainer}>
                {bannerList && !_.isEmpty(bannerList) ? <Slider size='large' images={bannerList} /> : null}
            </div>
            {
                error ? <Help type={"error"} helpStyle={"outline"} text1="¡Por favor recarga la página!" text2='Ocurrió un error al recuperar la información' additionalStyle={{ margin: "10px 10px" }} />
                    :
                    loading ? <Spinner />
                        :
                        contrato ?
                            <>
                                <Grid container className={classes.topContainer}>
                                    <Grid item container xs={12} sm={12} md={6} lg={6} className={classes.grids}>
                                        <Typography component={'span'} variant="h6" className={classes.titles} >
                                            <Box color="#fff">{"TIPO DE LLAMADA"}</Box>
                                        </Typography>
                                        <TextField
                                            id="callType"
                                            select
                                            value={callType}
                                            onChange={handleChange}
                                            className={classes.textfields}
                                        >
                                            {typesOfCalls.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item container xs={12} sm={12} md={6} lg={6} style={{ alignItems: 'center' }}>
                                        <Typography component={'span'} variant="h6" className={classes.titles} >
                                            <Box color="#fff">{"PERIODO"}</Box>
                                        </Typography>
                                        <TextField
                                            id="period"
                                            select
                                            value={callPeriod}
                                            onChange={handleChangePeriod}
                                            className={classes.textfields}
                                        >
                                            {periods.map((option) => (
                                                option.value && option.label ?
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                    :
                                                    null
                                            ))}
                                        </TextField>
                                        {callsList.length > 0 ?
                                            <button className={classes.downloadButton} onClick={() => exportCsv()}>
                                                Descargar
                                            </button>
                                            :
                                            null
                                        }
                                    </Grid>
                                </Grid>
                                <CallsTable callsList={callsList} />
                            </>
                            :
                            <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
            }
        </div >
    )
}

const mapState = (state: any) => ({
    banners: state.banners,
    currentContract: state.currentContract,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({
    ...bindActionCreators({ BannersActions: BannersRedux.Creators } as any, dispatch),
    getBanners: () => dispatch(BannersRedux.getBanners()),

})
const connector = connect(mapState, mapDispatch)

export default (connector(CallsList));