import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

interface IPaymentLoading extends WithStyles<typeof styles> {
}

const PaymentLoading = (props: IPaymentLoading) => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <img src="/spinner-blanco.svg" alt="" className={classes.circularProgress} />
            <Typography component={'span'} variant="h5">
                <Box fontWeight={700} color="white" className={classes.titleText} mb={2}>Procesando el pago...</Box>
            </Typography>
            <Typography component={'span'} variant="body1">
                <Box fontWeight={500} color="white" className={classes.descriptionText}>Este proceso puede tardar algunos minutos</Box>
            </Typography>
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
        height: "93vh",
        display: "flex",
        flexDirection: "column",
        backgroundImage: "linear-gradient(145deg, #002a4f 30%, #001120 90%)",
        justifyContent: "center",
        alignItems: "center",
    },
    circularProgress: {
        width: 90,
        height: 90,
        marginBottom: 24,
    },
    titleText: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
    },
    descriptionText: {
        width: 200,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
    }
});

export default withStyles(styles)(PaymentLoading);
