import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

const styles = () => createStyles({
    root: {
        width: '100%',
        backgroundColor: "#efefef",
        height: "20px",
        display: 'flex',
        flexDirection: 'column'
    },
    divFilled: {
        height: "20px",
    },
    divText: {
        fontWeight: 'bold',
        color: "white",
        position: "absolute",
        zIndex: 1,
        alignSelf: "center"
    }
})


interface ProgressBarProps extends WithStyles<typeof styles> {
    completed: string | number;
    bgColor?: string;
    title?: string;
}

const ProgressBar = ({ completed,
    bgColor = "#6a1b9a",
    title,
    classes
}: ProgressBarProps) => {
    return (
        <div className={classes.root} >
            <div className={classes.divFilled} style={{ background: bgColor, width: typeof completed === "number" ? `${completed}%` : `${completed}` }} />
            <div className={classes.divText}>{title}</div>
        </div >
    )
}

export default withStyles(styles)(ProgressBar);