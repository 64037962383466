import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import Help from './Help';

const styles = ({ palette, breakpoints }: any) => createStyles({
    channelListContainer: {
        fontFamily: 'Sarabun',
        width: '100%'
    },
    rowTV: {
        background: '#207ab4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px',
        // marginRight: '-8px',
        marginLeft: '-8px'
    },
    rowTVTitle: {
        background: '#4d4d4d',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '24px',
        textTransform: 'uppercase',
        /* padding: '7px', */
        color: 'white',
        // marginRight: '-8px',
        marginLeft: '-8px'
    },
    bundleButton: {
        color: '#333333',
        cursor: 'pointer',
        position: 'relative',
        textAlign: 'center',
        height: "100px",
        /* width: "100%", */
        /*marginRight: '-8px',*/
        marginLeft: '-8px',
        borderBottom: '#dedede 1px solid'
    },
    activeBundle: {
        color: '#ffffff',
        backgroundColor: "#207ab4",

    },
    selectedBundle: {
        /* color: '#ffffff', */
        backgroundColor: "#CCCCCC",
        marginRight: 0
    },
    textContainer: {
        position: 'relative',
        height: 'fit-content',
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: '14px'
    }
});

interface ChannelBundleListProps extends WithStyles<typeof styles> {
    activeBundle?: string;
    onClick: Function;
    bundles: Array<any>;
    selected?: string;
}

export const ChannelBundleList = ({ activeBundle, bundles, classes, onClick, selected }: ChannelBundleListProps) => {
    const [selectedBundle, setSelectedBundle] = React.useState(selected ? selected : "");

    useEffect(() => {
        if (selected) {
            setSelectedBundle(selected);
        }
    }, [selected])

    const bundleButton = (bundleInfo: any, key: number) => {
        return (
            <div
                key={`Bundle-Button-${key}`}
                className={`${classes.bundleButton} ${(activeBundle && activeBundle === bundleInfo.name) ? classes.activeBundle : selectedBundle === bundleInfo.paquete ? classes.selectedBundle : null}`}
                onClick={() => {
                    onClick(bundleInfo);
                    setSelectedBundle(bundleInfo.paquete)
                }}
            >
                <div className={classes.textContainer}>
                    <p style={{ fontWeight: 'bold', margin: '0px' }}>{bundleInfo.paquete.paquete}</p>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.channelListContainer}>
            <div className={classes.rowTV}>
                <img src={'../tv-icon.png'} alt="" />
            </div>
            <div className={classes.rowTVTitle}>Televisión</div>

            {bundles ?
                bundles.map((bundleInfo, key) => {
                    return (
                        bundleButton(bundleInfo, key)
                    )
                })
                :
                null}
            <Help type={"attention"} helpStyle={"outline"} text1="Si no cuentas con alguno de nuestros canales" text2='y te gustaría obtenerlo, comunícate con uno de nuestros asesores al 3396901234' additionalStyle={{ margin: "10px 10px" }} />

        </div>
    )
}

export default withStyles(styles)(ChannelBundleList);
