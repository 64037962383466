import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import _ from 'lodash';
// import moment from 'moment';
import { useHistory } from "react-router-dom";
// import ComponentHeader from '../../components/ComponentHeader';
// import Typography from '../../components/Typography';
// import AccountStatusPayment from '../../components/AccountStatusPayment';
import AccountStatusHeader from '../../components/AccountStatusHeader';
// import GeneralData from './components/GeneralData';
// import PreviousBalanceCharges from './components/PreviousBalanceCharges';
// import TitleSubtitle from './components/TitleSubtitle';
// import TableHeaders from './components/TableHeaders';
// import PreviousBalanceTable from './components/PreviousBalanceTable';
// import GeneralChargesTable from './components/GeneralChargesTable';

import { useDispatch } from 'react-redux';
import { connect, ConnectedProps } from "react-redux";
import { EstadoCuentaRedux } from 'megacable-mobile-library';

// import utils from '../../utils';
// import emptyObjects from '../../utils/emptyObjects';
// import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Theme } from '@material-ui/core';
// import Spinner from '../../components/Spinner';
import Help from '../../components/Help';
import SendInvoice from './components/SendInvoice';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';


const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        height: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        overflowY: 'hidden',
        [theme.breakpoints.down('md')]: {
            height: "auto",
            overflowY: 'auto',
        }
    },

    containerAccountData: {
        height: "calc(100vh - 204px)",
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
        [theme.breakpoints.down('md')]: {
            height: "auto",
            flexDirection: "column",
        },
    },

    container: {
        flex: 5,
        display: "flex",
        flexDirection: "column",
    },

    generalChargesContainer: {
        flex: 5,
        display: "flex",
        flexDirection: "column",
    },

    scroll: {
        overflowY: 'auto',
        '&::-webkit-scrollbar-track': {
            background: '#CCCCCC'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0078D0'
        },
        '&::-webkit-scrollbar': {
            width: 'auto'
        }
    },

    borderTest: {
        border: "3px solid #166AC2",
    },

    backgroundTest: {
        backgroundColor: "#166AC2",
    },
}));

// const simpleStyles = {
//     suscriptor: {
//         fontWeight: 700,
//         color: "#1e4b74",
//         textAlign: 'center',
//         margin: '15px 0px'
//     },
//     limitDate: {
//         fontWeight: 700,
//         color: "#006dcc",
//         textAlign: 'center',
//     }
// }

const mapState = (state: any) => ({
    login: state.login,
    loading: state.loading,
    estadoCuenta: state.estadoCuenta,
    currentContract: state.currentContract,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AccountStatusDetailsProps = PropsFromRedux & {

}

// type TParams = { proceso: string };

export const AccountStatusDetails = (props: AccountStatusDetailsProps) => {
    const classes = styles();
    const { login, estadoCuenta, currentContract } = props;
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const [contrato, setContrato] = React.useState<any>({});
    const [accountStatus, setAccountStatus] = React.useState<any>({});
    // const [sendFactura, setSendFactura] = React.useState(false);
    // const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    // let { proceso } = useParams<TParams>();
    let history = useHistory();

    // useEffect(() => {
    //     return () => {
    //         dispatch(EstadoCuentaRedux.Creators.setDetalleCuenta(undefined, false))
    //     }
    // }, [dispatch])

    useEffect(() => {
        if (login) {
            if (_.hasIn(login, ['auth', 'token'])) {
                setToken(login.auth.token);
            }
        }
        return () => { }
    }, [login]);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract', 'idCliente'])) {
                setIdCliente(currentContract.currentContract.idCliente);
            }
        }
    }, [currentContract]);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract'])) {
                setContrato(currentContract.currentContract);
            }
        }
    }, [currentContract]);

    useEffect(() => {
        if (estadoCuenta) {
            setAccountStatus(estadoCuenta);
        }
    }, [estadoCuenta]);

    useEffect(() => {
        if (contrato && idCliente && idCliente !== "" && idCliente !== "0") {
            // setLoading(true);
            checkToken(() => {
                if (keycloak.token)
                    dispatch(EstadoCuentaRedux.getEstadosCuenta(idCliente, contrato, keycloak.token, () => { }))
            })
        }
    }, [token, idCliente, contrato, dispatch]);

    // useEffect(() => {
    //     if (accountStatus && _.hasIn(accountStatus, ['listaEstadosCuenta'])) {
    //         if (accountStatus.listaEstadosCuenta.length > 0 && proceso) {
    //             let estado = _.find(accountStatus.listaEstadosCuenta, ['proceso', proceso]);
    //             if (idCliente && token) {
    //                 dispatch(EstadoCuentaRedux.getDetalleCuenta(estado, idCliente, token, () => { }))
    //             }
    //         }
    //     }
    // }, [accountStatus.listaEstadosCuenta]);

    useEffect(() => {
        // setLoading(false);
        return () => { }
    }, [accountStatus.detalleCuenta]);

    // let summaryDebt = null;
    // let previousBalance = null;
    // if (_.hasIn(accountStatus, 'detalleCuenta.detalleCuenta.resumen_deuda')) {
    //     summaryDebt = utils.getPeriodCharges(accountStatus.detalleCuenta.detalleCuenta.resumen_deuda);
    //     previousBalance = accountStatus.detalleCuenta.detalleCuenta.resumen_deuda;
    // }

    // let generalCharges = null;
    // if (_.has(accountStatus, 'detalleCuenta.detalleCuenta.cargos_generales')) {
    //     generalCharges = accountStatus.detalleCuenta.detalleCuenta.cargos_generales;
    // }

    // let generalData = emptyObjects.emptyGeneralData;
    // if (_.has(accountStatus, 'detalleCuenta.detalleCuenta.datos_generales')) {
    //     generalData = _.merge(generalData, accountStatus.detalleCuenta.detalleCuenta.datos_generales);
    // }

    // let fechaEmision = null;
    // let anio = null;
    // let mes = null;
    // let dia = null;
    // if (_.has(accountStatus, 'detalleCuenta.detalleCuenta.datos_generales.fechaEmision')) {
    //     fechaEmision = accountStatus.detalleCuenta.detalleCuenta.datos_generales.fechaEmision.substring(0, 10);
    //     anio = fechaEmision.slice(0, 4);
    //     mes = fechaEmision.slice(5, 7);
    //     dia = fechaEmision.slice(8, 10);
    //     mes = moment(mes, "MM").format("MMMM");
    // }

    return (
        <div className={clsx(classes.root)}>
            {
                contrato ? //sendFactura ?*/
                    <>
                        <AccountStatusHeader
                            name={currentContract && currentContract.currentContract && currentContract.currentContract.nombre ? currentContract.currentContract.nombre : ""}
                            number={currentContract && currentContract.currentContract && currentContract.currentContract.suscriptor ? currentContract.currentContract.suscriptor : ""}
                            type={currentContract && currentContract.currentContract && currentContract.currentContract.paquete ? currentContract.currentContract.paquete : ""}
                            onClickSend={() => (null) /*setSendFactura(true)*/}
                            onClickPay={() => history.push('/payments')} />
                        <SendInvoice onClickCancel={() => (null) /*setSendFactura(false)*/} />
                    </>
                    // :
                    // <>
                    //     <AccountStatusHeader
                    //         name={currentContract && currentContract.currentContract && currentContract.currentContract.nombre ? currentContract.currentContract.nombre : ""}
                    //         number={currentContract && currentContract.currentContract && currentContract.currentContract.suscriptor ? currentContract.currentContract.suscriptor : ""}
                    //         type={currentContract && currentContract.currentContract && currentContract.currentContract.paquete ? currentContract.currentContract.paquete : ""}
                    //         onClickSend={() => setSendFactura(true)}
                    //         onClickPay={() => history.push('/payments')} />
                    //     {
                    //         accountStatus.detalleCuenta && !accountStatus.loadingDetalleCuenta ?
                    //             <div className={clsx(classes.containerAccountData)}>
                    //                 <div className={clsx(classes.container)}>
                    //                     {
                    //                         generalData ?
                    //                             <div style={{ width: "100%", height: "100%" }}>
                    //                                 <ComponentHeader type="H4" title="ESTIMADO (A):" />
                    //                                 <GeneralData suscriptorData={generalData} />
                    //                                 <div style={{ background: '#CCCCCC', height: '10px' }} />
                    //                                 <ComponentHeader type="H4" title="SUSCRIPTOR" />
                    //                                 <Typography type="h3" text={generalData.suscriptor} style={simpleStyles.suscriptor} />
                    //                                 <ComponentHeader type="H4" title="FECHA LÍMITE DE PAGO" />
                    //                                 <Typography type="h2" text={moment(new Date(generalData.fechaLimite)).format("DD/MM/YYYY")} style={simpleStyles.limitDate} />
                    //                             </div>
                    //                             :
                    //                             null
                    //                     }
                    //                     {
                    //                         summaryDebt ?
                    //                             <>
                    //                                 <ComponentHeader type="H4" title="RESUMEN" />
                    //                                 <PreviousBalanceCharges charges={summaryDebt} />
                    //                                 <AccountStatusPayment payment={summaryDebt.total} />
                    //                             </>
                    //                             :
                    //                             null
                    //                     }
                    //                 </div>
                    //                 <div className={clsx(classes.generalChargesContainer, classes.scroll)}>
                    //                     <ComponentHeader type="H4" title="PERIODO" />
                    //                     {
                    //                         fechaEmision ?
                    //                             <div style={{ background: 'white' }}>
                    //                                 <TitleSubtitle title="MENSUALIDAD" subtitle={`${mes} ${anio}`.toUpperCase()} />
                    //                                 <TitleSubtitle title="FECHA DE EMISIÓN" subtitle={`${dia} / ${mes} / ${anio}`.toUpperCase()} />
                    //                             </div> : null
                    //                     }
                    //                     <ComponentHeader type="H4" title="DETALLE SALDO ANTERIOR" />
                    //                     <TableHeaders />
                    //                     {
                    //                         previousBalance ? <PreviousBalanceTable previousBalance={accountStatus.detalleCuenta.detalleCuenta.resumen_deuda} /> : null
                    //                     }
                    //                     <div style={{ height: '1px', background: '#cccccc' }} />
                    //                     <ComponentHeader type="H4" title="DETALLE DE CARGOS DEL PERIODO" />
                    //                     {
                    //                         generalCharges ? <GeneralChargesTable generalCharges={generalCharges} /> : null
                    //                     }
                    //                 </div>
                    //             </div>
                    //             :
                    //             <Spinner />
                    //     }
                    // </>
                    :
                    <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
            }
        </div>
    )
}


export default (connector(AccountStatusDetails));