import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

interface ILastUpdate extends WithStyles<typeof styles> {
    date: string,
}

const LastUpdate = (props: ILastUpdate) => {
    const { classes, date } = props;

    return (
        <div className={classes.root}>
            <Typography component={'span'} variant="body2">Última actualización: {date} </Typography>
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#d8d5d5",
        paddingLeft: 32,
        paddingTop: 4,
        paddingBottom: 4,
    },
});

export default withStyles(styles)(LastUpdate);
