import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import Typography from './Typography';

const styles = ({ palette, breakpoints }: any) => createStyles({
    addContractHeaderContainer: {
        backgroundImage: `linear-gradient(90deg, #0078D0 0%, #004FC0 100%)`,
        fontFamily: 'Sarabun',
        color: 'white',
        width: '100%',
        display: 'block'
    },
    container1: {
        width: '100%',
        display: 'flex'
    },
    div1: {
        marginLeft: '15px',
        position: 'relative',
        width: '70%',
        height: '50px'
    },
    div2: {
        position: 'relative',
        width: '30%',
        display: 'flex',
        height: '50px',
        whiteSpace: 'pre-wrap',
        direction: 'rtl',
        marginRight: '15px'
    },
    textContainer: {
        position: 'relative',
        height: 'fit-content',
        top: '50%',
        transform: 'translateY(-50%)'
    },
});

interface AddContractHeaderProps extends WithStyles<typeof styles> {
    title: string;
    number: string;
}

export const AddContractHeader = ({ number, title, classes }: AddContractHeaderProps) => {

    return (
        <div className={classes.addContractHeaderContainer}>
            <div className={classes.container1}>
                <div className={classes.div1}>
                    <div className={classes.textContainer}>
                        <Typography type="h2" text={title} style={{ color: "white", marginBottom: '0px', marginTop: '0px' }} />
                    </div>
                </div>
                <div className={classes.div2}>
                    <div className={classes.textContainer}>
                        <Typography type="h3" text={number} style={{ color: "white", marginBottom: '0px', marginTop: '0px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(AddContractHeader);
