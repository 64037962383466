import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '@material-ui/core';
import { useHistory } from "react-router";

const styles = ({ breakpoints }: Theme) => createStyles({
    slideshowContainer: {
        width: '100%',
        position: 'relative',
        margin: 'auto',
        backgroundImage: "linear-gradient(90deg, #0078D0 0%, #004FC0 100%)",
        height: "auto",
    },
    fade: {
        '-webkit-animation-name': 'fade',
        '-webkit-animation-duration': '1.5s',
        animationName: 'fade',
        animationDuration: '1.5s'
    },
    slide: {
        display: 'none'
    },
    numberText: {
        color: '#f2f2f2',
        fontSize: '12px',
        padding: '8px 12px',
        position: 'absolute',
        top: '0'
    },
    button: {
        cursor: 'pointer',
        position: 'absolute',
        transform: 'translateY(-50%)',
        '-ms-transform': 'translateY(-50%)',
        top: '50%',
        fontSize: '50px',
        width: 'auto',
        padding: '0px 16px',
        /* marginTop: '-22px', */
        color: '#276cb5',
        fontWeight: 'bold',
        transition: '0.6s ease',
        borderRadius: '0 3px 3px 0',
        userSelect: 'none',
        [breakpoints.up('xl')]: {
            color: "#fff",
        },
    },
    buttonNext: {
        right: '0',
        borderRadius: '3px 0 0 3px'
    },
    dotContainer: {
        position: 'absolute',
        bottom: '3%',
        transform: 'translateX(-50%)',
        '-ms-transform': 'translateX(-50%)',
        left: '50%'
    },
    dot: {
        opacity: '.4',
        cursor: 'pointer',
        height: '15px',
        width: '15px',
        margin: '0 2px',
        backgroundColor: '#bbb',
        borderRadius: '50%',
        display: 'inline-block',
        transition: 'background-color 0.6s ease',
    },
    image: {
        width: '100%',
        verticalAlign: 'middle',
        backgroundSize: "contain",
    },
});

interface SliderProps extends WithStyles<typeof styles> {
    images: Array<any>;
    size: 'large' | 'medium'
}

export const Slider = ({ images, size, classes }: SliderProps) => {
    const [activeSlide, setActiveSlide] = React.useState(0);
    let history = useHistory();

    const handleButtonClick = (value: number) => {
        const arrayLength = images.length;

        let slide = activeSlide + value;

        if (slide === -1) {
            slide = arrayLength - 1;
        } else if (slide === arrayLength) {
            slide = 0;
        }

        setActiveSlide(slide);
    }

    const goToScreen = (screen: string) => {
        history.push(`/` + screen);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSlide((prevCounter) => prevCounter + 1 === images.length ? 0 : prevCounter + 1);
        }, 5000);

        return () => clearInterval(interval);
    }, []);



    return (
        <div className={classes.slideshowContainer}>
            {
                images && images.map((image, key) => (
                    image.link ?
                        <a key={image.id} href={image.link} target="_blank" rel="noopener noreferrer" className={`${classes.slide} ${classes.fade}`} style={{ display: activeSlide === key ? 'block' : 'none' }}>
                            <img className={classes.image} src={image.imgName} alt={""} />
                        </a>
                        :
                        image.screenKey ?
                            <div key={image.id} onClick={() => goToScreen(image.screenKey)} className={`${classes.slide} ${classes.fade}`} style={{ display: activeSlide === key ? 'block' : 'none', cursor: 'pointer' }}>
                                <img className={classes.image} src={image.imgName} alt={""} />
                            </div>
                            :
                            <div key={image.id} className={`${classes.slide} ${classes.fade}`} style={{ display: activeSlide === key ? 'block' : 'none' }}>
                                <img className={classes.image} src={image.imgName} alt={""} />
                            </div>
                ))
            }
            <div className={classes.button} onClick={() => handleButtonClick(-1)}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className={`${classes.button} ${classes.buttonNext}`} onClick={() => handleButtonClick(1)}>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>

            <div className={classes.dotContainer} >
                {images && images.map((image, key) => (
                    <span
                        key={`image-${key}`}
                        className={classes.dot}
                        style={{
                            backgroundColor: activeSlide === key ? '#E2E2E2' : '#BBBBBB',
                            opacity: activeSlide === key ? '1' : '.4'
                        }}
                        onClick={() => setActiveSlide(key)}>
                    </span>
                ))}
            </div>
        </div>
    )
}

export default withStyles(styles)(Slider);
