import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

const styles = ({ palette }: any) => createStyles({
    container: {
        position: 'relative',
        backgroundImage: 'linear-gradient(90deg, #0078D0 0%, #004FC0 100%)',
        width: '100%',
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        color: 'white'
    },
    containerH2: {
        minHeight: '50px'
    },
    containerH4: {
        minHeight: '35px',
        textAlign: 'center'
    },
    titleH2: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        marginLeft: '15px',
        fontSize: "1.5em",
        lineHeight: '1.25em',
        height: '1.38em'
    },
    titleH4: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        left: '50%',
        fontSize: "1em",
        height: '0.94em',
        lineHeight: '0.9em',
        width: '257px'
    }
});

interface ComponentHeaderProps extends WithStyles<typeof styles> {
    title: string;
    type: 'H2' | 'H4';
}

export const ComponentHeader = ({ title, type, classes }: ComponentHeaderProps) => {
    return (
        <div className={`${classes.container} ${type === 'H2' ? classes.containerH2 : classes.containerH4}`}>
            <span className={`${type === 'H2' ? classes.titleH2 : classes.titleH4}`}>{title}</span>
        </div>
    )
}

export default withStyles(styles)(ComponentHeader);
