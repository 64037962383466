import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

interface IOptionsButton extends WithStyles<typeof styles> {
    icon: string,
    label: string,
    path: string,
    noLabel?: boolean
}

const OptionsButton = (props: IOptionsButton) => {
    const { classes, icon, label, path, noLabel } = props;
    let history = useHistory();

    const redirect = () => {
        if (path) {
            history.push(path);
        }
    }

    return (
        <Paper elevation={0} className={classes.root} onClick={redirect}>
            <div className={classes.iconContainer}>
                <img src={icon} alt={label} className={classes.icon} />
            </div>
            {
                noLabel ?
                    null
                    :
                    <div className={classes.labelContainer}>
                        <Typography component={'span'} style={{ marginTop: '0px', marginBottom: '0px', }}>
                            <Box className={classes.textStyle}>{label}</Box>
                        </Typography>
                    </div>
            }
            <div className={classes.div3}>
                <FontAwesomeIcon icon={faChevronRight} className={`${classes.chevronIcon}`} />
            </div>
        </Paper>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        height: '120px',
        width: '100%',
        display: "flex",
        flexDirection: "row",
        cursor: 'pointer',
        alignItems: "center",
        borderBottom: '1px #d4d4d4 solid',
        paddingLeft: 24,
        paddingRight: 24,
        transitionDuration: "300ms",
        borderRadius: '0px',
        '&:hover': {
            opacity: 0.3,
        },

        [theme.breakpoints.down('xs')]: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        marginRight: 15,
        [theme.breakpoints.down('xs')]: {
            marginRight: 8,
        }
    },
    icon: {
        // width: 65,
        height: 65,
    },
    labelContainer: {
        display: "flex",
        flexDirection: "column",
        marginRight: 16,
        [theme.breakpoints.down('md')]: {
            maxWidth: 299,
        }
    },
    div3: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto"
    },
    chevronIcon: {
        fontSize: '20px',
        color: "#276ba6",
    },

    textStyle: {
        fontSize: 16,
        fontWeight: "bolder",
        fontFamily: "Sarabun",
        lineHeight: '0.9em',
        letterSpacing: "0.2px",
        color: "#002A4F",
    }
});

export default withStyles(styles)(OptionsButton);
