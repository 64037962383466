import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const styles = ({ palette }: any) => createStyles({
    title: {
        fontFamily: "Sarabun",
        fontWeight: 'lighter',
        textTransform: 'initial',
        fontSize: '12px',
        color: '#000000',
        marginTop: '10px'
    },
    description: {
        fontFamily: "Sarabun",
        fontWeight: 'lighter',
        textTransform: 'initial',
        fontSize: '14px',
        color: '#4D4D4D;',
        marginBottom: '10px'
    },
    placeholder: {
        fontFamily: "Sarabun",
        fontWeight: 'lighter',
        textTransform: 'initial',
        fontSize: '10px',
        color: '#4D4D4D;',
        verticalAlign: "middle"
    },
    container: {
        color: 'grey',
        margin: "0px 10px",
        width: '320px',
        direction: 'ltr'
    },
    input: {
        backgroundColor: 'white',
        width: "100%",
        padding: "15px 15px",
        height: '50px',
        font: '400 13.3333px Sarabun',
        /* margin: "10px 0px", */
        marginTop: "5px",
        borderRadius: "5px",
        border: "solid 1px #56617F",
        borderWidth: "thin",
        boxSizing: "border-box",
        marginBottom: '0px',
        display: 'flex'
    },
    selectTextContainer: {
        width: '95%'
    },
    selectIconContainer: {
        /* width: '10%', */
        textAlign: 'center'
    },
    chevronIcon: {
        fontSize: '10px',
        /* color: '#002A4F' */
    },
    list: {
        listStyle: 'none',
        margin: '0px',
        /* padding: '0px 15px' */
        position: 'absolute',
        width: 'inherit',
        padding: '0px',
        fontFamily: 'Sarabun',
        fontSize: '12px',
        color: 'white',
        display: 'inline-block',
        zIndex: 100
    },
    inactiveList: {
        display: 'none'
    },
    listElement: {
        backgroundColor: 'white',
        color: 'black',
        height: '40px',
        border: '1px solid #6f6f6f',
        lineHeight: '40px',
        paddingLeft: '15px',
        '&:hover': {
            backgroundColor: '#66CCFF',
            color: 'white'
        }
    },
    selectedListElement: {
        backgroundColor: '#044C9B',
        color: 'white',
        '&:hover': {
            backgroundColor: '#044C9B',
        }
    }
});

interface SelectProps extends WithStyles<typeof styles> {
    title: string;
    placeholder?: string;
    description?: string;
    disabled?: boolean;
    options: Array<{ label: string, value: string }>;
    selected?: string,
    width?: string,
    onChange: any;
}

export const Select = ({ description, disabled = false, placeholder ="", options, selected = "", title, width='', onChange, classes }: SelectProps) => {
    const [openSelect, setOpenSelect] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState("");

    useEffect(() => { selected && setSelectedValue(selected) }, [ selected ] );

    const selectedOption = options.find((option) => option.value === selectedValue)

    return (
        <div className={classes.container} style={{ width: width && width}}>
            <span className={classes.title}>{title}</span>

            <div className={`${classes.input}`} /* placeholder={placeholder} */ onClick={() => setOpenSelect(!openSelect)}>
                <div className={classes.selectTextContainer}>{selectedOption ? selectedOption.label : placeholder}</div>
                <div className={classes.selectIconContainer}>
                    <FontAwesomeIcon className={classes.chevronIcon} icon={faChevronDown} />
                </div>
            </div>
            <ul className={`${classes.list} ${!openSelect && classes.inactiveList}`} >
                {options.map((option, key) => (
                    <li
                        key={`select-option-${key}`}
                        className={`${classes.listElement} ${selectedValue === option.value && classes.selectedListElement} `}
                        onClick={() => {
                            setSelectedValue(option.value)
                            onChange(option.value)
                            setOpenSelect(!openSelect)
                        }}
                    >
                        {option.label}
                    </li>
                ))}
            </ul>


            {description && <span className={classes.description}>{description}</span>}
        </div>
    )
}

export default withStyles(styles)(Select);


// interface SelectProps extends WithStyles<typeof styles> {
//     title: string;
//     placeholder: string;
//     description?: string;
//     disabled?: boolean;
//     options: Array<{ label: string, value: string }>;
//     /* classes: any; */
// }

// interface SelectState {
//     /* clicks: number; */
// }

// const initialState: SelectState = {
//     /* clicks: 0 */
// };

// const Select = withStyles(styles)(
//     class extends React.Component<SelectProps> {
//         static defaultProps = {
//             text: "World"
//         };

//         constructor(props: SelectProps) {
//             super(props);
//         }

//         componentDidUpdate(prevProps: SelectProps): void {

//         }

//         render() {
//             /* const [openSelect, setOpenSelect] = React.useState(false);
//             const [selectedValue, setSelectedValue] = React.useState(false);
//      */
//             const { description, disabled = false, placeholder, options, title } = this.props;

//             const classes = {
//                 container: "",
//                 title: "",
//                 input: "",
//                 selectTextContainer: "",
//                 selectIconContainer: "",
//                 chevronIcon: "",
//                 list: "",
//                 listElement: "",
//                 description: "",
//             };

//             return (
//                 <div className={classes.container}>
//                     <span className={classes.title}>{title}</span>

//                     <div className={`${classes.input}`} placeholder={placeholder} >
//                         <div className={classes.selectTextContainer}>Text</div>
//                         <div className={classes.selectIconContainer}>
//                             <FontAwesomeIcon className={classes.chevronIcon} icon={faChevronCircleDown} />
//                         </div>
//                     </div>
//                     <ul className={`${classes.list} `} >
//                         {options.map((option) => (
//                             <li className={classes.listElement} >{option.label}</li>
//                         ))}
//                     </ul>


//                     {description && <span className={classes.description}>{description}</span>}
//                 </div>
//             )
//         }

//     }
// )

// /* export default withStyles(styles)(Select); */
// export default Select;