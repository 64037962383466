import React, { useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '../../../components/Typography';
import { Button, Grid, TextField } from '@material-ui/core';
import SwipeableButton from './SwipeableButton';
import { useDispatch } from "react-redux";
import { AlertRedux } from 'megacable-mobile-library';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../../aws-exports';
import _ from 'lodash';
import ProcessingDialog from './ProccessingDialog';
import checkToken from '../../../utils/checKToken';
import { useHistory } from 'react-router-dom';
import { removeToken } from '../../../utils';
import BlockedDialog from './BlockedDialog';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface NortonHireProps extends WithStyles<typeof styles> {
    packages: any;
    idCliente: string;
    token: string;
    onClickHire: Function;
}

const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const NortonHire = (props: NortonHireProps) => {
    const { classes, packages, idCliente, token, onClickHire } = props;
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [isHiring3, setIsHiring3] = React.useState<boolean>(false);
    const [isHiring5, setIsHiring5] = React.useState<boolean>(false);
    const [isHiring10, setIsHiring10] = React.useState<boolean>(false);
    const [isEmailCorrect3, setIsEmailCorrect3] = React.useState<boolean>(false);
    const [isEmailCorrect5, setIsEmailCorrect5] = React.useState<boolean>(false);
    const [isEmailCorrect10, setIsEmailCorrect10] = React.useState<boolean>(false);
    const [email3, setEmail3] = React.useState<string>('');
    const [email5, setEmail5] = React.useState<string>('');
    const [email10, setEmail10] = React.useState<string>('');
    const [verification3, setVerification3] = React.useState<boolean>(false);
    const [verification5, setVerification5] = React.useState<boolean>(false);
    const [verification10, setVerification10] = React.useState<boolean>(false);
    const [sendingMessage, setSendingMessage] = React.useState<boolean>(false);
    const [verificationCode3, setVerificationCode3] = React.useState<string>("");
    const [verificationCode5, setVerificationCode5] = React.useState<string>("");
    const [verificationCode10, setVerificationCode10] = React.useState<string>("");
    const [verificationMessage, setVerificationMessage] = React.useState<string>("");
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [blockedDialog, setBlockedDialog] = React.useState<boolean>(false);
    const [chosenService, setChosenService] = React.useState<string>("");
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (regexEmail.test(email3)) {
            setIsEmailCorrect3(true)
        } else {
            setIsEmailCorrect3(false)
        }
        if (regexEmail.test(email5)) {
            setIsEmailCorrect5(true)
        } else {
            setIsEmailCorrect5(false)
        }
        if (regexEmail.test(email10)) {
            setIsEmailCorrect10(true)
        } else {
            setIsEmailCorrect10(false)
        }
    }, [email3, email5, email10]);

    const addService = (promotion: any, verificationCode: string, email: any,) => {
        if (idCliente) {
            let sucursal = idCliente.substring(0, 3);
            let suscriptor = idCliente.substring(3, 10);
            let objData = {
                suscriptor: suscriptor,
                id_paquete: promotion.IDPaquete,
                promocion: parseInt(promotion.promocion),
                origen: "WEB",
                nombrePaquete: promotion.paquete,
                codigo_validacion: verificationCode
            }
            let objDataEmailNorton = {
                idCliente: idCliente,
                email: email
            }
            let objBitacora = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length),
                origen: "WEB",
                tipoServicio: promotion.tipoServicio,
                servicio: promotion.servicio,
                promocion: promotion.promocion,
                accion: "Contratacion",
                correo: email
            }

            setIsReset(false);
            setChosenService(promotion.tipoServicio)

            apiV2.postApiV2EmailNorton(objDataEmailNorton, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(response => { })
                .catch(error => { })

            let objDataValidate = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length),
                id_paquete: promotion.IDPaquete,
                promocion: parseInt(promotion.promocion),
                origen: "WEB",
            }

            let dataToStore = {
                idPaquete: promotion.IDPaquete,
                lastAttempt: new Date().getTime(),
                idCliente: idCliente,
                codigo: promotion.codigo,
                codigoTipo: promotion.codigoTipo
            }

            const dataReviewTmp = localStorage.getItem('salesAttempts');
            let proceedWithSale = true;
            let dataReview: any = [];

            if (dataReviewTmp && !_.isEmpty(dataReviewTmp)) {
                dataReview = JSON.parse(dataReviewTmp);
                if (promotion.codigoTipo === "47") {
                    dataReview.forEach((element: any) => {
                        if ((element.codigo === promotion.codigo) && (Math.abs(new Date().getTime() - element.lastAttempt) / 1000) <= 1800) {
                            proceedWithSale = false;
                        }
                    })
                } else {
                    dataReview.forEach((element: any) => {
                        if ((element.idPaquete === promotion.IDPaquete) && (Math.abs(new Date().getTime() - element.lastAttempt) / 1000) <= 1800) {
                            proceedWithSale = false;
                        }
                    })
                }
            }

            if (proceedWithSale) {
                setDialogOpen(true);
                checkToken(() => {
                    apiV2.postApiV2ValidaContrataciones(objDataValidate, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response: any) => {
                            if (response.data && response.data.resultado === "1") {
                                apiV2.postApiV2VentasOperacionesVerifiedSucursal(sucursal, objData, { headers: { 'Authorization': `Bearer ${token}` } })
                                    .then((response) => {
                                        dataReview.push(dataToStore)
                                        localStorage.setItem('salesAttempts', JSON.stringify(dataReview));
                                        if (response && response.data && response.data.id && response.data.id.id && !_.isEmpty(response.data.id.id)) {
                                            onClickHire();
                                            apiV2.postApiV2BitacoraEvento(objBitacora, { headers: { 'Authorization': `Bearer ${token}` } })
                                        } else if (response.data.mensaje === "Registro duplicado.") {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, ya cuenta con una en proceso.", "Contratar " + promotion.tipoServicio));
                                        } else if (response.data.mensaje === "código incorrecto.") {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, el código de validación es incorrecto.", "Contratar " + promotion.tipoServicio));
                                        } else {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, intentalo más tarde.", "Contratar " + promotion.tipoServicio))
                                        }
                                    })
                                    .catch((error) => {
                                        // setIsReset(true);
                                        dispatch(AlertRedux.showAlert("No es posible realizar la contratación, intentalo más tarde.", "Contratar " + promotion.tipoServicio))
                                    })
                            } else if (response.data.codigo === "2") {
                                dispatch(AlertRedux.showAlert("No es posible continuar con la contratación, ya cuenta con una en proceso.", "Contratar " + promotion.tipoServicio))
                            } else {
                                dispatch(AlertRedux.showAlert("Ha ocurrido un error al validar su contratación, intentarlo más tarde.", "Contratar " + promotion.tipoServicio))
                            }
                            setDialogOpen(false);
                        })
                        .catch((err: any) => {
                            // setIsReset(true);
                            setDialogOpen(false);
                            err = _.get(err, 'response', err);
                            if (err && err.data && err.data.err && err.data.err.message && err.data.err.message === "jwt expired") {
                                dispatch(AlertRedux.showAlert("La sesión ha caducado.", "Sesión"));
                                setTimeout(function () {
                                    removeToken();
                                    history.push("/");
                                }, 3000);
                            } else {
                                dispatch(AlertRedux.showAlert("Ha ocurrido un error al validar su contratación, intentarlo más tarde.", "Contratar " + promotion.tipoServicio))
                            }
                        })
                })
            } else {
                setBlockedDialog(true);
            }
        }
    };

    const onSuccess3 = (element: any) => {
        if (verificationCode3.length !== 6) {
            setIsReset(true);
            dispatch(AlertRedux.showAlert("¡Estimado Usuario! El código de verificación debe ser de 6 dígitos.", "Contratar servicio"))
        } else {
            addService(element, verificationCode3, email3);
        }
    }

    const onSuccess5 = (element: any) => {
        if (verificationCode5.length !== 6) {
            setIsReset(true);
            dispatch(AlertRedux.showAlert("¡Estimado Usuario! El código de verificación debe ser de 6 dígitos.", "Contratar servicio"))
        } else {
            addService(element, verificationCode5, email5);
        }
    }

    const onSuccess10 = (element: any) => {
        if (verificationCode10.length !== 6) {
            setIsReset(true);
            dispatch(AlertRedux.showAlert("¡Estimado Usuario! El código de verificación debe ser de 6 dígitos.", "Contratar servicio"))
        } else {
            addService(element, verificationCode10, email10);
        }
    }

    const sendCode = (pack: any) => {
        setSendingMessage(true);
        let objData = {
            idCliente: idCliente,
            payload: pack
        }

        checkToken(() => {
            apiV2.postApiV2SendVerificationCode(objData, { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    if (response.data.estatus === "true") {
                        if (pack.IDPaquete === "9D25F2DD-648A-4282-B790-2D09D5B71BD9") {
                            setVerification3(true);
                        } else if (pack.IDPaquete === "2BB9680A-9E01-4363-A891-F379BE89C8F4") {
                            setVerification5(true);
                        } else if (pack.IDPaquete === "05B774E2-54D9-478C-9F6D-27BAD9B1E2DD") {
                            setVerification10(true);
                        }
                        setVerificationMessage("Ha sido enviado un código de verificación para la contratación del servicio vía SMS.")
                    } else if (response.data.codigo === "2") {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error en el envío de su código de verificación vía SMS, intentarlo más tarde.", "Contratar servicio"))
                    } else if (response.data.codigo === "3") {
                        dispatch(AlertRedux.showAlert("Ha llegado a su límite de intentos de contratación en el día, intentarlo el día de mañana.", "Contratar servicio"))
                    } else {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error al generar su código de verificación, intentarlo más tarde.", "Contratar servicio"))
                    }
                    setSendingMessage(false);
                })
                .catch((err) => {
                    err = _.get(err, 'response', err);
                    if (err && err.data && err.data.err && err.data.err.message && err.data.err.message === "jwt expired") {
                        dispatch(AlertRedux.showAlert("La sesión ha caducado.", "Sesión"));
                        setTimeout(function () {
                            removeToken();
                            history.push("/");
                        }, 3000);
                    } else {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error al generar su código de verificación, intentarlo más tarde.", "Contratar servicio"))
                    }
                    setSendingMessage(false);
                })
        })
    }


    return (
        <div className={classes.root}>
            <Grid container style={{ height: '100%' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.container}>
                    <Grid item xs={12} sm={12} md={7} lg={7} className={classes.imageBackground}>
                        <img alt="" src={"../norton-logo-security.png"} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.container2}>
                        {packages?.map(function (element: any, i: any) {
                            return (
                                element.IDPaquete === "9D25F2DD-648A-4282-B790-2D09D5B71BD9" && element.estado !== "CON" ?
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.background1}>
                                        {
                                            verification3 ?
                                                <>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', margin: '0px', textAlign: 'center' }} type="p" text={verificationMessage ? verificationMessage : ""} />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', fontWeight: "bold", margin: '0px', textAlign: 'center', paddingTop: '25px' }} type="p" text="Código de verificación" />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <TextField
                                                            value={verificationCode3}
                                                            name="verificationCode"
                                                            placeholder='123456'
                                                            variant="outlined"
                                                            autoComplete='notAutoComplete'
                                                            style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383', borderRadius: '5px' }}
                                                            InputProps={{ className: classes.textfield }}
                                                            inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
                                                            onChange={(event) => setVerificationCode3(event.target.value)} />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <div className={classes.swipeButton}>
                                                            <SwipeableButton id={i} resetStatus={isReset} onSuccess={() => onSuccess3(element)} color='#FFC300' text='Arrastra para aceptar' />
                                                        </div>
                                                    </Grid>
                                                </>
                                                : isHiring3 ?
                                                    <>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '16px', margin: '15px 15px', textAlign: 'center' }} type="h2" text={"¿Está seguro que desea contratar el paquete " + element.paquete + "? Ingrese su correo y deslice el botón para contratar."} />
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <TextField
                                                                value={email3}
                                                                name="email3"
                                                                placeholder='Correo electrónico'
                                                                type='email'
                                                                variant="outlined"
                                                                autoComplete='notAutoComplete'
                                                                style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383' }}
                                                                InputProps={{ className: classes.textfield }}
                                                                onChange={(event) => setEmail3(event.target.value)} />
                                                        </Grid>
                                                        {
                                                            isEmailCorrect3 ?
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <div className={classes.mainButtonContainer}>
                                                                        <Button disabled={sendingMessage} className={sendingMessage ? classes.nortonButtonDisabled : classes.nortonButton}
                                                                            onClick={() => sendCode(element)}>
                                                                            Confirmar
                                                                        </Button>
                                                                    </div>
                                                                </Grid>
                                                                :
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <div className={classes.mainButtonContainer}>
                                                                        <Button disabled className={classes.nortonButtonDisabled}>
                                                                            Confirmar
                                                                        </Button>
                                                                    </div>
                                                                </Grid>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '2em', margin: '0px' }} type="h2" text={element.descripcion} />
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#fecb16', fontSize: '3em', margin: '10px 0px 10px 0px', paddingLeft: '10px' }} type="h2" text={"$" + element.precio} />
                                                            <Typography style={{ color: '#fecb16', fontSize: '22px', margin: '0px', paddingLeft: '10px' }} type="h2" text="al mes" />
                                                        </Grid>
                                                        {
                                                            element.estado !== "PRO" ?
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <Button className={classes.nortonButton} onClick={() => setIsHiring3(true)}>
                                                                        Contrata ahora
                                                                    </Button>
                                                                </Grid>
                                                                :
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <Typography style={{ color: '#FFFFFF', fontSize: '1em', margin: '0px' }} type="h2" text={"Contratación en proceso..."} />
                                                                </Grid>
                                                        }
                                                    </>
                                        }
                                    </Grid>
                                    :
                                    null
                            )
                        })}
                        {packages?.map(function (element: any, i: any) {
                            return (
                                element.IDPaquete === "2BB9680A-9E01-4363-A891-F379BE89C8F4" && element.estado !== "CON" ?
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.background5}>
                                        {
                                            verification5 ?
                                                <>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', margin: '0px', textAlign: 'center' }} type="p" text={verificationMessage ? verificationMessage : ""} />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', fontWeight: "bold", margin: '0px', textAlign: 'center', paddingTop: '25px' }} type="p" text="Código de verificación" />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <TextField
                                                            value={verificationCode5}
                                                            name="verificationCode"
                                                            placeholder='123456'
                                                            variant="outlined"
                                                            autoComplete='notAutoComplete'
                                                            style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383', borderRadius: '5px' }}
                                                            InputProps={{ className: classes.textfield }}
                                                            inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
                                                            onChange={(event) => setVerificationCode5(event.target.value)} />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <div className={classes.swipeButton}>
                                                            <SwipeableButton id={i} resetStatus={isReset} onSuccess={() => onSuccess5(element)} color='#FFC300' text='Arrastra para aceptar' />
                                                        </div>
                                                    </Grid>
                                                </>
                                                : isHiring5 ?
                                                    <>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '16px', margin: '15px 15px', textAlign: 'center' }} type="h2" text={"¿Está seguro que desea contratar el paquete " + element.paquete + "? Ingrese su correo y deslice el botón para contratar."} />
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <TextField
                                                                value={email5}
                                                                name="email5"
                                                                placeholder='Correo electrónico'
                                                                type='email'
                                                                variant="outlined"
                                                                autoComplete='notAutoComplete'
                                                                style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383' }}
                                                                InputProps={{ className: classes.textfield }}
                                                                onChange={(event) => setEmail5(event.target.value)} />
                                                        </Grid>
                                                        {
                                                            isEmailCorrect5 ?
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <div className={classes.mainButtonContainer}>
                                                                        <Button disabled={sendingMessage} className={sendingMessage ? classes.nortonButtonDisabled : classes.nortonButton}
                                                                            onClick={() => sendCode(element)}>
                                                                            Confirmar
                                                                        </Button>
                                                                    </div>
                                                                </Grid>
                                                                :
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <div className={classes.mainButtonContainer}>
                                                                        <Button disabled className={classes.nortonButtonDisabled}>
                                                                            Confirmar
                                                                        </Button>
                                                                    </div>
                                                                </Grid>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '2em', margin: '0px' }} type="h2" text={element.descripcion} />
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#fecb16', fontSize: '3em', margin: '10px 0px 10px 0px', paddingLeft: '10px' }} type="h2" text={"$" + element.precio} />
                                                            <Typography style={{ color: '#fecb16', fontSize: '22px', margin: '0px', paddingLeft: '10px' }} type="h2" text="al mes" />
                                                        </Grid>
                                                        {
                                                            element.estado !== "PRO" ?
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <Button className={classes.nortonButton} onClick={() => setIsHiring5(true)}>
                                                                        Contrata ahora
                                                                    </Button>
                                                                </Grid>
                                                                :
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <Typography style={{ color: '#FFFFFF', fontSize: '1em', margin: '0px' }} type="h2" text={"Contratación en proceso..."} />
                                                                </Grid>
                                                        }
                                                    </>
                                        }
                                    </Grid>
                                    :
                                    null
                            )
                        })}
                        {packages?.map(function (element: any, i: any) {
                            return (
                                element.IDPaquete === "05B774E2-54D9-478C-9F6D-27BAD9B1E2DD" && element.estado !== "CON" ?
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.background10}>
                                        {
                                            verification10 ?
                                                <>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', margin: '0px', textAlign: 'center' }} type="p" text={verificationMessage ? verificationMessage : ""} />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <Typography style={{ color: '#FFFFFF', fontSize: '1.25em', fontWeight: "bold", margin: '0px', textAlign: 'center', paddingTop: '25px' }} type="p" text="Código de verificación" />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <TextField
                                                            value={verificationCode10}
                                                            name="verificationCode"
                                                            placeholder='123456'
                                                            variant="outlined"
                                                            autoComplete='notAutoComplete'
                                                            style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383', borderRadius: '5px' }}
                                                            InputProps={{ className: classes.textfield }}
                                                            inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
                                                            onChange={(event) => setVerificationCode10(event.target.value)} />
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.text}>
                                                        <div className={classes.swipeButton}>
                                                            <SwipeableButton id={i} resetStatus={isReset} onSuccess={() => onSuccess10(element)} color='#FFC300' text='Arrastra para aceptar' />
                                                        </div>
                                                    </Grid>
                                                </>
                                                : isHiring10 ?
                                                    <>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '16px', margin: '15px 15px', textAlign: 'center' }} type="h2" text={"¿Está seguro que desea contratar el paquete " + element.paquete + "? Ingrese su correo y deslice el botón para contratar."} />
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <TextField
                                                                value={email10}
                                                                name="email10"
                                                                placeholder='Correo electrónico'
                                                                type='email'
                                                                variant="outlined"
                                                                autoComplete='notAutoComplete'
                                                                style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383' }}
                                                                InputProps={{ className: classes.textfield }}
                                                                onChange={(event) => setEmail10(event.target.value)} />
                                                        </Grid>
                                                        {
                                                            isEmailCorrect10 ?
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <div className={classes.mainButtonContainer}>
                                                                        <Button disabled={sendingMessage} className={sendingMessage ? classes.nortonButtonDisabled : classes.nortonButton}
                                                                            onClick={() => sendCode(element)}>
                                                                            Confirmar
                                                                        </Button>
                                                                    </div>
                                                                </Grid>
                                                                :
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <div className={classes.mainButtonContainer}>
                                                                        <Button disabled className={classes.nortonButtonDisabled}>
                                                                            Confirmar
                                                                        </Button>
                                                                    </div>
                                                                </Grid>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#FFFFFF', fontSize: '2em', margin: '0px' }} type="h2" text={element.descripcion} />
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                            <Typography style={{ color: '#fecb16', fontSize: '3em', margin: '10px 0px 10px 0px', paddingLeft: '10px' }} type="h2" text={"$" + element.precio} />
                                                            <Typography style={{ color: '#fecb16', fontSize: '22px', margin: '0px', paddingLeft: '10px' }} type="h2" text="al mes" />
                                                        </Grid>
                                                        {
                                                            element.estado !== "PRO" ?
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <Button className={classes.nortonButton} onClick={() => setIsHiring10(true)}>
                                                                        Contrata ahora
                                                                    </Button>
                                                                </Grid>
                                                                :
                                                                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.text}>
                                                                    <Typography style={{ color: '#FFFFFF', fontSize: '1em', margin: '0px' }} type="h2" text={"Contratación en proceso...    "} />
                                                                </Grid>
                                                        }
                                                    </>
                                        }
                                    </Grid>
                                    :
                                    null
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <ProcessingDialog open={dialogOpen} />
            <BlockedDialog open={blockedDialog} servicio={chosenService} onExit={() => setBlockedDialog(false)} />
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
        height: '400px',
    },
    container: {
        height: '100%',
        minHeight: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    container2: {
        width: '100%',
        display: "flex",
        flexDirection: 'column'
    },
    nortonButton: {
        // backgroundColor: '#FFC300',
        backgroundImage: "linear-gradient(#fcbd24, #ec770b)",
        width: "200px",
        border: 'none',
        fontFamily: "Sarabun",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#FFFFFF',
        textAlign: 'center',
        textDecoration: 'none',
        margin: '5px 5px',
        padding: '10px',
        borderRadius: '50px',
        direction: 'ltr',
        '&:hover': {
            // backgroundColor: '#FFE17E',
            backgroundImage: "linear-gradient(#ec770b, #fcbd24)",
            cursor: 'pointer',
        }
    },
    nortonButtonDisabled: {
        backgroundImage: "linear-gradient(#444, #444)",
        width: "200px",
        border: 'none',
        fontFamily: "Sarabun",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#FFFFFF',
        textAlign: 'center',
        textDecoration: 'none',
        margin: '5px 5px',
        padding: '10px',
        borderRadius: '50px',
        direction: 'ltr',
        '&:hover': {
            backgroundImage: "linear-gradient(#444, #444)",
            cursor: 'pointer',
        }
    },
    background1: {
        backgroundColor: "#141f35",
        display: 'flex',
        flexDirection: 'row',
        height: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    background5: {
        backgroundColor: "#141f35",
        display: 'flex',
        flexDirection: 'row',
        height: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    background10: {
        backgroundColor: "#141f35",
        display: 'flex',
        flexDirection: 'row',
        height: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    imageBackground: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#2a3845',
        justifyContent: 'center',
        // backgroundSize: 'cover',
        // backgroundImage: 'url(norton-banner-security.png)',
        backgroundPositionY: 'top -63px'
    },
    swipeButton: {
        width: "300px",
    },
    textfield: {
        color: '#FFFFFF'
    },
    text: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    mainButtonContainer: {
        paddingTop: '15px'
    },
});

export default withStyles(styles)(NortonHire);
