import React, { useEffect } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
// import ContractInfoBanner from '../../components/ContractInfoBanner';
// import LastUpdate from '../../components/LastUpdate';
import ContractData from '../../components/ContractData';
import { useDispatch } from 'react-redux';
// import { useBarcode } from 'react-barcodes';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// import Slider from '../../components/Slider';
import { getBanners } from '../../store/actions/BannerAction';
import _ from 'lodash';
import GoBack from '../../components/GoBack';
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IContract } from '../../storeV2/ducks/types';
import { DatosPago } from 'megacable-mobile-api/models';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import Spinner from '../../components/Spinner';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import Help from '../../components/Help';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface IAddresses {
    calle: string,
    ciudad: string,
    codigopostal: string,
    colonia: string,
    departamento: string,
    entrecalle1: string,
    entrecalle2: string,
    estado: string,
    numeroexterior: string,
}

type PropsFromRedux = ConnectedProps<typeof connector>

type ICisLocation = PropsFromRedux & {

}

const CisLocation = (props: ICisLocation) => {
    const classes = styles();
    const { pagosData, login, /*loading, estadoCuenta,*/ currentContract, /*banners*/ } = props;
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    // const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [fullName, setFullName] = React.useState<string>('');
    // const [name, setName] = React.useState<string>("");
    const [loading, setLoading] = React.useState(true);
    const [token, setToken] = React.useState<string>('');
    // const [clientId, setClientId] = React.useState<string>("");
    const [suscriptor, setSuscriptor] = React.useState<string>("");
    // const [addresses, setAddresses] = React.useState<IAddresses>();
    const [limitDay, setLimitDay] = React.useState<string>("");
    const [barCode, setBarCode] = React.useState<string>("");
    const [paymentData, setPaymentData] = React.useState<DatosPago>();
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        startAnalytics('cons_pay_cis_id');
        return () => {
            logAnalytics('cons_pay_cis_id')
        }
    }, []);

    useEffect(() => {
        setContractSelected(currentContract.currentContract);
    }, [currentContract]);

    useEffect(() => {
        if (login && _.hasIn(login, ['auth']) && _.hasIn(login, ['user'])) {
            const auth = login.auth;
            if (_.hasIn(auth, ['token'])) {
                setToken(auth.token);
            }

        }
        return () => { }
    }, [login])

    useEffect(() => {
        if (paymentData) {
            if (_.hasIn(paymentData, ['datos', 'paymentLimitDate'])) {
                const limitDateTmp = paymentData.datos.paymentLimitDate;
                var check = moment(limitDateTmp, 'DD/MM/YYYY');
                var day = check.format('D');
                if (day) {
                    setLimitDay(day);
                }
            }
        }
        return () => { }
    }, [paymentData])

    // useEffect(() => {
    //     if(contractSelected){
    //         if(pagosData && _.hasIn(pagosData, ['allDatosPago'])){
    //             const paymentList : Array<DatosPago> = pagosData.allDatosPago;
    //             if(paymentList && paymentList.length > 0){
    //                 let paymentUser = _.find(paymentList, (paymentData: DatosPago) => { return paymentData.suscriptor === contractSelected.idCliente});
    //                 if(paymentUser){
    //                     setPaymentData(paymentUser);
    //                 }
    //             }
    //         }
    //     }
    //     return () => {}
    // }, [contractSelected, pagosData]);

    useEffect(() => {
        if (contractSelected) {
            checkToken(() => {
                apiV2.getApiV2GetDatosPago(contractSelected.idCliente, { headers: { "Authorization": `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (_.has(response, 'data.datos')) {
                            setPaymentData(response.data)
                            setLoading(false);
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                        setLoading(false);
                    })
            })
        }
        return () => { }
    }, [contractSelected, pagosData, token]);

    useEffect(() => {
        if (contractSelected) {
            // setName(contractSelected.nombreSuscriptor);
            // setClientId(contractSelected.idCliente);
            setSuscriptor(contractSelected.suscriptor);
            setFullName(`${contractSelected.nombreSuscriptor} ${contractSelected.apellidoPaterno} ${contractSelected.apellidoMaterno}`);
            // setAddresses(contractSelected.domicilio);
            // setLimitDate(contractSelected.fechaLimitePago);
            setBarCode(contractSelected.codigoBarras);
        }
    }, [contractSelected]);

    // useEffect(() => {
    //     if (banners) {
    //         setBannerList(banners.banners);
    //     }
    //     return () => { }
    // }, [banners]);

    useEffect(() => {
        dispatch(getBanners())
    }, [dispatch])

    const goToCis = () => {
        history.push("/cis");
    }

    return (
        <div className={classes.root}>
            {/* <div className={classes.bannerContainer}>
                {(banners && !_.isEmpty(banners)) ?
                    <Slider size='large' images={banners} />
                    :
                    <div/>
                }
            </div>
            <ContractInfoBanner contract={contractSelected}/>
            <LastUpdate time="12:33" date="19 enero 2021" /> */}
            {
                contractSelected ? (
                    loading ? <Spinner /> :
                        fullName && limitDay ? (
                            <div style={{ backgroundColor: "white" }}>
                                <div style={{ backgroundColor: "#002A4F" }}>
                                    <GoBack onClick={() => history.push('/payments')} />
                                </div>
                                <ContractData
                                    isButtonEnable
                                    suscriptor={suscriptor}
                                    fullName={fullName}
                                    limitDay={limitDay}
                                    barCode={barCode}
                                    onClick={goToCis} />
                            </div>

                        ) : null
                )
                    : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
            }
        </div>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    bannerContainer: {
        width: "100%",
    },
}));

const mapState = (state: any) => ({
    login: state.login,
    banners: state.banners,
    estadoCuenta: state.estadoCuenta,
    pagosData: state.pagosData,
    loading: state.loading,
    currentContract: state.currentContract,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({

});


const connector = connect(mapState, mapDispatch);

export default (connector(CisLocation));
