import React, { FunctionComponent, useEffect } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import ContractInfoBanner from '../../components/ContractInfoBanner';
// import LastUpdate from '../../components/LastUpdate';
import { Grid, Typography, Box, Button, makeStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, Link, FormControlLabel, Checkbox } from '@material-ui/core';
import PaymentLoading from '../../components/PaymentLoading';
import DialogAlert from '../../components/DialogAlert';
import Slider from '../../components/Slider';
// import OptionsButton from '../../components/OptionsButton';
import CardResponse from '../../components/CardResponse';
import Spinner from '../../components/Spinner';
import _ from 'lodash';
import { IContract } from '../../storeV2/ducks/types';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from "redux";
import { RouteComponentProps, useHistory } from "react-router";
// import { AlertRedux } from 'megacable-mobile-library';
import { AliasDynamo, ResponseProxy, SuscriptorYServicios, FullApiResponse, DatosPago, Body6, AddPaymentReq, AgregarTarjetaRequest } from 'megacable-mobile-api/models';
import IsValidToken from '../../utils/IsValidToken';
import log from '../../analitycsV2';
import clsx from 'clsx';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import GoBack from '../../components/GoBack';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import SwipeableButton from '../myServices/components/SwipeableButton';
import DisabledSwipeableButton from '../myServices/components/DisabledSwipeableButton';

// import { useDispatch } from "react-redux";
const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface ResponseProxyProps {
    responseProxy: ResponseProxy,
    alias: AliasDynamo,
    idSuscriptor: string,
    card: PaymentData,
    navigation: any,
    message?: string,
    from?: string,
    ani?: string,
    phoneTitle?: string,
    monto?: number,
    goTo?: string,
    buttonText?: string,
    tryAgain?: () => void;
}

type MenuOption = {
    icon: string,
    label: string,
    path: string,
}
interface PaymentData {
    number: string,
    expirationDate: string,
    cvv: string,
    saveCard: boolean,
    isValid: boolean
}

type StateType = {
    idCliente: string,
    paymentCard: PaymentData | null,
    currentContract: IContract,
    paymentData: DatosPago,
    amountToPay?: number,
    ani?: string,
    from?: "recarga" | "serviciosEnLinea"
}

type DialogConfig = {
    isOpen: boolean,
    title: string,
    message: string,
    type: "detail" | "alert",
    primaryButtonText: string,
    secondaryButtonText?: string,
    action?: (isAgree: boolean) => void,
}

const initialDialog: DialogConfig = {
    isOpen: false,
    title: "",
    message: "",
    type: "detail",
    primaryButtonText: "",
    secondaryButtonText: "",
    action: () => { },
}

type PropsFromRedux = ConnectedProps<typeof connector>

type IPayService = PropsFromRedux & RouteComponentProps<{}, {}, StateType>;

const PayService = (props: IPayService) => {
    const classes = styles();
    const { location, banners, /*login,*/ } = props;
    const { state } = location;
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingSaveCard, setLoadingSaveCard] = React.useState<boolean>(false);
    // const [token, setToken] = React.useState<string>('');
    const [contract, setContract] = React.useState<IContract>();
    const [cardData, setCardData] = React.useState<PaymentData | null>();
    const [payment, setPayment] = React.useState<DatosPago>();
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [cardNumber, setCardNumber] = React.useState<string>('');
    const [responseProxy, setResponseProxy] = React.useState<ResponseProxy | undefined>(undefined);
    const [paymentCompleted, setPaymentCompleted] = React.useState<boolean>(false);
    const [numberCard, setNumberCard] = React.useState<string>('');
    const [amountToPay, setAmountToPay] = React.useState<string>("");
    const [fechaCorte, setFechaCorte] = React.useState<string>("");
    // const [paymentStatus, setPaymentStatus] = React.useState<string>("");
    const [errorMessageAddPayment, setErrorMessageAddPayment] = React.useState<string>("");
    const [rechargeToPay, setRechargeToPay] = React.useState<number>(0)
    const [aniClient, setAniClient] = React.useState<string>()
    const [from, setFrom] = React.useState<"recarga" | "serviciosEnLinea">()
    // const [addPaymentObj, setAddPaymentObj] = React.useState<AddPaymentReq | undefined>(undefined);
    const [bTieneConvenio, setBTieneConvenio] = React.useState<boolean>();
    const [cardExpired, setCardExpired] = React.useState<FullApiResponse>();
    const [open, setOpen] = React.useState(false);
    const [hasPaid, setHasPaid] = React.useState(false);
    const [user, /*setUser*/] = React.useState<SuscriptorYServicios>();
    const [undefinedError, setUndefinedError] = React.useState<any | undefined>(undefined);
    const [isCreditCardSave, setIsCreditCardSave] = React.useState<boolean>(false);
    const [dialogConfig, setDialogConfig] = React.useState<DialogConfig>(initialDialog);
    const [checked, setChecked] = React.useState<boolean>(false);
    const link = "http://terminos.serviciosenlineav2.megacable.com.mx.s3-website-us-west-2.amazonaws.com/";
    const disclaimer = "Estoy de acuerdo con los términos y condiciones de uso";
    // const dispatch = useDispatch();
    let history = useHistory();
    let fechaCorteList = moment().format("DD-MM-YYYY").split('-');
    // let amountToPay = "";
    // let fechaCorte = "";

    useEffect(() => {
        startAnalytics('do_pay_id');
    }, []);

    // useEffect(() => {
    //     if (login) {
    //         if (_.hasIn(login, ['user'])) {
    //             setToken(login.user);
    //         }
    //         if (_.hasIn(login, ['auth', 'token'])) {
    //             setToken(login.auth.token);
    //         }
    //     }
    //     return () => { }
    // }, [login])

    useEffect(() => {
        if (state && _.hasIn(state, ['currentContract']) && _.hasIn(state, ['paymentData'])
            && _.hasIn(state, ['paymentCard']) && _.hasIn(state, ['idCliente'])) {
            setIdCliente(state.idCliente);
            setContract(state.currentContract);
            setCardData(state.paymentCard);
            setPayment(state.paymentData);

            if (_.hasIn(state, ['from'])) {
                setFrom(state.from);
            }

            if (_.hasIn(state, ['amountToPay']) && state.amountToPay) {
                setRechargeToPay(state.amountToPay);
            }

            if (_.hasIn(state, ['ani'])) {
                setAniClient(state.ani);
            }

        } else {
            history.push("/payments")
        }
        return () => { }
    }, []);

    useEffect(() => {
        if (payment) {
            if (_.hasIn(payment, ['datos', 'amount']) && _.has(payment, ['datos', 'paymentLimitDate'])) {
                const amount = payment.datos.amount;
                const limitDate = payment.datos.paymentLimitDate;
                if (amount.length > 0) {
                    getAmountAndDate(amount, limitDate);
                } else {
                    history.push("/payments/cardPayment");
                }
            }
        }
        return () => { }
    }, [payment]);

    useEffect(() => {
        if (rechargeToPay) {
            if (payment && _.has(payment, ['datos', 'paymentLimitDate'])) {
                const limitDate = payment.datos.paymentLimitDate;
                getAmountAndDate(`${rechargeToPay}`, limitDate);
            }
        }
        return () => { }
    }, [rechargeToPay]);

    useEffect(() => {
        if (cardData) {
            const numberCard = cardData.number.split("-");
            const newFormatNumberCard = numberCard.join('');
            setNumberCard(newFormatNumberCard);
        }
        return () => { }
    }, [cardData])

    useEffect(() => {
        if (cardData) {
            var textCard = cardData.number.slice((cardData.number.length - 4), cardData.number.length);
            var txt = '**** ' + textCard;
            setCardNumber(txt);
        }
        return () => { }
    }, [cardData])


    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const pad = (textoArellenar: string, length: Number): string => {
        let padding = 0;
        var str = textoArellenar + "";
        return (length <= str.length) ? str : pad(padding + str, length);
    }

    const getAmountAndDate = (amount: string, limitDate: string) => {
        if (amount && limitDate) {
            const paymentAmount = amount;
            const paymentLimitDate = limitDate;
            let amountToPayTmp = pad(paymentAmount.replace(/[.,]/g, ""), 7);
            fechaCorteList = paymentLimitDate.split("-");
            if (from === "recarga") {
                amountToPayTmp = Number(paymentAmount).toFixed(2);
                amountToPayTmp = pad(amountToPayTmp.replace(/[.,]/g, ""), 7);
                if (_.has(payment, ['datos', 'paymentLimitDate'])) {
                    fechaCorteList = paymentLimitDate.split("-");
                }
            } else {
                amountToPayTmp = pad(paymentAmount.replace(/[.,]/g, ""), 7);
                fechaCorteList = paymentLimitDate.split("-");
            }
            const fechaCorteTmp = fechaCorteList[0].padStart(2, "0") + fechaCorteList[1].padStart(2, "0") + fechaCorteList[2];
            setAmountToPay(amountToPayTmp);
            setFechaCorte(fechaCorteTmp);
        }
    }

    const paymentRoot: Body6 = {
        idCliente: idCliente,
        payment: {
            sucursalgrupo: contract?.suscriptor.substring(0, 3),
            reference: contract?.suscriptor + fechaCorte + amountToPay,
            name: contract?.nombreSuscriptor + " " + contract?.apellidoPaterno + " " + contract?.apellidoMaterno,
            number: numberCard,
            expmonth: cardData?.expirationDate.split("/")[0],
            expyear: cardData?.expirationDate.split("/")[1],
            cvv: cardData?.cvv,
            amount: from === "recarga" ? rechargeToPay.toFixed(2) : payment && payment.datos && payment.datos.amount && payment.datos.amount,
            type: from === "recarga" ? "recarga" : "serviciosEnLinea",
        }
    }

    const makeAddPayment = (startActionAddPayment: Date, resposeProxy: ResponseProxy, addPaymentObj: AddPaymentReq | undefined) => {
        setResponseProxy(undefined);
        if (addPaymentObj) {
            checkToken(() => {
                apiV2.postApiV2AddPayment(addPaymentObj, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(responseAddPayment => {
                        const durationAddPayment = (new Date().getTime() - startActionAddPayment.getTime());
                        if (responseAddPayment.data.callcenter.identificador.codigo === "00") {
                            log('add_payment_success', durationAddPayment, idCliente);
                            setResponseProxy(resposeProxy);
                        } else {
                            log('add_payment_fail', durationAddPayment, idCliente);
                            setResponseProxy({
                                ...resposeProxy,
                                data: {
                                    ...resposeProxy.data,
                                    status: 409,
                                    folio: 409
                                }
                            });
                            if (_.has(responseAddPayment, 'data.callcenter.identificador.internal_error') && responseAddPayment.data.callcenter.identificador.internal_error) {
                                setErrorMessageAddPayment(responseAddPayment.data.callcenter.identificador.internal_error);
                            } else {
                                setErrorMessageAddPayment("Error al realizar el pago de la recarga");
                            }
                        }
                    }).catch(errAddPayment => {
                        errAddPayment = _.get(errAddPayment, "response", errAddPayment);
                        console.error("Error - Screen DoPayment, postApiV2AddPayment", errAddPayment)
                        if (_.has(errAddPayment, 'data') && errAddPayment.data === "invalid token") {
                        } else {
                            log('add_payment_response_error', (new Date().getTime() - startActionAddPayment.getTime()), idCliente);
                            setResponseProxy({
                                ...resposeProxy,
                                data: {
                                    ...resposeProxy.data,
                                    status: 410,
                                    folio: 410
                                }
                            });
                            setErrorMessageAddPayment("Error al realizar el pago de la recarga");
                        }
                    })
            })
        } else {
            setResponseProxy({
                ...resposeProxy,
                data: {
                    ...resposeProxy.data,
                    status: 410,
                    folio: 410
                }
            });
            setErrorMessageAddPayment("Error al realizar el pago de la recarga");
        }
    }

    const pay = () => {
        logAnalytics('do_pay_id')
        setLoading(true);
        const startAction = new Date();
        checkToken(() => {
            apiV2.postApiV2MakePayment(paymentRoot, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                .then(resposeProxy => {
                    const duration = (new Date().getTime() - startAction.getTime())
                    //Metricas..
                    if (Number(resposeProxy.data.data.status) === 0 && Number(resposeProxy.data.data.folio) === 0) {
                        log('pay_proxy_error', duration, idCliente);
                        setResponseProxy(resposeProxy.data);
                    }
                    else if (Number(resposeProxy.data.data.status) === 1 && Number(resposeProxy.data.data.folio) === -1) {
                        log('pay_on_validation', duration, idCliente);
                        setResponseProxy(resposeProxy.data);
                    }
                    else if (Number(resposeProxy.data.data.status) === 1 && Number(resposeProxy.data.data.folio) === 0) {
                        setResponseProxy(resposeProxy.data);
                        log('pay_invalid_card_internet', duration, idCliente);
                    }
                    else if (Number(resposeProxy.data.data.status) === 1 && Number(resposeProxy.data.data.folio) > 1) {
                        log('pay_success', duration, idCliente);
                        if (from === "recarga") {
                            let formaPago = "TAC";
                            let typePay = _.split(resposeProxy.data.data.tipoTarjeta, "/")[0]
                            if (typePay === "DEBITO") {
                                formaPago = "TAD"
                            }
                            let addPaymentObj = {
                                ani: aniClient,
                                formaPago: formaPago,
                                monto: `${rechargeToPay}`,
                                referencia: resposeProxy.data.data.folio,
                                sucursal: idCliente.substring(0, 3)
                            }
                            const startActionAddPayment = new Date();
                            // setAddPaymentObj(addPaymentObj);
                            makeAddPayment(startActionAddPayment, resposeProxy.data, addPaymentObj);
                        } else {
                            setResponseProxy(resposeProxy.data);
                        }
                    }
                })
                .catch(err => {
                    err = _.get(err, 'response', err);
                    if (_.has(err, 'data') && err.data === "invalid token") {
                        IsValidToken(err.data);
                    } else {
                        log('pay_response_error', (new Date().getTime() - startAction.getTime()), idCliente);
                        setUndefinedError(err);
                        setPaymentCompleted(true);
                        setLoading(false);
                    }
                })
                .finally(() => {
                    log('pay_id', (new Date().getTime() - startAction.getTime()), idCliente);
                })
        })
    }

    // const paymentsMenuList: Array<MenuOption> = [
    //     {icon: "../../icon-pdf.png", label:"DESCARGAR PDF", path: ""},
    //     {icon: "../../icon-email.png", label:"ENVIAR POR SMS", path: ""},
    // ];

    // const menuComponent = () => {
    //     return paymentsMenuList.map((paymentMenu, index) => (
    //         <OptionsButton 
    //             key={index}
    //             icon={paymentMenu.icon} 
    //             label={paymentMenu.label} 
    //             path={paymentMenu.path}/>
    //     ));
    // };

    useEffect(() => {
        if (responseProxy) {
            setPaymentCompleted(true);
            setLoading(false);
            // if(_.hasIn(payment, ['data','status'])){
            //     setPaymentStatus(responseProxy.data.status);
            // }
        };
        return () => { }
    }, [responseProxy]);

    const finish = () => {
        if (isCreditCardSave) {
            addCreditCard();
        } else {
            validateConvenio();
        }
    }
    const validateConvenio = () => {
        if (!bTieneConvenio) {
            if (from === "recarga") {
                history.push("/mobiles");
            } else {
                const dialogAddCreditCard: DialogConfig = {
                    isOpen: true,
                    title: "Domiciliar",
                    message: `Estimado suscriptor, usted aún no cuenta con la domicialización de su servicio, le gustaría agregar la tarjeta con terminación ${cardNumber}`,
                    type: "alert",
                    primaryButtonText: "Domiciliar",
                    secondaryButtonText: "Ahora no",
                    action: handleDialogConvenio,
                }
                setDialogConfig(dialogAddCreditCard);
            }
        } else {
            goToMenu();
        }
    }
    const retry = () => {
        if (from === "recarga") {
            history.push("/mobiles");
        } else {
            history.push("/payments/cardPayment");
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const goToMenu = () => {
        history.push("/payments");
    }

    const goToAddConvenio = () => {
        history.push({
            pathname: '/addCardConvenio',
            state: {
                bTieneConvenio: bTieneConvenio,
                cardExpired: cardExpired,
                user: user,
            }
        });
    }

    const getConvenio = () => {
        if (!hasPaid) {
            checkToken(() => {

                apiV2.getApiV2IsCardExpired({ headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (_.has(response, 'data.tarjeta')) {
                            setBTieneConvenio(true);
                            setCardExpired(response.data);
                        } else if (response.data.codigo === '902') {
                            setBTieneConvenio(false);
                            setCardExpired(response.data)
                        }
                        setHasPaid(true);
                    })
                    .catch(err => {
                        err = _.get(err, 'response', err);
                        if (_.has(err, 'data') && err.data === "invalid token") {
                            IsValidToken(err.data);
                        }
                        setHasPaid(true);
                    })
            })
        }
    }

    const addCreditCard = () => {
        if (contract && contract.sucursal && contract.suscriptor && cardData && cardData.number) {
            setLoadingSaveCard(true);
            const sucursalTmp: string = contract.sucursal;
            const suscriptorTmp: string = contract.suscriptor.substring(3, 10)
            const tarjetaTmp: string = cardData.number.replaceAll("-", "");
            const tipoTmp: string = "nueva";

            const agregarTarjetaRequest: AgregarTarjetaRequest = {
                sucursal: sucursalTmp,
                suscriptor: suscriptorTmp,
                tarjeta: tarjetaTmp,
                tipo: tipoTmp,
            }
            checkToken(() => {
                apiV2.postApiV2AgregarTarjeta(agregarTarjetaRequest, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then((response) => {
                        setLoadingSaveCard(false)
                        if (response.data) {
                            // const status = response.data.estatus;
                            const message = response.data.mensaje;

                            const dialogAddCreditCard: DialogConfig = {
                                isOpen: true,
                                title: "Tarjeta",
                                message: message,
                                type: "detail",
                                primaryButtonText: "Continuar",
                                action: handleDialogAddCreditCard,
                            }
                            setDialogConfig(dialogAddCreditCard);
                        }
                    })
                    .catch(error => {

                    })
            })
        }
    }

    const handleDialogAddCreditCard = (isOpen: boolean) => {
        if (isOpen) {
            setDialogConfig(initialDialog);
            if (!bTieneConvenio) {
                if (from === "recarga") {
                    history.push("/mobiles");
                } else {
                    validateConvenio();
                    // setOpen(true);
                }
            } else {
                goToMenu();
            }
        } else {
            setDialogConfig(initialDialog);
        }
    }

    const handleDialogConvenio = (isOpen: boolean) => {
        if (isOpen) {
            setDialogConfig(initialDialog);
            goToAddConvenio()
        } else {
            setDialogConfig(initialDialog);
            goToMenu();
        }
    }

    const handleSaveCredictCardt = () => {
        setIsCreditCardSave(!isCreditCardSave);
    }

    useEffect(() => {
    }, [isCreditCardSave]);

    const makeResponseComponent = () => {
        if (responseProxy) {
            const responseProxyTmp = responseProxy;
            const statusTmp = responseProxyTmp.data.status;
            const folioTmp = responseProxyTmp.data.folio;

            if (Number(statusTmp) === 0 && Number(folioTmp) === 0) {
                // status === 0 && folio === 0. Error de validacion proxy / conexion / problemas al realizar pago.
                return <ErrorResponsePay
                    responseProxy={responseProxyTmp}
                    message="Error de validacion proxy / conexion / problemas al realizar pago." />
                // return <Error resposeProxy={responseProxy} alias={alias} idSuscriptor={idSuscriptor} card={card} navigation={props.navigation} message="Error de validacion proxy / conexion / problemas al realizar pago." from={from} ani={ani} phoneTitle={phoneTitle} monto={monto} />
            }
            if (Number(statusTmp) === 1 && Number(folioTmp) === -1) {
                return (<ErrorResponsePay
                    responseProxy={responseProxyTmp}
                    message={responseProxyTmp.data && responseProxyTmp.data.message ? responseProxyTmp.data.message : "Hubo un inconveniente al procesar su pago, para más información comuníquese con su institución bancaria."} />
                )
            }
            if (Number(statusTmp) === 1 && Number(folioTmp) === 0) {
                // status === 1 && folio === 0. "Tarjeta no válida para pagos por internet"
                return <ErrorResponsePay
                    responseProxy={responseProxy}
                    message={responseProxyTmp.data && responseProxyTmp.data.message ? responseProxyTmp.data.message : "Tarjeta no válida para pagos por internet"} />
            }
            if (Number(statusTmp) === 1 && Number(folioTmp) > 1) {
                getConvenio();
                return <SuccessResponsePay
                    responseProxy={responseProxyTmp} />
                // return <Success responseProxy={resposeProxy} alias={alias} idSuscriptor={idSuscriptor} card={card} navigation={props.navigation} from={from} ani={ani} phoneTitle={phoneTitle} monto={monto} />
            }
            if (Number(statusTmp) === 409 && Number(folioTmp) === 409) {
                return <ErrorResponsePay
                    responseProxy={responseProxyTmp}
                    message={errorMessageAddPayment ? errorMessageAddPayment : "Error al realizar la recarga."} />
                // return <Error responseProxy={resposeProxy} alias={alias} idSuscriptor={idSuscriptor} card={card} navigation={props.navigation} message={errorMessageAddPayment ? errorMessageAddPayment : "Error al realizar la recarga."} from={from} ani={ani} phoneTitle={phoneTitle} monto={monto} goTo="Menu" buttonText="ACEPTAR" />
            }
            if (Number(statusTmp) === 410 && Number(folioTmp) === 410) {
                return <ErrorResponsePay
                    responseProxy={responseProxyTmp}
                    message={errorMessageAddPayment ? errorMessageAddPayment : "Error al realizar la recarga."} />
                // return <Error responseProxy={resposeProxy} alias={alias} idSuscriptor={idSuscriptor} card={card} navigation={props.navigation} message={errorMessageAddPayment ? errorMessageAddPayment : "Error al realizar la recarga."} from={from} ani={ani} phoneTitle={phoneTitle} monto={monto} goTo="Menu" buttonText="ACEPTAR" tryAgain={() => makeAddPayment(new Date, resposeProxy, addPaymentObj)} />
            }
            else return <ErrorResponsePay
                responseProxy={responseProxyTmp}
                message="Error" />
        } else if (undefinedError) {
            getConvenio();
            return <SuccessResponsePay
                message="Transacción en proceso de revisión. Si el pago fué aprobado por su banco, en el transcurso de 24 a 48 horas será aplicado de forma automática por el sistema." />
        } else return <ErrorResponsePay
            message="Error" />
    }

    const confirmPay = (
        <>
            <div style={{ backgroundColor: "#002A4F" }}>
                <GoBack onClick={() => history.goBack()} />
            </div>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.infoContainer}>
                    <Typography component={'span'} variant="h4" color="textPrimary">
                        <Box fontWeight={700} mb={3} className={clsx(classes.textStyles, classes.megaCableColor)}>Confirmación de pago</Box>
                    </Typography>
                    <Typography component={'span'} variant="h6" color="textPrimary">
                        <Box fontWeight={500} mb={2} className={classes.textStyles}>¡Estimado usuario!</Box>
                    </Typography>
                    <Typography component={'span'} variant="h6" color="textPrimary">
                        <Box fontWeight={500}>Realizará el pago de: </Box>
                    </Typography>
                    <Typography component={'span'} variant="h5" color="textPrimary">
                        <Box fontWeight={500} mb={2} className={clsx(classes.textStyles, classes.megaCableColor)}>{`${contract?.nombre} - ${contract?.idCliente}`}</Box>
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "row" }} >
                        <Typography component={'span'} variant="h6" color="textPrimary">
                            <Box fontWeight={500} mb={2} mr={2} className={classes.textStyles}>Con su tarjeta con terminación</Box>
                        </Typography>
                        <Typography component={'span'} variant="h6">
                            <Box className={classes.cardNumberText}>{cardNumber}</Box>
                        </Typography>
                    </div>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.payContainer}>
                    <Typography component={'span'} variant="body1" color="textPrimary">
                        <Box fontWeight={500} mb={2} className={classes.descriptionTextStyle}>Para hacer el pago, favor de aceptar los términos y condiciones y deslizar el botón hacia la derecha</Box>
                    </Typography>
                    <Link href={link} style={{ color: "#0797fd", paddingTop: '20px', fontSize: '18px' }}>
                        Términos y condiciones.
                    </Link>
                    <div style={{ display: 'flex', padding: '20px 0px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    name="termsConditions"
                                    color="primary"
                                    required={true}
                                />
                            }
                            label={disclaimer}
                            style={{ fontWeight: 'bold', color: "#004fc0" }}
                        />
                    </div>
                    {/* <Button className={classes.buttom} onClick={pay}>
                        <Typography component={'span'} variant="body1">
                            <Box fontWeight={700} color="white">Pagar servicio</Box>
                        </Typography>
                    </Button> */}
                    {
                        checked ?
                            <div className={classes.swipeButton}>
                                <SwipeableButton onSuccess={() => pay()} color='#0797fd' text='Pagar servicio' />
                            </div>
                            :
                            <div className={classes.swipeButton}>
                                <div className={classes.swipeButton}>
                                    <DisabledSwipeableButton color='gray' text='Pagar servicio' />
                                </div>
                            </div>
                    }
                    <a href="https://sealserver.trustwave.com/cert.php?customerId=d0328ea2063448ebb51870d9716b80aa&size=105x54&style=invert" target="_blank" rel="noopener noreferrer">
                        <img src="../../securetrust.png" alt="Secure trust" />
                    </a>
                </Grid>
            </Grid>
        </>
    );

    const SuccessResponsePay: FunctionComponent<{ responseProxy?: ResponseProxy, message?: string }> = ({ responseProxy, message }) => (
        <div className={classes.root}>
            {
                loadingSaveCard ? <Spinner /> : (
                    <>
                        <CardResponse
                            titleText="¡Se ha realizado su pago!"
                            descriptionText={message ? message : responseProxy ? `Se ha realizado con éxito el pago de su "contrato ${contract?.idCliente}", por un monto de $${responseProxy.data.totalCompra} con su tarjeta con terminación **** ${responseProxy.data.numeroTarjeta}.` : ""}
                            infoText="Su pago se verá reflejado en un plazo máximo de 12 horas."
                            typeResponse="success"
                            buttonText="FINALIZAR"
                            onClick={finish} />
                        {
                            cardData && cardData.saveCard && (
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 32, backgroundColor: "#eeeeee" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", }}>
                                        <Typography component={'span'} variant="body1">
                                            <Box fontWeight={700} >GUARDAR TARJETA PARA FUTUROS PAGOS</Box>
                                        </Typography>
                                        <Switch
                                            color="primary"
                                            checked={isCreditCardSave}
                                            onChange={handleSaveCredictCardt} />
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    )

    const ErrorResponsePay: FunctionComponent<{ responseProxy?: ResponseProxy, message: string }> = ({ responseProxy, message }) => (
        <div className={classes.root}>
            <CardResponse
                titleText="¡Hemos tenido un inconveniente!"
                // descriptionText={`${message}, con su tarjeta de crédito/débito con terminación **** ${responseProxy.data.numeroTarjeta}`}
                descriptionText={message}
                infoText={responseProxy && responseProxy.data && responseProxy.data.reference ? `Referencia: ${responseProxy.data.reference}` : ''}
                typeResponse="error"
                buttonText="REINTENTAR"
                onClick={retry} />
        </div>
    )

    const DialogConvenio = () => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Domiciliar</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Estimado suscriptor, usted aún no cuenta con la domicialización de su servicio, le gustaría agregar la tarjeta con terminación {cardNumber}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goToMenu} color="primary">
                        Ahora no
                    </Button>
                    <Button onClick={goToAddConvenio} color="primary">
                        Domiciliar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            {
                loading ? <PaymentLoading /> : (
                    contract && payment ? (
                        <div className={classes.root}>
                            {(bannerList && !_.isEmpty(bannerList)) ? <Slider size='large' images={bannerList} /> : <div />}
                            <ContractInfoBanner contract={contract} paymentData={payment} />
                            {
                                loading ? <PaymentLoading /> : (
                                    !paymentCompleted ? (
                                        confirmPay
                                    ) : (
                                        makeResponseComponent()
                                    )
                                )
                            }
                        </div>
                    ) : null
                )
            }
            <DialogAlert
                isOpen={dialogConfig.isOpen}
                title={dialogConfig.title}
                message={dialogConfig.message}
                type={dialogConfig.type}
                primaryButtonText={dialogConfig.primaryButtonText}
                secondaryButtonText={dialogConfig.secondaryButtonText}
                handleCloseDialog={dialogConfig.action}
            />
            {DialogConvenio()}
        </>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
    },
    infoContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 32,
        [theme.breakpoints.down('md')]: {
            padding: 16,
        },

        [theme.breakpoints.down('sm')]: {
            alignItems: "center",
            paddingBottom: 0
        }
    },
    payContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 32,
        [theme.breakpoints.down('md')]: {
            padding: 16,
        },

        [theme.breakpoints.down('sm')]: {
            paddingTop: 0
        }
    },
    buttom: {
        height: 48,
        backgroundColor: "#0797fd",
        borderRadius: 10,
        border: 0,
        padding: '0 30px',
        marginBottom: 24,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        },
        "& .MuiTouchRipple-root span": {
            backgroundColor: "#6fc3fc",
        },
    },
    megaCableColor: {
        color: "#006DCC"
    },
    textStyles: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            justifyContent: "center"
        },
    },
    descriptionTextStyle: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center"
    },
    cardNumberText: {
        color: "#36c1ff",
    },
    cardContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginBottom: 2,
    },
    imgContainer: {
        width: 400,
        height: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    backgroundSuccessful: {
        backgroundColor: "#bdeedd",
    },
    detailContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 24,
    },
    actionContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "auto",
        paddingLeft: 24,
        paddingRight: 24,
    },
    buttonFinish: {
        width: 400,
        height: 48,
        backgroundColor: "#0797fd",
        borderRadius: 10,
        border: 0,
        padding: '0 30px',
        marginBottom: 24,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        },
        "& .MuiTouchRipple-root span": {
            backgroundColor: "#6fc3fc",
        },
    },
    swipeButton: {
        width: "300px",
        marginBottom: '30px',
    },
}));

const mapState = (state: any) => ({
    banners: state.banners,
    login: state.login,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({

});

const connector = connect(mapState, mapDispatch);

export default (connector(PayService));
