import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '../../../components/Typography';
import { TextField, Button } from '@material-ui/core';
import SwipeableButton from './SwipeableButton';
import { useDispatch } from 'react-redux';
import { AlertRedux } from 'megacable-mobile-library';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../../aws-exports';
import _ from 'lodash';
import ProcessingDialog from './ProccessingDialog';
import checkToken from '../../../utils/checKToken';
import { useHistory } from 'react-router-dom';
import { removeToken } from '../../../utils';
import BlockedDialog from './BlockedDialog';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface IStarHireProps extends WithStyles<typeof styles> {
    onClickHire: Function;
    idCliente: string;
    token: string;
    serviceSelected: any;
    parentRef: any;
};

interface imgCollection {
    first: string;
    second: string;
    background: string;
};

const BREAKPOINTS = {
    small: 580,
    large: 1200,
};

const StarHire = (props: IStarHireProps) => {
    const { classes, onClickHire, idCliente, token, serviceSelected, parentRef } = props;
    const [isHiring, setIsHiring] = React.useState<boolean>(false);
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [verification, setVerification] = React.useState<boolean>(false);
    const [sendingMessage, setSendingMessage] = React.useState<boolean>(false);
    const [verificationCode, setVerificationCode] = React.useState<string>("");
    const [verificationMessage, setVerificationMessage] = React.useState<string>("");
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [blockedDialog, setBlockedDialog] = React.useState<boolean>(false);
    const [chosenService, setChosenService] = React.useState<string>("");
    const dispatch = useDispatch();
    const history = useHistory();
    const [widthCategory, setWidthCategory] = React.useState('');
    const divRef = React.useRef<HTMLDivElement | null>(null);
    const [imageCollection, setImageCollection] = React.useState<imgCollection>({
        first: "../star-sm-1.png",
        second: "../star-sm-2.png",
        background: "../bg-star-plus-sm.jpg"
    });

    const onSuccess = () => {
        setSendingMessage(true);
        serviceSelected.nombrePaquete = serviceSelected.paquete;
        let objData = {
            idCliente: idCliente,
            payload: serviceSelected
        }

        checkToken(() => {
            apiV2.postApiV2SendVerificationCode(objData, { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    if (response.data.estatus === "true" && response.data.codigo === "1") {
                        setVerification(true);
                        setVerificationMessage("Ha sido enviado un código de verificación para la contratación del servicio vía SMS.")
                    } else if (response.data.codigo === "2") {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error en el envío de su código de verificación vía SMS, intentarlo más tarde.", "Contratar servicio"))
                    } else if (response.data.codigo === "3") {
                        dispatch(AlertRedux.showAlert("Ha llegado a su límite de intentos de contratación en el día, intentarlo el día de mañana.", "Contratar servicio"))
                    } else {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error al generar su código de verificación, intentarlo más tarde.", "Contratar servicio"))
                    }
                    setSendingMessage(false);
                })
                .catch((err) => {
                    err = _.get(err, 'response', err);
                    if (err && err.data && err.data.err && err.data.err.message && err.data.err.message === "jwt expired") {
                        dispatch(AlertRedux.showAlert("La sesión ha caducado.", "Sesión"));
                        setTimeout(function () {
                            removeToken();
                            history.push("/");
                        }, 3000);
                    } else {
                        dispatch(AlertRedux.showAlert("Ha ocurrido un error al generar su código de verificación, intentarlo más tarde.", "Contratar servicio"))
                    }
                    setSendingMessage(false);
                })
        })
    }

    const addService = (promotion: any, verificationCode: string) => {
        if (idCliente) {
            let sucursal = idCliente.substring(0, 3);
            let suscriptor = idCliente.substring(3, 10);
            let objData = {
                suscriptor: suscriptor,
                id_paquete: promotion.IDPaquete,
                promocion: parseInt(promotion.promocion),
                origen: "WEB",
                nombrePaquete: promotion.paquete,
                codigo_validacion: verificationCode
            }
            let objBitacora = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length),
                origen: "WEB",
                tipoServicio: promotion.tipoServicio,
                servicio: promotion.servicio,
                promocion: promotion.promocion,
                accion: "Contratacion",
                correo: ""
            }

            setIsReset(false);
            setChosenService(promotion.tipoServicio)

            let objDataValidate = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length),
                id_paquete: promotion.IDPaquete,
                promocion: parseInt(promotion.promocion),
                origen: "WEB",
            }

            let dataToStore = {
                idPaquete: promotion.IDPaquete,
                lastAttempt: new Date().getTime(),
                idCliente: idCliente,
                codigo: promotion.codigo,
                codigoTipo: promotion.codigoTipo
            }

            const dataReviewTmp = localStorage.getItem('salesAttempts');
            let proceedWithSale = true;
            let dataReview: any = [];

            if (dataReviewTmp && !_.isEmpty(dataReviewTmp)) {
                dataReview = JSON.parse(dataReviewTmp);
                if (promotion.codigoTipo === "47") {
                    dataReview.forEach((element: any) => {
                        if ((element.codigo === promotion.codigo) && (Math.abs(new Date().getTime() - element.lastAttempt) / 1000) <= 1800) {
                            proceedWithSale = false;
                        }
                    })
                } else {
                    dataReview.forEach((element: any) => {
                        if ((element.idPaquete === promotion.IDPaquete) && (Math.abs(new Date().getTime() - element.lastAttempt) / 1000) <= 1800) {
                            proceedWithSale = false;
                        }
                    })
                }
            }

            if (proceedWithSale) {
                setDialogOpen(true);
                checkToken(() => {
                    apiV2.postApiV2ValidaContrataciones(objDataValidate, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response: any) => {
                            if (response.data && response.data.resultado === "1") {
                                apiV2.postApiV2VentasOperacionesVerifiedSucursal(sucursal, objData, { headers: { 'Authorization': `Bearer ${token}` } })
                                    .then((response) => {
                                        dataReview.push(dataToStore)
                                        localStorage.setItem('salesAttempts', JSON.stringify(dataReview));
                                        if (response && response.data && response.data.id && response.data.id.id && !_.isEmpty(response.data.id.id)) {
                                            onClickHire();
                                            apiV2.postApiV2BitacoraEvento(objBitacora, { headers: { 'Authorization': `Bearer ${token}` } })
                                        } else if (response.data.mensaje === "Registro duplicado.") {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, ya cuenta con una en proceso.", "Contratar " + promotion.tipoServicio));
                                        } else if (response.data.mensaje === "código incorrecto.") {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, el código de validación es incorrecto.", "Contratar " + promotion.tipoServicio));
                                        } else {
                                            dispatch(AlertRedux.showAlert("No es posible realizar la contratación, intentalo más tarde.", "Contratar " + promotion.tipoServicio))
                                        }
                                    })
                                    .catch((error) => {
                                        // setIsReset(true);
                                        dispatch(AlertRedux.showAlert("No es posible realizar la contratación, intentalo más tarde.", "Contratar " + promotion.tipoServicio))
                                    })
                            } else if (response.data.codigo === "2") {
                                dispatch(AlertRedux.showAlert("No es posible continuar con la contratación, ya cuenta con una en proceso.", "Contratar " + promotion.tipoServicio))
                            } else {
                                dispatch(AlertRedux.showAlert("Ha ocurrido un error al validar su contratación, intentarlo más tarde.", "Contratar " + promotion.tipoServicio))
                            }
                            setDialogOpen(false);
                        })
                        .catch((err: any) => {
                            // setIsReset(true);
                            setDialogOpen(false);
                            err = _.get(err, 'response', err);
                            if (err && err.data && err.data.err && err.data.err.message && err.data.err.message === "jwt expired") {
                                dispatch(AlertRedux.showAlert("La sesión ha caducado.", "Sesión"));
                                setTimeout(function () {
                                    removeToken();
                                    history.push("/");
                                }, 3000);
                            } else {
                                dispatch(AlertRedux.showAlert("Ha ocurrido un error al validar su contratación, intentarlo más tarde.", "Contratar " + promotion.tipoServicio))
                            }
                        })
                })
            } else {
                setBlockedDialog(true);
            }
        }
    };

    const confirmVerification = () => {
        if (verificationCode.length !== 6) {
            setIsReset(true);
            dispatch(AlertRedux.showAlert("¡Estimado Usuario! El código de verificación debe ser de 6 dígitos.", "Contratar servicio"))
        } else {
            addService(serviceSelected, verificationCode);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            if (divRef.current) {
                const divWidth = divRef.current.getBoundingClientRect().width;

                if (divWidth <= BREAKPOINTS.small) {
                    setWidthCategory('small');
                } else if (divWidth < BREAKPOINTS.large) {
                    setWidthCategory('medium');
                } else {
                    setWidthCategory('large');
                }
            }
        };

        handleResize(); // Initial calculation
        window.addEventListener('resize', handleResize);
        window.addEventListener('DOMContentLoaded', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('DOMContentLoaded', handleResize);
        };
    }, [divRef, parentRef.current]);

    useEffect(() => {
        if (!_.isEmpty(widthCategory)) {
            if (widthCategory === "small") {
                setImageCollection({
                    first: "../star-sm-1.png",
                    second: "../star-sm-2.png",
                    background: "../bg-star-plus-sm.jpg"
                })
            } else if (widthCategory === "medium") {
                setImageCollection({
                    first: "../star-md-1.png",
                    second: "../star-md-2.png",
                    background: "../bg-star-plus-md.jpg"
                })
            } else if (widthCategory === "large") {
                setImageCollection({
                    first: "../star-lg-1.png",
                    second: "../star-lg-2.png",
                    background: "../bg-star-plus-lg.jpg"
                })
            }
        }
    }, [widthCategory]);

    return (
        <div className={classes.root} ref={divRef}>
            {
                verification ?
                    <div className={classes.container}>
                        <img
                            src={imageCollection.background}
                            alt="star"
                            className={classes.image}
                        />
                        <div className={classes.buttonContainerThird}>
                            <Typography
                                style={{ color: '#fff', fontSize: '1.25em', margin: '0px', textAlign: 'center', marginTop: '20px' }}
                                type="p"
                                text={verificationMessage}
                            />
                            <Typography
                                style={{ color: '#fff', fontSize: '1.25em', fontWeight: "bold", margin: '0px', textAlign: 'center', paddingTop: '25px' }}
                                type="p"
                                text="Código de verificación"
                            />
                            <div className={classes.inputText}>
                                <TextField
                                    value={verificationCode}
                                    name="verificationCode"
                                    placeholder='123456'
                                    variant="outlined"
                                    autoComplete='notAutoComplete'
                                    style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#838383', borderRadius: '5px' }}
                                    InputProps={{ className: classes.textfield }}
                                    inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
                                    onChange={(event) => setVerificationCode(event.target.value)} />
                            </div>
                            <div className={widthCategory === "large" ? classes.swipeButtonLarge : classes.swipeButton}>
                                <SwipeableButton
                                    resetStatus={isReset}
                                    onSuccess={confirmVerification}
                                    color='#00ccff'
                                    text='Deslice para contratar'
                                />
                            </div>
                        </div>
                    </div>
                    : isHiring ?
                        <div className={classes.container}>
                            <img
                                src={imageCollection.second}
                                alt="star"
                                className={classes.image}
                            />
                            <div className={classes.buttonContainerSecond}>
                                <a
                                    className={classes.termsLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.megacable.com.mx/terminos-y-condiciones"
                                >
                                    Términos y condiciones
                                </a>
                                <Button
                                    disabled={sendingMessage}
                                    className={sendingMessage ? classes.disneyButtondisabled : classes.starButton}
                                    onClick={() => onSuccess()}
                                >
                                    Contratar
                                </Button>
                            </div>
                        </div>
                        :
                        <div className={classes.container}>
                            <img
                                src={imageCollection.first}
                                alt="star"
                                className={classes.image}
                            />
                            <div className={classes.buttonContainerFirst}>
                                <Button
                                    className={classes.starButton}
                                    onClick={() => setIsHiring(true)}
                                >
                                    Contratar
                                </Button>
                            </div>
                        </div>
            }
            <ProcessingDialog open={dialogOpen} />
            <BlockedDialog
                open={blockedDialog}
                servicio={chosenService}
                onExit={() => setBlockedDialog(false)}
            />
        </div>
    );
};

const styles = ({ breakpoints }: any) => createStyles({
    root: {
        width: "100%",
    },
    disneyButtondisabled: {
        marginTop: "15px",
        backgroundColor: '#c7c6c6',
        width: "200px",
        border: 'none',
        fontFamily: "Sarabun",
        fontSize: '18px',
        color: '#e71f68',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: "uppercase",
        borderRadius: '20px',
        direction: 'ltr',
        '&:hover': {
            backgroundColor: '#c7c6c6',
            cursor: 'pointer',
        }
    },
    inputText: {
        display: 'flex',
        justifyContent: 'center'
    },
    swipeButton: {
        width: "50%",
        [breakpoints.down('xs')]: {
            width: "90%",
        },
    },
    swipeButtonLarge: {
        width: "20%"
    },
    textfield: {
        color: '#FFFFFF'
    },
    buttonContainerFirst: {
        position: "absolute",
        width: "100%",
        bottom: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    buttonContainerSecond: {
        position: "absolute",
        width: "100%",
        bottom: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    buttonContainerThird: {
        position: "absolute",
        width: "100%",
        bottom: "10.5vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        [breakpoints.down('lg')]: {
            bottom: "20.4vh",
        },
        [breakpoints.down('md')]: {
            bottom: "17.7vh",
        },
        [breakpoints.down('sm')]: {
            bottom: "9.6vh",
        },
        [breakpoints.down('xs')]: {
            bottom: "4.2vh",
        },
    },
    image: {
        width: "100%",
        marginBottom: "-4px"
    },
    starButton: {
        marginTop: "15px",
        backgroundColor: "#fff",
        color: "#e71f68",
        width: "160px",
        textTransform: "uppercase",
        fontSize: "18px",
        padding: "6px",
        borderRadius: "30px",
        "&:hover": {
            backgroundColor: "#fff",
            cursor: "pointer",
        },
        [breakpoints.down('sm')]: {
            width: "150px",
            padding: "4px",
            fontSize: "16px"
        },
    },
    termsLink: {
        color: '#fff',
        margin: '-20px 0px 0px 0px',
        fontSize: "10px"
    },
    container: {
        position: "relative"
    },
});

export default withStyles(styles)(StarHire);
