import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import Login from '../../components/Login';
import { Grid } from '@material-ui/core';
import AlertDialogLoginScreen from '../../components/AlertDialogLoginScreen';
import { useLocation } from 'react-router-dom';
import ForgotData from '../../components/ForgotData';
import Register from '../../components/Register';

const styles = ({ breakpoints }: any) => createStyles({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        [breakpoints.down('sm')]: {
            overflowY: 'auto',
        }
    },
    footer: {
        backgroundColor: '#044c9b',
        color: '#FFFFFF',
        margin: 0,
        height: '80px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        [breakpoints.down('md')]: {
            height: '118px',
        },
    },
    footerText: {
        fontFamily: 'Sarabun',
        margin: 0,
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '25px',
    },
    imageBackground: {
        height: 'calc(100vh - 85px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundSize: 'cover',
        backgroundImage: 'url(servicios-bg.jpg)',
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    imageContent: {
        width: '80%',
        height: 'auto',
        backgroundSize: 'cover',
        // [breakpoints.up('xl')]: {

        // },
        // [breakpoints.down('lg')]: {
        //     width: '90%'
        // },
        // [breakpoints.down('md')]: {
        //     height: '70%'
        // }
    }
});

interface LoginProps extends WithStyles<typeof styles> {

}

export const LoginScreen = ({ classes }: LoginProps) => {
    const location = useLocation();

    return (
        <div className={classes.container}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} style={{ backgroundColor: '#CECECE' }}>
                    {
                        location.pathname === '/register' ?
                            <Register />
                            : location.pathname === '/forgotData' ?
                                <ForgotData />
                                :
                                <Login />
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={9} xl={9} style={{ backgroundColor: '#044c9b' }} >
                    <div className={classes.imageBackground}>
                        <img src="../servicios-content.png" alt="" className={classes.imageContent} />
                    </div>
                    <div className={classes.footer}>
                        <img src="../megacable-logo.png" alt="" style={{ height: '50px', paddingRight: '20px' }} />
                        <p className={classes.footerText}>TODOS TUS SERVICIOS EN UN SOLO LUGAR</p>
                    </div>
                </Grid>
                <AlertDialogLoginScreen />
            </Grid>
        </div >
    )
}

export default withStyles(styles)(LoginScreen);