import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import FormCardPayment from '../../components/FormCardPayment';
import ButtonPayment from '../../components/ButtonPayment';
import moment from 'moment';
import { IContract } from '../../storeV2/ducks/types';
import { DatosPago, AgregarTarjetaRequest } from 'megacable-mobile-api/models';
import { AlertRedux } from 'megacable-mobile-library';
import { useSelector, useDispatch } from "react-redux";
import GoBack from '../../components/GoBack';
import { useHistory } from "react-router";
import Help from '../../components/Help';
import CreditCard from '../../components/CreditCard';
import Expansion from '../../components/Expansion';
import Spinner from '../../components/Spinner';
import awsmobile from '../../aws-exports';
import * as api from 'megacable-mobile-api';
import _ from 'lodash';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface PaymentData {
    number: string,
    expirationDate: string,
    cvv: string,
    saveCard: boolean,
    isValid: boolean
}

interface PaymentErrors {
    number: string,
    expirationDate: string,
    cvv: string,
}

const emptyCreditCard = {
    number: "",
    expirationDate: "",
    cvv: "",
    saveCard: true,
    isValid: false
}
interface ICardInfo {
    resultado: boolean,
    tarjeta: string,
    tarjetaDecrypted: string
}

type ICardPayments = {
    idCliente: string,
    contract: IContract,
    payment: DatosPago,
};

const CardPayments = (props: ICardPayments) => {
    const classes = styles();
    const { idCliente, payment, contract } = props;
    const [paymentData, setPaymentData] = React.useState<PaymentData>(emptyCreditCard);
    const [paymentErrors, setPaymentErrors] = React.useState<PaymentErrors>({ number: "", expirationDate: "", cvv: "" });
    const [creditCard, setCreditCard] = React.useState<ICardInfo>({ resultado: false, tarjeta: "", tarjetaDecrypted: "" });
    const token = useSelector((state: any) => state.login.auth.token);
    const sucursal = useSelector((state: any) => state.currentContract.currentContract.sucursal);
    const suscriptor = useSelector((state: any) => state.currentContract.currentContract.suscriptor.substring(3, 10));
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingSaveCard, setLoadingSaveCard] = React.useState<boolean>(false);
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        const getCreditCard = () => {
            checkToken(() => {
                apiV2.postApiV2ConsultarTarjeta({ sucursal, suscriptor }, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        setLoading(false);
                        if (_.hasIn(response, ["data"]) && response.data) {
                            setCreditCard(response.data)
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                    })
            })
        }
        getCreditCard();
        return () => { }
    }, [sucursal, suscriptor, token]);

    const addPaymentData = (value: string, typeData: "number" | "date" | "cvv") => {
        let valueTmp = value;
        const typeDataTmp = typeData;
        switch (typeDataTmp) {
            case "number":
                var numberRaw = valueTmp.split("-").join("");
                var numberFormated = valueTmp.match(new RegExp('.{1,4}', 'g'))?.join("-");
                if (numberRaw.length <= 16) {
                    if (numberFormated) {
                        setPaymentData({ ...paymentData, number: numberFormated });
                    } else {
                        setPaymentData({ ...paymentData, number: '' });
                    }
                }
                break;
            case "date":
                let expirationDate: string = _.cloneDeep(valueTmp);
                if (expirationDate) {
                    expirationDate = expirationDate.split("/").join("")
                    if (expirationDate.length <= 4) {
                        let cardFormatArray: Array<string> | null = expirationDate.match(/.{1,2}/g);
                        if (cardFormatArray) {
                            let newCardFormat: string = cardFormatArray.join("/");
                            setPaymentData({ ...paymentData, expirationDate: newCardFormat });
                        } else {
                            setPaymentData({ ...paymentData, expirationDate: '' });
                        }
                    }
                } else {
                    setPaymentData({ ...paymentData, expirationDate: "" });
                }
                break;
            case "cvv":
                setPaymentData({ ...paymentData, cvv: valueTmp });
                break;
            default:
                break;
        }
    }

    function validation() {
        const formData = _.cloneDeep(paymentData);
        const { number, expirationDate, cvv } = formData;
        let errors: PaymentErrors = { number: "", expirationDate: "", cvv: "" };

        let currentYear = moment().year()
        let yearExp = currentYear.toString().substring(2, 4);

        const mounthAndYear = expirationDate && expirationDate.split("/");
        const cardMonth = mounthAndYear ? mounthAndYear[0] : '';
        const cardYear = mounthAndYear ? mounthAndYear[1] : '';

        if (!number) {
            if (!number) errors.number = "El número de tarjeta es requerido";
        } else {
            if (number.length !== 19) errors.number = "El número de tarjeta no es válido";
        }

        if (!expirationDate) {
            errors.expirationDate = "La fecha de vencimiento es requerida";
        } else {
            if (expirationDate.length !== 5) {
                errors.expirationDate = "Fecha de vencimiento inválida";
            }
            if (Number(cardMonth) < 1 || Number(cardMonth) > 12) {
                errors.expirationDate = "El mes de la tarjeta es inválido";
            }
            if (Number(cardYear) < Number(yearExp)) {
                errors.expirationDate = "El año de tarjeta ha expirado";
            }
        }
        if (!cvv) {
            if (!cvv || cvv.length < 3) errors.cvv = "El cvv de tarjeta es requerido";
        } else {
            if (cvv.length < 3) errors.cvv = "El cvv es no es válido";
        }
        return errors
    }

    const pay = () => {
        const errors = validation();
        if (errors.number || errors.expirationDate || errors.cvv) {
            setPaymentErrors(errors)
        } else {
            const formData = _.cloneDeep(paymentData);
            goToPayment(formData);
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            pay();
        }
    }

    const updateMyCreditCard = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => {
        updateCreditCard(newPaymentData.number)
    }

    const updateCreditCard = (cardNumber: string) => {
        if (contract && contract.sucursal && contract.suscriptor && cardNumber) {
            setLoadingSaveCard(true);
            const sucursalTmp: string = contract.sucursal;
            const suscriptorTmp: string = contract.suscriptor.substring(3, 10)
            const tarjetaTmp: string = cardNumber.replaceAll("-", "");
            const tipoTmp: string = "actualizacion";
            const agregarTarjetaRequest: AgregarTarjetaRequest = {
                sucursal: sucursalTmp,
                suscriptor: suscriptorTmp,
                tarjeta: tarjetaTmp,
                tipo: tipoTmp,
            }
            checkToken(() => {
                apiV2.postApiV2AgregarTarjeta(agregarTarjetaRequest, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then((response) => {
                        setLoadingSaveCard(false);
                        if (response.data) {
                            // const status = response.data.estatus;
                            const message = response.data.mensaje;
                            dispatch(AlertRedux.showAlert(message, "Tarjeta"));
                        }
                    })
                    .catch(error => {
                        setLoadingSaveCard(false);
                    })
            })
        }
    }

    const payWithMyCreditCard = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => {
        const saveCreditCardInfo: PaymentData = newPaymentData;
        goToPayment(saveCreditCardInfo);
    }

    const goToPayment = (creditCard: PaymentData) => {
        history.push({
            pathname: '/payService',
            state: {
                idCliente: idCliente,
                paymentCard: creditCard,
                currentContract: contract,
                paymentData: payment,
            }
        });
    }

    const handleFocus = (typeData: "number" | "date" | "cvv") => {
        switch (typeData) {
            case "number":
                setPaymentErrors({ ...paymentErrors, number: "" });
                break;
            case "date":
                setPaymentErrors({ ...paymentErrors, expirationDate: "" });
                break;
            case "cvv":
                setPaymentErrors({ ...paymentErrors, cvv: "" });
                break;
            default:
                break;
        }
    }

    const handleSaveCard = (value: boolean) => {
        setPaymentData({ ...paymentData, saveCard: value })
    }

    const newCreditCard = () => {
        return (
            <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 8 }}>
                        <FormCardPayment
                            addPaymentData={addPaymentData}
                            paymentData={paymentData}
                            paymentErrors={paymentErrors}
                            onFocus={handleFocus}
                            onKeyDown={handleKeyDown}
                            saveCard={handleSaveCard} />
                    </div>
                    <div className={classes.secureTrustImgContainer}>
                        <a href="https://sealserver.trustwave.com/cert.php?customerId=d0328ea2063448ebb51870d9716b80aa&size=105x54&style=invert" target="_blank" rel="noopener noreferrer">
                            <img src="../../securetrust.png" alt="Secure trust" />
                        </a>
                    </div>
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <Help type={"attention"} helpStyle={"outline"} text1="¡Lo sentimos!" text2='Por el momento no aceptamos American Express' additionalStyle={{ margin: "10px 10px" }} />
                </div>
                <div style={{ marginBottom: 16 }}>
                    <ButtonPayment onClick={pay} />
                </div>
            </>
        )
    }

    return (
        <div className={classes.root}>
            <div style={{ backgroundColor: "#002A4F" }}>
                <GoBack onClick={() => history.push('/payments')} />
            </div>
            {
                (creditCard && loading) || loadingSaveCard ? <Spinner /> : (
                    <>
                        {
                            payment && payment.datos && payment.datos.amount ? (
                                Number(payment.datos.amount) > 0 ? (
                                    <>
                                        {
                                            creditCard.tarjeta ? (
                                                <>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <Typography component={'span'} color="primary" variant="body1"><Box mb={1} style={{ paddingLeft: 30, marginTop: '10px' }}>TARJETA GUARDADA</Box></Typography>
                                                        <CreditCard
                                                            onClick={payWithMyCreditCard}
                                                            onClickUpdate={updateMyCreditCard}
                                                            cardInfo={creditCard} />
                                                    </div>
                                                    <Expansion title="PAGAR CON OTRA TARJETA" content={newCreditCard()} />
                                                </>
                                            ) : newCreditCard()
                                        }
                                    </>
                                )
                                    : <Help type={"attention"} helpStyle={"outline"} text1="¡No tienes adeudos!" text2='' additionalStyle={{ margin: "10px 10px" }} />
                            ) : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor recarga la página!" text2='Ocurrió un error al recuperar la información' additionalStyle={{ margin: "10px 10px" }} />
                        }
                    </>
                )
            }
        </div>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white"
    },
    secureTrustImgContainer: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
}));

export default CardPayments;
