import React, { useEffect } from 'react';
import { Theme, createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import Slider from '../../components/Slider';
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { BannersRedux } from 'megacable-mobile-library';
import _ from 'lodash';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import Help from '../../components/Help';
import ListPaymentsTable from './ListPaymentsTable';
import GoBack from '../../components/GoBack';
import { useHistory } from "react-router";
import AddContractHeader from '../../components/AddContractHeader';
import checkToken from '../../utils/checKToken';
import { keycloak } from '../../App';
import Spinner from '../../components/Spinner';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles(({ breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        marginBottom: '15px',
        backgroundColor: 'white'
    },
    bannerContainer: {
        width: "100%",
    }
}));

type PropsFromRedux = ConnectedProps<typeof connector>

type ListPaymentsProps = PropsFromRedux & {

}

export const ListPayments = (props: ListPaymentsProps) => {
    const classes = styles();
    const { banners, currentContract } = props;
    const [contrato, setContrato] = React.useState<any>({});
    const [idCliente, setIdCliente] = React.useState('');
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [paymentList, setPaymentList] = React.useState<any>();
    const [loading, setLoading] = React.useState(true);
    let history = useHistory();

    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    useEffect(() => {
        if (currentContract && currentContract.currentContract) {
            setIdCliente(currentContract.currentContract.idCliente);
        }
    }, [currentContract]);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract'])) {
                setContrato(currentContract.currentContract);
            }
        }
    }, [currentContract]);

    useEffect(() => {
        setLoading(true);
        if (idCliente) {
            checkToken(() => {
                apiV2.getApiV2ListadoPagosIdCliente(idCliente, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (response.data) {
                            setPaymentList(response.data)
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                        setLoading(false);
                    })
            })
        }
    }, [idCliente]);

    return (
        <div className={classes.root}>
            <div className={classes.bannerContainer}>
                {bannerList && !_.isEmpty(bannerList) ? <Slider size='large' images={bannerList} /> : null}
            </div>
            {
                contrato ?
                    <>
                        <AddContractHeader title="Últimos pagos" number={idCliente ? idCliente : ''} />
                        <div style={{ backgroundColor: "#002A4F" }}>
                            <GoBack onClick={() => history.push('/payments')} />
                        </div>
                        {
                            loading ?
                                <Spinner /> :
                                !_.isEmpty(paymentList) ?
                                    <ListPaymentsTable activityList={paymentList} />
                                    :
                                    <Help type={"information"} helpStyle={"outline"} text1="¡INFORMACIÓN!" text2='No hay registros.' additionalStyle={{ margin: "10px 0px 10px 0px" }} />
                        }
                    </>
                    :
                    <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
            }
        </div >
    )
}

const mapState = (state: any) => ({
    banners: state.banners,
    currentContract: state.currentContract,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({
    ...bindActionCreators({ BannersActions: BannersRedux.Creators } as any, dispatch),
    getBanners: () => dispatch(BannersRedux.getBanners()),

})
const connector = connect(mapState, mapDispatch)

export default (connector(ListPayments));