import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Grid, Col, Row } from 'react-flexbox-grid';
import Typography from './Typography';
import GoBack from './GoBack';

const styles = ({ palette }: any) => createStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        background: '#002A4F',
        width: '100%',
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        color: 'white'
    },
    containerH2: {
        minHeight: '50px'
    },
    containerH4: {
        height: '35px',
        textAlign: 'center'
    },
    rightCol: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});
interface ButtonProps {
    onClick: (event: React.SyntheticEvent<any>, reason: string) => void;
    size?: 'large' | 'medium' | 'small' | 'slim';
    type?: "primary" | "secondary" | "tertiary" | "success" | "information" | "warning" | "danger" | "dark" | "light";
    text?: string;
    icon?: any;

}

interface ComponentHeaderProps extends WithStyles<typeof styles> {
    title?: string;
    type?: 'H2' | 'H4';
    button?: ButtonProps ;
}



export const ComponentHeaderSecondary = ({ title, type, classes, button }: ComponentHeaderProps) => {
    return (
        <div className={`${classes.container} ${type ? (type === 'H2' ? classes.containerH2 : classes.containerH4) : classes.containerH4}`}>
            <Grid style={{ width: "100%", height: '100%' }}>
                <Row style={{ width: "100%",height: '100%', display: 'flex', alignItems: 'center' }}>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography type="h5" text={title ? title : ""} weight='bold' style={{ marginBottom: '0px', marginTop: '0px', fontSize: '15px', paddingLeft: '10px' }} />
                    </Col>
                    <Col className={classes.rightCol} xs={6} sm={6} md={6} lg={6} xl={6}>
                        { button && <GoBack onClick={button.onClick} /> }
                    </Col>
                </Row>
            </Grid>

        </div>
    )
}

export default withStyles(styles)(ComponentHeaderSecondary);
