import React, { Component } from 'react';
import _ from 'lodash';
import utils from '../../../utils';

class Map4 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let location = utils.defaultLocation;

        this.setState({ location }, () => {
            this.createMap()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { cis } = this.props;

        let location = {
            latitude: cis && cis.latitude ? Number(cis.latitude) : utils.defaultLocation.latitude,
            longitude: cis && cis.longitude ? Number(cis.longitude) : utils.defaultLocation.longitude
        }

        if (!_.isEqual(location, this.state.location)) {
            this.setState({ location }, () => {
                this.createMap()
            })
        }
    }

    createMap = () => {
        const { google } = window;
        const { location } = this.state;
        const { cis } = this.props;

        const latitude = location.latitude !== null || location.latitude === 0 ? location.latitude : utils.defaultLocation.latitude;
        const longitude = location.longitude !== null || location.longitude === 0 ? location.longitude : utils.defaultLocation.longitude;

        const position = { lat: latitude, lng: longitude };

        let map = null;

        if (google) {
            map = new google.maps.Map(document.getElementById('map'), { zoom: this.props.mapZoom ? this.props.mapZoom : 14, center: position });
        }

        this.setState({ map }, () => {
            if (!_.isEmpty(cis))
                this.generateMarkers()
        })
    }

    generateMarkers = () => {
        const { location, map } = this.state;
        const { google } = window;

        const latitude = location.latitude !== null || location.latitude === 0 ? location.latitude : utils.defaultLocation.latitude;
        const longitude = location.longitude !== null || location.longitude === 0 ? location.longitude : utils.defaultLocation.longitude;

        const position = { lat: latitude, lng: longitude };

        if (google) {
            new google.maps.Marker({
                position: position,
                map: map
            });
        }

    }

    render() {
        const { google } = window;
        if (google) {
            return (
                <div id="map" style={{ height: '100%', width: '100%' }} />
            )
        } else {
            return (
                <div>NO Mapa</div>
            )
        }
    }
}

export default Map4;