import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

const styles = ({ palette }: any) => createStyles({
    h1: {
        display: "block",
        fontSize: "2em",
        marginTop: "0.67em",
        marginBottom: "0.67em",
        marginLeft: "0",
        marginRight: "0",
        fontWeight: "bolder",
        fontFamily: "Sarabun"
    },
    h2: {
        display: "block",
        fontSize: "1.5em",
        marginTop: "0.83em",
        marginBottom: "0.83em",
        marginLeft: "0",
        marginRight: "0",
        fontWeight: "bolder",
        fontFamily: "Sarabun",

        lineHeight: '1em',
        height: '1.2em'
    },
    h3: {
        display: "block",
        fontSize: 16,
        marginTop: "1em",
        marginBottom: "1em",
        marginLeft: "0",
        marginRight: "0",
        fontWeight: "bolder",
        fontFamily: "Sarabun",
        height: '1.09em',
        color: "#002A4F"
    },
    h4: {
        display: "block",
        fontSize: "1em",
        marginTop: "1.33em",
        marginBottom: "1.33em",
        marginLeft: "0",
        marginRight: "0",
        fontWeight: "bolder",
        fontFamily: "Sarabun",
        height: '0.94em',
        lineHeight: '0.9em'
    },
    h5: {
        display: "block",
        fontSize: "0.83em",
        marginTop: "1.67em",
        marginBottom: "1.67em",
        marginLeft: "0",
        marginRight: "0",
        fontWeight: "bolder",
        fontFamily: "Sarabun"
    },
    h6: {
        display: "block",
        fontSize: "0.67em",
        marginTop: "2.33em",
        marginBottom: "2.33em",
        marginLeft: "0",
        marginRight: "0",
        fontWeight: "bolder",
        fontFamily: "Sarabun"
    },
    p: {
        display: "block",
        fontSize: "1em",
        marginTop: "1em",
        marginBottom: "1em",
        marginLeft: "0",
        marginRight: "0",
        fontWeight: "normal",
        fontFamily: "Sarabun"
    },
    bold: {
        fontWeight: "bold",
    },
    bolder: {
        fontWeight: "bolder",
    },
    normal: {
        fontWeight: "normal",
    }
});


interface TypographyProps extends WithStyles<typeof styles> {
    style?: {};
    type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
    text: string | number;
    weight?: 'normal' | 'bold' | 'bolder'
}

export const Typography = ({ weight, type, style, text, classes }: TypographyProps) => {
    return (
        <p className={`${classes[type]} ${weight && classes[weight]}`} style={style}>
            {text}
        </p>
    )
}

export default withStyles(styles)(Typography);
