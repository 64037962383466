import React, { useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { /*Avatar,*/ Box, Grid, Typography, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ServicesItemList from '../components/ServicesItemList';
import DeviceItemList from '../components/DeviceItemList';
import clsx from 'clsx';
import { getInitialLetters } from '../utils/';
import { IContract, IServicios, IServiciosFormateados } from '../storeV2/ducks/types';
import { DatosPago } from 'megacable-mobile-api/models';
import _ from 'lodash';
import * as api from 'megacable-mobile-api';
import checkToken from '../utils/checKToken';
import awsmobile from '../aws-exports';
import { keycloak } from '../App';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);
interface IServicesDetail extends WithStyles<typeof styles> {
    contractSelected: IContract | undefined,
    servicesFormat: IServiciosFormateados | undefined,
}

const ServicesDetail = (props: IServicesDetail) => {
    const { classes, contractSelected, servicesFormat } = props;
    const [id, setId] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [avatarText, setAvatarText] = React.useState("");
    const [packageType, setPackageType] = React.useState<string>("");
    const [servicesList, setServicesList] = React.useState<Array<any>>([]);
    const [premierList, setPremierList] = React.useState<Array<any>>([]);
    const [devicesList, setDevicesList] = React.useState<Array<any>>([]);
    const [amount, setAmount] = React.useState<string>('');
    const [payment, setPayment] = React.useState<DatosPago>();
    const [hasDebt, setHasDebt] = React.useState<boolean>(false);
    const [services, setServices] = React.useState<IServicios | undefined>();

    useEffect(() => {
        if (servicesFormat) {
            setServicesList(servicesFormat.Servicios);
            setPremierList(servicesFormat.Premier);
            setDevicesList(servicesFormat.Equipos);
        } else {
            setServicesList([]);
            setPremierList([]);
            setDevicesList([]);
        }
        return () => { }
    }, [servicesFormat]);

    useEffect(() => {
        if (contractSelected) {
            setId(contractSelected.idCliente);
            setName(contractSelected.nombre);
            setPackageType(contractSelected.tipoPaquete);
        }
        return () => { }
    }, [contractSelected]);

    useEffect(() => {
        if (name) {
            const avatarText = getInitialLetters(name);
            setAvatarText(avatarText)
        }
        return () => { }
    }, [name]);

    useEffect(() => {
        if (contractSelected) {
            checkToken(() => {
                apiV2.getApiV2GetDatosPago(contractSelected.idCliente, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (_.has(response, 'data.datos')) {
                            setPayment(response.data)
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
            })
        }
        return () => { }
    }, [contractSelected]);

    useEffect(() => {
        if (payment) {
            if (_.hasIn(payment, ['datos', 'amount'])) {
                setAmount(payment.datos.amount);
            }
        }
        return () => { }
    }, [payment])

    useEffect(() => {
        if (contractSelected) {
            if (_.has(contractSelected, ['servicios'])) {
                setServices(contractSelected.servicios)
            }
        }
    }, [contractSelected]);

    useEffect(() => {
        if (services && services.servicios) {
            services.servicios.forEach((element: any) => {
                if ((element.tiposervicio === '1') || (element.tiposervicio === '4') || (element.tiposervicio === '9')) {
                    if (((element.estado === 'COR') || (element.estado === 'STB') || (element.estado === 'PCN')) && Number(amount) > 0) {
                        setHasDebt(true);
                    }
                }
            })
        }
    }, [services, amount]);

    const cardDetailServices = (
        <div className={classes.cardServices}>
            <div className={clsx(classes.titleContainer, classes.backgroundLight)}>
                <Typography component={'span'} variant="body1">
                    <Box color="#fff" fontWeight={700}>
                        Servicios
                    </Box>
                </Typography>
            </div>
            <div className={classes.cardContainer}>
                {
                    servicesList ? (
                        servicesList.map((service, index) => {
                            return <ServicesItemList service={service} showDate key={index} />
                        })
                    ) : null
                }
            </div>
        </div>
    );

    const cardDetailPremier = (
        <div className={classes.cardServices}>
            <div className={clsx(classes.titleContainer, classes.backgroundMain)}>
                <Typography component={'span'} variant="body1">
                    <Box color="#fff" fontWeight={700}>
                        Premier
                    </Box>
                </Typography>
            </div>
            <div className={classes.cardContainer}>
                {
                    premierList ? (
                        premierList.map((premier, index) => {
                            return <ServicesItemList service={premier} showDate key={index} />
                        })
                    ) : null
                }
            </div>
        </div>
    );

    const cardDetailDivices = (
        <div className={classes.cardServices}>
            <div className={clsx(classes.titleContainer, classes.backgroundDark)}>
                <Typography component={'span'} variant="body1">
                    <Box color="#fff" fontWeight={700}>
                        Equipos
                    </Box>
                </Typography>
            </div>
            <div className={classes.cardContainer}>
                {
                    devicesList ? (
                        devicesList.map((device, index) => {
                            return <DeviceItemList device={device} key={index} />
                        })
                    ) : null
                }
            </div>
        </div>
    );

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" fontWeight={700} color="primary" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.accordion}>
                    {/* <Avatar alt={name} className={classes.orange}>{avatarText}</Avatar> */}
                    <div className={classes.div1}>
                        <div className={classes.circle}>
                            <span className={classes.contractNumber}>{avatarText}</span>
                        </div>
                    </div>
                    <div className={classes.userDataContainer}>
                        <Typography component={'span'} style={{ color: '#002A4F' }} variant="h6" >
                            <Box fontWeight={700}>
                                {name}
                            </Box>
                        </Typography>
                        <Typography component={'span'} style={{ color: '#002A4F' }} variant="body1">
                            <Box>
                                {id}
                            </Box>
                        </Typography>
                        {
                            hasDebt ?
                                <Typography component={'span'} style={{ color: '#FC0022' }} variant="h5">
                                    <Box fontWeight={700}>
                                        {amount ? `Tiene un saldo vencido de: $${amount}` : ""}
                                    </Box>
                                </Typography>
                                : null
                        }
                        <Typography component={'span'} style={{ color: '#002A4F' }} variant="body1">
                            <Box fontWeight={700}>
                                {packageType}
                            </Box>
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ margin: 0, padding: 0 }}>
                    <div className={classes.accordinDetailsContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                {cardDetailServices}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                {cardDetailPremier}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                {cardDetailDivices}
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    accordion: {
        display: "flex",
        flexDirection: "row",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    orange: {
        width: 70,
        height: 70,
        marginRight: 32,
        color: "#FFF",
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        fontSize: '17px',
        backgroundColor: "#36c1ff",
        alignSelf: "center",
        [theme.breakpoints.down('xs')]: {
            width: 50,
            height: 50,
            marginRight: 8,
        }
    },
    userDataContainer: {
        display: "flex",
        flexDirection: "column",
        marginRight: 60,
        [theme.breakpoints.down('xs')]: {
            marginRight: 16,
        }
    },
    priceDataContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "auto",
    },
    accordinDetailsContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardServices: {
        display: "flex",
        flexDirection: "column",
    },
    titleContainer: {
        paddingTop: 4,
        paddingLeft: 24,
        paddingBottom: 4,
    },

    backgroundLight: {
        backgroundImage: "linear-gradient(90deg, #0078D0 0%, #004FC0 100%)",
    },

    backgroundMain: {
        backgroundColor: "#004b98",
    },

    backgroundDark: {
        backgroundColor: theme.palette.secondary.main,
    },

    cardContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: 24,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 24,
    },

    carditem: {
        display: "flex",
        flexDirection: "row",
    },

    cardItemDetailService: {
        display: "flex",
        flexDirection: "column",
        marginRight: 40,
    },

    cardItemDateService: {
        display: "flex",
        flexDirection: "column",
    },

    divider: {
        marginTop: 8,
        marginBottom: 8,
    },
    div1: {
        position: 'relative',
        textAlign: 'center',
    },
    circle: {
        width: '55px',
        height: '55px',
        borderRadius: '50%',
        color: '#fff',
        lineHeight: '55px',
        textAlign: 'center',
        margin: '0px 30px 0px 25px',
        top: '50%',
        position: 'relative',
        transform: 'translateY(-50%)',
        backgroundColor: '#00CCFF'
    },
    contractNumber: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '17px',
        color: 'white',
    },
});

export default withStyles(styles)(ServicesDetail);
