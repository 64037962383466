import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from './Button';

interface ILogOutDialogProps {
    isOpen: boolean,
    handleCloseDialog: (isAgree: boolean) => void,
}

const LogOutDialog = (props: ILogOutDialogProps) => {
    const { isOpen, handleCloseDialog } = props;

    const onClick = (isAgree: boolean) => {
        handleCloseDialog(isAgree)
    }
    
    return (
        <Dialog
            open={isOpen}
            onClose={() => onClick(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Cerrar sesión</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Está seguro que desea cerrar sesión?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button type="danger" text="Cancelar" size="small" onClick={() => onClick(false)} />
                <Button type="accept2" text="Aceptar" size="small" onClick={() => onClick(true)} />
            </DialogActions>
        </Dialog>
    )
}

export default LogOutDialog