import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import _ from 'lodash';

interface DisneyWaitingActivationProps extends WithStyles<typeof styles> {
    parentRef: any;
};

interface imgCollection {
    first: string;
};

const BREAKPOINTS = {
    small: 580,
    large: 1200,
};

const DisneyWaitingActivation = (props: DisneyWaitingActivationProps) => {
    const { classes, parentRef } = props;
    const [widthCategory, setWidthCategory] = React.useState('');
    const divRef = React.useRef<HTMLDivElement | null>(null);
    const [imageCollection, setImageCollection] = React.useState<imgCollection>({
        first: "../disney-sm-3.png",
    });

    useEffect(() => {
        const handleResize = () => {
            if (divRef.current) {
                const divWidth = divRef.current.getBoundingClientRect().width;

                if (divWidth <= BREAKPOINTS.small) {
                    setWidthCategory('small');
                } else if (divWidth < BREAKPOINTS.large) {
                    setWidthCategory('medium');
                } else {
                    setWidthCategory('large');
                }
            }
        };

        handleResize(); // Initial calculation
        window.addEventListener('resize', handleResize);
        window.addEventListener('DOMContentLoaded', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('DOMContentLoaded', handleResize);
        };
    }, [divRef, parentRef.current]);

    useEffect(() => {
        if (!_.isEmpty(widthCategory)) {
            if (widthCategory === "small") {
                setImageCollection({
                    first: "../disney-sm-3.png",
                })
            } else if (widthCategory === "medium") {
                setImageCollection({
                    first: "../disney-md-3.png",
                })
            } else if (widthCategory === "large") {
                setImageCollection({
                    first: "../disney-lg-3.png",
                })
            }
        }
    }, [widthCategory]);

    return (
        <div className={classes.root} ref={divRef}>
            <div className={classes.container}>
                <img
                    src={imageCollection.first}
                    alt="disney"
                    className={classes.image}
                />
            </div>
        </div>
    );
}

const styles = ({ breakpoints }: any) => createStyles({
    root: {
        width: "100%",
    },
    container: {
        position: "relative"
    },
    image: {
        width: "100%",
        marginBottom: "-4px"
    },
});

export default withStyles(styles)(DisneyWaitingActivation);
