import React, { useEffect } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import { CssBaseline, makeStyles, Toolbar } from '@material-ui/core';
import AppBarMenu from './components/AppBarMenu';
import SideBar from './components/SideBar';
import { keycloak } from './App';
import checkToken from './utils/checKToken';

import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { BannersRedux, LoginRedux, CisRedux, AlertRedux, MobileRedux } from 'megacable-mobile-library';
import { SuscriptorYServicios } from 'megacable-mobile-api/models';
import { Creators as ContractActions } from './storeV2/ducks/currentContract';
import Help from './components/Help';

import _ from 'lodash';
import Spinner from './components/Spinner';
import { IContract } from './storeV2/ducks/types'
import AlertDialog from './components/AlertDialog';
import { getToken, alertError, removeToken } from './utils';
import MobileResolutionDialog from './components/MobileResolutionDialog';

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        display: 'flex',
        overflowX: 'hidden',
    },
    content: {
        height: "100%",
        flexGrow: 1,
        paddingLeft: 10,
        paddingRight: 10,
        overflowY: 'hidden',
        overflowX: 'hidden',
        backgroundColor: "#f5f5f5"
    },
}));

const mapState = (state: any) => ({
    login: state.login,
    banners: state.banners,
    estadoCuenta: state.estadoCuenta,
    pagosData: state.pagosData,
    loading: state.loading,
    currentContract: state.currentContract,
    cis: state.cis,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
    ...bindActionCreators({ LoginCreators: LoginRedux.Creators, BannersActions: BannersRedux.Creators } as any, dispatch),
    getBanners: () => dispatch(BannersRedux.getBanners()),
    getCIS: () => dispatch(CisRedux.getCIS),
    signIn: (user: string, password: string, forceUpdate?: boolean) => dispatch(LoginRedux.signInSel(user, password, () => { }, forceUpdate)),
    setCurrentContract: (currentContract: IContract) => dispatch(ContractActions.setCurrentContract(currentContract)),
});

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type MainProps = PropsFromRedux & {
    classes: any;
    children?: React.ReactChild;
}

let routes = [
    { title: "MI ID", path: "/myId" },
    { title: "MIS SERVICIOS", path: "/services" },
    { title: "PAGOS", path: "/payments" },
    { title: "ESTADO DE CUENTA", path: "/statement" },
    { title: "SOPORTE TÉCNICO", path: "/support" },
    { title: "CONTRATOS ASOCIADOS", path: "/contracts" },
    // { title: "REGISTRO DE ACTIVIDADES", path: "/activityLog" },
    { title: "TÉRMINOS Y CONDICIONES", path: "/terms" },
    { title: "CONFIGURACIÓN", path: "/settings" },
];

let routesPhone = [
    { title: "MI ID", path: "/myId" },
    { title: "MIS SERVICIOS", path: "/services" },
    { title: "PAGOS", path: "/payments" },
    { title: "ESTADO DE CUENTA", path: "/statement" },
    { title: "SOPORTE TÉCNICO", path: "/support" },
    { title: "CONTRATOS ASOCIADOS", path: "/contracts" },
    { title: "HISTORIAL DE LLAMADAS", path: "/callsList" },
    // { title: "REGISTRO DE ACTIVIDADES", path: "/activityLog" },
    { title: "TÉRMINOS Y CONDICIONES", path: "/terms" },
    { title: "CONFIGURACIÓN", path: "/settings" },
];

let routesMovil = [
    { title: "MI ID", path: "/myId" },
    { title: "MIS SERVICIOS", path: "/services" },
    { title: "PAGOS", path: "/payments" },
    { title: "ESTADO DE CUENTA", path: "/statement" },
    { title: "SOPORTE TÉCNICO", path: "/support" },
    { title: "TELEFONÍA MÓVIL", path: "/mobiles" },
    { title: "CONTRATOS ASOCIADOS", path: "/contracts" },
    // { title: "REGISTRO DE ACTIVIDADES", path: "/activityLog" },
    { title: "TÉRMINOS Y CONDICIONES", path: "/terms" },
    { title: "CONFIGURACIÓN", path: "/settings" },
];

let routesMovilPhone = [
    { title: "MI ID", path: "/myId" },
    { title: "MIS SERVICIOS", path: "/services" },
    { title: "PAGOS", path: "/payments" },
    { title: "ESTADO DE CUENTA", path: "/statement" },
    { title: "SOPORTE TÉCNICO", path: "/support" },
    { title: "TELEFONÍA MÓVIL", path: "/mobiles" },
    { title: "CONTRATOS ASOCIADOS", path: "/contracts" },
    { title: "HISTORIAL DE LLAMADAS", path: "/callsList" },
    // { title: "REGISTRO DE ACTIVIDADES", path: "/activityLog" },
    { title: "TÉRMINOS Y CONDICIONES", path: "/terms" },
    { title: "CONFIGURACIÓN", path: "/settings" },
];

export const Main = (props: MainProps) => {
    const classes = styles();
    const { children, login, /*pagosData,*/ loading, estadoCuenta, currentContract } = props;
    const [userName, setUserName] = React.useState<string>('');
    const [user, setUser] = React.useState<SuscriptorYServicios>();
    const [token, setToken] = React.useState<string>('');
    const [contractsList, setContractsList] = React.useState<Array<IContract>>();
    const [contractsMenu, setContractsMenu] = React.useState<Array<string>>([]);
    const [contractSelected, setContractSelected] = React.useState("");
    const mobileReducer = useSelector((state: any) => state.mobile);
    const [isOpenMenu, setIsOpenMenu] = React.useState(false);
    const [isMegaPhone, setIsMegaPhone] = React.useState(false);
    const [optionRoute, setOptionRoute] = React.useState<Array<any>>([]);
    const dispatch = useDispatch();

    const handleOpenDrawerMenu = () => {
        setIsOpenMenu(!isOpenMenu);
    }

    useEffect(() => {
        auth();
    }, [])

    function auth() {
        dispatch(LoginRedux._retrieveDataSel(
            () => getToken(),
            () => alertError(dispatch(AlertRedux.showAlert("La sesión ha caducado.", "Sesión"))),
            removeToken,
            false
        ))
    }

    useEffect(() => {
        props.getBanners();
        props.getCIS();
    }, []);

    useEffect(() => {
        if (login) {
            const authData = login.auth;
            const authUser = login.user;
            if (authData && authUser) {
                setUser(authUser)
                setToken(authData.token);
            }
        }
    }, [login]);

    useEffect(() => {
        if (estadoCuenta) {
            const contractList: Array<IContract> = estadoCuenta.contratos;
            let contractMenuList: Array<string> = [];
            if (contractList && contractList.length > 0) {
                setContractsList(contractList);
                contractList.forEach((contract: IContract) => {
                    contractMenuList.push(contract.idCliente);
                });
            }
            if (contractMenuList.length > 0) {
                setContractsMenu(contractMenuList);
            }
        }
    }, [estadoCuenta && estadoCuenta.contratos]);

    useEffect(() => {
        if (contractsMenu && contractsMenu.length > 0) {
            const contractListTmp: Array<IContract> = _.cloneDeep(estadoCuenta.contratos);
            let mainContractId = '';
            contractListTmp.forEach((contract) => {
                if (contract && _.hasIn(contract, ['mainContract'])) {
                    mainContractId = contract.idCliente;
                }
            });
            if (mainContractId) {
                const mainContractIndex = _.findIndex(contractsMenu, (contract) => { return contract === mainContractId });
                setContractSelected(contractsMenu[mainContractIndex]);
            }
        }
        return () => { }
    }, [contractsMenu]);

    useEffect(() => {
        if (currentContract && _.hasIn(currentContract, ['currentContract', 'nombreSuscriptor'])) {
            setUserName(currentContract.currentContract.nombre);
        }
        return () => { }
    }, [currentContract]);

    useEffect(() => {
        if (contractsList) {
            const contractsListTmp = _.cloneDeep(contractsList);
            let currentContract = _.find(contractsListTmp, (contract) => contract.idCliente === contractSelected);
            props.setCurrentContract(currentContract);
        }
        return () => { }
    }, [contractSelected]);

    useEffect(() => {
        if (contractSelected && contractSelected !== "" && contractSelected !== "0") {
            checkToken(() => {
                if (keycloak.token)
                    dispatch(MobileRedux.getAni(keycloak.token, contractSelected, () => { }))
            })
        }
    }, [token, contractSelected]);

    function changeContractSelected(selected: string) {
        setContractSelected(selected);
    }

    useEffect(() => {
        setIsMegaPhone(false);
        if (_.has(currentContract, ['currentContract', 'servicios'])) {
            let servicios = currentContract.currentContract.servicios.servicios;
            servicios.forEach((service: any) => {
                if (service.tiposervicio === '9') {
                    setIsMegaPhone(true);
                }
            })
        }
    }, [currentContract]);

    useEffect(() => {
        if (_.has(mobileReducer, 'ani') && mobileReducer.ani && mobileReducer.ani.length > 0) {
            if (isMegaPhone) {
                setOptionRoute(routesMovilPhone);
            } else {
                setOptionRoute(routesMovil);
            }
        } else {
            if (isMegaPhone) {
                setOptionRoute(routesPhone);
            } else {
                setOptionRoute(routes);
            }
        }
    }, [mobileReducer && mobileReducer.ani, isMegaPhone])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarMenu
                isOpenMenu={isOpenMenu}
                userName={userName}
                handleOpenDrawerMenu={handleOpenDrawerMenu} />
            <SideBar
                isOpenMenu={isOpenMenu}
                contractSelected={contractSelected}
                routes={optionRoute}
                contracts={contractsMenu}
                onChangeContract={(e: React.ChangeEvent<HTMLSelectElement>) => changeContractSelected(e.target.value)}
                handleOpenDrawerMenu={handleOpenDrawerMenu} />
            <main className={classes.content}>
                <Toolbar />
                {
                    contractsMenu ? (
                        <div>
                            <MobileResolutionDialog />
                            {loading.showLoading === true ? <Spinner /> : children}
                        </div>
                    ) : (
                        <Help
                            type={"attention"}
                            helpStyle={"outline"}
                            text1="¡Error de conexión!"
                            text2='Hubo un error por favor recargue la página o intente más tarde.' />
                    )
                }
            </main>
            <AlertDialog />
        </div >
    )
}

export default connector(Main);
