import { keycloak } from '../App';
import { saveToken } from './';

const checkToken = async (callback: Function) => {
    if (keycloak.isTokenExpired()) {
        await keycloak.updateToken(60).then(() => {
            saveToken(JSON.stringify(keycloak.token));
            callback && callback();
        })
    } else {
        callback && callback();
    }
}

export default checkToken;
