import React, { useEffect } from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@material-ui/core';


const styles = ({ palette, breakpoints }: any) => createStyles({
    checkTicketContainer: {
        display: 'flex',
        fontFamily: 'Sarabun',
        borderBottom: '1px #d4d4d4 solid',
        minHeight: '100px',
        width: '100%',
        color: '#002A4F',
        cursor: 'pointer'
    },
    firstCheckTicketContainer: {
        borderTop: '1px #d4d4d4 solid'
    },
    selectedTicket: {
        backgroundColor: "#35CCFF",
        color: 'white'
    },
    div1: {
        textAlign: 'center',
        position: 'relative'
    },
    div2: {
        position: 'relative',
        whiteSpace: 'pre-wrap',
        width: 'inherit'
    },
    div3: {
        position: 'relative',
        margin: '0px 30px 0px 10px'
    },
    distance: {
        fontWeight: 'bold',
        fontSize: '13px'
    },
    distanceContainer: {
        margin: '0px 15px 0px 10px',
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    textContainer: {
        position: 'relative',
        minWidth: '100px',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    buttonContainer: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    chevronIcon: {
        fontSize: '20px',
        color: '#0078D0'
    },
    selectedChevronIcon: {
        color: 'white'
    }
});

interface CIS {
    name: string;
    address: string;
    suburb: string;
    distance: string;
}

interface CISListProps extends WithStyles<typeof styles> {
    cisElements: Array<CIS>;
    onPress: any;
    permission: string;
}

export const CISList = ({ cisElements, classes, onPress, permission }: CISListProps) => {
    const [selectedElement, setSelectedElement] = React.useState(0);

    useEffect(() => {
        if (selectedElement !== 0) {
            let cis = cisElements[selectedElement - 1];
            onPress(cis)
        }
    }, [selectedElement, cisElements, onPress])

    useEffect(() => {
        setSelectedElement(0);
    }, [permission])

    const cisElement = (cisDetail: CIS, key: number) => {
        return (
            <div key={`Ticket-${key}`} className={`${classes.checkTicketContainer} ${key === 0 && classes.firstCheckTicketContainer} ${key + 1 === selectedElement && classes.selectedTicket}`} onClick={() => { setSelectedElement(key + 1) }}>
                <div className={classes.div1}>
                    <div className={classes.distanceContainer}>
                        <span className={classes.distance}>{cisDetail.distance}</span>
                    </div>
                </div>
                <div className={classes.div2}>
                    <div className={classes.textContainer}>
                        <Typography component={'span'} variant="body1">
                            <Box fontWeight={700} color="#002A4F">{cisDetail.name}</Box>
                        </Typography>
                        <Typography component={'span'} variant="body2">
                            <Box color="#002A4F">{cisDetail.address} </Box>
                        </Typography>
                        <Typography component={'span'} variant="body2">
                            <Box color="#002A4F">{cisDetail.suburb}</Box>
                        </Typography>
                    </div>
                </div>
                <div className={classes.div3}>
                    <div className={classes.buttonContainer}>
                        <FontAwesomeIcon icon={faChevronRight} className={`${classes.chevronIcon} ${key + 1 === selectedElement && classes.selectedChevronIcon}`} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {cisElements.map((cisDetail, key) => {
                return (
                    cisElement(cisDetail, key)
                )
            })}
        </div>
    )
}

export default withStyles(styles)(CISList);
