import React, { useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChannelBundleList from '../../components/ChannelBundleList';
import _ from 'lodash';
import checkToken from '../../utils/checKToken';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import Spinner from '../../components/Spinner';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

const apiV2 = new api.V2Api({}, baseUrl);

const styles = () => createStyles({
    rowTV: {
        background: '#207ab4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px'
    },
    rowTVTitle: {
        background: '#4d4d4d',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px',
        color: 'white'
    },
    divShadow: {
        padding: 0,
        boxShadow: '3px 0 0.2em rgba(128, 128, 128, 0.5)',
    },
    boxChannels: {
        padding: 0,
        height: '100%',
    },
    accordion: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#333333',
        height: '40px'
    },
});

interface ChannelListProps extends WithStyles<typeof styles> {
    idCliente?: string;
    token?: any;
}

const ChannelList = ({ classes, idCliente, token }: ChannelListProps) => {
    const [bundles, setBundles] = React.useState<Array<any>>([]);
    const [loading, setLoading] = React.useState(false);
    const [arrayChannel, setArrayChannel] = React.useState<any>([]);

    useEffect(() => {
        setLoading(true);
        if (idCliente && token) {
            let sucursal = idCliente.substr(0, 3)
            checkToken(() => {
                apiV2.getApiV2AlineacionCanalesSucursal(sucursal, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then(response => {
                        if (response && response.data) {
                            setBundles(response.data);
                            setArrayChannel(response.data[0])
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
                setLoading(false);
            })
        }
    }, [idCliente, token])

    const changeList = (element: any) => {
        setArrayChannel(element);
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {loading ? <Spinner />
                :
                <Grid style={{ height: '100%', background: 'white', width: '100%', padding: 0 }}>
                    <Row style={{ marginLeft: '8px', marginRight: '0px' }}>
                        <Col sm={12} md={4} className={classes.divShadow}>
                            {<ChannelBundleList onClick={(element: any) => changeList(element)} bundles={bundles ? bundles : []} selected={arrayChannel ? arrayChannel.paquete : ""} />}
                        </Col>
                        <Col sm={12} md={8} className={classes.boxChannels}>
                            {arrayChannel && arrayChannel.categorias && arrayChannel.categorias.map((ob: any, key: any) => {
                                return (
                                    <div key={`div-key-${key}`}>
                                        <Accordion style={{margin: '0px'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon fontSize="large" fontWeight={700} style={{ color: "#00CCFF" }} />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                className={classes.accordion}>
                                                {(ob.categoria && !_.isEmpty(ob.canales)) &&
                                                    < Col xs={12} style={{ background: '#333333', color: 'white', padding: '10px', fontSize: 16, display: 'flex', alignItems: 'left', justifyContent: 'left' }}>{ob.categoria}</Col>
                                                }
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Row style={{ margin: '20px -8px' }}>
                                                    {ob.canales.map((i: any, k: any) => {
                                                        return (
                                                            <Col key={`channel-key-${key}-${k}`} xs={4} sm={3} md={3} lg={3} style={{ padding: '15px', display: 'flex', justifyContent: 'center' }}>
                                                                <img width='90px' src={`https://www.megacable.com.mx/images/canales/thumbs/${i.imagen}`} alt="" />
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Grid>
            }
        </div >
    )
}

export default withStyles(styles)(ChannelList);

