import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import Typography from './Typography';
import { Tooltip } from '@material-ui/core';
import * as api from 'megacable-mobile-api';
import awsmobile from '../aws-exports';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { AlertRedux, EstadoCuentaRedux } from 'megacable-mobile-library';
// import { Creators as ContractActions } from '../storeV2/ducks/currentContract';
import Spinner from '../components/Spinner';

import { keycloak } from '../App';
import checkToken from '../utils/checKToken';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

const styles = ({ breakpoints }: any) => createStyles({
    contractContainer: {
        display: 'flex',
        fontFamily: 'Sarabun',
        height: '105px',
        width: '100%',
    },
    contractContainerBorder: {
        borderBottom: '1px #d4d4d4 solid',
    },
    firstContractContainer: {
        borderTop: '1px #d4d4d4 solid'
    },
    div1: {
        position: 'relative',
        textAlign: 'center',
    },
    div2: {
        position: 'relative',
        whiteSpace: 'pre-wrap',
        width: 'inherit'
    },
    div3: {
        position: 'relative',
        margin: '0px 25px 0px 10px'
    },
    contractNumber: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '17px',
        color: 'white',
    },
    circle: {
        width: '55px',
        height: '55px',
        borderRadius: '50%',
        color: '#fff',
        lineHeight: '55px',
        textAlign: 'center',
        margin: '0px 30px 0px 25px',
        top: '50%',
        position: 'relative',
        transform: 'translateY(-50%)'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '100px',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    circleBackground1: {
        backgroundColor: '#00CCFF'
    },
    circleBackground2: {
        backgroundColor: '#0090D2'
    },
    circleBackground3: {
        backgroundColor: '#0078D0'
    },
    buttonContainer: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex'

        /* flex: '1',
        float: 'right',
        direction: 'rtl' */
    },
    cancelIcon: {
        margin: '0px 30px 0px 0px',
        fontSize: '20px',
        color: '#646464',
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    trashIcon: {
        margin: '0px 30px 0px 0px',
        fontSize: '20px',
        color: '#646464',
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    editIcon: {
        margin: '0px 17px 0px 0px',
        fontSize: '20px',
        color: '#646464',
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    expansionShadow: {
        boxShadow: '0px 3px rgba(175, 175, 175, 0.5)'
    },
    desasociarIcon: {
        marginTop: '5px'
    }
});

export interface ContractData {
    number: string;
    contractName: string;
    subscriberName: string;
    externalNumber: string;
}

interface Contract {
    id: string | number;
    data: ContractData;
    type: string;
}

interface ContractProps extends WithStyles<typeof styles> {
    contracts?: any;
    idClienteCurrent?: string;
    token?: string;
    form: Function;
    onClickSave?: Function;
    /*  form: React.ReactNode; */
}

export const ContractList = ({ contracts, form, classes, onClickSave, idClienteCurrent, token }: ContractProps) => {
    const [openElement, setOpen] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const setOpenElement = (element: string) => {
        if (openElement === element) {
            setOpen("");
        } else {
            setOpen(element);
        }
    }

    const clickSave = (key: number) => {
        setOpenElement(`Contract${key}`)
        if (onClickSave) {
            onClickSave()
        }
    }

    const unlinkContract = (element: string) => {
        if (idClienteCurrent) {
            let objData = {
                sucursal: idClienteCurrent.substr(0, 3),
                suscriptor: idClienteCurrent.substr(3, idClienteCurrent.length),
                sucursalAsociado: element.substr(0, 3),
                suscriptorAsociado: element.substr(3, element.length),
            }
            setLoading(true);
            checkToken(() => {
                apiV2.postApiV2DesasociarContrato(objData, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (response && _.has(response, ['data', "estatus"])) {
                            if (response.data.estatus === 'Exito') {
                                dispatch(AlertRedux.showAlert("Se ha desasociado el contrato con éxito", "Desasociar Contrato"));
                                apiV2.getApiV2GetContratos(idClienteCurrent, true, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                                    .then(responseContratos => {
                                        dispatch(EstadoCuentaRedux.Creators.setContratos(responseContratos.data.contratos))
                                    })
                            } else {
                                dispatch(AlertRedux.showAlert("Hubo un error al intentar procesar su solicitud, intente más tarde.", "Desasociar Contrato"));
                            }
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        dispatch(AlertRedux.showAlert("Hubo un error al intentar procesar su solicitud, intente más tarde.", "Desasociar Contrato"));
                    })
            })
            // setLoading(false);
        }
    }

    const confirmUnlink = (element: string) => {
        if (window.confirm("¿Está seguro que quiere desasociar el contrato de su cuenta?")) {
            unlinkContract(element);
        }
    }

    const contractDetail = (contractInfo: any, key: number) => {
        const backgroundType = (key + 1) % 3 === 0 ? 'circleBackground3' : (key + 1) % 2 === 0 ? 'circleBackground2' : 'circleBackground1';
        return (
            <div style={{ width: '100%', height: '100%' }}>
                {
                    loading ?
                        <Spinner />
                        :
                        <div key={`Contract-${key}`} className={`${classes.contractContainer} ${openElement === `Contract${key}` && classes.expansionShadow}`}>
                            <div className={classes.div1}>
                                <div className={`${classes.circle} ${classes[backgroundType]}`}>
                                    <span className={classes.contractNumber}>{`C${key + 1}`}</span>
                                </div>
                            </div>
                            <div className={classes.div2}>
                                <div className={classes.textContainer}>
                                    <Typography type="h2" text={`Contrato ${contractInfo.id}`} style={{ color: "#002A4F", marginTop: '0px', marginBottom: '0px' }} />
                                    <Typography type="h3" text={contractInfo.suscriptor ? contractInfo.suscriptor : ""} weight={'normal'} style={{ color: "#002A4F", marginTop: '0px', marginBottom: '0px' }} />
                                    <Typography type="h4" text={contractInfo.tipoPaquete ? contractInfo.tipoPaquete : ""} style={{ color: "#002A4F", marginTop: '10px', marginBottom: '0px' }} />
                                </div>
                            </div>
                            {<div className={classes.div3}>
                                <div className={classes.buttonContainer}>
                                    {openElement !== `Contract${key}` ?
                                        <>
                                            {contractInfo.suscriptor === idClienteCurrent ?
                                                <>
                                                    <Tooltip title="Editar">
                                                        <div className={`${classes.editIcon}`}>
                                                            <FontAwesomeIcon icon={faEdit} onClick={() => setOpenElement(`Contract${key}`)} />
                                                        </div>
                                                    </Tooltip>
                                                </>
                                                :
                                                <>
                                                    <Tooltip title="Desasociar">
                                                        <div className={`${classes.cancelIcon}`}>
                                                            <img src="../desasociar.png" className={classes.desasociarIcon} alt="" onClick={() => confirmUnlink(contractInfo.suscriptor)} />
                                                        </div>
                                                    </Tooltip>
                                                    <Tooltip title="Editar">
                                                        <div className={`${classes.editIcon}`}>
                                                            <FontAwesomeIcon icon={faEdit} onClick={() => setOpenElement(`Contract${key}`)} />
                                                        </div>
                                                    </Tooltip>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <Tooltip title="Cancelar">
                                                <div className={`${classes.cancelIcon}`}>
                                                    <FontAwesomeIcon icon={faTimes} onClick={() => setOpenElement("")} />
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Guardar">
                                                <div className={`${classes.editIcon}`}>
                                                    <FontAwesomeIcon icon={faSave} onClick={() => clickSave(key)} />
                                                </div>
                                            </Tooltip>
                                        </>
                                    }
                                </div>
                            </div>}
                        </div>
                }
            </div>
        )
    }

    return (
        <div>
            {contracts &&
                contracts.map((contractInfo: any, key: any) => {
                    return (
                        <>
                            {
                                contractInfo.suscriptor === idClienteCurrent ? null
                                    :
                                    <div className={`${classes.contractContainerBorder} ${key === 0 && classes.firstContractContainer} `} key={`Contract-Container-${key}`}>
                                        {contractDetail(contractInfo, key)}
                                        {openElement === `Contract${key}` && form(contractInfo)}
                                    </div>
                            }
                        </>
                    )
                })}
        </div>
    )
}

export default withStyles(styles)(ContractList);
