import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface PublicRouteProps {
    component?: any;
    restricted?: any;
    path?: any;
    exact?: any;
}

const PublicRoute = ({ component: Component, restricted, ...rest }: PublicRouteProps) => {
    return (
        <Route {...rest} render={props => (
            localStorage.getItem('token') && restricted ?
                <Redirect to="/myId" />
                : <Component {...props} />
        )} />
    );
};

export default PublicRoute;