import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Box, Grid, Tooltip, Typography, TextField } from '@material-ui/core';
import { connect, ConnectedProps, useDispatch } from "react-redux";
import _ from 'lodash';
import awsmobile from '../aws-exports';
import * as api from 'megacable-mobile-api';
import { AlertRedux, /*EstadoCuentaRedux*/ } from 'megacable-mobile-library';
import { faEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import moment from 'moment';
import getIdCliente from '../utils/getIdCliente';
import { Creators as ContractActions } from '../storeV2/ducks/currentContract';

import { keycloak } from '../App';
import checkToken from '../utils/checKToken';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles(({ palette, breakpoints }: any) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundImage: `linear-gradient(90deg, #0078D0 0%, #004FC0 100%)`
    },

    gridContainer: {
        flex: 1,
    },

    cardInfo: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "left",
        color: "white",
        padding: "16px 16px 16px 50px",
    },

    cardPriceColor: {
        backgroundColor: "#002d4d"
    },

    buttonEditAlias: {
        fontSize: 20,
        cursor: "pointer",
        marginLeft: "15px"
    },

    divNameEdit: {
        display: 'flex',
        alignItems: 'center'
    },

    aliasInput: {
        fontWeight: 700,
        color: 'white',
        fontSize: '2.1329685026092em',
        lineHeight: '1.235',
        letterSpacing: '0.00735em',
        textAlign: 'center'
    },
}));

const mapState = (state: any) => ({
    pagosData: state.pagosData,
    login: state.login,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type ContractInfoProps = PropsFromRedux & {
    contract?: any
}

export const ContractInfo = (props: ContractInfoProps) => {

    const classes = styles();
    const { contract, /*pagosData,*/ login } = props;
    const [idCliente, setIdCliente] = React.useState<string>("");
    const [idClienteCurrent, setIdClienteCurrent] = React.useState<string>("");
    // const [paymentDate, setPaymentDate] = React.useState<string>("");
    // const [totalAmount, setTotalAmount] = React.useState<string>("");
    const [alias, setAlias] = React.useState<string>("");
    const [editableAlias, setEditableAlias] = React.useState(false);
    // const [token, setToken] = React.useState<string>('');
    // const [dataPagos, setDataPagos] = React.useState<any>({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (login) {
            const idCliente = getIdCliente();
            if (idCliente) {
                setIdCliente(idCliente);
            }
            // if (login && login.auth && login.auth.idCliente) {
            //     setIdCliente(login.auth.idCliente);
            // }
            // if (_.hasIn(login, ['auth', 'token'])) {
            //     setToken(login.auth.token);
            // }
        }
    }, [login]);

    useEffect(() => {
        if (contract && contract.idCliente) {
            setIdClienteCurrent(contract.idCliente);
        }
    }, [contract]);

    // useEffect(() => {
    //     if (pagosData) {
    //         setDataPagos(pagosData);
    //     }
    // }, [pagosData]);

    // useEffect(() => {
    //     if (!_.isEmpty(idCliente) && dataPagos) {
    //         let datosPagoTmp = _.find(dataPagos.allDatosPago, ['suscriptor', idCliente]);
    //         if (!_.isEmpty(datosPagoTmp)) {
    //             if (_.hasIn(datosPagoTmp, ['datos', 'paymentLimitDate'])) {
    //                 const dateTmp = datosPagoTmp.datos.paymentLimitDate;
    //                 if (dateTmp) {
    //                     const dateNewFormat = moment(dateTmp, "DD/MM/YYYY").format('DD-MMM-YYYY');
    //                     setPaymentDate(dateNewFormat);
    //                 }
    //             }
    //         }
    //         if (pagosData && _.hasIn(pagosData, ['allDatosPago'])) {
    //             const paymentList = pagosData.allDatosPago;
    //             if (paymentList && paymentList.length > 0) {
    //                 let paymentUser = _.find(paymentList, (paymentData: any) => { return paymentData.suscriptor === idCliente });
    //                 if (paymentUser && paymentUser.datos && paymentUser.datos.amount) {
    //                     setTotalAmount(paymentUser.datos.amount);
    //                 }
    //             }
    //         }
    //     }
    // }, [idCliente, dataPagos]);

    function enableEdit() {
        setEditableAlias(true);
    }

    function disableEdit() {
        setEditableAlias(false);
    }

    function changeAlias() {
        if (alias && idClienteCurrent) {
            let obj = {
                alias: alias,
                idCliente: idClienteCurrent
            }
            checkToken(() => {
                apiV2.postApiV2Alias(obj, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        apiV2.getApiV2GetContratos(idCliente, true, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                            .then(responseContratos => {
                                // dispatch(EstadoCuentaRedux.Creators.setContratos(responseContratos.data.contratos))
                                let newContract = _.find(responseContratos.data.contratos, (contractTmp) => contractTmp.idCliente === idClienteCurrent);
                                dispatch(ContractActions.setCurrentContract(newContract));
                            })
                    })
                    .catch(error => {
                        dispatch(AlertRedux.showAlert("Hubo un error al intentar cambiar el alias.", "Editar alias"))
                    })
                setEditableAlias(false);
            })
        } else {
            dispatch(AlertRedux.showAlert("El nombre del contrato es obligatorio", "Editar alias"))
        }
        setAlias("");
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={8} sm={8} md={7} lg={6} xl={6}>
                    <div className={classes.cardInfo}>
                        <div className={classes.divNameEdit}>
                            {
                                editableAlias ?
                                    <>
                                        <TextField
                                            autoFocus
                                            value={alias}
                                            onChange={(e: any) => setAlias(e.target.value)}
                                            inputProps={{
                                                className: classes.aliasInput,
                                            }} />
                                        <Tooltip title="Cancelar">
                                            <div className={classes.buttonEditAlias} onClick={disableEdit}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Confirmar">
                                            <div className={classes.buttonEditAlias} onClick={changeAlias}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </div>
                                        </Tooltip>
                                    </>
                                    :
                                    <>
                                        <Typography component={'span'} variant="h4">
                                            <Box fontWeight={700} >
                                                {contract ? contract.nombre : ""}
                                            </Box>
                                        </Typography>
                                        <Tooltip title="Editar alias">
                                            <div className={classes.buttonEditAlias} onClick={enableEdit}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </div>
                                        </Tooltip>
                                    </>
                            }
                        </div>
                        <Typography component={'span'}>
                            {contract ? contract.suscriptor : ""}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={5} lg={6} xl={6}>
                    <div className={classes.cardInfo}>
                        <Typography component={'span'}>
                            {contract ? contract.paquete : ""}
                        </Typography>
                    </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <div className={classes.cardInfo}>
                        <Typography>
                            Fecha limite de pago
                        </Typography>
                        <Typography variant="h6">
                            <Box fontWeight={700} color="#ffffff">
                                {paymentDate ? paymentDate : ""}
                            </Box>
                        </Typography>
                    </div>
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className={[classes.cardInfo, classes.cardPriceColor].join(' ')}>
                        <Typography>
                            TOTAL A PAGAR
                        </Typography>
                        <Typography variant="h3">
                            <Box fontWeight={700}>
                                {totalAmount ? `$${totalAmount}` : ""}
                            </Box>
                        </Typography>
                    </div>
                </Grid> */}
            </Grid>
        </div>
    )
}

export default (connector(ContractInfo));
