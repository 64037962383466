import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IDialogAlert extends WithStyles<typeof styles> {
    title: string;
    message: string;
    isOpen: boolean;
    primaryButtonText: string,
    secondaryButtonText?: string,
    type?: "detail" | "alert";
    handleCloseDialog?: (isAgree: boolean) => void;
}

const DialogAlert = (props: IDialogAlert) => {
    const { classes, title, message, primaryButtonText, secondaryButtonText, isOpen, type, handleCloseDialog } = props;

    const onPress = (isAgree: boolean) => {
        if(handleCloseDialog){
            handleCloseDialog(isAgree);
        }
    }

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <Dialog
                    open={isOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={classes.message}>
                            { message }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {
                            type === "alert" ?  (
                                <Button onClick={()=> onPress(false)} color="primary">
                                    {secondaryButtonText ? secondaryButtonText : "Cancelar"}
                                </Button>
                            ) : null
                        }
                        <Button onClick={()=> onPress(true)} color="primary">
                            {primaryButtonText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    main: {
        flex: 1,
        backgroundColor: "skyblue",
        width: 150,
    },
    container: {
        width: 150,
        backgroundColor: "pink"
    },
    title: {
        color: "#003c6d",
        fontWeight: "bold"
    },
    message: {

    },
    button: {
        fontSize: 14,
        fontWeight: "bold"
    }
});

export default withStyles(styles)(DialogAlert);
