import React, { useEffect, useState } from 'react';
import { createStyles } from '@material-ui/styles';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';

import Slider from '../../components/Slider';
import AddContractHeader from '../../components/AddContractHeader';
import Help from '../../components/Help';
import MobileList from '../../components/MobileList';
import MobileDetailHeader from '../../components/MobileDetailHeader';
import MobileDetail from '../../components/MobileDetail';
import Button from '../../components/Button';
import MobileRecharges from './MobileRecharges';
import Spinner from '../../components/Spinner';
import { DatosPago, AgregarTarjetaRequest } from 'megacable-mobile-api/models';
import { useHistory /*, useRouteMatch*/ } from 'react-router-dom';
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { MobileRedux, AlertRedux } from 'megacable-mobile-library';
import { IContract } from '../../storeV2/ducks/types';
import awsmobile from '../../aws-exports';
import * as api from 'megacable-mobile-api';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import ListPrePayments from './components/ListPrePayments';
import GoBack from '../../components/GoBack';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);
interface PaymentData {
    number: string,
    expirationDate: string,
    cvv: string,
    saveCard: boolean,
    isValid: boolean
}
interface PaymentErrors {
    number: string,
    expirationDate: string,
    cvv: string,
}

const emptyCreditCard = {
    number: "",
    expirationDate: "",
    cvv: "",
    saveCard: false,
    isValid: false
}

type PropsFromRedux = ConnectedProps<typeof connector>

type MobileListViewProps = PropsFromRedux & {

}

const MobileListView = (props: MobileListViewProps) => {
    const classes = styles();
    const { banners, currentContract, login, pagosData } = props;
    const dispatch = useDispatch();
    const [bannersReducer, setBannersReducer] = React.useState<Array<string>>([]);
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    const [loading, setLoading] = React.useState(false);
    const [token, setToken] = React.useState<string>('');
    const [idCliente, setIdCliente] = React.useState<string>();
    const [typeViewData, setTypeViewData] = useState<any>({});
    const [cardData, setCardData] = React.useState<PaymentData>(emptyCreditCard);
    const [paymentErrors, setPaymentErrors] = React.useState<PaymentErrors>({ number: "", expirationDate: "", cvv: "" });
    const mobileReducer = useSelector((state: any) => state.mobile);
    const [totalMegas, setTotalMegas] = React.useState<any>(0)
    const [usedMegas, setUsedMegas] = React.useState<number>(0)
    const [effectiveDate, setEffectiveDate] = React.useState<any>();
    const [paymentData, setPaymentData] = React.useState<DatosPago>();
    const [amountPlanSelected, setamountPlanSelected] = React.useState<number>(0);
    // const consumos = useSelector((state: any) => _.get(state, 'mobile.consumos'));
    const [consumos, setConsumos] = React.useState<any>();
    const [loadingConsumos, setLoadingConsumos] = React.useState(false);
    const [isHistory, setIsHistory] = React.useState(false);
    let history = useHistory();

    const addAmountPlanSelected = (value: number) => {
        setamountPlanSelected(value);
    }

    useEffect(() => {
        if (contractSelected) {
            if (pagosData && _.hasIn(pagosData, ['allDatosPago'])) {
                const paymentList: Array<DatosPago> = pagosData.allDatosPago;
                if (paymentList && paymentList.length > 0) {
                    let paymentUser = _.find(paymentList, (paymentData: DatosPago) => { return paymentData.suscriptor === contractSelected.idCliente });
                    if (paymentUser) {
                        setPaymentData(paymentUser);
                    }
                }
            }
        }
        return () => { }
    }, [contractSelected, pagosData]);

    useEffect(() => {
        if (banners) {
            setBannersReducer(banners.banners);
        }
    }, [banners]);

    useEffect(() => {
        if (login) {
            if (_.hasIn(login, ['auth', 'token'])) {
                setToken(login.auth.token);
            }
        }
        return () => { }
    }, [login]);


    // useEffect(() => {
    //     if (mobileReducer) {
    //         setLoading(false);
    //         let totalMegas = 0;
    //         let usedMegas = 0;
    //         // let consumos: ConsumosResponse | null = null;
    //         let effectiveDate = moment();
    //         if (_.has(mobileReducer, 'consumos.consumos')) {
    //             totalMegas = mobileReducer.consumos.consumos.ofertaOriginalProcess;
    //             usedMegas = totalMegas - mobileReducer.consumos.consumos.saldoProcess;
    //             effectiveDate = mobileReducer.consumos.consumos.vigencia ? moment(mobileReducer.consumos.consumos.vigencia) : moment();
    //             // consumos = mobileReducer.consumos;
    //         }

    //         setTotalMegas(totalMegas);
    //         setUsedMegas(usedMegas);
    //         setEffectiveDate(effectiveDate);
    //     }
    //     return () => { }
    // }, [mobileReducer])

    const parseStorageUnitsMeasure = (measure: string) => {
        let newMeasure = measure.toUpperCase();
        let number = newMeasure.match(/[.\d]+/g);
        let cadena = newMeasure.match(/[A-Za-zñÑ]+/g);

        switch (cadena && cadena[0]) {
            case 'KB':
                return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) / 1024 : 0;
            case 'MB':
                return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) : 0;
            case 'GB':
                return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) * 1024 : 0;
            default:
                return;
        }
    }

    useEffect(() => {
        if (consumos && consumos.consumos) {
            let totalMegas = parseStorageUnitsMeasure(consumos.consumos.ofertaOriginal);
            let saldoTmp = parseStorageUnitsMeasure(consumos.consumos.saldo);
            let usedMegas = (totalMegas ? totalMegas : 0) - (saldoTmp ? saldoTmp : 0);
            let effectiveDate = consumos.consumos.vigencia ? moment(consumos.consumos.vigencia) : moment();

            setTotalMegas(totalMegas);
            setUsedMegas(usedMegas);
            setEffectiveDate(effectiveDate);
        }
        return () => { }
    }, [consumos])

    useEffect(() => {
        if (idCliente && idCliente !== "" && idCliente !== "0") {
            setLoading(true);
            checkToken(() => {
                if (keycloak.token)
                    dispatch(MobileRedux.getAni(keycloak.token, idCliente, () => { }))
            })
            setTypeViewData({})
        }
    }, [token, idCliente, dispatch]);

    useEffect(() => {
        if (currentContract) {
            setContractSelected(currentContract.currentContract);
        }
        return () => { }
    }, [currentContract]);

    useEffect(() => {
        if (contractSelected && _.hasIn(contractSelected, ['idCliente'])) {
            setIdCliente(contractSelected.idCliente);
        }
        return () => { }
    }, [contractSelected]);

    useEffect(() => {
        if (typeViewData.phone && typeViewData.view === "details") {
            checkToken(() => {
                if (keycloak.token) {
                    setLoadingConsumos(true);
                    apiV2.getApiV2GetConsumos(typeViewData.phone, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                        .then((response) => {
                            setConsumos(response.data);
                            setLoadingConsumos(false);
                        })
                        .catch((err) => {
                            setLoadingConsumos(false);
                        })
                    // dispatch(MobileRedux.getConsumos(keycloak.token, typeViewData.phone, () => { }))
                    dispatch(MobileRedux.getHistory(keycloak.token, typeViewData.phone, () => { }))
                }
            })
        }

        if (typeViewData.phone && typeViewData.view === "recharge") {
            checkToken(() => {
                if (keycloak.token)
                    dispatch(MobileRedux.getRecargas(keycloak.token, typeViewData.phone, () => { }))
            })
        }
    }, [typeViewData, token, dispatch]);

    useEffect(() => {
        if (mobileReducer) {
            if (_.has(mobileReducer, 'ani') && mobileReducer.ani && mobileReducer.ani.length > 0) {
                _.forEach(mobileReducer.ani, (mobile, key) => {
                    mobile.number = mobile.ani;
                })
            }
            setLoading(false);
        }
    }, [mobileReducer])

    const addPaymentData = (value: string, typeData: "number" | "date" | "cvv") => {
        let valueTmp = value;
        const typeDataTmp = typeData;
        switch (typeDataTmp) {
            case "number":
                let cardNumber: string = _.cloneDeep(valueTmp);
                if (cardNumber) {
                    cardNumber = cardNumber.split("-").join("")
                    if (cardNumber.length <= 16) {
                        let cardFormatArray: Array<string> | null = cardNumber.match(/.{1,4}/g);
                        if (cardFormatArray) {
                            let newCardFormat: string = cardFormatArray.join("-");
                            setCardData({ ...cardData, number: newCardFormat });
                        }
                    }
                } else {
                    setCardData({ ...cardData, number: "" });
                }
                break;
            case "date":
                let expirationDate: string = _.cloneDeep(valueTmp);
                if (expirationDate) {
                    expirationDate = expirationDate.split("/").join("")
                    if (expirationDate.length <= 4) {
                        let cardFormatArray: Array<string> | null = expirationDate.match(/.{1,2}/g);
                        if (cardFormatArray) {
                            let newCardFormat: string = cardFormatArray.join("/");
                            setCardData({ ...cardData, expirationDate: newCardFormat });
                        }
                    }
                } else {
                    setCardData({ ...cardData, expirationDate: "" });
                }
                break;
            case "cvv":
                setCardData({ ...cardData, cvv: valueTmp });
                break;
            default:
                break;
        }
    }

    function validation() {
        const formData = _.cloneDeep(cardData);
        const { number, expirationDate, cvv } = formData;
        let errors: PaymentErrors = { number: "", expirationDate: "", cvv: "" };

        let currentYear = moment().year()
        let yearExp = currentYear.toString().substring(2, 4);

        const mounthAndYear = expirationDate && expirationDate.split("/");
        const cardMonth = mounthAndYear ? mounthAndYear[0] : '';
        const cardYear = mounthAndYear ? mounthAndYear[1] : '';

        if (!number) {
            if (!number) errors.number = "El número de tarjeta es requerido";
        } else {
            if (number.length !== 19) errors.number = "El número de tarjeta no es válido";
        }

        if (!expirationDate) {
            errors.expirationDate = "La fecha es requerida";
        } else {
            if (expirationDate.length !== 5) {
                errors.expirationDate = "Fecha de vencimiento inválida";
            }
            if (Number(cardMonth) < 1 || Number(cardMonth) > 12) {
                errors.expirationDate = "El mes es inválido";
            }
            if (Number(cardYear) < Number(yearExp)) {
                errors.expirationDate = "El año de tarjeta ha expirado";
            }
        }
        if (!cvv) {
            if (!cvv || cvv.length < 3) errors.cvv = "El cvv es requerido";
        } else {
            if (cvv.length < 3) errors.cvv = "El cvv es no es válido";
        }
        return errors
    }

    const goToPayment = () => {
        const errors = validation();
        if (errors.number || errors.expirationDate || errors.cvv) {
            setPaymentErrors(errors)
        } else {
            if (amountPlanSelected && amountPlanSelected > 0 && typeViewData.phone) {
                const cardDataTmp: PaymentData = _.cloneDeep(cardData);
                goToConfirmPayment(cardDataTmp);
            } else {
                if (amountPlanSelected === 0) {
                    dispatch(AlertRedux.showAlert("Selecciona una recarga para continuar con el pago.", "Estimado(a) suscriptor"));
                }
            }
        }
    }

    const payWithMyCreditCard = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => {
        const saveCreditCardInfo: PaymentData = newPaymentData;
        if (amountPlanSelected && amountPlanSelected > 0 && typeViewData.phone) {
            goToConfirmPayment(saveCreditCardInfo);
        } else {
            if (amountPlanSelected === 0) {
                dispatch(AlertRedux.showAlert("Selecciona una recarga para continuar con el pago.", "Estimado(a) suscriptor"));
            }
        }
    }

    const updateMyCreditCard = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => {
        updateCreditCard(newPaymentData.number);
    }

    const updateCreditCard = (cardNumber: string) => {
        if (contractSelected && contractSelected.sucursal && contractSelected.suscriptor && cardNumber) {
            // setLoadingSaveCard(true);
            const sucursalTmp: string = contractSelected.sucursal;
            const suscriptorTmp: string = contractSelected.suscriptor.substring(3, 10)
            const tarjetaTmp: string = cardNumber.replaceAll("-", "");
            const tipoTmp: string = "actualizacion";
            const agregarTarjetaRequest: AgregarTarjetaRequest = {
                sucursal: sucursalTmp,
                suscriptor: suscriptorTmp,
                tarjeta: tarjetaTmp,
                tipo: tipoTmp,
            }

            checkToken(() => {
                apiV2.postApiV2AgregarTarjeta(agregarTarjetaRequest, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then((response) => {
                        // setLoadingSaveCard(false);
                        if (response.data) {
                            // const status = response.data.estatus;
                            const message = response.data.mensaje;
                            dispatch(AlertRedux.showAlert(message, "Tarjeta"));
                        }
                    })
                    .catch(error => {
                        // setLoadingSaveCard(false);
                    })
            })
        }
    }



    const goToConfirmPayment = (creditCard: PaymentData) => {
        history.push({
            pathname: '/payService',
            state: {
                idCliente: idCliente,
                paymentCard: creditCard,
                currentContract: contractSelected,
                ani: typeViewData.phone,
                paymentData: paymentData,
                amountToPay: amountPlanSelected,
                from: "recarga"
            }
        });
    }


    const showHistory = () => {
        const mobileReducerTmp = _.cloneDeep(mobileReducer);
        if (mobileReducerTmp) {
            if (_.has(mobileReducerTmp, 'history') && mobileReducerTmp.history.length > 0) {
                setIsHistory(true);
            } else {
                dispatch(AlertRedux.showAlert("Por el momento el historial de recargas no esta disponible. Intente de nuevo mas tarde.", "¡Lo sentimos!"));
            }
        }
    }

    const handleSaveCard = (value: boolean) => {
        setCardData({ ...cardData, saveCard: value })
    }

    return (
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.generalContainer}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {bannersReducer && !_.isEmpty(bannersReducer) ? <Slider size='large' images={bannersReducer} /> : null}
            </Grid>
            {
                idCliente && idCliente !== "" && idCliente !== "0" ? (
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <AddContractHeader title="Telefonía móvil" number={idCliente} />
                        </Grid>
                        {
                            loading ? <Spinner /> :
                                mobileReducer && mobileReducer.ani && mobileReducer.ani.length > 0 ? (
                                    typeViewData.view === "details" && typeViewData.phone ? (
                                        <>
                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.mobileListContainer}>
                                                {
                                                    mobileReducer && mobileReducer.ani ? (
                                                        <MobileList mobiles={mobileReducer.ani} onPress={(e: object) => setTypeViewData(e)} />
                                                    ) : <Help type={"error"} helpStyle={"outline"} text1="¡Oh vaya!" text2='Por el momento no hay información disponible. No hay teléfonos.' />
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.containerLayout}>
                                                {
                                                    loadingConsumos ? <Spinner /> :
                                                        consumos && !_.isEmpty(consumos) && consumos.consumos && !_.isEmpty(consumos.consumos) ?
                                                            <div>
                                                                {/* TODO: Agregar titulo de historial de recargas */}
                                                                <MobileDetailHeader title={isHistory ? 'Historial de recargas' : 'Detalles'} type={consumos.consumos.tipo.replace(/[[\]]/g, '')} />
                                                                {
                                                                    isHistory ?
                                                                        <div style={{ backgroundColor: "#002A4F" }}>
                                                                            <GoBack onClick={() => setIsHistory(false)} />
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                <div className={classes.phoneDetailContainer}>
                                                                    {
                                                                        isHistory && mobileReducer && mobileReducer.history ?
                                                                            <ListPrePayments activityList={mobileReducer.history} />
                                                                            :
                                                                            <>
                                                                                <MobileDetail consumos={consumos} totalMB={totalMegas} usedMB={usedMegas} phoneNumber={typeViewData.phone} expiration={effectiveDate} />
                                                                                {consumos && consumos.consumos && consumos.consumos.tipo !== "[Postpago]" ?
                                                                                    <div className={classes.phoneDetailBtnContainer}>
                                                                                        <Button type="tertiary" text="HISTORIAL DE RECARGAS" size="large" onClick={showHistory} />
                                                                                        <Button type="information" text="HACER UNA RECARGA" size="large" onClick={() => setTypeViewData({ phone: typeViewData.phone, view: "recharge" })} />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div> :
                                                            <Help type={"error"} helpStyle={"outline"} text1="¡Lo sentimos!" text2='No hay información disponible.' additionalStyle={{ margin: '10px 10px' }} />
                                                }
                                            </Grid>
                                        </>
                                    ) :
                                        (
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                {
                                                    typeViewData.view === "recharge" && typeViewData.phone
                                                        ? <MobileRecharges
                                                            onPressBack={(e: object) => setTypeViewData(e)}
                                                            selectedPhone={typeViewData.phone}
                                                            paymentData={cardData}
                                                            paymentErrors={paymentErrors}
                                                            addPaymentData={addPaymentData}
                                                            goToPayment={goToPayment}
                                                            updateMyCreditCard={updateMyCreditCard}
                                                            payWithMyCreditCard={payWithMyCreditCard}
                                                            amountPlanSelected={amountPlanSelected}
                                                            handleSaveCard={handleSaveCard}
                                                            addAmountPlanSelected={addAmountPlanSelected}
                                                        />
                                                        : mobileReducer && mobileReducer.ani && <MobileList mobiles={mobileReducer.ani} onPress={(e: object) => setTypeViewData(e)} />
                                                }
                                            </Grid>
                                        )
                                ) : <Help type={"error"} helpStyle={"outline"} text1="¡Oh vaya!" text2='Por el momento no hay información disponible. No hay teléfonos.' />
                        }
                    </>
                ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' />
                    </Grid>
                )
            }
        </Grid>
    )
}

const styles = makeStyles(({ palette, breakpoints }: Theme) => createStyles({
    container: {
        height: '100%',
        padding: 0,
        // marginLeft: '8px',
        background: 'white',
    },
    detailsContainer: {
        height: '100%',
        padding: 0,
        margin: 0,
        backgroundImage: 'url(/movil-background.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        background: 'black',
        backgroundSize: 'cover',
        border: "1px solid red"

    },
    mobileDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '50px 0px',
    },

    root: {
        display: "flex",
        flexDirection: "column",
    },

    generalContainer: {
        backgroundColor: "white",
    },

    mobileListContainer: {
        display: "flex",
        flexDirection: "column",
        order: 1,
        [breakpoints.down('md')]: {
            order: 2,
        }
    },

    containerLayout: {
        display: "flex",
        flexDirection: "column",
        backgroundImage: 'url(/movil-background.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        background: 'black',
        backgroundSize: 'cover',
        order: 2,
        [breakpoints.down('md')]: {
            order: 1,
        }
    },

    phoneDetailContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 24,
    },

    phoneDetailBtnContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 16,
        [breakpoints.down('xs')]: {
            flexDirection: "column",
        }
    }
}));

const mapState = (state: any) => ({
    login: state.login,
    banners: state.banners,
    loading: state.loading,
    pagosData: state.pagosData,
    currentContract: state.currentContract,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({

});

const connector = connect(mapState, mapDispatch)

export default (connector(MobileListView));