import React, { useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Drawer, Hidden, List, Toolbar } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import history from '../history';
import _ from 'lodash';

interface ISideBar extends WithStyles<typeof styles> {
    isOpenMenu: boolean;
    routes: Array<{ title: string, path?: string, search?: string }>;
    contracts: string[];
    contractSelected?: string;
    onChangeContract: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleOpenDrawerMenu: () => void;
}
const drawerWidth = 265;

const SideBar = (props: ISideBar) => {
    const { classes, routes, contracts, contractSelected, isOpenMenu, onChangeContract, handleOpenDrawerMenu } = props;
    const [/*selectedMenu*/, setSelectedMenu] = React.useState("");

    const location = useLocation();

    useEffect(() => {
        history.push(location.pathname);
    }, [routes, location.pathname])

    function changeView(route: any) {
        setSelectedMenu(`${route.path}`)
        history.push(route.path)
        window.scrollTo(0, 0);
    }

    function changeViewMobile(route: any) {
        setSelectedMenu(`${route.path}`)
        history.push(route.path)
        window.scrollTo(0, 0);
        handleOpenDrawerMenu();
    }

    const drawer = (
        <div className={classes.drawerContainer}>
            <div className={classes.containerSelect} >
                <span className={classes.title}>{"Selecciona un contrato"}</span>
                <select className={classes.select} value={contractSelected ? contractSelected : 0} onChange={onChangeContract}>
                    <option value={0}>Selecciona un contrato...</option>
                    {
                        _.map(contracts, (contract, key) => {
                            return <option key={`menu-contract-${key}`} value={contract}>{contract}</option>
                        })
                    }
                </select>
            </div>
            <List style={{ paddingTop: 0 }}>
                {routes.map((route, key) => (
                    route.path ?
                        <NavLink key={key} to={{ pathname: `${route.path}`, search: route.search ? route.search : "" }} style={{ textDecoration: 'none', color: 'black' }}>
                            <div
                                className={`${classes.button} ${history.location.pathname === route.path ? classes.buttonActive : undefined}`}
                                key={`menu-option-${key}`}
                                onClick={() => changeView(route)}>
                                {/* <div key={`menu-option-${key}`} className={`${classes.button}`}> */}
                                <p className={classes.buttonText}>{route.title}</p>
                            </div>
                        </NavLink >
                        :
                        <div key={`menu-option-${key}`} className={`${classes.button}`}>
                            <p className={classes.buttonText}>{route.title}</p>
                        </div>
                ))}
            </List>
        </div>
    );

    const drawerMobile = (
        <div className={classes.drawerContainer}>
            <div className={classes.containerSelect} >
                <span className={classes.title}>{"Selecciona un contrato"}</span>
                <select className={classes.select} value={contractSelected ? contractSelected : 0} onChange={onChangeContract}>
                    <option value={0}>Selecciona un contrato...</option>
                    {
                        _.map(contracts, (contract, key) => {
                            return <option key={`menu-contract-${key}`} value={contract}>{contract}</option>
                        })
                    }
                </select>
            </div>
            <List style={{ paddingTop: 0 }}>
                {routes.map((route, key) => (
                    route.path ?
                        <NavLink key={key} to={{ pathname: `${route.path}`, search: route.search ? route.search : "" }} style={{ textDecoration: 'none', color: 'black' }}>
                            <div
                                className={`${classes.button} ${history.location.pathname === route.path ? classes.buttonActive : undefined}`}
                                key={`menu-option-${key}`}
                                onClick={() => changeViewMobile(route)}>
                                {/* <div key={`menu-option-${key}`} className={`${classes.button}`}> */}
                                <p className={classes.buttonText}>{route.title}</p>
                            </div>
                        </NavLink >
                        :
                        <div key={`menu-option-${key}`} className={`${classes.button}`}>
                            <p className={classes.buttonText}>{route.title}</p>
                        </div>
                ))}
            </List>
        </div>
    );

    return (
        <nav>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor='left'
                    open={isOpenMenu}
                    onClose={handleOpenDrawerMenu}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}>
                    {drawerMobile}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open>
                    <Toolbar />
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    )
}

const styles = (theme: Theme) => createStyles({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#006DCC"
    },
    drawerContainer: {
        overflow: 'auto',
    },
    containerSelect: {
        background: '#002A4F',
        padding: '25px',
    },
    title: {
        fontFamily: "Sarabun",
        fontWeight: 'lighter',
        textTransform: 'initial',
        size: '12px',
        color: '#FFFFFF'
    },
    select: {
        marginTop: '10px',
        padding: '5px 5px',
        width: '100%',
        border: '1px solid transparent',
        borderColor: 'transparent transparent rgba(0, 0, 0, 0.1) transparent',
        cursor: 'pointer',
        userSelect: 'none'
    },
    buttonText: {
        fontSize: '16px',
        color: '#FFFFFF',
        verticalAlign: 'middle',
        margin: '0px 25px',
        lineHeight: '50px'
    },
    button: {
        width: "100%",
        fontFamily: 'Sarabun',
        fontWeight: 'normal',
        cursor: 'pointer',
        background: '#006DCC',
        '&:hover': {
            background: '#0095ff'
        }
    },
    buttonActive: {
        background: '#044C9B',
        '&:hover': {
            background: '#044C9B'
        }
    },
});

export default withStyles(styles)(SideBar);
