import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Hidden, IconButton, Typography, Box, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { removeToken } from '../utils';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LogOutDialog from '../components/LogOutDialog';
interface IAppBarMenu extends WithStyles<typeof styles> {
    isOpenMenu: boolean;
    userName?: string;
    handleOpenDrawerMenu: () => void;
}
const AppBarMenu = (props: IAppBarMenu) => {
    const { classes, userName, handleOpenDrawerMenu } = props;
    const [isOpenLogoutDialog, setIsOpenLogoutDialog] = React.useState<boolean>(false);
    const history = useHistory();

    const logout = () => {
        setIsOpenLogoutDialog(true);
    }

    const handleCloseDialog = (isAgree: boolean) => {
        if (isAgree) {
            removeToken();
            history.push("/");
        } else {
            setIsOpenLogoutDialog(false);
        }
    }

    return (
        <AppBar position="fixed" elevation={1} className={classes.appBar}>
            <Toolbar>
                <Hidden mdUp implementation="css">
                    <IconButton
                        className={classes.menuButton}
                        color="default"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleOpenDrawerMenu}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <img src={'/mega-logotipo.svg'} alt='Logo' className={classes.megacable} />
                {
                    userName ? (
                        <Hidden xsDown implementation="css">
                            <Typography component={'span'} color="textPrimary" variant="body1">
                                <Box fontWeight={700}>
                                    ¡Hola! {userName}
                                </Box>
                            </Typography>
                        </Hidden>
                    ) : null
                }
                <Tooltip title="Cerrar sesión" aria-label="add">
                    <div className={classes.buttonLogout} onClick={logout}>
                        <FontAwesomeIcon icon={faSignOutAlt} className={`${classes.chevronIcon}`} />
                        {/* <img src="/cerrar-sesión-listado.png" className={`${classes.chevronIcon}`} /> */}
                    </div>
                </Tooltip>
            </Toolbar>
            <LogOutDialog
                isOpen={isOpenLogoutDialog}
                handleCloseDialog={handleCloseDialog} />
        </AppBar>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        width: "100%",
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#fff",
        // backgroundImage: "linear-gradient(90deg, #0078D0 0%, #004FC0 100%)"

    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    megacable: {
        width: '245px',
        height: '38px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginRight: 40,
        [theme.breakpoints.down('xs')]: {
            width: '150px',
            height: 'auto',
            marginRight: 16,
        },
    },

    chevronIcon: {

    },

    buttonLogout: {
        fontSize: 30,
        color: "#929292",
        marginLeft: "auto",
        cursor: "pointer",
        '&:hover': {
            color: "#004FC0",
        },
    }
});

export default withStyles(styles)(AppBarMenu);
