import { keycloak } from '../App';
import { saveToken } from './';

const padLeft: any = (value: string, length: number) => {
    return (value.toString().length < length) ? padLeft("0" + value, length) :
        value;
}

const checkToken = async () => {
    if (keycloak.isTokenExpired()) {
        await keycloak.updateToken(60).then(() => {
            saveToken(JSON.stringify(keycloak.token));
        })
    }
}

const getIdCliente = () => {
    if (!keycloak || !keycloak.userInfo)
        return "";

    const user: any = keycloak.userInfo;
    const idCliente = padLeft(user.sucursal, 3) + padLeft(user.suscriptor, 7);

    checkToken();

    return idCliente;
}

export default getIdCliente;
