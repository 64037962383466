import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { createStyles, styled, withStyles, WithStyles } from '@material-ui/styles';
import * as React from 'react';

const styles = () => createStyles({

});

interface ListPaymentsTableProps extends WithStyles<typeof styles> {
    activityList?: Array<any>;
}

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#D1E3FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface Column {
    id: 'folio' | 'fecha' | 'origen' | 'monto';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const ListPaymentsTable = ({ classes, activityList }: ListPaymentsTableProps) => {
    const [page, /*setPage*/] = React.useState(0);
    const [rowsPerPage, /*setRowsPerPage*/] = React.useState(1000);

    const columns: readonly Column[] = [
        { id: 'folio', label: 'Folio', minWidth: 100 },
        { id: 'fecha', label: 'Fecha', minWidth: 100 },
        { id: 'origen', label: 'Realizado desde', minWidth: 100 },
        { id: 'monto', label: 'Monto', minWidth: 100 },
    ];

    const rows: any = activityList;

    return (
        <Paper style={{ width: '100%', overflow: 'hidden', borderRadius: '0' }}>
            <TableContainer style={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: '#0051C1', color: '#FFFFFF' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any) => {
                                return (
                                    <StyledTableRow key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : column.id === 'monto' && value
                                                            ? '$' + value
                                                            : value}
                                                </TableCell>
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default withStyles(styles)(ListPaymentsTable);