import React, { useEffect } from 'react';
import { createStyles } from '@material-ui/styles';
import { /*TextField, FormLabel,*/ makeStyles, Grid, } from '@material-ui/core';
import Button from '../../components/Button';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import Help from '../../components/Help';
// import ComponentHeaderSecondary from '../../components/ComponentHeaderSecondary';
interface IUpdatePassword {
    nip: string,
    repeatNip: string,
}

interface IUpdatePasswordProps {
    userPassword: IUpdatePassword,
    userPasswordErrors: IUpdatePassword,
    handleValueChange: (value: string, type: string) => void,
    onClick: () => void,
}

const UpdatePassword = (props: IUpdatePasswordProps) => {
    const { /*userPassword, userPasswordErrors,*/ onClick, /*handleValueChange*/ } = props;
    const classes = styles();

    useEffect(() => {
        startAnalytics('change_pass_id');
        return () => {
            logAnalytics('change_pass_id')
        }
    }, []);

    return (
        <>
            {/* <ComponentHeaderSecondary type='H2' title={'CAMBIAR CONTRASEÑA'} /> */}
            <div className={classes.animated}>
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Help type={"attention"} helpStyle={"outline"} text1="¡Atención!" text2='Para cambiar su contraseña, de click en "CAMBIAR"' additionalStyle={{ margin: "10px 10px" }} />
                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.alertStyle}>
                        <span className={classes.warning}>{'¡Atención! '}</span>
                        <span className={classes.warning2}>{' Para cambiar su contraseña, edite los campos y de en click en "CAMBIAR”'}</span>
                    </div>
                </Grid> */}
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={3} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 24 }}>
                        <img style={{ maxHeight: '140px', maxWidth: '140px', marginBottom: 16 }} alt={'Cambiar contraseña'} src={'../cambiar-contraseña-big.png'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ padding: 16 }}>
                        {/* <FormLabel className={classes.label} component="legend">Nueva contraseña</FormLabel>
                        <TextField
                            value={userPassword.nip}
                            name="password"
                            placeholder='Nueva contraseña'
                            type='password'
                            variant="outlined"
                            helperText={userPasswordErrors && userPasswordErrors.nip}
                            error={userPasswordErrors && userPasswordErrors.nip ? true : false}
                            style={{ marginBottom: 16 }}
                            autoComplete='notAutoComplete'
                            fullWidth
                            // onFocus={() => setUserPasswordErrors({ ...userPasswordErrors, nip: "" })}
                            onChange={(event) => handleValueChange(event.target.value, 'nip')} />
                        <FormLabel className={classes.label} component="legend">Repetir nueva contraseña</FormLabel>
                        <TextField
                            value={userPassword.repeatNip}
                            name="passwordRepeat"
                            placeholder='Repetir nueva contraseña'
                            helperText={userPasswordErrors && userPasswordErrors.repeatNip}
                            error={userPasswordErrors && userPasswordErrors.repeatNip ? true : false}
                            type='password'
                            variant="outlined"
                            style={{ marginBottom: 24 }}
                            autoComplete='notAutoComplete'
                            fullWidth
                            // onFocus={() => setUserPasswordErrors({ ...userPasswordErrors, repeatNip: "" })}
                            onChange={(event) => handleValueChange(event.target.value, 'repeatNip')} /> */}
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button type="primary" text="CAMBIAR" size="large" onClick={onClick} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

const styles = makeStyles(() => createStyles({

    animated: {
        position: 'relative',
        left: '5%',
        animationName: '$fadein',
        animationDuration: '.5s',
        animationFillMode: 'forwards',
        transition: 'All 500ms ease-in-out',
        Webkittransition: 'All 500ms ease-in-out',
        MozTransition: 'All 500ms ease-in-out',
        OTransition: 'All 500ms ease-in-out',
    },
    '@keyframes fadein': {
        '0%': {
            display: 'none',
            position: 'relative',
            left: '2%',
        },
        '100%': {
            position: 'relative',
            left: '0%',
        },
    },
    label: {
        color: 'inherit',
        paddingLeft: '5px',
        paddingBottom: '10px'
    },
    container: {
        display: 'flex',
        width: '100%',
        visibility: 'visible'
    },

    warning: {
        color: '#044C9B',
        fontSize: 14,
        fontWeight: 'bold'
    },
    warning2: {
        color: '#044C9B',
        fontSize: 14,
        marginLeft: '5px'
    },
    alertStyle: {
        border: "2px solid #044c9b",
        borderRadius: 10,
        padding: 14,
        marginBottom: 16,
    },
}));

export default (UpdatePassword);