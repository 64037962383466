import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import ComponentHeader from '../../components/ComponentHeader';
import Help from '../../components/Help';
import ServicesDetail from '../../components/ServicesDetail';
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { BannersRedux, AlertRedux } from 'megacable-mobile-library';
import { IContract, IServicios, IServiciosFormateados } from '../../storeV2/ducks/types';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import _ from 'lodash';
import Spinner from '../../components/Spinner';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import NetflixActivation from './components/NetflixActivation';
import AmazonActivation from './components/AmazonActivation';
import MLBActivation from './components/MLBActivation';
import HBOHire from './components/HBOHire';
import NFLActivation from './components/NFLActivation';
import NetflixWaitingActivation from './components/NetflixWaitingActivation';
import AmazonWaitingActivation from './components/AmazonWaitingActivation';
import DisneyWaitingActivation from './components/DisneyWaitingActivation';
import StarWaitingActivation from './components/StarWaitingActivation';
import ComboWaitingActivation from './components/ComboWaitingActivation';
import NetflixHire from './components/NetflixHire';
import AmazonHire from './components/AmazonHire';
import NortonHire from './components/NortonHire';
import NortonHireAlternatives from './components/NortonHireAlternatives';
import NortonBanner from './components/NortonBanner';
import DisneyActivation from './components/DisneyActivation';
import StarActivation from './components/StarActivation';
import ComboActivation from './components/ComboActivation';
import ComboHire from './components/ComboHire';
import StarHire from './components/StarHire';
import DisneyHire from './components/DisneyHire';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import DisneyLogin from './components/DisneyLogin';
import StarLogin from './components/StarLogin';
import ComboLogin from './components/ComboLogin';
import HBOWaitingActivation from './components/HBOWaitingActivation';
import FoxWaitingActivation from './components/FoxWaitingActivation';
import FoxHire from './components/FoxHire';
import PortabilityBanner from './components/PortabilityBanner';
import FormPortability from '../formPortability/FormPortability';
import RequestConfirmationDialog from './components/RequestConfirmationDialog';
import NBAHire from './components/NBAHire';
import NBAActivate from './components/NBAActivate';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

type PropsFromRedux = ConnectedProps<typeof connector>

type MyServicesProps = PropsFromRedux & {

}

export const MyServices = (props: MyServicesProps) => {
    const { currentContract, pagosData } = props;
    const classes = styles();
    const [contractSelected, setContractSelected] = React.useState<IContract | undefined>();
    const [services, setServices] = React.useState<IServicios | undefined>();
    const [servicesFormat, setServicesFormat] = React.useState<IServiciosFormateados | undefined>();
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const dispatch = useDispatch();
    const [isAmazon, setIsAmazon] = React.useState<boolean>(false);
    const [isNetflix, setIsNetflix] = React.useState<boolean>(false);
    const [isMLB, setIsMLB] = React.useState<boolean>(false);
    const [isNFL, setIsNFL] = React.useState<boolean>(false);
    const [isNBA, setIsNBA] = React.useState<boolean>(false);
    const [isNBAAct, setIsNBAAct] = React.useState<boolean>(false);
    const [isDisney, setIsDisney] = React.useState<boolean>(false);
    // const [isDisneyAct, setIsDisneyAct] = React.useState<boolean>(false);
    const [isStar, setIsStar] = React.useState<boolean>(false);
    // const [isStarAct, setIsStarAct] = React.useState<boolean>(false);
    const [isCombo, setIsCombo] = React.useState<boolean>(false);
    // const [isComboAct, setIsComboAct] = React.useState<boolean>(false);
    const [isDisneyHire, setIsDisneyHire] = React.useState<boolean>(false);
    const [isStarHire, setIsStarHire] = React.useState<boolean>(false);
    const [isComboHire, setIsComboHire] = React.useState<boolean>(false);
    const [isHBO, setIsHBO] = React.useState<boolean>(false);
    const [isFox, setIsFox] = React.useState<boolean>(false);
    const [isShowNorton, setIsShowNorton] = React.useState<boolean>(false);
    const [isNorton, setIsNorton] = React.useState<boolean>(false);
    const [isNortonAlt, setIsNortonAlt] = React.useState<boolean>(false);
    const [isShowPortability, setIsShowPortability] = React.useState<boolean>(false);
    const [nflLink, setNflLink] = React.useState<string>('');
    const [nflCode, setNflCode] = React.useState<string>('');
    const [isHBOLock, setIsHBOLock] = React.useState<boolean>(false);
    const [isFoxLock, setIsFoxLock] = React.useState<boolean>(false);
    const [isNetflixLock, setIsNetflixLock] = React.useState<boolean>(false);
    const [isAmazonLock, setIsAmazonLock] = React.useState<boolean>(false);
    const [isDisneyLock, setIsDisneyLock] = React.useState<boolean>(false);
    const [isStarLock, setIsStarLock] = React.useState<boolean>(false);
    const [isComboLock, setIsComboLock] = React.useState<boolean>(false);
    const [packages, setPackages] = React.useState<Array<any>>();
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const parentRef = React.useRef(0);

    useEffect(() => {
        if (keycloak && keycloak.token) {
            setToken(keycloak.token);
        }
    }, [keycloak]);

    useEffect(() => {
        if (idCliente && token) {
            let objData = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length)
            }
            if (objData) {
                checkToken(() => {
                    apiV2.postApiV2GetTokenServices(objData, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then(response => {
                            if (response && _.has(response, ['data', "mlb", "status"])) {
                                if (response.data.mlb.status === 'true') {
                                    setIsMLB(true);
                                    parentRef.current = parentRef.current + 1;
                                }
                            }
                            if (response && _.has(response, ['data', "nfl", "status"])) {
                                if (response.data.nfl.status === 'true') {
                                    setIsNFL(true);
                                    setNflLink("https://www.nflgamepass.com/es/subscribe/plans");
                                    setNflCode(response.data.nfl.code);
                                    parentRef.current = parentRef.current + 1;
                                }
                            }
                        })
                        .catch(error => {
                            error = _.get(error, 'response', error);
                        })
                })
            }
        }
    }, [idCliente, token])

    useEffect(() => {
        validarServicios(false);
    }, [idCliente, token])


    useEffect(() => {
        startAnalytics('my_services_id');
        return () => {
            logAnalytics('my_services_id')
        }
    }, []);

    useEffect(() => {
        setContractSelected(currentContract.currentContract);
        if (_.has(currentContract, ['currentContract', 'servicios'])) {
            setServices(currentContract.currentContract.servicios)
        }
    }, [currentContract]);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract', 'idCliente'])) {
                setIdCliente(currentContract.currentContract.idCliente);
            }
        }
    }, [currentContract]);

    useEffect(() => {
        if (services && _.has(services, ['serviciosFormateados'])) {
            setServicesFormat(services.serviciosFormateados);
        }
        return () => { }
    }, [services])

    useEffect(() => {
        return () => { }
    }, [servicesFormat]);

    const validarServicios = (hired: boolean) => {
        if (hired) {
            setDialogOpen(true);
        }
        setIsAmazonLock(false);
        setIsHBOLock(false);
        setIsFoxLock(false);
        setIsNetflixLock(false);
        setIsDisneyLock(false);
        setIsStarLock(false);
        setIsComboLock(false);
        setIsNorton(false);
        setIsNortonAlt(false);
        setIsHBO(false);
        setIsFox(false);
        setIsDisney(false);
        setIsDisneyHire(false);
        // setIsDisneyAct(false);
        setIsStar(false);
        setIsStarHire(false);
        // setIsStarAct(false);
        setIsCombo(false);
        setIsComboHire(false);
        // setIsComboAct(false);
        setIsNBA(false);
        setIsNBAAct(false);
        setPackages([]);
        if (idCliente) {
            checkToken(() => {
                apiV2.getApiV2ValidarServicios(idCliente, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        if (response && _.has(response, ['data', "estatus"])) {
                            if (response.data.estatus === true) {
                                setPackages(response.data.productos);
                                response.data.productos.forEach((products: any) => {
                                    if (products.promocion === "3522" && products.estado === "PRO") {
                                        setIsAmazonLock(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    if (products.promocion === "3522" && products.estado === "CON") {
                                        setIsAmazon(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    if ((/* products.promocion === "4236" || */ (products.promocion === "3524" || products.promocion === "3523")) && products.estado === "PRO") {
                                        setIsNetflixLock(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    if ((/* products.promocion === "4236" || */ (products.promocion === "3524" || products.promocion === "3523")) && products.estado === "CON") {
                                        setIsNetflix(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    if ((products.IDPaquete === "9D25F2DD-648A-4282-B790-2D09D5B71BD9" || products.IDPaquete === "2BB9680A-9E01-4363-A891-F379BE89C8F4" || products.IDPaquete === "05B774E2-54D9-478C-9F6D-27BAD9B1E2DD") && products.estado !== "CON") {
                                        setIsNorton(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    if ((products.IDPaquete === "B9160FC9-0BCD-492B-ACAE-DA64A6D07382" || products.IDPaquete === "DFC56D7E-13EC-458F-809E-DA2793A3EF37") && products.estado !== "CON") {
                                        setIsNortonAlt(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    if ((products.promocion === "4117") && products.estado === "CON") {
                                        setIsDisney(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if ((products.promocion === "4117") && products.estado === "NOC") {
                                        setIsDisneyHire(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if ((products.promocion === "4117") && products.estado === "PRO") {
                                        setIsDisneyLock(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    // else if ((products.promocion === "4117") && products.estado === "ACT") {
                                    //     setIsDisneyAct(true);
                                    //     parentRef.current = parentRef.current + 1;
                                    // }
                                    if ((products.promocion === "4059") && products.estado === "CON") {
                                        setIsStar(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if ((products.promocion === "4059") && products.estado === "NOC") {
                                        setIsStarHire(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if ((products.promocion === "4059") && products.estado === "PRO") {
                                        setIsStarLock(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    // else if ((products.promocion === "4059") && products.estado === "ACT") {
                                    //     setIsStarAct(true);
                                    //     parentRef.current = parentRef.current + 1;
                                    // }
                                    if ((products.promocion === "4342") && products.estado === "CON") {
                                        setIsCombo(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if ((products.promocion === "4342") && products.estado === "NOC") {
                                        setIsComboHire(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if ((products.promocion === "4342") && products.estado === "PRO") {
                                        setIsComboLock(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    // else if ((products.promocion === "4342") && products.estado === "ACT") {
                                    //     setIsComboAct(true);
                                    //     parentRef.current = parentRef.current + 1;
                                    // }
                                    if (products.promocion === ("4191") && products.estado === "NOC") {
                                        setIsHBO(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if (products.promocion === ("4191") && ((products.estado === "POR") || (products.estado === "PRO"))) {
                                        setIsHBOLock(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    if (products.promocion === ("4102") && products.estado === "NOC") {
                                        setIsFox(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if (products.promocion === ("4102") && ((products.estado === "POR") || (products.estado === "PRO"))) {
                                        setIsFoxLock(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                    if (products.promocion === "4322" && products.estado === "NOC") {
                                        setIsNBA(true);
                                        parentRef.current = parentRef.current + 1;
                                    } else if (products.promocion === "4322" && products.estado === "CON") {
                                        setIsNBAAct(true);
                                        parentRef.current = parentRef.current + 1;
                                    }
                                });
                            }
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
            })
        }
    }

    const getTokenDisney = () => {
        if (idCliente) {
            let objData = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length)
            }
            let objBitacora = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length),
                origen: "WEB",
                tipoServicio: "",
                servicio: "",
                promocion: "",
                accion: "Activacion",
                correo: ""
            }

            if (packages && packages.length > 0) {
                packages.forEach((products: any) => {
                    if (products.tipoServicio === "DISNEY") {
                        objBitacora.tipoServicio = products.tipoServicio;
                        objBitacora.servicio = products.servicio;
                        objBitacora.promocion = products.promocion;
                    }
                })
            }

            if (objData) {
                checkToken(() => {
                    apiV2.postApiV2GetTokenDisney(objData, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                        .then(response => {
                            if (response && _.has(response, ['data', "disney", "errorCode"])) {
                                if (response.data.disney.errorCode === 'TOKEN_GENERADO' || response.data.disney.errorCode === 'ACTIVADO') {
                                    window.open(response.data.disney.url);
                                    apiV2.postApiV2BitacoraEvento(objBitacora, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                                } else {
                                    dispatch(AlertRedux.showAlert("Por el momento el no está disponible el enlace. Intente de nuevo más tarde.", "¡Lo sentimos!"));
                                }
                            }
                        })
                        .catch(error => {
                            error = _.get(error, 'response', error);
                            dispatch(AlertRedux.showAlert("Por el momento el no está disponible el enlace. Intente de nuevo más tarde.", "¡Lo sentimos!"));
                        })
                })
            }
        }
    }

    const showNorton = () => {
        setIsShowNorton(true)
    }

    const showPortability = () => {
        setIsShowPortability(true);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {pagosData.loadingAllDatosPago ? <Spinner /> :
                contractSelected ? (
                    services && servicesFormat && (
                        <>
                            <ComponentHeader
                                type="H2"
                                title="Mis Servicios"
                            />
                            <ServicesDetail
                                contractSelected={contractSelected}
                                servicesFormat={servicesFormat}
                            />
                            {isAmazon || isNetflix || isMLB || isNFL || isHBO || isNorton || isNortonAlt || isDisney || isStar || isCombo || isNBA ?
                                <ComponentHeader
                                    type="H2"
                                    title="Contrata y Activa tus servicios"
                                />
                                :
                                null
                            }
                            <Grid container style={{ marginBottom: 24 }}>
                                <div className={classes.container}>
                                    {
                                        isAmazon ?
                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                <AmazonActivation
                                                    packages={packages}
                                                    token={token}
                                                    idCliente={idCliente}
                                                    parentRef={parentRef}
                                                />
                                            </Grid>
                                            :
                                            isAmazonLock ?
                                                <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                    <AmazonWaitingActivation
                                                        parentRef={parentRef}
                                                    />
                                                </Grid>
                                                :
                                                packages?.map(function (element) {
                                                    return (
                                                        element.promocion === "3522" && element.estado === "NOC" ?
                                                            <AmazonHire
                                                                serviceSelected={element}
                                                                idCliente={idCliente}
                                                                token={token}
                                                                onClickHire={() => validarServicios(true)}
                                                                parentRef={parentRef}
                                                            />
                                                            :
                                                            null
                                                    )
                                                })
                                    }
                                    {
                                        isFoxLock ?
                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                <FoxWaitingActivation
                                                    parentRef={parentRef}
                                                />
                                            </Grid>
                                            : isFox ?
                                                packages?.map(function (element) {
                                                    return (
                                                        element.promocion === "4102" && element.estado !== "CON" ?
                                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                                <FoxHire
                                                                    serviceSelected={element}
                                                                    idCliente={idCliente}
                                                                    token={token}
                                                                    onClickHire={() => validarServicios(true)}
                                                                    parentRef={parentRef}
                                                                />
                                                            </Grid>
                                                            :
                                                            null
                                                    )
                                                })
                                                : null
                                    }
                                    {
                                        isNetflix ?
                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                <NetflixActivation
                                                    packages={packages}
                                                    token={token}
                                                    idCliente={idCliente}
                                                    parentRef={parentRef}
                                                />
                                            </Grid>
                                            :
                                            isNetflixLock ?
                                                <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                    <NetflixWaitingActivation
                                                        parentRef={parentRef}
                                                    />
                                                </Grid>
                                                :
                                                packages?.map(function (element) {
                                                    return (
                                                        (element.promocion === "3523" || element.promocion === "3524" /*|| element.promocion === "4236" */)
                                                            && element.estado === "NOC" ?
                                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12} >
                                                                <NetflixHire
                                                                    serviceSelected={element}
                                                                    idCliente={idCliente}
                                                                    token={token}
                                                                    promocion={element.promocion}
                                                                    description={element.descripcion}
                                                                    price={element.precio}
                                                                    onClickHire={() => validarServicios(true)}
                                                                    parentRef={parentRef}
                                                                />
                                                            </Grid>
                                                            :
                                                            null
                                                    )
                                                })
                                    }
                                    {
                                        // isDisneyAct ?
                                        //     <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                        //         <DisneyLogin
                                        //             onClick={() => getTokenDisney()}
                                        //             parentRef={parentRef}
                                        //         />
                                        //     </Grid>
                                        //     : 
                                        isDisney ?
                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                <DisneyActivation
                                                    onClick={() => getTokenDisney()}
                                                    parentRef={parentRef}
                                                />
                                            </Grid>
                                            : isDisneyLock ?
                                                <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                    <DisneyWaitingActivation
                                                        parentRef={parentRef}
                                                    />
                                                </Grid>
                                                : isDisneyHire && packages ?
                                                    packages.map(function (element) {
                                                        return (
                                                            (element.promocion === "4117" && element.estado !== "CON") ?
                                                                <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                                    <DisneyHire
                                                                        serviceSelected={element}
                                                                        idCliente={idCliente}
                                                                        token={token}
                                                                        onClickHire={() => validarServicios(true)}
                                                                        parentRef={parentRef}
                                                                    />
                                                                </Grid>
                                                                :
                                                                null
                                                        )
                                                    })
                                                    : null
                                    }
                                    {
                                        isHBOLock ?
                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                <HBOWaitingActivation
                                                    parentRef={parentRef}
                                                />
                                            </Grid>
                                            :
                                            isHBO ?
                                                packages?.map(function (element) {
                                                    return (
                                                        element.promocion === "4191" && element.estado !== "CON" ?
                                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                                <HBOHire
                                                                    serviceSelected={element}
                                                                    idCliente={idCliente}
                                                                    token={token}
                                                                    onClickHire={() => validarServicios(true)}
                                                                    parentRef={parentRef}
                                                                />
                                                            </Grid>
                                                            :
                                                            null
                                                    )
                                                })
                                                :
                                                null
                                    }
                                    {
                                        // isComboAct ?
                                        //     <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                        //         <ComboLogin
                                        //             onClick={() => getTokenDisney()}
                                        //             parentRef={parentRef}
                                        //         />
                                        //     </Grid>
                                        //     : 
                                        isCombo ?
                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                <ComboActivation
                                                    onClick={() => getTokenDisney()}
                                                    parentRef={parentRef}
                                                />
                                            </Grid>
                                            : isComboLock ?
                                                <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                    <ComboWaitingActivation
                                                        parentRef={parentRef}
                                                    />
                                                </Grid>
                                                : isComboHire && packages ?
                                                    packages.map(function (element) {
                                                        return (
                                                            (element.promocion === "4342" && element.estado !== "CON") ?
                                                                <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                                    <ComboHire
                                                                        serviceSelected={element}
                                                                        idCliente={idCliente}
                                                                        token={token}
                                                                        onClickHire={() => validarServicios(true)}
                                                                        parentRef={parentRef}
                                                                    />
                                                                </Grid>
                                                                :
                                                                null
                                                        )
                                                    })
                                                    : null
                                    }
                                    {
                                        // isStarAct ?
                                        //     <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                        //         <StarLogin
                                        //             onClick={() => getTokenDisney()}
                                        //             parentRef={parentRef}
                                        //         />
                                        //     </Grid>
                                        //     : 
                                        isStar ?
                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                <StarActivation
                                                    onClick={() => getTokenDisney()}
                                                    parentRef={parentRef}
                                                />
                                            </Grid>
                                            : isStarLock ?
                                                <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                    <StarWaitingActivation
                                                        parentRef={parentRef}
                                                    />
                                                </Grid>
                                                : isStarHire && packages ?
                                                    packages.map(function (element) {
                                                        return (
                                                            (element.promocion === "4059" && element.estado !== "CON") ?
                                                                <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                                    <StarHire
                                                                        serviceSelected={element}
                                                                        idCliente={idCliente}
                                                                        token={token}
                                                                        onClickHire={() => validarServicios(true)}
                                                                        parentRef={parentRef}
                                                                    />
                                                                </Grid>
                                                                :
                                                                null
                                                        )
                                                    })
                                                    : null
                                    }
                                    {
                                        isMLB &&
                                        <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                            <MLBActivation idCliente={idCliente} />
                                        </Grid>
                                    }
                                    {
                                        isNFL &&
                                        <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                            <NFLActivation nflLink={nflLink} code={nflCode} />
                                        </Grid>
                                    }
                                    {
                                        isNBAAct ?
                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                <NBAActivate
                                                    packages={packages}
                                                    token={token}
                                                    idCliente={idCliente}
                                                    parentRef={parentRef}
                                                />
                                            </Grid>
                                            : isNBA ?
                                                packages?.map(function (element) {
                                                    return (
                                                        element.promocion === "4322" && element.estado === "NOC" ?
                                                            <Grid item className={classes.item} xs={12} sm={12} md={12} lg={12}>
                                                                <NBAHire
                                                                    serviceSelected={element}
                                                                    idCliente={idCliente}
                                                                    token={token}
                                                                    onClickHire={() => validarServicios(true)}
                                                                    parentRef={parentRef}
                                                                />
                                                            </Grid>
                                                            :
                                                            null
                                                    )
                                                })
                                                :
                                                null
                                    }
                                    <PortabilityBanner onClick={() => showPortability()} />
                                    {
                                        isShowPortability ?
                                            <FormPortability />
                                            : null
                                    }
                                    {
                                        !isShowNorton && (isNorton || isNortonAlt) &&
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <NortonBanner onClick={showNorton} />
                                        </Grid>
                                    }
                                    {
                                        isNorton && isShowNorton &&
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <NortonHire
                                                onClickHire={() => validarServicios(true)}
                                                packages={packages}
                                                idCliente={idCliente}
                                                token={token}
                                            />
                                        </Grid>
                                    }
                                    {
                                        isNortonAlt && isShowNorton &&
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <NortonHireAlternatives
                                                onClickHire={() => validarServicios(true)}
                                                packages={packages}
                                                idCliente={idCliente}
                                                token={token}
                                            />
                                        </Grid>
                                    }
                                </div>
                            </Grid>
                        </>
                    ))
                    : <Help
                        type={"error"}
                        helpStyle={"outline"}
                        text1="¡Por favor!"
                        text2='Seleccione algún contrato.'
                        additionalStyle={{ margin: "10px 10px" }}
                    />
            }
            <RequestConfirmationDialog
                open={dialogOpen}
                onExit={() => setDialogOpen(false)}
            />
        </div>
    )
};

const styles = makeStyles(({ breakpoints }: any) => createStyles({
    bannerContainer: {
        width: "100%",
    },
    hireServiceBanner: {
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundSize: 'cover',
        cursor: 'pointer'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
    },
    item: {
        width: 'auto',
        height: 'auto',
        flex: 1,
        flexBasis: '30%',
        [breakpoints.down('lg')]: {
            flexBasis: '50%',
        },
        [breakpoints.down('md')]: {
            flexBasis: '100%',
        },
    }
}));

const mapState = (state: any) => ({
    currentContract: state.currentContract,
    pagosData: state.pagosData,
    login: state.login,
    estadoCuenta: state.estadoCuenta,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({
    ...bindActionCreators({ BannersActions: BannersRedux.Creators } as any, dispatch),
})
const connector = connect(mapState, mapDispatch)

export default (connector(MyServices));
