// import utils from '../../utils';
import { keycloak } from '../../App';

export function getWifiPoints() {
    return {
        type: 'GET_WIFI_POINTS',
        payload: {
            request: {
                url: `/wifiPoints`,
                headers: {
                    'Authorization': `Bearer ${keycloak.token}`
                }
            }
        }
    }
}
