import React from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const DataNotLoading = (props: any) => {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <Typography component={'span'} variant="body2">Hubo un problema al obtener el saldo, intentelo más tarde.</Typography>
        </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#d8d5d5",
        paddingLeft: 32,
        paddingTop: 4,
        paddingBottom: 4,
    },
});

export default withStyles(styles)(DataNotLoading);