
import React from 'react';
// import Examples from './Examples'
import AddContracts from './views/addContracts/AddContracts';
import MyServices from './views/myServices/MyServices';
import OnlinePayments from './views/onlinePayment/OnlinePayments';
import Cis from './views/cis/Cis';
import AccountStatusList from './views/accountStatus/AccountStatusList';
import AccountStatusDetails from './views/accountStatus/AccountStatusDetails';
import TechnicalSupport from './views/technicalSupport/TechnicalSupport';
import Configuration from './views/settings/Settings';
import MobileListView from './views/mobile/MobileListView';
import WifiPoints from './views/wifiPoints/WifiPoints';
import MyId from './views/myId/MyId';
import PayService from './views/payService/PayService';
import Main from './Main';
import PrivateRoute from './components/PrivateRoute';
import AddCardConvenio from './views/addCardConvenio/AddCardConvenio';
import CallsList from './views/callsList/CallsList';
// import ActivityLog from './views/activityLog/ActivityLog';
import ListPayments from './views/listPayments/ListPayments';
import TermsConditions from './views/termsConditions/TermsConditions';

function Routes() {

    return (
        <div style={{ height: '100%' }}>
            <Main>
                {/* <PrivateRoute exact path="/examples" component={Examples} /> */}
                <PrivateRoute exact path="/myId" component={MyId} />
                <PrivateRoute path="/payments" component={OnlinePayments} />
                <PrivateRoute path={`/addCardConvenio`} component={AddCardConvenio} />
                <PrivateRoute exact path="/services" component={MyServices} />
                <PrivateRoute exact path="/contracts" component={AddContracts} />
                <PrivateRoute exact path="/cis" component={Cis} />
                <PrivateRoute exact path="/statement" component={AccountStatusList} />
                <PrivateRoute exact path="/details/:proceso" component={AccountStatusDetails} />
                <PrivateRoute path="/support" component={TechnicalSupport} />
                <PrivateRoute exact path="/mobiles" component={MobileListView} />
                <PrivateRoute exact path="/settings" component={Configuration} />
                <PrivateRoute exact path="/terms" component={TermsConditions} />
                <PrivateRoute exact path="/wifi" component={WifiPoints} />
                <PrivateRoute exact path="/payService" component={PayService} />
                <PrivateRoute exact path="/addContracts" component={PayService} />
                <PrivateRoute exact path="/callsList" component={CallsList} />
                <PrivateRoute exact path="/listPayments" component={ListPayments} />
                {/* <PrivateRoute exact path="/activityLog" component={ActivityLog} /> */}
            </Main>
        </div>
    );
}

export default Routes;
