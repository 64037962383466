import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import ProgressBar from "./ProgressBar";

const styles = () => createStyles({
    root: {
        padding: '0 13% 0 9%',
        marginTop: '3%'
    },
    divText: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    divTextLeft: {
        color: "#070797",
        fontWeight: 'bold'
    },
    divTextRight: {
        marginTop: "2%",
        color: '#61617d',
        fontSize: '0.78292490919303em'
    }
})

interface ProgressBarDataProps extends WithStyles<typeof styles> {
    completed: number | string, progressBarTitle: string;
    bgColor: string;
    leftText: string;
    rightText: string;
}

export const ProgressBarData = ({ completed, progressBarTitle, bgColor, leftText, rightText, classes }: ProgressBarDataProps) => {
    return (
        <div className={classes.root}>
            <ProgressBar title={progressBarTitle} bgColor={bgColor} completed={completed} />
            <div className={classes.divText}>
                <div className={classes.divTextLeft}>
                    {leftText}
                </div>
                <div className={classes.divTextRight}>
                    {rightText}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(ProgressBarData);