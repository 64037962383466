import _ from 'lodash';
import { keycloak } from '../App';

export const token: string = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZENsaWVudGUiOiIwNDIwMDk4OTgyIiwiaWF0IjoxNTg4Njk1NDE1LCJleHAiOjE2MjAyMzE0MTV9.i1VgHiutkjQfyKxYSssd3PL6jdYkNDJ-cl0F0LxxyCU";

// export const idSuscriptor: string = "0420098982"; //ERIK
// export const idSuscriptor: string = "0411060596"; //PERLA
// export const idSuscriptor: string = "0120051255"; //Suscriptor con tickets
// export const idSuscriptor: string = "0410898978"; //Suscriptor con facturas
export const idSuscriptor: string = "0440225075"; //Pruebas Movil
// export const idSuscriptor: string = "0411313631"; //Marstom Contrato Mega
// export const idSuscriptor: string = "0440160939"; //Papá Ron

// rizomx10@gmail.com  Arizo1010 Usuario Movil

export const defaultLocation = {
    latitude: 20.588023900000003,
    longitude: -100.34682409999999
}

export const accountStatusObj: any = {
    "suscriptor": "98982",
    "paquete": "Paquete Mega Total Plus",
    "proceso": "200406",
    "sucursal": "42"
}

export const bannerFormatter = (banners: Array<Banner>): string[] => {
    if (!banners || banners.length < 1) {
        return [];
    }

    let newBanners: string[] = [];
    _.forEach(banners, (banner, key) => {
        newBanners.push(banner.imgName);
    })

    return newBanners;
}

interface Banner {
    id: string;
    imgName: string;
    link: string;
}

export const getMonth = (month: string): string => {
    switch (month) {
        case "1":
        case "01":
            return "ENE"
        case "2":
        case "02":
            return "FEB"
        case "3":
        case "03":
            return "MAR"
        case "4":
        case "04":
            return "ABR"
        case "5":
        case "05":
            return "MAY"
        case "6":
        case "06":
            return "JUN"
        case "7":
        case "07":
            return "JUL"
        case "8":
        case "08":
            return "AGO"
        case "9":
        case "09":
            return "SEP"
        case "10":
            return "OCT"
        case "11":
            return "NOV"
        case "12":
            return "DIC"
        default:
            return month
    }
}

export const parseStorageUnitsMeasure = (measure: string) => {
    let newMeasure = measure.toUpperCase();
    let number = newMeasure.match(/[.\d]+/g);
    let cadena = newMeasure.match(/[A-Za-zñÑ]+/g);

    switch (cadena && cadena[0]) {
        case 'KB':
            return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) / 1024 : 0;
        case 'MB':
            return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) : 0;
        case 'GB':
            return number && parseFloat(number[0]) > 0 ? parseFloat(number[0]) * 1024 : 0;
        default:
            return;
    }
}

export const pad = (textoArellenar: string, length: number): string => {
    let padding = 0;
    var str = textoArellenar + "";

    return (length <= str.length) ? str : pad(padding + str, length);
}

export const getPeriodCharges = (summaryDebt: []): any => {
    let resumen = { saldo: 0, cargos: 0, total: 0 };
    _.forEach(summaryDebt, (obj: any) => {
        if (obj.orden === "101") {
            resumen.saldo = obj.total;
        }

        if (obj.orden === "102") {
            resumen.cargos = obj.total;
        }

        if (obj.orden === "103") {
            resumen.total = obj.total;
        }
    });

    return resumen;
}

export const getInitialLetters = (value: string) => {
    const wordsList = value.split(' ');
    let firstLetters: string = ''
    for (let index = 0; index < wordsList.length; index++) {
        const element = wordsList[index];
        firstLetters = firstLetters + element.charAt(0);
    }
    return firstLetters;
}

export const saveToken = (token: string) => {
    localStorage.setItem('token', token);
}

export const removeToken = () => {
    localStorage.removeItem('token');
    keycloak.logout();
}

export const getToken = (): Promise<null | string> => new Promise<null | string>((resolve, reject) => {
    // const lsToken = localStorage.getItem('token');
    // let token = lsToken ? JSON.parse(lsToken) : null;
    // if (token) {
    //     goTo()
    // }
    let token = keycloak.token ? keycloak.token : null;
    resolve(token);
})

export const alertError = (alertFunction: any) => {
    removeToken();
    // alertFunction();
}

export default {
    token,
    idSuscriptor,
    accountStatusObj,
    defaultLocation,
    bannerFormatter,
    getMonth,
    pad,
    getPeriodCharges,
    parseStorageUnitsMeasure,
    saveToken,
    removeToken,
    getToken,
    alertError
}