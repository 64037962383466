import React from 'react';
import { createStyles, withStyles } from '@material-ui/styles';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Button from './Button';

const styles = ({ palette }: any) => createStyles({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
    }
});

interface GoBackProps {
    onClick: any;
    classes: any;
}

const GoBack = ({ classes, onClick }: GoBackProps) => {
    return (
        <div className={classes.container}>
            <Button type="information" text="REGRESAR" size="medium" icon={faArrowLeft} onClick={() => onClick()}/>
        </div>
    )
}

export default withStyles(styles)(GoBack);