import React from 'react';
import { createStyles, withStyles } from '@material-ui/styles';
import Button from './Button';
import utils from '../utils';
import _ from 'lodash';
import * as api from 'megacable-mobile-api';
import awsmobile from '../aws-exports';
import { saveFile } from '../assets/saveFile';
import { useDispatch } from "react-redux";
import { AlertRedux } from 'megacable-mobile-library';

// import { keycloak } from '../App';
import checkToken from '../utils/checKToken';
import moment from 'moment';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

const apiV2 = new api.V2Api({}, baseUrl);

const styles = ({ breakpoints }: any) => createStyles({
    statusContainer: {
        width: '100%',
        background: 'white',
        height: '100%'
    },
    row: {
        alignItems: 'center',
        display: 'flex',
        height: '105px',
        [breakpoints.down('xs')]: {
            height: '150px',
        },
        width: '100%',
        borderBottom: 'solid 1px grey'
    },
    verticalText: {
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        fontSize: '16px',
        color: 'white',
        textTransform: 'uppercase',
        writingMode: 'vertical-lr',
        transform: 'rotate(-180deg)',
        textAlign: 'center',
        height: '100%',
        paddingLeft: '2px'
    },
    backgroundColorPending: {
        backgroundColor: '#004FC0'
    },
    backgroundColorPaid0: {
        backgroundColor: "#54a1d0"
    },
    backgroundColorPaid1: {
        backgroundColor: '#002A4F'
    },
    backgroundColorPaid2: {
        backgroundColor: '#01192B'
    },
    month: {
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '17px',
        color: 'white',
        lineHeight: '1'
    },
    packTitle: {
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '15px',
        color: '#002A4F',
    },
    packDescription: {
        fontFamily: 'Sarabun',
        fontWeight: 'normal',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#002A4F',
    },
    circle: {
        width: '55px',
        height: '55px',
        borderRadius: '50%',
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#fff',
        lineHeight: '55px',
        textAlign: 'center',
        margin: '0px 15px 0px 25px'
    },
    textContainer: {
        margin: '40px 0px'
    },
    buttonContainer: {
        flex: '1',
        float: 'right',
        direction: 'rtl',
        marginRight: '28px',
        [breakpoints.down('xs')]: {
            marginRight: '0'
        },
    },
    downloadIcon: {
        margin: '30px 0px',
        height: '55px',
        width: '55px',
        marginRight: '20px'
    },
    closeIcon: {
        margin: '45px 0px',
        height: '25px',
        width: '25px',
        marginRight: '20px',
        cursor: 'pointer'
    },
    downloadButtonContainer: {
        margin: '29px 15px 29px 0px'
    },
    centerButton: {
        display: 'flex',
        alignItems: 'center',
        [breakpoints.down('xs')]: {
            display: 'block'
        },
    }
});

interface AccountStatusProps {
    classes: any;
    accountStatusItems: any,
    invoiceButton?: boolean;
    // onClickSend: Function;
    onClickDownload: Function;
    onClickPay: Function;
    idCliente: string;
    token: string;
}

export const AccountStatus = ({ classes, accountStatusItems, invoiceButton, /*onClickSend,*/ onClickDownload, onClickPay, idCliente, token }: AccountStatusProps) => {
    // const [edosCuenta, setEdosCuenta] = React.useState<any>({});
    const dispatch = useDispatch();


    function downloadPDF(item: any) {
        if (idCliente && token) {
            let objPDFBase64 = {
                sucursal: idCliente.substr(0, 3),
                suscriptor: idCliente.substr(3, idCliente.length),
                proceso: item.proceso
            }

            checkToken(() => {
                apiV2.postApiV2ObtPDFBase64(objPDFBase64, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response && response.data && _.has(response, ['data', "estatus"])) {
                            if (response.data.estatus === "Exito") {
                                let pdfFile = response.data.archivo;
                                if (pdfFile) {
                                    saveFile(pdfFile, "application/pdf", `EdoCuenta_${item && item.mes}_${item && item.anio}.pdf`)
                                }
                            } else {
                                dispatch(AlertRedux.showAlert("No fue posible descargar tu estado de cuenta en este momento, intenta más tarde.", "Descargar estado de cuenta"))
                            }
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                        dispatch(AlertRedux.showAlert("No fue posible descargar tu estado de cuenta en este momento, intenta más tarde.", "Descargar estado de cuenta"))
                    })
            })
        }
    }

    function getFacturas(item: any) {
        if (idCliente && token) {
            apiV2.getApiV2Facturas(idCliente, true, { headers: { "Authorization": 'Bearer: ' + token } })
                .then((response) => {
                    if (response.data.facturas.length > 0) {
                        let factura = {};
                        _.forEach(response.data.facturas, (facturaTmp) => {
                            let mesTmp = moment(facturaTmp.fecha).format('MM');
                            let nombreMesTmp = moment(facturaTmp.fecha).format('MMM');
                            let annioTmp = moment(facturaTmp.fecha).format('YYYY');
                            if ((item.mes && item.anio) && mesTmp === item.mes && annioTmp === "20" + item.anio) {
                                facturaTmp.date = 'factura ' + nombreMesTmp + ' ' + annioTmp;
                                facturaTmp.image = 'iconPdf';
                                facturaTmp.bottomLabel = 'descargar';
                                facturaTmp.factura = 0;
                                factura = facturaTmp;

                            }
                        })
                        onClickDownload(factura)
                    } else {
                        dispatch(AlertRedux.showAlert("No existe la factura a descargar.", "Descargar factura"))
                    }
                })
                .catch(err => {
                    dispatch(AlertRedux.showAlert("No fue posible descargar tu factura en este momento, intenta más tarde.", "Descargar factura"))
                })
        }
    }

    return (
        <div className={classes.statusContainer}>
            {
                _.map(accountStatusItems, (item, key) => {
                    return (
                        <div className={classes.row} key={`div-accountStatus${key}`}>
                            <div className={`${classes.verticalText} ${item.estado === "pendiente" ?
                                classes.backgroundColorPending
                                :
                                (item.estado === "pagado" && key.toString() === "0") ?
                                    classes.backgroundColorPaid0
                                    :
                                    (item.estado === "pagado" && key.toString() === "1") ?
                                        classes.backgroundColorPaid1
                                        :
                                        classes.backgroundColorPaid2}`}>
                                {item.estado}
                            </div>
                            <div className={`${classes.circle} ${item.estado === "pendiente" ?
                                classes.backgroundColorPending
                                :
                                (item.estado === "pagado" && key.toString() === "0") ?
                                    classes.backgroundColorPaid0
                                    :
                                    (item.estado === "pagado" && key.toString() === "1") ?
                                        classes.backgroundColorPaid1
                                        :
                                        classes.backgroundColorPaid2}`}>
                                <span className={classes.month}>{utils.getMonth(item.mes)}</span>
                            </div>
                            <div className={classes.textContainer}>
                                <div>
                                    <span className={classes.packTitle}>{item.paquete}</span>
                                </div>
                                {/* <div>
                                    <span className={classes.packDescription}>Pronto Pago</span>
                                </div> */}
                            </div>

                            <div className={`${classes.buttonContainer} ${classes.centerButton}`}>
                                {
                                    item.btn1 === "Pagar" ?
                                        <div>
                                            <Button type="information" text="PAGAR" size="small" onClick={() => onClickPay()} />
                                        </div>
                                        :
                                        <>
                                            {/* <div>
                                                <Button type="primary" text="DESCARGAR EDO. DE CTA." size="large" onClick={() => downloadPDF(item)} />
                                            </div>
                                            <div>
                                                <Button type="secondary" text="ENVIAR POR SMS" size="medium" onClick={item.btnDetails ? item.btnDetails : null} />
                                            </div> */}
                                        </>
                                }
                                {
                                    <>
                                        <div>
                                            <Button type="primary" text="DESCARGAR EDO. DE CTA." size="large" onClick={() => downloadPDF(item)} />
                                        </div>
                                        <div>
                                            <Button type="secondary" text="ENVIAR POR SMS" size="medium" onClick={item.btnDetails ? item.btnDetails : null} />
                                        </div>
                                    </>
                                }
                                {
                                    item.btn2 === "Factura" ?
                                        <div>
                                            {invoiceButton && <Button type="secondary" text="DESCARGAR FACTURA" size="large" onClick={() => getFacturas(item)} />}
                                        </div>
                                        :
                                        <>
                                        </>
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default withStyles(styles)(AccountStatus);
