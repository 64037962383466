import React, { useEffect } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles, Grid, Button, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import Payment from '@material-ui/icons/Payment';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../components/Spinner';
import Slider from '../../components/Slider';
import _ from 'lodash';
import moment from 'moment';
import { RouteComponentProps, useHistory } from "react-router";
import { connect, ConnectedProps, useSelector, useDispatch, } from "react-redux";
import { Dispatch } from "redux";
import { BannersRedux, FinancialInstitutionsRedux, AlertRedux } from 'megacable-mobile-library';
// import { IContract } from '../../storeV2/ducks/types';
import { /*DatosPago,*/ FullApiResponse, SuscriptorYServicios } from 'megacable-mobile-api/models';
import IsValidToken from '../../utils/IsValidToken';
import GoBack from '../../components/GoBack';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import Help from '../../components/Help';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import AddContractHeader from '../../components/AddContractHeader';

import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import { IContract } from '../../storeV2/ducks/types';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface IAddresses {
    calle: string,
    ciudad: string,
    codigopostal: string,
    colonia: string,
    departamento: string,
    entrecalle1: string,
    entrecalle2: string,
    estado: string,
    numeroexterior: string,
}


interface CardPaymentData {
    number: string,
    expirationDate: string,
}

interface CardPaymentErrors {
    number: string,
    expirationDate: string,
}

const emptyCreditCard = {
    number: "",
    expirationDate: "",
}

type StateType = {
    bTieneConvenio: boolean,
    cardExpired: any;
    user: any;
}

type PropsFromRedux = ConnectedProps<typeof connector>

type IAddCardConvenio = PropsFromRedux & RouteComponentProps<{}, {}, StateType>;

const AddCardConvenio = (props: IAddCardConvenio) => {
    const classes = styles();
    const { /*pagosData,*/ currentContract, banners, /*login,*/ location } = props;
    const { state } = location;
    const [loading, setLoading] = React.useState<boolean>(false);
    // const [contractSelected, setContractSelected] = React.useState<IContract>();
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    // const [paymentData, setPaymentData] = React.useState<DatosPago>();
    const [cardPaymentData, setCardPaymentData] = React.useState<CardPaymentData>(emptyCreditCard);
    const [cardPaymentErrors, setCardPaymentErrors] = React.useState<CardPaymentErrors>({ number: "", expirationDate: "" });
    const convenioBanners = useSelector((state: any) => state.banners.convenioBanners);
    const [imgSinConvenio, setImgSinConvenio] = React.useState<string>('');
    const [imgConvenio, setImgConvenio] = React.useState<string>('');
    const [bTieneConvenio, setBTieneConvenio] = React.useState<boolean>(false);
    const [cardExpired, setCardExpired] = React.useState<FullApiResponse>();
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [user, setUser] = React.useState<SuscriptorYServicios>();
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(BannersRedux.getConvenioBanners());
        dispatch(FinancialInstitutionsRedux.getFinancialInstitutions());
    }, []);

    useEffect(() => {
        setContractSelected(currentContract.currentContract);
    }, [currentContract]);

    useEffect(() => {
        startAnalytics('add_update_card_convenio_id');
        if (_.hasIn(state, ['cardExpired']) && _.hasIn(state, ['user'])) {
            setBTieneConvenio(state.bTieneConvenio)
            setCardExpired(state.cardExpired);
            setUser(state.user);
        }
        // else {
        //     history.push("/payments");
        // }
        return () => {
            logAnalytics('add_update_card_convenio_id');
        }
    }, []);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract', 'idCliente'])) {
                setIdCliente(currentContract.currentContract.idCliente);
            }
        }
    }, [currentContract]);

    useEffect(() => {
        if (convenioBanners) {
            let imgSinConvenio = convenioBanners && convenioBanners.length > 0 ? convenioBanners[0].imgName : "";
            let imgConvenio = convenioBanners && convenioBanners.length > 0 ? convenioBanners[1].imgName : "";
            if (imgSinConvenio) { setImgSinConvenio(imgSinConvenio); }
            if (imgConvenio) { setImgConvenio(imgConvenio); }
        }
        return () => { }
    }, [convenioBanners]);

    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    // useEffect(() => {
    //     if(contractSelected){
    //         if(pagosData && _.hasIn(pagosData, ['allDatosPago'])){
    //             const paymentList : Array<DatosPago> = pagosData.allDatosPago;
    //             if(paymentList && paymentList.length > 0){
    //                 let paymentUser = _.find(paymentList, (paymentData: DatosPago) => { return paymentData.suscriptor === contractSelected.idCliente});
    //                 if(paymentUser){
    //                     setPaymentData(paymentUser);
    //                 }
    //             }
    //         }
    //     }
    //     return () => {}
    // }, [contractSelected, pagosData]);

    // useEffect(() => {
    //     if(currentContract){
    //         const contractSelected = currentContract.currentContract;
    //         setContractSelected(contractSelected);
    //     }
    // }, [currentContract]);

    const addPaymentData = (value: string, typeData: "number" | "date") => {
        let valueTmp = value;
        const typeDataTmp = typeData;
        switch (typeDataTmp) {
            case "number":
                var numberRaw = valueTmp.split("-").join("");
                var numberFormated = valueTmp.match(new RegExp('.{1,4}', 'g'))?.join("-");
                if (numberRaw.length <= 16) {
                    if (numberFormated) {
                        setCardPaymentData({ ...cardPaymentData, number: numberFormated });
                    } else {
                        setCardPaymentData({ ...cardPaymentData, number: '' });
                    }
                }
                break;
            case "date":
                let expirationDate: string = _.cloneDeep(valueTmp);
                if (expirationDate) {
                    expirationDate = expirationDate.split("/").join("")
                    if (expirationDate.length <= 4) {
                        let cardFormatArray: Array<string> | null = expirationDate.match(/.{1,2}/g);
                        if (cardFormatArray) {
                            let newCardFormat: string = cardFormatArray.join("/");
                            setCardPaymentData({ ...cardPaymentData, expirationDate: newCardFormat });
                        } else {
                            setCardPaymentData({ ...cardPaymentData, expirationDate: '' });
                        }
                    }
                } else {
                    setCardPaymentData({ ...cardPaymentData, expirationDate: "" });
                }
                break;
            default:
                break;
        }
    }

    const validate = () => {

        let creditNumberTmp: string = _.cloneDeep(cardPaymentData.number);
        let mmaaTmp: string = _.cloneDeep(cardPaymentData.expirationDate);

        let cardFormErrors: CardPaymentErrors = {
            number: "",
            expirationDate: "",
        };

        let hasErrorMMAA = true;

        if (!creditNumberTmp || !mmaaTmp) {
            if (!creditNumberTmp) { cardFormErrors.number = "Dato requerido" }
            if (!mmaaTmp) { cardFormErrors.expirationDate = "Dato requerido" }
        } else {
            if (creditNumberTmp.length < 19) {
                cardFormErrors.number = "El numero de credito debe tener 16 digitos.";
            }
            //eslint-disable-next-line
            var reg = new RegExp("(0[1-9]|1[012])\/[0-9]{2}");
            if (reg.test(mmaaTmp))
                hasErrorMMAA = false;
            else {
                if (mmaaTmp.indexOf("/") < 0) {
                    cardFormErrors.number = "El numero de credito debe tener 16 digitos."
                } else {
                    cardFormErrors.expirationDate = "El campo Mes (MM) y/o año (AA) es incorrecto.";
                }
            }

            let mes = mmaaTmp.slice(0, 2);
            let mesActual = moment().format("MM");
            let anio = mmaaTmp.slice(3, 5);
            let anioActual = moment().format("YY");

            if (anio === anioActual && mes < mesActual)
                cardFormErrors.expirationDate = "El mes de la tarjeta ha expirado.";

            if (anio < anioActual) {
                cardFormErrors.expirationDate = "\n El año de la tarjeta ha expirado.";
            }
        }

        if (hasErrorMMAA === false && cardFormErrors.number === "" && cardFormErrors.expirationDate === "") {
            setLoading(true);
            updateExpirationDate();
        } else {
            setCardPaymentErrors(cardFormErrors);
        }
    }

    const updateExpirationDate = () => {
        let bTieneConvenioTmp = _.cloneDeep(bTieneConvenio);
        let cardExpiredTmp = _.cloneDeep(cardExpired);
        let userTmp = _.cloneDeep(user);

        // const { auth } = login;

        let creditNumberTmp: string = _.cloneDeep(cardPaymentData.number);
        let mmaaTmp: string = _.cloneDeep(cardPaymentData.expirationDate);
        let newCreditNumber = _.replace(creditNumberTmp, new RegExp("-", "g"), "");

        let error = "No se ha podido actualizar la tarjeta";
        let date = moment(mmaaTmp, 'MM/YY');

        let simpleSmsObj: any = null;

        if (userTmp && _.has(userTmp, 'telefonoCelular') && userTmp.telefonoCelular && _.has(userTmp, 'idCliente') && userTmp.idCliente) {
            simpleSmsObj = {
                to: `52${userTmp.telefonoCelular.toString()}`,
                typeMessage: 'DOMICILIAR',
                idCliente: userTmp.idCliente
            }
        }

        if (bTieneConvenioTmp) {
            if (cardExpiredTmp) {
                const headers = { "Authorization": `Bearer ${keycloak.token}` };
                let objData = {
                    text: newCreditNumber
                }
                checkToken(() => {
                    apiV2.postApiV2EncryptTarjeta(objData, { headers })
                        .then(response => {
                            if (response.data.encryptedText) {
                                let request = {
                                    "terminacion": cardExpiredTmp && cardExpiredTmp.tarjeta.terminacion ? cardExpiredTmp.tarjeta.terminacion : '',
                                    "tarjeta": {
                                        "numero": response.data.encryptedText,
                                        "vencimiento": {
                                            "anio": date.format('YYYY'),
                                            "mes": date.format('MM')
                                        }
                                    }
                                }
                                apiV2.postApiV2UpdateExpirationCard(request, { headers })
                                    .then(response => {
                                        logAnalytics('action_update_card_convenio_id');
                                        if (_.isEmpty(response)) {
                                            dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
                                        } else if (!_.has(response, 'data.codigo')) {
                                            dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
                                        } else if (response.data.codigo === 0) {
                                            dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
                                        } else if (response.data.codigo > 0) {
                                            dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
                                        } else {
                                            dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
                                        }
                                        setLoading(false);
                                    })
                                    .catch(err => {
                                        err = _.get(err, 'response', err);
                                        if (_.has(err, 'data') && err.data === "invalid token") {
                                            IsValidToken(err.data);
                                        } else {
                                            dispatch(AlertRedux.showAlert("No se ha podido actualizar", "Estimado(a) suscriptor"));
                                        }
                                        setLoading(false);
                                    })
                            } else {
                                dispatch(AlertRedux.showAlert("No se ha podido actualizar", "Estimado(a) suscriptor"));
                            }
                        })
                        .catch(err => {
                            dispatch(AlertRedux.showAlert("No se ha podido actualizar", "Estimado(a) suscriptor"));
                        })
                })
                startAnalytics('action_update_card_convenio_id');
            }
        } else {
            let objData = {
                text: newCreditNumber
            }
            const headers = { "Authorization": `Bearer ${keycloak.token}` };
            startAnalytics('action_add_card_convenio_id');
            checkToken(() => {
                apiV2.postApiV2EncryptTarjeta(objData, { headers })
                    .then(response => {
                        if (response.data.encryptedText) {
                            let request = {
                                "tarjeta": {
                                    "numero": response.data.encryptedText,
                                    "vencimiento": {
                                        "anio": date.format('YYYY'),
                                        "mes": date.format('MM')
                                    }
                                }
                            }
                            apiV2.postApiV2AddCard(request, { headers })
                                .then(response => {
                                    logAnalytics('action_add_card_convenio_id');
                                    if (_.isEmpty(response)) {
                                        dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
                                    } else if (!_.has(response, 'data.codigo')) {
                                        dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
                                    } else if (response.data.codigo === 0) {
                                        if (!_.isEmpty(simpleSmsObj)) {
                                            apiV2.postApiV2SendSimpleSms(simpleSmsObj, { headers })
                                        }
                                        dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
                                    } else if (response.data.codigo > 0) {
                                        dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
                                    } else {
                                        dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
                                    }
                                    setLoading(false);
                                })
                                .catch(err => {
                                    err = _.get(err, 'response', err);
                                    if (_.has(err, 'data') && err.data === "invalid token") {
                                        IsValidToken(err.data);
                                    } else {
                                        dispatch(AlertRedux.showAlert("No se ha podido actualizar", "Estimado(a) suscriptor"));
                                    }
                                    setLoading(false);
                                })
                        } else {
                            dispatch(AlertRedux.showAlert("No se ha podido actualizar", "Estimado(a) suscriptor"));
                        }

                    })
                    .catch(err => {
                        dispatch(AlertRedux.showAlert("No se ha podido actualizar", "Estimado(a) suscriptor"));
                    })
            })
        }
    }

    // const updateExpirationDate = () => {
    //     let bTieneConvenioTmp = _.cloneDeep(bTieneConvenio);
    //     let cardExpiredTmp = _.cloneDeep(cardExpired);
    //     let userTmp = _.cloneDeep(user);

    //     // const { auth } = login;

    //     let creditNumberTmp: string = _.cloneDeep(cardPaymentData.number);
    //     let mmaaTmp: string = _.cloneDeep(cardPaymentData.expirationDate);
    //     let newCreditNumber = _.replace(creditNumberTmp, new RegExp("-", "g"), "");

    //     let error = "No se ha podido actualizar la tarjeta";
    //     let date = moment(mmaaTmp, 'MM/YY');
    //     let simpleSmsObj: any = null;

    //     if (userTmp && _.has(userTmp, 'telefonoCelular') && userTmp.telefonoCelular && _.has(userTmp, 'idCliente') && userTmp.idCliente) {
    //         simpleSmsObj = {
    //             to: `52${userTmp.telefonoCelular.toString()}`,
    //             typeMessage: 'DOMICILIAR',
    //             idCliente: userTmp.idCliente
    //         }
    //     }

    //     if (bTieneConvenioTmp) {
    //         if (cardExpiredTmp) {
    //             let request = {
    //                 "terminacion": cardExpiredTmp.tarjeta.terminacion,
    //                 "tarjeta": {
    //                     "numero": newCreditNumber,
    //                     "vencimiento": {
    //                         "anio": date.format('YYYY'),
    //                         "mes": date.format('MM')
    //                     }
    //                 }
    //             }

    //             startAnalytics('action_update_card_convenio_id');
    //             checkToken(() => {
    //                 const headers = { "Authorization": `Bearer ${keycloak.token}` };
    //                 apiV2.postApiV2UpdateExpirationCard(request, { headers })
    //                     .then(response => {
    //                         logAnalytics('action_update_card_convenio_id');
    //                         if (_.isEmpty(response)) {
    //                             dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
    //                         } else if (!_.has(response, 'data.codigo')) {
    //                             dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
    //                         } else if (response.data.codigo === 0) {
    //                             dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
    //                         } else if (response.data.codigo > 0) {
    //                             dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
    //                         } else {
    //                             dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
    //                         }
    //                         setLoading(false);
    //                     })
    //                     .catch(err => {
    //                         err = _.get(err, 'response', err);
    //                         if (_.has(err, 'data') && err.data === "invalid token") {
    //                             IsValidToken(err.data);
    //                         } else {
    //                             dispatch(AlertRedux.showAlert("No se ha podido actualizar", "Estimado(a) suscriptor"));
    //                         }
    //                         setLoading(false);
    //                     })
    //             })
    //         }
    //     } else {
    //         let request = {
    //             "tarjeta": {
    //                 "numero": newCreditNumber,
    //                 "vencimiento": {
    //                     "anio": date.format('YYYY'),
    //                     "mes": date.format('MM')
    //                 }
    //             }
    //         };

    //         startAnalytics('action_add_card_convenio_id');
    //         checkToken(() => {
    //             const headers = { "Authorization": `Bearer ${keycloak.token}` };
    //             apiV2.postApiV2AddCard(request, { headers })
    //                 .then(response => {
    //                     logAnalytics('action_add_card_convenio_id');
    //                     if (_.isEmpty(response)) {
    //                         dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
    //                     } else if (!_.has(response, 'data.codigo')) {
    //                         dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
    //                     } else if (response.data.codigo === 0) {
    //                         if (!_.isEmpty(simpleSmsObj)) {
    //                             apiV2.postApiV2SendSimpleSms(simpleSmsObj, { headers })
    //                         }
    //                         dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
    //                     } else if (response.data.codigo > 0) {
    //                         dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
    //                     } else {
    //                         dispatch(AlertRedux.showAlert(error, "Estimado(a) suscriptor"));
    //                     }
    //                     setLoading(false);
    //                 })
    //                 .catch(err => {
    //                     err = _.get(err, 'response', err);
    //                     if (_.has(err, 'data') && err.data === "invalid token") {
    //                         IsValidToken(err.data);
    //                     } else {
    //                         dispatch(AlertRedux.showAlert("No se ha podido actualizar", "Estimado(a) suscriptor"));
    //                     }
    //                     setLoading(false);
    //                 })
    //         })
    //     }
    // }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            validate();
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.bannerContainer}>
                {(bannerList && !_.isEmpty(bannerList)) ? <Slider size='large' images={bannerList} /> : <div />}
            </div>
            {
                contractSelected ? (
                    loading ? <Spinner /> : (
                        <>
                            <AddContractHeader title="Domiciliar pago" number={idCliente ? idCliente : ''} />
                            <div style={{ backgroundColor: "#002A4F" }}>
                                <GoBack onClick={() => history.push('/payments')} />
                            </div>
                            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.formContainer}>
                                    {
                                        cardExpired && cardExpired.tarjeta ?
                                            <Help type={"attention"} helpStyle={"outline"} text1="¡Atención!" text2='Ya cuentas con una tarjeta domiciliada ¿Deseas actualizar tus datos?' additionalStyle={{ margin: "10px 10px" }} />
                                            :
                                            null
                                    }
                                    <div className={classes.imgContainer}>
                                        <img src={'/mega-logotipo.svg'} alt='Logo' className={classes.megacable} />
                                    </div>
                                    <div className={classes.inputContainer}>
                                        <TextField
                                            className={clsx(classes.inputNumberCard)}
                                            value={cardPaymentData.number}
                                            placeholder="Número de tarjeta"
                                            error={cardPaymentErrors.number ? true : false}
                                            helperText={cardPaymentErrors.number ? cardPaymentErrors.number : ''}
                                            onChange={(event) => addPaymentData(event.target.value.replace(/[^0-9]/g, ''), 'number')}
                                            onFocus={() => setCardPaymentErrors({ ...cardPaymentErrors, number: "" })}
                                            onKeyDown={(event: React.KeyboardEvent) => handleKeyDown(event)}
                                            inputProps={{ style: { color: "#005ca7" }, }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Payment style={{ color: "#cccccc" }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <TextField
                                            className={clsx(classes.inputDateAndCvv)}
                                            value={cardPaymentData.expirationDate}
                                            placeholder="MM/AA"
                                            error={cardPaymentErrors.expirationDate ? true : false}
                                            helperText={cardPaymentErrors.expirationDate ? cardPaymentErrors.expirationDate : ''}
                                            onChange={(event) => addPaymentData(event.target.value.replace(/[^0-9]/g, ''), 'date')}
                                            onFocus={() => setCardPaymentErrors({ ...cardPaymentErrors, expirationDate: "" })}
                                            onKeyDown={(event: React.KeyboardEvent) => handleKeyDown(event)}
                                            inputProps={{ style: { color: "#005ca7" }, maxLength: 5 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <Tooltip
                                                        className={classes.tooltip}
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{ timeout: 600 }}
                                                        title="Fecha ubicada al frente de tu tarjeta, debajo del número de tarjeta.">
                                                        <InputAdornment position="start">
                                                            <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "#cccccc" }} />
                                                        </InputAdornment>
                                                    </Tooltip>
                                                ),
                                            }} />
                                        <Button className={classes.button} onClick={validate}>Agregar tarjeta</Button>
                                    </div>
                                    <div className={classes.secureTrustImgContainer}>
                                        <a href="https://sealserver.trustwave.com/cert.php?customerId=d0328ea2063448ebb51870d9716b80aa&size=105x54&style=invert" target="_blank" rel="noopener noreferrer">
                                            <img src="../../securetrust.png" alt="Secure trust" />
                                        </a>
                                    </div>
                                    <div style={{ paddingBottom: '10px' }}>
                                        <Help type={"attention"} helpStyle={"outline"} text1="¡Lo sentimos!" text2='Por el momento no aceptamos American Express' additionalStyle={{ margin: "10px 10px" }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.convenioImgContainer}>
                                    {
                                        imgConvenio && imgSinConvenio &&
                                            bTieneConvenio
                                            ? <img src={imgConvenio} alt='Convenio Megacable' className={classes.convenioImg} />
                                            : <img src={imgSinConvenio} alt='Sin convenio Megacable' className={classes.convenioImg} />
                                    }
                                </Grid>
                            </Grid>
                        </>
                    )
                )
                    :
                    <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
            }
        </div>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white"
    },

    bannerContainer: {
        width: "100%",
    },

    info: {
        padding: 8,
        backgroundColor: "#0797fd",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    infoTexContainer: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
    },

    icon: {
        fontSize: 32,
        color: "white",
        marginRight: 16,
    },

    megacable: {
        height: 35,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginTop: 10,
    },

    imgContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 32,
    },

    space: {
        marginRight: 16,
    },

    tooltip: {
        backgroundColor: "transparent",
        color: theme.palette.common.black
    },

    formContainer: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        paddingLeft: 40,
        paddingRight: 40,
        // border: "1px solid red",
        [theme.breakpoints.down('md')]: {
            paddingLeft: 24,
            paddingRight: 24,
        }
    },

    inputContainer: {
        width: "70%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },

    inputNumberCard: {
        marginBottom: 24,
    },

    inputDateAndCvv: {
        marginBottom: 24,
    },

    button: {
        height: 48,
        backgroundColor: "#0797fd",
        borderRadius: 10,
        color: "white",
        border: 0,
        padding: '0 30px',
        marginBottom: 32,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        },
        "& .MuiTouchRipple-root span": {
            backgroundColor: "#6fc3fc",
        },
    },

    convenioImgContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // border: "1px solid blue",
        [theme.breakpoints.down('md')]: {
            padding: 16,
        },
        [theme.breakpoints.down('sm')]: {
            padding: 24,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 24,
            justifyContent: "flex-start",
            alignItems: "center",
        }
    },

    convenioImg: {
        // width: '100%',
        marginTop: 16,
        borderRadius: 10,
        backgroundSize: "contain",
        marginBottom: 16,
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            // width: "100%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "90%",
        }
    },

    secureTrustImgContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }
}));

const mapState = (state: any) => ({
    login: state.login,
    banners: state.banners,
    estadoCuenta: state.estadoCuenta,
    pagosData: state.pagosData,
    loading: state.loading,
    currentContract: state.currentContract,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({

});


const connector = connect(mapState, mapDispatch);

export default (connector(AddCardConvenio));
