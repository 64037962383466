import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
// import { Grid, Row, Col } from 'react-flexbox-grid';
// import Input from './Input';
// import Button from './Button';

import { Typography, TextField, InputAdornment, Grid, Box, Theme, Tooltip, /*Switch*/ } from '@material-ui/core';
// import { useSelector } from 'react-redux';
// import { getBanners } from '../store/actions/BannerAction';
// import { postPago } from '../store/actions/PaymentsAction';

// import flags from '../assets/countries';
// import CheckBox from './Checkbox';
// import moment from 'moment';
// import _ from 'lodash';
import Payment from '@material-ui/icons/Payment';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Fade from '@material-ui/core/Fade';



interface cardProps {
    id: string | number;
    cardNumber: string;
    country: string;
    exp: string;
    expiryMonth: string
    expiryYear: string
}

// const emptyCard = {
//     id: "",
//     cardNumber: "",
//     country: "",
//     exp: "",
//     expiryMonth: "",
//     expiryYear: ""
// }

interface PaymentData {
    number: string,
    expirationDate: string,
    cvv: string,
    saveCard: boolean,
    isValid: boolean
}

interface PaymentErrors {
    number: string,
    expirationDate: string,
    cvv: string,
}
interface IFormCardPayment extends WithStyles<typeof styles> {
    paymentData: PaymentData,
    paymentErrors: PaymentErrors,
    onFocus?: (typeData: "number" | "date" | "cvv") => void,
    onKeyDown: (event: React.KeyboardEvent) => void,
    addPaymentData: (value: string, typeData: "number" | "date" | "cvv") => void,
    saveCard: (value: boolean) => void,
}

const FormCardPayment = (props: IFormCardPayment) => {
    const { classes, addPaymentData, onKeyDown, onFocus, /*saveCard,*/ paymentData, paymentErrors } = props;

    return (
        <div className={classes.root}>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography component={'span'} color="primary" variant="body1"><Box mb={2}>NUEVA TARJETA</Box></Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className={classes.numberCardContainer}>
                        <TextField
                            fullWidth
                            value={paymentData.number}
                            placeholder="Número de tarjeta"
                            error={paymentErrors.number ? true : false}
                            onKeyDown={(event: React.KeyboardEvent) => onKeyDown(event)}
                            helperText={paymentErrors.number ? paymentErrors.number : ' '}
                            onChange={(event) => addPaymentData(event.target.value.replace(/[^0-9]/g, ''), 'number')}
                            onFocus={() => { onFocus && onFocus("number") }}
                            inputProps={{ style: { color: "#005ca7" }, }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Payment style={{ color: "#cccccc" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className={classes.dateCardContainer}>
                        <TextField
                            value={paymentData.expirationDate}
                            placeholder="MM/AA"
                            error={paymentErrors.expirationDate ? true : false}
                            className={clsx(classes.space, classes.inputDateAndCvv)}
                            helperText={paymentErrors.expirationDate ? paymentErrors.expirationDate : ' '}
                            onKeyDown={(event: React.KeyboardEvent) => onKeyDown(event)}
                            onChange={(event) => addPaymentData(event.target.value.replace(/[^0-9]/g, ''), 'date')}
                            onFocus={() => { onFocus && onFocus("date") }}
                            inputProps={{ style: { color: "#005ca7" }, maxLength: 5 }}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        className={classes.tooltip}
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        title="Fecha ubicada al frente de tu tarjeta, debajo del número de tarjeta.">
                                        <InputAdornment position="start">
                                            <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "#cccccc" }} />
                                        </InputAdornment>
                                    </Tooltip>
                                ),
                            }}
                        />
                        <TextField
                            value={paymentData.cvv}
                            error={paymentErrors.cvv ? true : false}
                            onChange={(event) => addPaymentData(event.target.value.replace(/[^0-9]/g, ''), 'cvv')}
                            placeholder="CVV"
                            className={clsx(classes.inputDateAndCvv)}
                            onKeyDown={(event: React.KeyboardEvent) => onKeyDown(event)}
                            helperText={paymentErrors.cvv ? paymentErrors.cvv : ' '}
                            onFocus={() => { onFocus && onFocus("cvv") }}
                            inputProps={{ style: { color: "#005ca7" }, maxLength: 3 }}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        className={classes.tooltip}
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        title="Código de 3 digitos ubicado al reverso de tu tarjeta de crédito.">
                                        <InputAdornment position="start">
                                            <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "#cccccc" }} />
                                        </InputAdornment>
                                    </Tooltip>
                                ),
                            }}
                        />
                    </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                     <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems:"center", marginBottom: 24}}>
                         <Typography variant="body1"><Box>GUARDAR TARJETA</Box></Typography>
                        <Switch 
                            color="primary"
                            checked={paymentData.saveCard}
                            onChange={(event) => {saveCard && saveCard(event.target.checked)}}/>
                     </div>
                 </Grid> */}
            </Grid>
        </div>
    )
}

const styles = ({ palette, breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        paddingTop: 40,
        paddingLeft: 30,
        paddingRight: 30,
    },
    centerCheckbox: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '32px',
    },
    boxButtonRegister: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    infoText1: {
        fontWeight: 'bold'
    },
    infoText2: {
        fontWeight: 'normal',
        marginLeft: '5px'
    },
    rowCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    boxNewTarget: {
        width: '100%',
        padding: '10px 0px 40px',
        borderBottom: '#c5c5c5 solid 1px'
    },
    colButtonsCVV: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '8px',
    },
    numberCardContainer: {
        marginRight: 40,
        [breakpoints.down('md')]: {
            marginRight: 0,
            marginBottom: 24,
        }
    },

    dateCardContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        [breakpoints.down('md')]: {
            justifyContent: "flex-start",
        },
    },

    inputDateAndCvv: {
        flex: 1,
    },

    space: {
        marginRight: 16,
    },

    saveCardContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },

    saveCardText: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    tooltip: {
        backgroundColor: "transparent",
        color: palette.common.black
    }
});

export default withStyles(styles)(FormCardPayment);
