import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { TextField, InputAdornment, Theme, } from '@material-ui/core';
import Payment from '@material-ui/icons/Payment';


interface ITextFieldCardNumber extends WithStyles<typeof styles> {
    cardNumberValue: string,
    cardNumberFormat?: string,
    cardNumberError?: string,
    otherStyles?: any,
    onKeyDown?: (event: React.KeyboardEvent) => void,
    onFocus?: (typeData: "number" | "date" | "cvv") => void,
    addPaymentData: (value: string, typeData: "number" | "date" | "cvv") => void,
}

const TextFieldCardNumber = (props: ITextFieldCardNumber) => {
    const {classes, cardNumberValue, cardNumberError, otherStyles, onKeyDown, onFocus, addPaymentData} = props;

    return (
        <div className={classes.root} style={otherStyles}>
             <TextField
                fullWidth
                value={cardNumberValue} 
                placeholder="Número de tarjeta"
                error={cardNumberError ? true : false}
                onKeyDown={(event: React.KeyboardEvent) => {onKeyDown && onKeyDown(event)}}
                helperText={cardNumberError ? cardNumberError : ' '}
                onChange={(event) => addPaymentData(event.target.value.replace(/[^0-9]/g, ''), 'number')} 
                onFocus={() => {onFocus && onFocus("number")}} 
                inputProps={{style: { color: "#005ca7" }}}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Payment style={{color: "#cccccc"}}/>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

const styles = ({ palette, breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
});

export default withStyles(styles)(TextFieldCardNumber);
