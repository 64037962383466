import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Typography, Hidden } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';

interface IMobileResolutionDialog extends WithStyles<typeof styles> {

}

const MobileResolutionDialog = (props: IMobileResolutionDialog) => {
    const { classes } = props;
    const [open, setOpen] = React.useState<boolean>(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Hidden smUp>
            <Dialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                classes={{ paper: classes.dialog }}>
                <MuiDialogContent style={{ margin: 0, padding: 0 }}>
                    <div style={{ minHeight: 550, display: "flex", flexDirection: "column", padding: 16, alignItems: "center", justifyContent: "center" }}>
                        <img src={'/mega-horizontal-blanco@4.png'} alt="megacable" className={classes.logo} />
                        <img src={'/descarga-la-app.png'} alt="Descarga la app" className={classes.dowloadImg} />
                        <Typography component={'span'} variant="h5">
                            <Box color="#46F2FF" fontWeight={700} textAlign="center" mb={1}>
                                ¡Pásate al móvil!
                            </Box>
                        </Typography>

                        <Typography component={'span'} variant="body1">
                            <Box color="#fff" fontWeight={500} textAlign="center" width={250} mb={1}>
                                Para una mejor experiencia en esta resolución, lo invitamos a descarga la Megacable App.
                            </Box>
                        </Typography>

                        <Typography component={'span'} variant="body2" >
                            <Box color="#46F2FF" fontWeight={300} textAlign="center" mb={3}>
                                Disponible en Android & iOS
                            </Box>
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row", }}>
                            <a href="https://play.google.com/store/apps/details?id=mx.com.megacable" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                <img src={'/disponible-google-play.png'} alt="GooglePlay" className={classes.googlePlayButton} />
                            </a>
                            <a href="https://apps.apple.com/us/app/megacable-app/id1466002118?ign-itsct=apps_box&ign-itscg=30200" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                <img src={'/descarga-en-app-store.png'} alt="AppStore" className={classes.appStoreButton} />
                            </a>
                        </div>
                    </div>
                </MuiDialogContent>
            </Dialog>
        </Hidden>
    )
}

const styles = (theme: Theme) => createStyles({
    dialog: {
        backgroundImage: "url(/vert-cambiate-movil-bg.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflowX: "hidden",
        padding: 8,
    },

    logo: {
        width: 160,
    },

    dowloadImg: {
        width: 260,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },

    googlePlayButton: {
        width: 120,
        height: 38,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        marginRight: 5,

    },

    appStoreButton: {
        width: 120,
        height: 38,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    }
});

export default withStyles(styles)(MobileResolutionDialog);
