import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import _ from 'lodash';

import Button from './Button';
import Help from './Help';
import Spinner from './Spinner';
import SocialNetworkIcon from './SocialNetworkIcon';
import { Box, Typography, Theme } from '@material-ui/core';

const styles = ({ breakpoints }: Theme) => createStyles({
    mobileBundleContainer: {
        fontFamily: 'Sarabun',
        width: '100%',
        padding: 16,
    },
    green: {
        background: '#26C281'
    },
    red: {
        background: '#CE4861'
    },
    blue: {
        background: '#00CCFF'
    },
    bundleHeader: {
        cursor: 'pointer',
        borderRadius: '14px',
        '-moz-border-radius': '14px',
        '-webkit-border-radius': '14px',
        width: '155px',
        color: 'white',
        textAlign: 'center',
        fontSize: '18px',
        paddingTop: '1px',
        fontFamily: 'Sarabun',
        lineHeight: '2px',
        fontWeight: 'bold',
        /* margin: 'auto', */
        margin: '0px 25px',
        '-webkit-box-shadow': '-1px 5px 15px 1px rgba(0,0,0,0.30)',
        '-moz-box-shadow': '-1px 5px 15px 1px rgba(0,0,0,0.30)',
        'box-shadow': '-1px 5px 15px 1px rgba(0,0,0,0.30)'
    },
    info: {
        cursor: 'pointer',
        background: '#F2F2F2',
        /* background : 'rgba(242, 242, 242, 1)', */
        borderRadius: '14px',
        '-moz-border-radius': '14px',
        '-webkit-border-radius': '14px',
        width: '180px',
        color: '#000000',
        paddingTop: '12px',
        paddingBottom: '13px',
        textAlign: 'center',
        fontFamily: 'Sarabun',
        fontSize: '12px',
        fontWeight: 'normal',
    },
    unlimited: {
        color: '#207ce5',
    },
    row: {
        display: 'flex',
        margin: '20px 0px'
    },
    column: {
        display: 'flex',
        margin: 'auto'
        /* width: '100%' */
    },
    textContainer: {
        width: '100%',
        height: 'auto',
        backgroundColor: '#282828',
        color: 'white',
        opacity: '80%',
        textAlign: 'center',
        borderRadius: '10px',
        padding: "20px",
    },
    infoContainer: {
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: "70px",
        [breakpoints.down('xs')]: {
            marginTop: "20px",
        }
    },
    greenExpiration: {
        color: "#f15a24",
        fontWeight: "bolder"
    },
    redExpiration: {
        color: "#ff1d25",
        fontWeight: "bolder"
    },
    blueExpiration: {
        color: "#93278f",
        fontWeight: "bolder"
    },

    cardInfo: {
        width: 250,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 8,
    },

    cardInfoHeader: {
        width: "70%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        padding: 8,
    },

    cardInfoBody: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 15,
        cursor: 'pointer',
        background: '#F2F2F2',
        padding: 16,
    },

    selectedHeader: {
        border: '5px solid #00CCFF',
        borderBottom: 'none',
    },
    selectedInfo: {
        border: '5px solid #00CCFF',
    },

    container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        paddingLeft: 16,
        paddingRight: 16,
    }
});

interface MobileRecharge {
    id: string;
    precio: number;
    titulo: string;
    subtitulo: string;
    detalles: string;
    vigencia: string;
    color: string;
    servicios: {
        redesSociales: Array<string>,
        minutos: string,
        datos: string,
    },
    colors: Array<string>
}

interface ContractProps extends WithStyles<typeof styles> {
    recharges: Array<MobileRecharge>;
    onPress: any;
    onCancel: any;
}

export const OnlineSupport = ({ classes, recharges, onPress, onCancel }: ContractProps) => {
    const [selectedBundle, setSelectedBundle] = React.useState("");
    const [arrayOfColumns, setArrayOfColumns] = React.useState<any>(null);

    React.useEffect(() => {
        let newArrayOfColumns: Array<any> = [];
        if (recharges && recharges.length > 0) {
            for (let i = 0, j = 0; i < Math.ceil(recharges.length / 2); i++) {
                let arrayTmp: Array<MobileRecharge> = [];
                do {
                    if (recharges[j]) {
                        arrayTmp.push(recharges[j]);
                    }
                    j++;
                } while ((j) % 2 !== 0);
                newArrayOfColumns.push(arrayTmp);
            }
        }
        setArrayOfColumns(newArrayOfColumns);
    }, [recharges])

    React.useEffect(() => {
        if (selectedBundle) {
            let rechargeTmp = _.find(recharges, (o) => { return o.id === selectedBundle })
            if (rechargeTmp && _.has(rechargeTmp, 'precio')) {
                onPress(rechargeTmp.precio);
            }
        }

    }, [selectedBundle])

    const contractDetail = (color: string, expiration: string, title: string, id: string, servicios?: any) => {
        return (
            <div className={classes.cardInfo} onClick={() => setSelectedBundle(id)}>
                <div
                    style={{ backgroundImage: `linear-gradient(90deg, ${color[0]} 0%, ${color[1]} 100%)` }}
                    className={`${classes.cardInfoHeader} ${selectedBundle === id && classes.selectedHeader} `}>
                    <Typography component={'span'} variant="h6">
                        <Box color="#fff" fontWeight={700}> {`Recarga ${title}`} </Box>
                    </Typography>
                </div>
                <div className={`${classes.cardInfoBody} ${selectedBundle === id && classes.selectedInfo}`}>
                    {servicios && servicios.datos ?
                        <Typography component={'span'} variant="body1">
                            <Box>{`INCLUYE: ${servicios.datos}`.toUpperCase()}</Box>
                        </Typography>
                        :
                        null}
                    <Typography component={'span'} variant="body1">
                        <Box>Minutos | SMS | Redes</Box>
                    </Typography>
                    <SocialNetworkIcon />
                    <Typography component={'span'} variant="h4">
                        <Box className={classes.unlimited} fontWeight={700}>ILIMITADOS</Box>
                    </Typography>
                    <Typography component={'span'} variant="body2">
                        <Box>
                            Vigencia <span style={{ color: color[1], fontWeight: 700, fontSize: 16 }} className={`
                                    // ${color === 'green' ? classes.greenExpiration : color === 'red' ? classes.redExpiration : color === 'blue' ? classes.blueExpiration : undefined}`}>
                                {`${expiration} días`}
                            </span>
                        </Box>
                    </Typography>
                </div>
            </div>
        )
    }

    const cancelRecharge = () => {
        onPress("");
        onCancel();
    }

    return (
        <div className={classes.mobileBundleContainer} >
            {
                !arrayOfColumns ? <Spinner /> :

                    arrayOfColumns && arrayOfColumns.length > 0 ?
                        _.map(arrayOfColumns, (objArray, key) => {
                            return objArray && objArray.length < 2 ? (
                                <div className={classes.container}>
                                    {
                                        _.map(objArray, (obj, key) => (
                                            obj.precio > 0 && contractDetail(obj.colors, obj.vigencia, obj.titulo, obj.id, obj.servicios ? obj.servicios : null)
                                        ))
                                    }
                                    <div className={classes.infoContainer}>
                                        <div className={classes.textContainer}>
                                            <Typography component={'span'} variant="h6" style={{ lineHeight: 1 }}>Favor de seleccionar plan de prepago, que desea contratar.</Typography>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <Button type="danger" text="CANCELAR" size="small" onClick={() => cancelRecharge()} style={{ padding: '14px 11px', lineHeight: '13px', marginTop: '13px' }} />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={classes.container}>
                                    {
                                        _.map(objArray, (obj, key) => (
                                            obj.precio > 0 && contractDetail(obj.colors, obj.vigencia, obj.titulo, obj.id, obj.servicios ? obj.servicios : null)
                                        ))
                                    }
                                </div>
                            )
                        })

                        : <Help type={"error"} helpStyle={"outline"} text1="¡Lo sentimos!" text2='No hay información disponible.' additionalStyle={{ margin: '10px 10px' }} />
            }
        </div>
    )
}

export default withStyles(styles)(OnlineSupport);
