import React, { useEffect } from 'react';
import { createStyles } from '@material-ui/styles';
import { Grid, TextField, makeStyles, Box, Typography, FormLabel } from '@material-ui/core';
import Button from '../../../components/Button';
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { IContract } from '../../../storeV2/ducks/types';
import { AlertRedux } from 'megacable-mobile-library';
import * as api from 'megacable-mobile-api';
import _ from 'lodash';
import awsmobile from '../../../aws-exports';
import Spinner from '../../../components/Spinner';
import Help from '../../../components/Help';

import { keycloak } from '../../../App';
import checkToken from '../../../utils/checKToken';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles(() => createStyles({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "white"
    },
    text: {
        // textAlign: 'center'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20px'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '25px'
    },
    label: {
        color: 'inherit',
        paddingLeft: '5px',
        paddingBottom: '10px'
    },
}));

const mapState = (state: any) => ({
    login: state.login,
    currentContract: state.currentContract,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type SendInvoiceProps = PropsFromRedux & {
    onClickCancel: Function;
}

export const SendInvoice = (props: SendInvoiceProps) => {
    const classes = styles();
    const { currentContract, login, /*onClickCancel*/ } = props;
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [nombreSuscriptor, setNombreSuscriptor] = React.useState<string>('');
    const [cellphone, setCellphone] = React.useState<string>('');
    const [cellphone2, setCellphone2] = React.useState<string>('');
    const [error, setError] = React.useState(false);
    const [token, setToken] = React.useState<string>('');
    const [loading, setLoading] = React.useState(false);
    const [helperText, setHelperText] = React.useState<string>('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (cellphone && cellphone2) {
            if (cellphone === cellphone2) {
                setHelperText(" ");
            } else {
                setHelperText("Los números no coinciden");
            }
        }
    }, [cellphone, cellphone2])

    useEffect(() => {
        if (currentContract) {
            setContractSelected(currentContract.currentContract);
        }
    }, [currentContract]);

    useEffect(() => {
        if (contractSelected) {
            if (contractSelected && _.hasIn(contractSelected, ['idCliente'])) {
                setIdCliente(contractSelected.idCliente);
                setNombreSuscriptor(contractSelected.nombre)
            }
        }
    }, [contractSelected])

    useEffect(() => {
        // if (login && _.hasIn(login, ['auth'])) {
        // const auth = login.auth;
        // if (_.hasIn(auth, ['token'])) {
        if (keycloak && keycloak.token) {
            setToken(keycloak.token);
        }
        // }
        // }
        return () => { }
    }, [login]);

    function sendBill() {
        if (cellphone && cellphone2) {
            if (cellphone.length === 10 && cellphone2.length === 10) {
                if (cellphone === cellphone2) {
                    setLoading(true);
                    checkToken(() => {
                        apiV2.getApiV2SendSMSEdoCuenta(idCliente, cellphone, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(response => {
                                if (response && response.data && _.has(response, ['data', "resultado"])) {
                                    if (response.data.resultado === "true") {
                                        dispatch(AlertRedux.showAlert("¡Estimado Usuario! Se ha enviado un SMS al " + cellphone + " con el estado de cuenta " + nombreSuscriptor + " " + idCliente + ".", "Enviar estado de cuenta"))
                                        setError(false);
                                    } else if (response.data.resultado === "false") {
                                        dispatch(AlertRedux.showAlert("No fue posible enviar tu estado de cuenta en este momento, intenta más tarde.", "Enviar estado de cuenta"))
                                    }
                                }
                                setLoading(false);
                            })
                            .catch(error => {
                                error = _.get(error, 'response', error);
                                if (error && _.has(error, "data.message")) {
                                    dispatch(AlertRedux.showAlert(error.data.message, "Enviar estado de cuenta"))
                                } else {
                                    dispatch(AlertRedux.showAlert("No fue posible enviar tu estado de cuenta en este momento, intenta más tarde.", "Enviar estado de cuenta"))
                                }
                                setLoading(false);
                            })
                    })
                } else {
                    setError(true);
                    setHelperText("Los números no coinciden");
                }
            } else {
                setError(true);
                setHelperText("El número celular debe tener 10 digítos.");
            }
        } else {
            setError(true);
            setHelperText("Ambos campos son requeridos");
        }
    }

    return (
        <div className={classes.container}>
            {
                loading ? <Spinner /> :
                    <>
                        <Help type={"attention"} helpStyle={"outline"} text1="¡Atención!" text2='Para enviar su estado de cuenta por SMS, digite y confirme su número celular y de click en ENVIAR' additionalStyle={{ margin: "10px 10px" }} />
                        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 24 }}>
                                <img style={{ maxHeight: '140px', maxWidth: '140px', marginBottom: 16 }} alt={'Enviar estado de cuenta'} src={'../icon-send-sms@4.png'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ padding: 16 }}>
                                <Typography component={'span'} variant="h4" color="primary">
                                    <Box fontWeight={700}>Enviar estado de cuenta por SMS</Box>
                                </Typography>
                                <p className={classes.text}>¿Desea enviar por SMS el estado de cuenta de su contrato {idCliente ? idCliente : ""}?</p>
                                <p className={classes.text}>Digite y confirme su número celular, enseguida, de click en enviar.</p>
                                <FormLabel className={classes.label} component="legend">Número celular</FormLabel>
                                <TextField
                                    value={cellphone}
                                    placeholder="Ingrese los 10 dígitos de su número celular"
                                    helperText={helperText ? helperText : ''}
                                    error={error ? true : false}
                                    variant="outlined"
                                    style={{ marginBottom: 24 }}
                                    fullWidth
                                    onChange={(e: any) => setCellphone(e.target.value.replace(/[^0-9]/g, ''))}
                                    inputProps={{ maxLength: 10 }} />
                                <FormLabel className={classes.label} component="legend">Confirme número celular</FormLabel>
                                <TextField
                                    value={cellphone2}
                                    placeholder="Confirme los mismos 10 dígitos de su número celular"
                                    helperText={helperText ? helperText : ''}
                                    error={error ? true : false}
                                    variant="outlined"
                                    style={{ marginBottom: 24 }}
                                    fullWidth
                                    onChange={(e: any) => setCellphone2(e.target.value.replace(/[^0-9]/g, ''))}
                                    inputProps={{ maxLength: 10 }} />
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button type="primary" text="Enviar" size="large" onClick={() => { sendBill() }} />
                                </div>
                            </Grid>
                        </Grid>
                    </>
            }
        </div >
    )
}

export default connector(SendInvoice);