import * as api from 'megacable-mobile-api';
import awsmobile from '../aws-exports';
// import store from '../storeV2';
import getIdCliente from '../utils/getIdCliente';
// import { keycloak } from '../App';
import _ from 'lodash';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

// const padLeft: any = (value: string, length: number) => {
//     return (value.toString().length < length) ? padLeft("0" + value, length) :
//         value;
// }

const log = (idAction: string, duration: number, idCliente: undefined | string = undefined) => {
    if (idAction) {
        // const state = store.getState();
        // if (!idCliente) {
        //     idCliente = _.get(state, 'login.auth.idCliente', "");
        // }

        // if (keycloak && keycloak.userInfo) {
        //     const user: any = keycloak.userInfo;
        //     idCliente = padLeft(user.sucursal, 3) + padLeft(user.suscriptor, 7)
        // }

        idCliente = getIdCliente();

        // idCliente = "0440445095"
        apiV2.getApiV2AnalyticsWeb(idCliente, idAction, duration)
            .then(response => {
            })
            .catch(err => {
                err = _.get(err, 'response', err);
            })
    }
}

export default log;

interface NavAction {
    [key: string]: any;
}

const didFocus: NavAction = {};
const didBlur: NavAction = {};
const analyticsStartTime: NavAction = {};

export const screenAnalyticsMount = (idAction: string, navigation: any) => {
    if (navigation) {
        didFocus[idAction] = navigation.addListener('didFocus', () => {
            startAnalytics(idAction);
        })
        didBlur[idAction] = navigation.addListener('willBlur', () => {
            logAnalytics(idAction);
        })
    }
}

export const screenAnalyticsUnmount = (idAction: string) => {
    if (didFocus[idAction]) didFocus[idAction].remove();
    if (didBlur[idAction]) didBlur[idAction].remove();
}

export const startAnalytics = (idAction: string) => {
    analyticsStartTime[idAction] = new Date();
}

export const logAnalytics = (idAction: string) => {
    if (analyticsStartTime[idAction]) {
        const rTime = (new Date().getTime() - analyticsStartTime[idAction]);
        log(idAction, rTime);
    }
}