import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '../../../components/Typography';
import { Button } from '@material-ui/core';
import { keycloak } from '../../../App';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../../aws-exports';
import checkToken from '../../../utils/checKToken';
import _ from 'lodash';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

interface IMLBActivationProps {
    idCliente: string;
}

const MLBActivation = (props: IMLBActivationProps) => {
    const { idCliente } = props;
    const [mlbLink, setMlbLink] = React.useState<string>('');
    const [mlbCode, setMlbCode] = React.useState<string>('');
    const [generateCode, setGenerateCode] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const classes = styles();

    const getToken = () => {
        if (idCliente) {
            let objData = {
                sucursal: idCliente.substring(0, 3),
                suscriptor: idCliente.substring(3, idCliente.length)
            }
            if (objData) {
                checkToken(() => {
                    setLoading(true);
                    apiV2.postApiV2GetTokenServiceMlb(objData, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                        .then(response => {
                            if (response && _.has(response, ['data', "status"])) {
                                if (response.data.status === 'true' && response.data.code) {
                                    setMlbLink(response.data.urlToken);
                                    setMlbCode(response.data.code);
                                }
                            }
                            setLoading(false);
                        })
                        .catch(error => {
                            error = _.get(error, 'response', error);
                            setLoading(false);
                        })
                })
            }
        }
    }

    const openScreen = async () => {
        await getToken();
        setGenerateCode(true);
    }

    const closeScreen = () => {
        setGenerateCode(false);
        setMlbCode('');
    }

    return (
        <div className={classes.root}>
            <div className={classes.imageBackground}>
                {
                    !generateCode && _.isEmpty(mlbCode) ?
                        <>
                            <img src="../mlb-players.png" className={classes.players} alt="body" />
                            <div className={classes.btnContainer}>
                                <img
                                    src="../mlb-da-clic-aqui.png"
                                    className={classes.mlbButton}
                                    alt="button"
                                    onClick={() => openScreen()}
                                />
                            </div>
                        </>
                        :
                        generateCode && !_.isEmpty(mlbCode) ?
                            <div className={classes.textContainer}>
                                <img src={"../mlb-extra-innings.png"} alt="extra" />
                                <Typography style={{ color: '#0053d1', fontSize: '18px', fontWeight: 'lighter', margin: '10px 0px 10px 0px' }} type="p" text={`De click en Aceptar para visitar el sitio e iniciar el proceso,`} />
                                <Typography style={{ color: '#0053d1', fontSize: '18px', fontWeight: 'lighter', margin: '10px 0px 10px 0px' }} type="p" text={`Cancelar para permanecer aquí.`} />
                                <Typography style={{ color: '#0053d1', fontSize: '18px', fontWeight: 'lighter', margin: '10px 0px 10px 0px' }} type="p" text={`Código de activación`} />
                                <Typography style={{ color: '#0053d1', fontSize: '22px', margin: '10px 0px 20px 0px' }} type="h3" text={mlbCode} />
                                <Button className={classes.mlbButton2}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(mlbLink);
                                    }}>
                                    Aceptar
                                </Button>
                                <Button className={classes.mlbButton2} onClick={() => closeScreen()}>
                                    Cancelar
                                </Button>
                            </div>
                            : loading ?
                                <div className={classes.textContainer}>
                                    <Typography style={{ color: '#0053d1', fontSize: '30px', fontWeight: 'lighter', margin: '10px 0px 10px 0px' }} type="p" text={`Cargando...`} />
                                </div>
                                :
                                <div className={classes.textContainer}>
                                    <img src={"../mlb-extra-innings.png"} alt="extra" />
                                    <Typography style={{ color: '#0053d1', fontSize: '18px', fontWeight: 'lighter', margin: '10px 0px 10px 0px' }} type="p" text={`Por el momento no está disponible el código de activación. Intente de nuevo más tarde.`} />
                                    <Button className={classes.mlbButton2} onClick={() => closeScreen()}>
                                        Cancelar
                                    </Button>
                                </div>
                }
            </div>
        </div>
    )
}

const styles = makeStyles(({ breakpoints }: any) => createStyles({
    root: {
        width: "100%",
    },
    imageBackground: {
        width: '100%',
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundImage: 'url(mlb-bg-1.jpg)',
        backgroundPositionX: "center",
        backgroundPositionY: "10%",
    },
    mlbButton: {
        width: "200px",
        cursor: 'pointer',
        [breakpoints.down('sm')]: {
            width: "150px",
        },
    },
    mlbButton2: {
        backgroundColor: '#0053d1',
        width: "200px",
        border: 'none',
        fontFamily: "Sarabun",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#fff',
        textAlign: 'center',
        textDecoration: 'none',
        margin: '5px 5px',
        padding: '10px',
        borderRadius: '5px',
        direction: 'ltr',
        '&:hover': {
            backgroundColor: '#0053d1',
            cursor: 'pointer',
        }
    },
    players: {
        width: "100%",
        maxHeight: "400px"
    },
    btnContainer: {
        position: "absolute",
        marginTop: "15%",
        marginRight: "13%",
        [breakpoints.down('sm')]: {
            marginTop: "60%",
            marginRight: "40%",
        },
    },
    textContainer: {
        alignSelf: 'center',
        textAlign: 'center'
    },
    extra: {
        height: "50px",
        width: "50px"
    }
}));

export default MLBActivation;
