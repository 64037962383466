import React, { useEffect } from 'react';
import { createStyles } from '@material-ui/styles';
import { Grid, Hidden, makeStyles, Paper, Theme } from '@material-ui/core';
import ComponentHeaderSecondary from '../../components/ComponentHeaderSecondary';
import Typography from '../../components/Typography';
import Help from '../../components/Help';
import Tickets from '../tickets/Tickets';
import IsValidToken from '../../utils/IsValidToken';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Slider from '../../components/Slider';
import { Dispatch } from "redux";
import { connect, ConnectedProps, useSelector, useDispatch } from "react-redux";
import { AlertRedux, FaqRedux, } from 'megacable-mobile-library';
import { IContract } from '../../storeV2/ducks/types';
import analitycs, { startAnalytics, logAnalytics } from '../../analitycsV2';
import awsmobile from '../../aws-exports';
import * as api from 'megacable-mobile-api';
import _ from 'lodash';
import CustomHeader from './components/CustomHeader';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import ChannelList from '../channelList/ChannelList';
import UpdateSignal from '../updateSignal/UpdateSignal';
import OnlineSupportAlt from '../../components/OnlineSupportAlt';
import Diagnostic from '../diagnostic/Diagnostic';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

const styles = makeStyles((theme: Theme) => createStyles({

    animated: {
        position: 'relative',
        left: '5%',
        animationName: '$fadein',
        animationDuration: '.5s',
        animationFillMode: 'forwards',
        transition: 'All 500ms ease-in-out',
        Webkittransition: 'All 500ms ease-in-out',
        MozTransition: 'All 500ms ease-in-out',
        OTransition: 'All 500ms ease-in-out',
    },

    container: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column"
        },
    },

    menuContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 5,
    },

    answerContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 7,
    },

}));

type PropsFromRedux = ConnectedProps<typeof connector>

type TechnicalSupportProps = PropsFromRedux & {

}

interface Option {
    name: string;
    icon?: string;
    iconWhite?: string;
    onClick?: Function;
    extra?: any;
}

interface questionProps {
    answer?: string;
    question?: string;
}

export const TechnicalSupport = (props: TechnicalSupportProps) => {
    const classes = styles();
    const { banners, currentContract, login } = props;
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const questions = useSelector((store: any) => store.faq.faqs);
    const [information, setInformation] = React.useState([]);
    const [questionSelected, setQuestionSelected] = React.useState<Option | null>(null)
    const [titleInfo, setTitleInfo] = React.useState("");
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isTVQuestions, setIsTVQuestions] = React.useState<boolean>(false);
    const [viewChannels, setViewChannels] = React.useState<boolean>(false);
    const [isInternetQuestions, setIsInternetQuestions] = React.useState<boolean>(false);
    const [viewUpdateSignal, setViewUpdateSignal] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        startAnalytics('sup_id');
        return () => {
            logAnalytics('sup_id')
        }
    }, []);

    useEffect(() => {
        if (login && _.hasIn(login, ['auth'])) {
            const auth = login.auth;
            if (_.hasIn(auth, ['token'])) {
                setToken(auth.token);
            }
        }
        return () => { }
    }, [login]);

    useEffect(() => {
        if (currentContract) {
            setContractSelected(currentContract.currentContract);
        }
    }, [currentContract]);

    useEffect(() => {
        if (contractSelected) {
            if (_.hasIn(contractSelected, ['idCliente'])) {
                setIdCliente(contractSelected.idCliente);
            }
        }
    }, [contractSelected])

    useEffect(() => {
        if (questions) {
            doArrayInformation(questions['video']);
            setTitleInfo('TELEVISIÓN');
        }
    }, [questions])

    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    useEffect(() => {
        dispatch(FaqRedux.getFaqs());
    }, [dispatch])

    function getInfo(a: any) {
        setQuestionSelected(a)
    }

    useEffect(() => {
        diqueHasError();
        return () => { }
    }, [idCliente, token])

    const diqueHasError = () => {
        const idClienteTmp = _.cloneDeep(idCliente);
        const tokenTmp = _.cloneDeep(token);

        if (idClienteTmp && tokenTmp) {
            checkToken(() => {
                const headers = { "Authorization": `Bearer ${keycloak.token}` };
                apiV2.getApiV2Consultar(idClienteTmp, { headers }).then(response => {
                    if (response.data.mensaje && response.data.resultado !== 0) {
                        dispatch(AlertRedux.showAlert(response.data.mensaje, "Estimado(a) suscriptor"));
                    }
                }).catch(err => {
                    err = _.get(err, 'response', err);
                    if (_.has(err, 'data') && err.data === "invalid token") {
                        IsValidToken(err.data);
                    }
                })
            })
        }
    }

    function doArrayInformation(info: Array<questionProps>) {
        var array: any = []
        info.forEach((e: questionProps) => {
            array.push({
                name: e.question ? e.question : "",
                extra: e.answer ? e.answer : '',
                onClick: (a: any) => getInfo(a)

            })
        })
        setQuestionSelected(null);
        setInformation(array);
    }

    function changeViewInfo(a: Option) {
        if (questions && !_.isEmpty(questions)) {
            switch (a.name) {
                case 'TELEVISIÓN':
                    analitycs('sup_tel_faqs_id', 1);
                    doArrayInformation(questions['video'])
                    setTitleInfo('TELEVISIÓN')
                    break;
                case 'INTERNET':
                    analitycs('sup_int_faqs_id', 1);
                    doArrayInformation(questions['internet'])
                    setTitleInfo('INTERNET')
                    break;
                case 'TELÉFONO':
                    analitycs('sup_phone_faqs_id', 1);
                    doArrayInformation(questions['telefonia'])
                    setTitleInfo('TELÉFONO')
                    break;
                case 'TELEFONÍA MÓVIL':
                    analitycs('sup_mov_faqs_id', 1);
                    doArrayInformation(questions['45g'])
                    setTitleInfo('TELEFONÍA MÓVIL')
                    break;
                // case 'DIAGNÓSTICO TÉCNICO':
                //     setTitleInfo('DIAGNÓSTICO TÉCNICO');
                //     break;
                // case 'ÓRDENES DE SERVICIO':
                //     setTitleInfo('ÓRDENES DE SERVICIO');
                //     break;
                default:
                    break;
            }
        }
    };

    const options: Array<Option> = [
        { name: "TELEVISIÓN", icon: "../tv-icon-color.png", iconWhite: "../tv-icon.png", onClick: (a: Option) => changeViewInfo(a) },
        { name: "INTERNET", icon: "../wifi-icon-color.png", iconWhite: "../wifi-icon.png", onClick: (a: Option) => changeViewInfo(a) },
        { name: "TELÉFONO", icon: "../phone-icon-color.png", iconWhite: "../phone-icon.png", onClick: (a: Option) => changeViewInfo(a) },
        { name: "TELEFONÍA MÓVIL", icon: "../telefonia-movil.png", iconWhite: "../telefonia-movil-white.png", onClick: (a: Option) => changeViewInfo(a) },
        // { name: "DIAGNÓSTICO TÉCNICO", icon: "../diagnostico-tecnico.png", iconWhite: "../diagnostico-tecnico-selected.png", onClick: (a: Option) => changeViewInfo(a) },
        // { name: "ÓRDENES DE SERVICIO", icon: "../tickets-icon.png", iconWhite: "../tickets-icon-white.png", onClick: (a: Option) => changeViewInfo(a) },
    ];

    const optionsTV: Array<any> = [
        { name: "Canales disponibles en tu localidad", onClick: (setViewChannels) },
        { name: "Preguntas frecuentes", onClick: (setIsTVQuestions) }
    ];

    const optionsInternet: Array<any> = [
        { name: "Actualizar señal", onClick: (setViewUpdateSignal) },
        { name: "Preguntas frecuentes", onClick: (setIsInternetQuestions) }
    ];

    interface IQuestionViewProps {

    };

    const QuestionView: React.FunctionComponent<IQuestionViewProps> = (props) => {
        return (
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.animated} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', }}>
                    {questionSelected && <Typography type="h3" text={questionSelected.name} />}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '8px 35px 35px 35px', textAlign: 'justify' }}>
                    {questionSelected && <Typography type="p" text={questionSelected.extra} />}
                </Grid>
            </Grid>
        )
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", width: '100%', height: '100%', backgroundColor: "white" }}>
            <div>
                {bannerList && !_.isEmpty(bannerList) ? <Slider size='large' images={bannerList} /> : null}
            </div>
            {
                contractSelected ? (
                    <>
                        <Hidden mdUp>
                            <div className={classes.container}>
                                <Paper className={classes.menuContainer} elevation={3}>
                                    <div>
                                        <CustomHeader
                                            type="H2"
                                            title="Soporte técnico"
                                            contract={contractSelected}
                                        />
                                        {
                                            viewChannels ?
                                                <>
                                                    <div className={classes.container}>
                                                        <div className={classes.answerContainer}>
                                                            <ComponentHeaderSecondary
                                                                type='H2'
                                                                title={"CANALES DISPONIBLES EN TU LOCALIDAD"}
                                                                button={{ type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setViewChannels(false) }} />
                                                        </div>
                                                    </div>
                                                    <ChannelList
                                                        idCliente={idCliente}
                                                        token={keycloak.token}
                                                    />
                                                </>
                                                :
                                                isOpen ?
                                                    <>
                                                        <div className={classes.answerContainer}>
                                                            {
                                                                // titleInfo === 'ÓRDENES DE SERVICIO' ?
                                                                //     <Tickets button={{ onClick: () => setIsOpen(false) }} />
                                                                //     :
                                                                // titleInfo === 'DIAGNÓSTICO TÉCNICO' ? <Diagnostic /> :
                                                                titleInfo === 'TELEVISIÓN' ?
                                                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                                                        <ComponentHeaderSecondary
                                                                            type='H2'
                                                                            title={titleInfo}
                                                                            button={
                                                                                (questionSelected && !_.isEmpty(questionSelected))
                                                                                    ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setQuestionSelected(null) }
                                                                                    : isTVQuestions ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setIsTVQuestions(false) }
                                                                                        :
                                                                                        { onClick: () => setIsOpen(false) }
                                                                            } />
                                                                        {
                                                                            isTVQuestions ?
                                                                                <div style={{ paddingLeft: 5 }}>
                                                                                    {
                                                                                        !_.isEmpty(information) ? (
                                                                                            (!questionSelected && _.isEmpty(questionSelected))
                                                                                                ? <div><OnlineSupportAlt options={information} /></div>
                                                                                                : <QuestionView />
                                                                                        ) : <div />
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div><OnlineSupportAlt options={optionsTV} /></div>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    titleInfo === 'INTERNET' ?
                                                                        viewUpdateSignal ?
                                                                            <UpdateSignal
                                                                                showHeader={true}
                                                                                button={
                                                                                    { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setViewUpdateSignal(false) }
                                                                                } />
                                                                            :
                                                                            <div style={{ display: "flex", flexDirection: "column", }}>
                                                                                <ComponentHeaderSecondary
                                                                                    type='H2'
                                                                                    title={titleInfo}
                                                                                    button={
                                                                                        (questionSelected && !_.isEmpty(questionSelected))
                                                                                            ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setQuestionSelected(null) }
                                                                                            : isInternetQuestions ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setIsInternetQuestions(false) }
                                                                                                :
                                                                                                { onClick: () => setIsOpen(false) }
                                                                                    } />
                                                                                {
                                                                                    isInternetQuestions ?
                                                                                        <div style={{ paddingLeft: 5 }}>
                                                                                            {
                                                                                                !_.isEmpty(information) ? (
                                                                                                    (!questionSelected && _.isEmpty(questionSelected))
                                                                                                        ? <div><OnlineSupportAlt options={information} /></div>
                                                                                                        : <QuestionView />
                                                                                                ) : <div />
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            <div><OnlineSupportAlt options={optionsInternet} /></div>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        :
                                                                        (
                                                                            <div style={{ display: "flex", flexDirection: "column", }}>
                                                                                <ComponentHeaderSecondary
                                                                                    type='H2'
                                                                                    title={titleInfo}
                                                                                    button={
                                                                                        (questionSelected && !_.isEmpty(questionSelected))
                                                                                            ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setQuestionSelected(null) }
                                                                                            : { onClick: () => setIsOpen(false) }
                                                                                    } />
                                                                                <div style={{ paddingLeft: 5 }}>
                                                                                    {
                                                                                        !_.isEmpty(information) ? (
                                                                                            (!questionSelected && _.isEmpty(questionSelected))
                                                                                                ? <div><OnlineSupportAlt options={information} /></div>
                                                                                                : <QuestionView />
                                                                                        ) : <div />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <OnlineSupportAlt onClick={() => setIsOpen(true)} options={options} autoSelection />
                                        }
                                    </div>
                                </Paper>
                            </div>
                        </Hidden>
                        <Hidden smDown>
                            {
                                viewChannels ?
                                    <>
                                        <div className={classes.container}>
                                            <Paper className={classes.menuContainer} elevation={3}>
                                                <div>
                                                    <CustomHeader type="H2" title="Soporte técnico" contract={contractSelected} />
                                                </div>
                                            </Paper>
                                            <div className={classes.answerContainer}>
                                                <ComponentHeaderSecondary
                                                    type='H2'
                                                    title={"CANALES DISPONIBLES EN TU LOCALIDAD"}
                                                    button={{ type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setViewChannels(false) }} />
                                            </div>
                                        </div>
                                        <ChannelList idCliente={idCliente} token={keycloak.token} />
                                    </>
                                    :
                                    <div className={classes.container}>
                                        <Paper className={classes.menuContainer} elevation={3}>
                                            <div>
                                                <CustomHeader type="H2" title="Soporte técnico" contract={contractSelected} />
                                                <OnlineSupportAlt options={options} autoSelection />
                                            </div>
                                        </Paper>
                                        <div className={classes.answerContainer}>
                                            {
                                                // titleInfo === 'ÓRDENES DE SERVICIO' ? <Tickets /> :
                                                // titleInfo === 'DIAGNÓSTICO TÉCNICO' ? <Diagnostic /> :
                                                    titleInfo === 'TELEVISIÓN' ?
                                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                                            <ComponentHeaderSecondary
                                                                type='H2'
                                                                title={titleInfo}
                                                                button={
                                                                    (questionSelected && !_.isEmpty(questionSelected))
                                                                        ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setQuestionSelected(null) }
                                                                        : isTVQuestions ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setIsTVQuestions(false) }
                                                                            :
                                                                            undefined
                                                                } />
                                                            {
                                                                isTVQuestions ?
                                                                    <div style={{ paddingLeft: 5 }}>
                                                                        {
                                                                            !_.isEmpty(information) ? (
                                                                                (!questionSelected && _.isEmpty(questionSelected))
                                                                                    ? <div><OnlineSupportAlt options={information} /></div>
                                                                                    : <QuestionView />
                                                                            ) : <div />
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div><OnlineSupportAlt options={optionsTV} /></div>
                                                                    </>
                                                            }
                                                        </div>
                                                        :
                                                        titleInfo === 'INTERNET' ?
                                                            viewUpdateSignal ?
                                                                <>
                                                                    <div className={classes.container}>
                                                                        <div className={classes.answerContainer}>
                                                                            <ComponentHeaderSecondary
                                                                                type='H2'
                                                                                title={"ACTUALIZAR SEÑAL"}
                                                                                button={{ type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setViewUpdateSignal(false) }} />
                                                                        </div>
                                                                    </div>
                                                                    <UpdateSignal showHeader={true} />
                                                                </>
                                                                :
                                                                <div style={{ display: "flex", flexDirection: "column", }}>
                                                                    <ComponentHeaderSecondary
                                                                        type='H2'
                                                                        title={titleInfo}
                                                                        button={
                                                                            (questionSelected && !_.isEmpty(questionSelected))
                                                                                ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setQuestionSelected(null) }
                                                                                : isInternetQuestions ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setIsInternetQuestions(false) }
                                                                                    :
                                                                                    undefined
                                                                        } />
                                                                    {
                                                                        isInternetQuestions ?
                                                                            <div style={{ paddingLeft: 5 }}>
                                                                                {
                                                                                    !_.isEmpty(information) ? (
                                                                                        (!questionSelected && _.isEmpty(questionSelected))
                                                                                            ? <div><OnlineSupportAlt options={information} /></div>
                                                                                            : <QuestionView />
                                                                                    ) : <div />
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div><OnlineSupportAlt options={optionsInternet} /></div>
                                                                            </>
                                                                    }
                                                                </div>
                                                            :
                                                            (
                                                                <div style={{ display: "flex", flexDirection: "column", }}>
                                                                    <ComponentHeaderSecondary
                                                                        type='H2'
                                                                        title={titleInfo}
                                                                        button={
                                                                            (questionSelected && !_.isEmpty(questionSelected))
                                                                                ? { type: "light", text: "REGRESAR", size: "slim", icon: faArrowLeft, onClick: () => setQuestionSelected(null) }
                                                                                : undefined
                                                                        } />
                                                                    <div style={{ paddingLeft: 5 }}>
                                                                        {
                                                                            !_.isEmpty(information) ? (
                                                                                (!questionSelected && _.isEmpty(questionSelected))
                                                                                    ? <div><OnlineSupportAlt options={information} /></div>
                                                                                    : <QuestionView />
                                                                            ) : <div />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                            }
                                        </div>
                                    </div>
                            }
                        </Hidden>
                    </>
                ) : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
            }
        </div>
    )
}

const mapState = (state: any) => ({
    banners: state.banners,
    currentContract: state.currentContract,
    login: state.login,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({

})

const connector = connect(mapState, mapDispatch)

export default (connector(TechnicalSupport));
