import React, { /*useState,*/ useEffect } from 'react';
import { createStyles, withStyles } from '@material-ui/styles';
import { Grid, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import GoBack from '../../components/GoBack';
import Help from '../../components/Help';
import MobileBundle from '../../components/MobileBundle';
import MobileTopUpHeader from '../../components/MobileTopUpHeader';
// import CreditCardsList from './components/CreditCardsList';
import CreditCard from '../../components/CreditCard';
import Expansion from '../../components/Expansion';
import FormCardPayment from '../../components/FormCardPayment';
import ButtonPayment from '../../components/ButtonPayment';
import Spinner from '../../components/Spinner';
import { ConsultarTarjetaRequest } from 'megacable-mobile-api/models';
import * as api from 'megacable-mobile-api';
import awsmobile from '../../aws-exports';
import _ from 'lodash';

import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);
interface PaymentData {
    number: string,
    expirationDate: string,
    cvv: string,
    saveCard: boolean,
    isValid: boolean
}
interface PaymentErrors {
    number: string,
    expirationDate: string,
    cvv: string,
}
interface ICardInfo {
    resultado: boolean,
    tarjeta: string,
    tarjetaDecrypted: string
}

// const emptyCreditCard = {
//     number: "",
//     expirationDate: "",
//     cvv: "",
//     saveCard: true,
//     isValid: false
// }
interface MobileRechargesProps {
    classes: any;
    onPressBack: any;
    selectedPhone: string;
    paymentData: PaymentData;
    paymentErrors: PaymentErrors;
    amountPlanSelected: number;
    payWithMyCreditCard: (event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => void;
    updateMyCreditCard: (event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => void;
    handleSaveCard: (value: boolean) => void;
    addAmountPlanSelected: (value: number) => void;
    addPaymentData: (value: string, typeData: "number" | "date" | "cvv") => void;
    goToPayment: () => void;
}

const MobileRecharges = (props: MobileRechargesProps) => {
    const { classes, onPressBack, selectedPhone, amountPlanSelected, updateMyCreditCard, payWithMyCreditCard, handleSaveCard, addAmountPlanSelected, paymentData, paymentErrors, addPaymentData, goToPayment } = props;
    const mobileReducer = useSelector((state: any) => state.mobile);
    // const [loading, setLoading] = React.useState<boolean>(false);
    const [creditCard, setCreditCard] = React.useState<ICardInfo>({ resultado: false, tarjeta: "", tarjetaDecrypted: "" });
    const token = useSelector((state: any) => state.login.auth.token);
    const sucursal = useSelector((state: any) => state.currentContract.currentContract.sucursal);
    const suscriptor = useSelector((state: any) => state.currentContract.currentContract.suscriptor.substring(3, 10));

    useEffect(() => {
        // setCreditCard({resultado: true, tarjeta: "*****5555", tarjetaDecrypted: "5555555555555555"})
        const getCreditCard = () => {
            const consultarTarjetaRequest: ConsultarTarjetaRequest = {
                sucursal,
                suscriptor,
            }
            checkToken(() => {
                apiV2.postApiV2ConsultarTarjeta(consultarTarjetaRequest, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                    .then(response => {
                        // setLoading(false);
                        if (_.hasIn(response, ["data"]) && response.data) {
                            setCreditCard(response.data)
                        }
                    })
                    .catch(error => {
                        // setLoading(false);
                    })
            })
        }
        getCreditCard();
        return () => { }
    }, [sucursal, suscriptor, token])

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            goToPayment();
        }
    }


    const newCreditCardForm = () => {
        return (
            <div style={{ paddingTop: 16, paddingBottom: 16 }}>
                <FormCardPayment
                    addPaymentData={addPaymentData}
                    onKeyDown={handleKeyDown}
                    paymentData={paymentData}
                    paymentErrors={paymentErrors}
                    saveCard={handleSaveCard} />
                <div className={classes.secureTrustImgContainer}>
                    <a href="https://sealserver.trustwave.com/cert.php?customerId=d0328ea2063448ebb51870d9716b80aa&size=105x54&style=invert" target="_blank" rel="noopener noreferrer">
                        <img src="/securetrust.png" alt="Secure trust" />
                    </a>
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <Help type={"attention"} helpStyle={"outline"} text1="¡Lo sentimos!" text2='Por el momento no aceptamos American Express' additionalStyle={{ margin: "10px 10px" }} />
                </div>
                <ButtonPayment onClick={goToPayment} />
            </div>
        )
    }

    return (
        mobileReducer.loadingRecargas ? <Spinner /> :
            mobileReducer && mobileReducer.recargas && mobileReducer.recargas.length > 0 ? (
                <div>
                    <div style={{ backgroundColor: "#002A4F" }}>
                        <GoBack onClick={() => onPressBack({})} />
                    </div>
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.rowCreditCards}>
                            {
                                creditCard.tarjeta ? (
                                    <>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography component={'span'} color="primary" variant="body1"><Box mb={1} style={{ paddingLeft: 30 }}>TARJETA GUARDADA</Box></Typography>
                                            <CreditCard cardInfo={creditCard} onClick={payWithMyCreditCard} onClickUpdate={updateMyCreditCard} />
                                        </div>
                                        <Expansion title="PAGAR CON OTRA TARJETA" content={newCreditCardForm()} />
                                    </>
                                ) : newCreditCardForm()
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.rechargesContainer}>
                            <>
                                <MobileTopUpHeader
                                    phone={selectedPhone}
                                    amount={`${amountPlanSelected}`} />
                                <MobileBundle
                                    recharges={mobileReducer.recargas}
                                    onPress={(e: number) => addAmountPlanSelected(e)} onCancel={() => onPressBack({})} />
                            </>
                        </Grid>
                    </Grid>
                </div>
            ) : <div className={classes.divError}>
                <GoBack onClick={() => onPressBack({})} />
                <Help type={"error"} helpStyle={"outline"} text1="¡Lo sentimos!" text2='No hay información disponible.' additionalStyle={{ margin: '10px 10px 10px 0px  ' }} />
            </div>
    )
}

const styles = ({ breakpoints }: Theme) => createStyles({
    rechargesContainer: {
        backgroundImage: 'url(/movil-background.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        background: 'black',
        backgroundSize: 'cover',
        order: 2,
        [breakpoints.down('md')]: {
            marginBottom: 24,
        },
        [breakpoints.down('sm')]: {
            order: 1
        }
    },
    mobileDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '50px 0px'
    },
    rowCreditCards: {
        // height: 'calc(100% - 50px)',
        display: "flex",
        flexDirection: "column",
        order: 1,
        // overflowY: 'scroll',
        // '&::-webkit-scrollbar-track': {
        //     background: '#CCCCCC'
        // },
        // '&::-webkit-scrollbar-thumb': {
        //     background: '#0078D0'
        // },
        // '&::-webkit-scrollbar': {
        //     width: 'auto'
        // },
        [breakpoints.down('sm')]: {
            order: 2
        }
    },
    divError: {
        width: '100%',
        margin: '0px 0px 0px 8px'
    },
    divLoading: {
        margin: '0px 0px 0px 8px'
    },
    secureTrustImgContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0 0 3% 0'
    }
});


export default withStyles(styles)(MobileRecharges);