import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import Button from './Button';
import { Box, Typography } from '@material-ui/core';
// import Typography from './Typography';

const styles = ({ palette, breakpoints }: any) => createStyles({
    contractContainer: {
        width: '100%',
        display: 'flex',
        fontFamily: 'Sarabun',
        height: '105px',
    },
    contractContainerBorder: {
        borderBottom: '1px #d4d4d4 solid',
    },
    firstContractContainer: {
        borderTop: '1px #d4d4d4 solid'
    },
    div1: {
        position: 'relative',
        textAlign: 'center',
    },
    div2: {
        position: 'relative',
        whiteSpace: 'pre-wrap',
        width: 'inherit'
    },
    div3: {
        position: 'relative',
        margin: '0px 25px 0px 10px'
    },
    div4: {
        position: 'relative',
        margin: '0px 25px 0px 10px'
    },
    contractNumber: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '17px',
        color: 'white',
    },

    circleBackground1: {
        backgroundColor: '#0797FD'
    },
    circleBackground2: {
        backgroundColor: '#15578B'
    },
    circleBackground3: {
        backgroundColor: '#00406D'
    },
    planContainer: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex'
    },
    leftContainer: {
        display: 'flex',
        direction: 'rtl'
    },
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 8,
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        // [breakpoints.down('xs')]:{}
        /* display: 'flex' */
    },

    circle: {
        width: '55px',
        height: '55px',
        borderRadius: '50%',
        color: '#fff',
        lineHeight: '55px',
        textAlign: 'center',
        alignSelf: "center",
        marginRight: 24,
    },
});

interface Mobile {
    ani: string;
    number: string;
}

interface MobileListProps extends WithStyles<typeof styles> {
    mobiles: Array<Mobile>;
    onPress: any;
    /*  form: React.ReactNode; */
}

export const MobileList = ({ mobiles, classes, onPress }: MobileListProps) => {

    const mobileDetail = (mobileInfo: Mobile, key: number) => {
        const backgroundType = (key + 1) % 3 === 0 ? 'circleBackground3' : (key + 1) % 2 === 0 ? 'circleBackground2' : 'circleBackground1';

        return (
            <div className={classes.root}>
                <div className={`${classes.circle} ${classes[backgroundType]}`}>
                    <span className={classes.contractNumber}>{`T${key + 1}`}</span>
                </div>
                <div className={classes.textContainer}>
                    <Typography component={'span'} variant="h6">
                        <Box fontWeight={700}>
                            {`TELÉFONO ${key + 1}`}
                        </Box>
                    </Typography>
                    <Typography component={'span'} variant="body1">
                        <Box>
                            {mobileInfo.number}
                        </Box>
                    </Typography>
                    {/* <Typography variant="body1">
                        <Box>
                            {mobileInfo.type}
                        </Box>
                    </Typography> */}
                    {/* <Typography type="h2" text={`TELÉFONO ${key + 1}`} style={{ marginTop: '0px', marginBottom: '0px', color: '#002A4F' }} />
                    <Typography type="h3" text={mobileInfo.number} weight={'normal'} style={{ marginTop: '0px', marginBottom: '0px', color: '#002A4F' }} />
                    <Typography type="h4" text={mobileInfo.type} weight={'normal'} style={{ marginTop: '0px', marginBottom: '0px', color: '#002A4F' }} /> */}
                </div>
                <div className={classes.buttonContainer}>
                    {/* <Button type="detail" text="RECARGAR" size="small" onClick={() => onPress({ view: "recharge", phone: mobileInfo.number })} /> */}
                    <Button type="recharge" text="DETALLES" size="small" onClick={() => onPress({ view: "details", phone: mobileInfo.number })} />
                </div>
            </div>

            // <div key={`Contract-${key}`} className={classes.contractContainer} >
            //     <div className={classes.div1}>
            // <div className={`${classes.circle} ${classes[backgroundType]}`}>
            //     <span className={classes.contractNumber}>{`T${key + 1}`}</span>
            // </div>
            //     </div>
            //     <div className={classes.div2}>
            // <div className={classes.textContainer}>
            //     <Typography type="h2" text={`TELÉFONO ${key + 1}`} style={{ marginTop: '0px', marginBottom: '0px', color: '#002A4F' }} />
            //     <Typography type="h3" text={mobileInfo.number} weight={'normal'} style={{ marginTop: '0px', marginBottom: '0px', color: '#002A4F' }} />
            //     <Typography type="h4" text={mobileInfo.type} weight={'normal'} style={{ marginTop: '0px', marginBottom: '0px', color: '#002A4F' }} />
            // </div>
            //     </div>
            //     <div className={classes.leftContainer}>
            //         <div className={classes.div4}>
            // <div className={classes.buttonContainer}>
            //     <Button type="detail" text="RECARGAR" size="small" onClick={() => onPress({view: "recharge", phone: mobileInfo.number})} />
            //     <Button type="recharge" text="DETALLES" size="small" onClick={() => onPress({view: "details", phone: mobileInfo.number})} />
            // </div>
            //         </div>
            //         <div className={classes.div3}>
            //             <div className={classes.planContainer}>
            //                 <Typography type="h2" text={mobileInfo.plan} style={{ marginTop: '0px', marginBottom: '0px' }} />
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }

    return (
        <div>
            {mobiles.map((mobileInfo, key) => {
                return (
                    <div className={`${classes.contractContainerBorder} ${key === 0 && classes.firstContractContainer} `} key={`Contract-Container-${key}`}>
                        {mobileDetail(mobileInfo, key)}
                    </div>
                )
            })}
        </div>
    )
}

export default withStyles(styles)(MobileList);
