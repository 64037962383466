import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { IContract } from '../../storeV2/ducks/types';
import { DatosPago } from 'megacable-mobile-api/models';
import { AlertRedux } from 'megacable-mobile-library';
import { useDispatch } from "react-redux";
import GoBack from '../../components/GoBack';
import { useHistory } from "react-router";
import Help from '../../components/Help';
import awsmobile from '../../aws-exports';
import * as api from 'megacable-mobile-api';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { keycloak } from '../../App';
import _ from 'lodash';
import checkToken from '../../utils/checKToken';
import CardResponse from '../../components/CardResponse';
import moment from 'moment';
import Spinner from '../../components/Spinner';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;
const apiV2 = new api.V2Api({}, baseUrl);

type ICardPayments = {
    idCliente: string,
    contract: IContract,
    payment: DatosPago,
    reloadAction: Function,
    clientId: string
};

const PaypalPayment = (props: ICardPayments) => {
    const classes = styles();
    const { payment, reloadAction, clientId, idCliente } = props;
    let history = useHistory();
    const dispatch = useDispatch();
    const [paymentSuccessful, setPaymentSuccessful] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [idTransaccion, setIdTransaccion] = React.useState('');
    const [actualClient, setActualClient] = React.useState('');
    const [actualAmount, setActualAmount] = React.useState('');
    const CryptoJS = require('crypto-js');

    const initialOptions = {
        "client-id": clientId,
        currency: "MXN",
        components: "buttons",
    };

    function _arrayBufferToBase64(buffer: any) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    function base64toHEX(base64: any) {
        var raw = atob(base64);
        var HEX = '';
        for (let i = 0; i < raw.length; i++) {
            var _hex = raw.charCodeAt(i).toString(16)
            HEX += (_hex.length === 2 ? _hex : '0' + _hex);
        }
        return HEX.toUpperCase();
    }

    function cifrarAES(data: any, password: any) {
        let buf = new Uint8Array(16);
        window.crypto.getRandomValues(buf); //Obtenermos 16 bytes
        let buffer_b64 = _arrayBufferToBase64(buf); //
        let iv = CryptoJS.enc.Hex.parse(base64toHEX(buffer_b64));
        var key = CryptoJS.enc.Hex.parse(password);

        let cipherParams = {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }

        let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, cipherParams);

        let merge = encrypted.iv.clone();
        merge.concat(encrypted.ciphertext);
        let merge2 = CryptoJS.enc.Base64.stringify(merge);

        return merge2;
    }


    const createOrder = async (data: any, actions: any) => {
        let sucursal = idCliente.substring(0, 3);
        let suscriptor = idCliente.substring(3, 10);
        let objData = {
            sucursal: sucursal,
            suscriptor: suscriptor,
            monto: payment.datos.amount,
            origen: 'WEB',
            returnUrl: 'https://example.com/return',
            cancelarUrl: 'https://example.com/return',
            metadata: ''
        };

        let orderTmp = "";
        let dataEncrypted = cifrarAES(objData, "5DCC67393750523CD165F17E1EFADD21");
        let objDataEncrypted = {
            param: dataEncrypted,
            sucursal: sucursal,
            suscriptor: suscriptor,
        };

        setActualClient(idCliente);
        setActualAmount(payment.datos.amount);

        checkToken(() => { });

        await apiV2.postApiV2PaypalGenerar(objDataEncrypted, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
            .then(response => {
                let tmpData: any = response.data;
                if (response.data.estatus === "1") {
                    orderTmp = tmpData.id_operacion;
                } else if (response.data.estatus === "4") {
                    dispatch(AlertRedux.showAlert(response.data.mensaje, "Pagar con Paypal"));
                } else {
                    dispatch(AlertRedux.showAlert('Su pago no ha sido procesado con Paypal, intente más tarde.', "Pagar con Paypal"));
                }
            })
            .catch(error => {
                dispatch(AlertRedux.showAlert('No fue posible pagar con Paypal, intenta más tarde.', "Pagar con Paypal"));
            })

        return orderTmp;
    };

    const onApprove = async (data: any, actions: any) => {
        let sucursal = idCliente.substring(0, 3);
        let suscriptor = idCliente.substring(3, 10);
        let objData = {
            sucursal: sucursal,
            suscriptor: suscriptor,
            id_operacion: data.orderID,
            origen: 'WEB',
            metadata: ''
        };

        checkToken(async () => {
            setLoading(true);
            await apiV2.postApiV2PaypalEstatus(objData, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                .then(response => {
                    let tmpData: any = response.data;
                    if (response.data.estatus && response.data.estatus === '1') {
                        // dispatch(AlertRedux.showAlert('Tu pago PayPal ha sido exitoso.', "Pagar con Paypal"));
                        if (tmpData.id_transaccion) {
                            setIdTransaccion(tmpData.id_transaccion);
                        }
                        setPaymentSuccessful(true);
                    } else {
                        dispatch(AlertRedux.showAlert('Su pago no ha sido procesado con Paypal, intente más tarde.', "Pagar con Paypal"));
                    }
                    // reloadAction();
                })
                .catch(error => {
                    dispatch(AlertRedux.showAlert('No fue posible pagar con Paypal, intenta más tarde.', "Pagar con Paypal"));
                })
            setLoading(false);
        })
    };

    const onCancel = async (data: any, actions: any) => {
        let objData = {
            idOperacion: data.orderID,
            cancelarUrl: 'https://example.com/return'
        }

        checkToken(async () => {
            setLoading(true);
            await apiV2.postApiV2PaypalCancelar(objData, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
                .then(response => {
                    dispatch(AlertRedux.showAlert(response.data.mensaje, "Pagar con Paypal"));
                })
                .catch(error => {
                    // dispatch(AlertRedux.showAlert('Se ha cancelado el pago.', "Pagar con Paypal"));
                })
            setLoading(false);
        })
    }

    const finish = () => {
        reloadAction();
        history.push('/payments');
    }

    return (
        <div className={classes.root}>
            <div style={{ backgroundColor: "#002A4F" }}>
                <GoBack onClick={() => history.push('/payments')} />
            </div>
            <>
                {
                    loading ? <Spinner /> :
                        paymentSuccessful ?
                            <CardResponse
                                titleText="Tu pago PayPal ha sido exitoso."
                                descriptionText={`Fecha: ${moment(new Date()).format("DD/MM/YYYY")}`}
                                descriptionText2={!_.isEmpty(actualClient) ? `Suscriptor: ${actualClient}` : ''}
                                descriptionText3={!_.isEmpty(actualAmount) ? `Monto: $${actualAmount}` : ''}
                                descriptionText4={!_.isEmpty(idTransaccion) ? `ID de transacción: ${idTransaccion}` : ''}
                                infoText="Su pago se verá reflejado en un plazo máximo de 48 horas."
                                typeResponse="success"
                                buttonText="FINALIZAR"
                                onClick={() => finish()} />
                            :
                            payment && payment.datos && payment.datos.amount && !_.isEmpty(clientId) ? (
                                Number(payment.datos.amount) > 0 ? (
                                    <>
                                        <Typography component={'span'} color="primary" variant="body1"><Box mb={1} style={{ paddingLeft: 30, marginTop: '10px' }}>PAGO CON PAYPAL</Box></Typography>
                                        <div className={classes.statusContainer}>
                                            <PayPalScriptProvider options={initialOptions}>
                                                <div className={classes.paypalButtonContainer}>
                                                    <PayPalButtons
                                                        style={{
                                                            color: "blue",
                                                            shape: "pill",
                                                            label: "pay",
                                                            tagline: false,
                                                            layout: "horizontal",
                                                        }}
                                                        createOrder={createOrder}
                                                        onApprove={onApprove}
                                                        onCancel={onCancel}
                                                    />
                                                </div>
                                            </PayPalScriptProvider>
                                        </div>
                                    </>
                                )
                                    : <Help type={"attention"} helpStyle={"outline"} text1="¡No tienes adeudos!" text2='' additionalStyle={{ margin: "10px 10px" }} />
                            ) : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor recarga la página!" text2='Ocurrió un error al recuperar la información' additionalStyle={{ margin: "10px 10px" }} />
                }
            </>
        </div>
    )
}

const styles = makeStyles(({ breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white"
    },
    secureTrustImgContainer: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    statusContainer: {
        width: '100%',
        minHeight: '105px',
        display: 'flex',
        flexDirection: "column",
        borderBottom: 'solid 1px #c5c5c5',
        borderTop: 'solid 1px #c5c5c5',
    },
    paypalButtonContainer: {
        margin: '30px 0px 0px 10px',
        width: '400px',
        [breakpoints.down('sm')]: {
            width: "320px",
        }
    }
}));

export default PaypalPayment;
