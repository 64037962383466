import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AccountStatus from '../../components/AccountStatus';
import Slider from '../../components/Slider';
import ContractInfo from '../../components/ContractInfo';
import Help from '../../components/Help';
// import Modal from '../../components/Modal';
// import ModalText from '../../components/ModalText';
// import Input from '../../components/Input';
// import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
// import Typography from '../../components/Typography';
import { saveFile } from '../../assets/saveFile';
import { startAnalytics, logAnalytics } from '../../analitycsV2';
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
// import { DatosPago } from 'megacable-mobile-api/models';
import { AlertRedux, EstadoCuentaRedux, /*PagosDataRedux*/ } from 'megacable-mobile-library';
// import { IContract } from '../../storeV2/ducks/types';
import { keycloak } from '../../App';
import checkToken from '../../utils/checKToken';
import _ from 'lodash';
// import { getXmlPdf, postSendEmail } from '../../store/actions/InvoicesAction';
import { apiV2 } from 'megacable-mobile-library/dist/aws-exports';

const styles = makeStyles(({ palette }: any) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid red",
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    generalContainer: {
        backgroundColor: "white"
    }
}));

// interface InvoiceInfoProps {
//     anio: string;
//     estado_factura: string;
//     factura: string | number;
//     fecha: string;
//     folio: string | number
//     importe_iva: string | number
//     importe_neto: string | number
//     mes: string;
//     rfc: string;
//     serie: string;
//     uuid: string;
// }

// interface sendEmailProps {
//     rfc: string;
//     serie: string | number;
//     folio: string | number;
//     email: string;
// }

const mapState = (state: any) => ({
    login: state.login,
    banners: state.banners,
    currentContract: state.currentContract,
    pagosData: state.pagosData,
    estadoCuenta: state.estadoCuenta,

    // filePDF: state.currentContract,
    // fileXml: state.currentContract,
    // messageEmail: state.currentContract,
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type AccountStatusListProps = PropsFromRedux & {

}

export const AccountStatusList = (props: AccountStatusListProps) => {
    const classes = styles();
    const { banners, currentContract, /*estadoCuenta,*/ /*filePDF, fileXml, messageEmail, login,*/ pagosData } = props;
    const accountStatus = useSelector((state: any) => state.estadoCuenta);
    const dispatch = useDispatch();
    const [idCliente, setIdCliente] = React.useState<string>('');
    const [invoices, setInvoices] = React.useState<Array<any>>([]);
    const [contrato, setContrato] = React.useState<any>({});
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [token, setToken] = React.useState<string>('');
    const [totalAmount, setTotalAmount] = React.useState<string>("");
    const [activeBilling, setActiveBilling] = React.useState<boolean>(false);
    // const [infoInvoice, setInfoInvoice] = React.useState<InvoiceInfoProps | null>(null);
    // const [email, setEmail] = React.useState("");
    // const [errorEmail, setErrorEmail] = React.useState<string | null>(null);
    // const [openModalText, setOpenModalText] = React.useState(false);
    // const [paymentData, setPaymentData] = React.useState<DatosPago>();
    const [loading, setLoading] = React.useState(true);
    // const [contractsList, setContractsList] = React.useState<Array<IContract>>();
    // const [contractsMenu, setContractsMenu] = React.useState<Array<string>>([]);
    // const [okDownload, setOkDownload] = React.useState(false);
    // const [openModal, setOpenModal] = React.useState(false);
    // const [okSend, setOkSend] = React.useState(false);
    // const [text1, setText1] = React.useState("");
    // const [typeModal, setTypeModal] = React.useState<"success" | "error">("success");

    let history = useHistory();

    useEffect(() => {
        startAnalytics('edo_cuenta_id');
        return () => {
            logAnalytics('edo_cuenta_id')
        }
    }, []);

    useEffect(() => {
        if (keycloak && keycloak.token) {
            // if (_.hasIn(login, ['auth', 'token'])) {
            setToken(keycloak.token);
            // }
        }
        return () => { }
    }, [keycloak]);

    useEffect(() => {
        if (token && contrato && idCliente && idCliente !== "" && idCliente !== "0") {
            checkToken(() => {
                if (keycloak.token)
                    dispatch(EstadoCuentaRedux.getEstadosCuenta(idCliente, contrato, keycloak.token, () => { }))
            })
        }
    }, [token, idCliente, contrato, dispatch]);

    useEffect(() => {
        setBannerList(banners.banners);
        return () => { }
    }, [banners]);

    useEffect(() => {
        if (currentContract) {
            if (_.hasIn(currentContract, ['currentContract'])) {
                setContrato(currentContract.currentContract);
            }
        }
    }, [currentContract]);

    useEffect(() => {
        if (contrato) {
            if (_.hasIn(contrato, ['idCliente'])) {
                setIdCliente(contrato.idCliente);
            }
        }
    }, [contrato]);

    useEffect(() => {
        if (accountStatus && accountStatus.listaEstadosCuenta) {
            setInvoices(accountStatus.listaEstadosCuenta)
        }
    }, [accountStatus]);

    useEffect(() => {
        if (!_.isEmpty(idCliente)) {
            let datosPagoTmp = _.find(pagosData.allDatosPago, ['suscriptor', idCliente]);
            if (!_.isEmpty(datosPagoTmp)) {
                setTotalAmount(datosPagoTmp.datos.amount)
            }
        }
    }, [idCliente, pagosData]);

    useEffect(() => {
        if (idCliente) {
            checkToken(() => {
                apiV2.postApiV2ConsultarDatosFacturacionIdCliente(idCliente, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response && _.has(response, ['data', "rfc"])) {
                            setActiveBilling(true);
                        } else if (response && _.has(response, ['data', "estatus"])) {
                            setActiveBilling(false);
                        }
                    })
                    .catch(error => {
                        error = _.get(error, 'response', error);
                    })
            })
        }
    }, [idCliente, token])

    // useEffect(() => {

    //     if (contrato) {
    //         if (pagosData && _.hasIn(pagosData, ['allDatosPago'])) {
    //             const paymentList: Array<DatosPago> = pagosData.allDatosPago;
    //             if (paymentList && paymentList.length > 0) {
    //                 let paymentUser = _.find(paymentList, (paymentData: DatosPago) => { return paymentData.suscriptor === contrato.idCliente });
    //                 if (paymentUser) {
    //                     setPaymentData(paymentUser);
    //                 }
    //             }
    //         }
    //     }
    //     return () => { }
    // }, [contrato, pagosData]);

    // useEffect(() => {
    //     if (paymentData) {
    //         setLoading(false);
    //     }
    //     return () => { }
    // }, [paymentData])

    // useEffect(() => {
    //     if(estadoCuenta){
    //         const contractList: Array<IContract> = estadoCuenta.contratos;
    //         let contractMenuList: Array<string> = [];
    //         if(contractList && contractList.length > 0) {
    //             setContractsList(contractList);
    //             contractList.forEach((contract: IContract) => {
    //                 contractMenuList.push(contract.idCliente);
    //             });
    //         }
    //         if (contractMenuList.length > 0){
    //             setContractsMenu(contractMenuList);
    //         }
    //     }
    // }, [estadoCuenta && estadoCuenta.contratos]);

    // useEffect(() => {
    //     if(token){
    //         dispatch(PagosDataRedux.getAllDatosPago(contractsMenu, token, ()=>{}))
    //     }
    // }, [contractsMenu]);

    // useEffect(() => {
    //     if (filePDF && okDownload) {
    //         saveFile(filePDF.cfdiPdf, "application/pdf", `factura_${infoInvoice && infoInvoice.mes}_${infoInvoice && infoInvoice.anio}.pdf`)
    //     }
    //     if (fileXml && okDownload) {
    //         saveFile(fileXml.cfdiXml, "application/pdf", `factura_${infoInvoice && infoInvoice.mes}_${infoInvoice && infoInvoice.anio}.xml`)
    //     }
    // }, [filePDF, fileXml])

    // useEffect(() => {
    //     if (messageEmail && okSend) {
    //         if (messageEmail.response === 'Correo enviado') {
    //             setOkSend(false)
    //             setOpenModalText(false)

    //             setText1("Correo enviado con exito")
    //             setTypeModal('success')
    //             setOpenModal(true)
    //         } else {
    //             setOkSend(false)
    //             setOpenModalText(false)

    //             setText1("Hubo en error intentalo más tarde")
    //             setTypeModal('error')
    //             setOpenModal(true)
    //         }
    //     }
    // }, [messageEmail])

    const statusAccountFormatted = (accountStatusList: any) => {
        let estadosCuenta: any[] = [];

        if (accountStatusList && accountStatusList.listaEstadosCuenta && accountStatusList.listaEstadosCuenta.length > 0) {
            let arrResponse = {
                suscriptor: accountStatusList.listaEstadosCuenta[0].suscriptor,
                pagosPendientes:
                {
                    folio: accountStatusList.listaEstadosCuenta[0].suscriptor,
                    historialPagos: _.orderBy(accountStatusList.listaEstadosCuenta, 'proceso', 'desc')
                }
            };

            let cont = 1;
            _.forEach(arrResponse.pagosPendientes.historialPagos, pago => {
                let mes = pago.proceso.substring(2, 4);
                pago.mes = mes;
                let anio = pago.proceso.substring(0, 2);
                pago.anio = anio;

                if (totalAmount !== '0.00' && cont === 1) {
                    pago.estado = 'pendiente'
                } else {
                    pago.estado = 'pagado'
                }
                if (pago.estado === 'pagado') {
                    pago.btn1 = "Enviar";
                    pago.btn2 = activeBilling ? "Factura" : "";
                } else {
                    pago.btn1 = "Pagar";
                    pago.btn2 = "Enviar";
                }
                cont++;
                estadosCuenta.push(pago);
            })
        }

        return estadosCuenta;
    }

    let accountStatusItems = [];
    if (accountStatus && idCliente !== "") {
        accountStatusItems = statusAccountFormatted(accountStatus);
    }

    if (accountStatusItems) {
        _.forEach(accountStatusItems, (item, key) => {
            if (key === 0) {
                item.btnDetails = () => history.push({
                    pathname: `/details/${item.proceso}`,
                    state: { sendFactura: "true" }
                })
            } else {
                item.btnDetails = () => history.push({
                    pathname: `/details/${item.proceso}`,
                    state: { sendFactura: "false" }
                })
            }
            if (invoices) {
                var invoice = invoices.find((e: any) => (item.mes === e.mes && item.anio === e.anio))
                if (invoice) {
                    item.invoice = invoice;
                }
            }
        })
    }

    useEffect(() => {
        if (accountStatusItems.length > 0) {
            setLoading(false);
        }
        return () => { }
    }, [accountStatusItems])

    function downloadFile(invoice: any) {
        if (!_.isEmpty(invoice)) {
            let item = invoice.fecha.split("-");
            let request = {
                rfc: invoice.emisor,
                serie: invoice.serie,
                folio: invoice.folio
            }
            apiV2.postApiV2FacturaPdf(request, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    if (response && response.data && _.has(response, ['data', "estatus"])) {
                        if (response.data.estatus === true) {
                            let pdfFile = response.data.archivo;
                            if (pdfFile) {
                                saveFile(pdfFile, "application/pdf", `Factura_${item && item[1]}_${item && item[0]}.pdf`)
                            } else {
                                dispatch(AlertRedux.showAlert("No fue posible descargar tu factura en este momento, intenta más tarde.", "Descargar factura"))
                            }
                        } else {
                            dispatch(AlertRedux.showAlert("No fue posible descargar tu factura en este momento, intenta más tarde.", "Descargar factura"))
                        }
                    } else {
                        dispatch(AlertRedux.showAlert("No fue posible descargar tu factura en este momento, intenta más tarde.", "Descargar factura"))
                    }
                })
                .catch(err => {
                    dispatch(AlertRedux.showAlert("No fue posible descargar tu factura en este momento, intenta más tarde.", "Descargar factura"))
                })
        } else {
            dispatch(AlertRedux.showAlert("No existe la factura a descargar.", "Descargar factura"))
        }
    }

    // function sendInvoice(i: any) {
    //     setInfoInvoice(i.invoice)
    //     setOpenModalText(true)
    // }

    // function sendFilesEmail() {
    //     var emailRegExp = new RegExp("^[\\w\\.-]+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}(\\.[a-zA-Z]{2,3})?$");
    //     if (emailRegExp.test(email)) {
    //         setErrorEmail(null)
    //         if (infoInvoice) {
    //             // let obj: sendEmailProps = {
    //             //     rfc: infoInvoice.rfc,
    //             //     serie: infoInvoice.serie,
    //             //     folio: infoInvoice.folio,
    //             //     email: email
    //             // }

    //             // dispatch(postSendEmail(obj))
    //             // setOkSend(true)
    //         }
    //     } else {
    //         setErrorEmail('El email es invalido')
    //     }
    // }


    // const body = (
    //     <div>
    //         <div className={classes.center}>
    //             <Typography type="h4" text={'Para enviar la factura, ingrese su correo electrónico'} />
    //         </div>
    //         <div className={classes.center}>
    //             <Input colorDescription={'red'} description={errorEmail} onChange={(e: any) => setEmail(e.target.value)} value={email} title="Correo electrónico" placeholder="ejemplo@correo.com" />
    //         </div>
    //         <div className={classes.center} style={{ marginTop: '10px' }}>
    //             <Button type="primary" text="Aceptar" size="slim" onClick={() => sendFilesEmail()} />
    //         </div>
    //     </div>
    // );

    return (
        <div className={classes.generalContainer}>
            <Grid style={{ width: "100%", padding: 0, margin: 0, }}>
                <Row style={{ padding: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {
                            !bannerList ?
                                <Spinner />
                                :
                                bannerList !== null && bannerList.length < 1 ?
                                    null
                                    :
                                    <Slider size='large' images={bannerList} />
                        }
                    </Col>
                </Row>
                {
                    contrato ? (
                        // paymentData && pagosData.datosPagoOnRefresh || loading ? <Spinner /> : paymentData && paymentData.datos && paymentData.datos.amount ? (
                        <div style={{ width: "100%" }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    {
                                        <ContractInfo contract={contrato} />
                                    }
                                </Col>
                            </Row>
                            <Row >
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    {
                                        loading ? <Spinner /> : accountStatusItems && accountStatusItems.length > 0 ?
                                            <AccountStatus
                                                onClickDownload={(i: any) => downloadFile(i)}
                                                // onClickSend={(i: any) => sendInvoice(i)}
                                                accountStatusItems={accountStatusItems}
                                                invoiceButton={(invoices && !_.isEmpty(invoices) ? true : false)}
                                                onClickPay={() => history.push('/payments')}
                                                idCliente={idCliente}
                                                token={token} />
                                            :
                                            <Help type={"error"} helpStyle={"outline"} text1="¡Oh vaya!" text2='Por el momento no hay información disponible.' additionalStyle={{ margin: "10px 10px" }} />
                                    }
                                </Col>
                            </Row>
                        </div>
                        // ) : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor recarga la página!" text2='Ocurrió un error al recuperar la información' additionalStyle={{ margin: "10px 10px" }} />
                    ) : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
                }
                {/* <ModalText open={openModalText} onClose={() => setOpenModalText(false)}>
                    {body}
                </ModalText> */}
                {/* <Modal open={openModal} onClose={() => setOpenModal(false)} type={typeModal} modalStyle={"outline"} text1="Factura " text2={text1} align="top" /> */}
            </Grid>
        </div>
    )
}

export default (connector(AccountStatusList));