import React from 'react';
import { createStyles, withStyles } from '@material-ui/styles';

const styles = ({ palette }: any) => createStyles({
    container: {
        display: 'flex',
        width: '100%',
        visibility: 'visible',
    },
    snackBar: {
        padding: '10px 24px',
        // height: '48px',
        lineHeight: '1.5em',
        borderRadius: '5px',
        /* maxWidth: 'inherit',
        minWidth: 'inherit', */
        /* width: 'calc(100vw - 48px)', */
        width: '100%',
        flexGrow: 1,
        boxSizing: 'border-box',
        /* border-color: */
        /* color: */
        fontFamily: 'Sarabun',
        color: "#FFFFFF",
        backgroundColor: "#FFFFFF"
        /* width: '100%',
        padding: '20px 20px',
        margin: '10px 0px',
        borderRadius: '5px',
        boxSizing: 'border-box' */
    },
    text1: {
        fontWeight: 'bold'
    },
    text2: {
        fontWeight: 'normal',
        marginLeft: '5px'
    },
    outlineattention: {
        borderColor: '#044C9B',
        border: 'solid 1px',
        color: '#044C9B'
    },
    flatattention: {
        backgroundColor: '#044C9B'
    },
    outlinesuccess: {
        borderColor: '#26C281',
        border: 'solid 1px',
        color: '#26C281'
    },
    flatsuccess: {
        backgroundColor: '#26C281'
    },
    outlineerror: {
        borderColor: '#CE4861',
        border: 'solid 1px',
        color: '#CE4861'
    },
    flaterror: {
        backgroundColor: '#CE4861'
    },
    outlinewarning: {
        borderColor: '#F79E1E',
        border: 'solid 1px',
        color: '#F79E1E'
    },
    flatwarning: {
        backgroundColor: '#F79E1E'
    },
    outlineinformation: {
        borderColor: '#00CCFF',
        border: 'solid 1px',
        color: '#00CCFF'
    },
    flatinformation: {
        backgroundColor: '#00CCFF'
    },
    outlinedefault: {
        borderColor: '#CCCCCC',
        border: 'solid 1px',
        color: '#CCCCCC'
    },
    flatdefault: {
        backgroundColor: '#CCCCCC',
        color: '#666666'
    }
});

interface HelpProps {
    helpStyle: "outline" | "flat";
    text1?: string;
    text2: string;
    type?: "attention" | "success" | "error" | "warning" | "information" | "default";
    classes: any;
    additionalStyle?: {}
}

export const Help = ({ helpStyle, text1, text2, type, classes, additionalStyle }: HelpProps) => {
    const snackbarClass = helpStyle + (type ? type : 'default');

    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.snackBar} ${classes[snackbarClass]}`} style={additionalStyle}>
                <span className={classes.text1}>{text1}</span>
                <span className={classes.text2}>{text2}</span>
            </div>
        </div>
    )
}

export default withStyles(styles)(Help);
