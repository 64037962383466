import React, { useEffect } from 'react';
import { Theme, createStyles } from '@material-ui/core/styles';
import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import Slider from '../../components/Slider';
import Spinner from '../../components/Spinner';
import Help from '../../components/Help';
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { BannersRedux, } from 'megacable-mobile-library';
import { IContract } from '../../storeV2/ducks/types';
import _ from 'lodash';
import ComponentHeader from '../../components/ComponentHeader';

const styles = makeStyles(({ breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    bannerContainer: {
        width: "100%",
    },
    container: {
        alignItems: "center",
        backgroundColor: "#fff",
        height: '250px',
        [breakpoints.down('sm')]: {
            height: '300px',
        },
    },
    contractInfo: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: 20,
        width: "100%"
    },
}));

const link1 = "https://www.megacable.com.mx/aviso-de-privacidad";
const link2 = "https://www.megacable.com.mx/terminos-y-condiciones";

type PropsFromRedux = ConnectedProps<typeof connector>

type TermsConditionsProps = PropsFromRedux & {

}

export const TermsConditions = (props: TermsConditionsProps) => {
    const classes = styles();
    const { banners, currentContract } = props;
    const [contractSelected, setContractSelected] = React.useState<IContract>();
    const [bannerList, setBannerList] = React.useState<Array<any>>([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setContractSelected(currentContract.currentContract);
    }, [currentContract]);

    useEffect(() => {
        setBannerList(banners.banners);
        setLoading(false);
        return () => { }
    }, [banners]);

    return (
        <div className={classes.root}>
            <div className={classes.bannerContainer}>
                {bannerList && !_.isEmpty(bannerList) ? <Slider size='large' images={bannerList} /> : null}
            </div>
            {
                contractSelected ? (
                    <>
                        {loading ? <Spinner /> :
                            <>
                                <ComponentHeader
                                    type="H2"
                                    title="Términos y condiciones" />
                                <Grid container className={classes.container}>
                                    <div className={classes.contractInfo}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography component={'span'} variant="h6">
                                                <Box color="">AVISO DE PRIVACIDAD</Box>
                                            </Typography>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box color="">Conoce la actualización del Aviso de privacidad en</Box>
                                                <Link href={link1} style={{ color: "#0797fd", marginLeft: '5px' }}>
                                                    {link1}
                                                </Link>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                                            <Typography component={'span'} variant="h6">
                                                <Box color="">TÉRMINOS Y CONDICIONES </Box>
                                            </Typography>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box color="">Consulta nuestros términos y condiciones en</Box>
                                                <Link href={link2} style={{ color: "#0797fd", marginLeft: '5px' }}>
                                                    {link2}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </>
                        }
                    </>
                ) : <Help type={"error"} helpStyle={"outline"} text1="¡Por favor!" text2='Seleccione algún contrato.' additionalStyle={{ margin: "10px 10px" }} />
            }
        </div>
    )
}

const mapState = (state: any) => ({
    banners: state.banners,
    currentContract: state.currentContract,
})

const mapDispatch = (dispatch: Dispatch<any>) => ({
    ...bindActionCreators({ BannersActions: BannersRedux.Creators } as any, dispatch),
    getBanners: () => dispatch(BannersRedux.getBanners()),

})

const connector = connect(mapState, mapDispatch)

export default (connector(TermsConditions));
