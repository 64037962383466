import React, { useEffect } from 'react';
import { createStyles, withStyles } from '@material-ui/styles';
import { Tooltip, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPen, /*faTimes, faSave, faCreditCard*/ } from '@fortawesome/free-solid-svg-icons';
import TextFieldCardNumber from '../components/TextFieldCardNumber';
import TextFieldCardMMAA from '../components/TextFieldCardMMAA';
import TextFieldCardCVV from '../components/TextFieldCardCVV';
import _ from 'lodash';
import moment from 'moment';
import Button from './Button';

const styles = ({ palette, breakpoints }: Theme) => createStyles({
    statusContainer: {
        width: '100%',
        minHeight: '105px',
        display: 'flex',
        flexDirection: "row",
        borderBottom: 'solid 1px #c5c5c5',
        borderTop: 'solid 1px #c5c5c5',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: "row",
        [breakpoints.down('xs')]: {
            flexDirection: "column",
        }
    },
    verticalText: {
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        fontSize: '16px',
        color: 'white',
        textTransform: 'uppercase',
        writingMode: 'vertical-lr',
        transform: 'rotate(-180deg)',
        textAlign: 'center'
    },
    backgroundColorPending: {
        backgroundColor: '#004FC0'
    },
    backgroundColorPaid1: {
        backgroundColor: '#002A4F'
    },
    backgroundColorPaid2: {
        backgroundColor: '#01192B'
    },
    month: {
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '17px',
        color: 'white',
    },
    cardTitle: {
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '18px',
        color: '#002A4F',
        marginBottom: 16,

    },
    textContainer: {
        color: "#276ba6",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 16,
        marginRight: 24,
    },
    buttonContainer: {
        // marginLeft: "auto",
        flex: 1,
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
    },
    cardIcon: {
        marginLeft: 16,
        marginRight: 15,
        marginTop: 30,
        height: '55px',
        width: '55px',
        /* marginRight: '20px' */
    },
    trashIcon: {
        fontSize: '21px',
        color: '#276ba6',
        cursor: 'pointer',
        marginRight: 32,
        [breakpoints.down('sm')]: {
            marginRight: 16,
        },
        transitionDuration: "300ms",
        '&:hover': {
            opacity: 0.5,
        },
    },
    chevronIcon: {
        fontSize: '21px',
        color: '#276ba6',
        cursor: 'pointer',
        transitionDuration: "300ms",
        '&:hover': {
            opacity: 0.5,
        },
    },
});

interface PaymentData {
    number: string,
    expirationDate: string,
    cvv: string,
    saveCard: boolean,
    isValid: boolean
}

interface PaymentDataErrors {
    number: string,
    expirationDate: string,
    cvv: string,
}

interface ICardInfo {
    resultado: boolean,
    tarjeta: string,
    tarjetaDecrypted: string
}
interface CreditCardProps {
    classes: any;
    text?: string;
    cardInfo: ICardInfo;
    onClickUpdate?: ((event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => void);
    onClick?: ((event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => void);
}

export const CreditCard = ({ classes, text, cardInfo, onClickUpdate, onClick }: CreditCardProps) => {
    // const [isShowDialog, setIsShowDialog] = React.useState<boolean>(false);
    const [isShowPaymentForm, setIsShowPaymentForm] = React.useState<"update" | "confirm" | "none">("none");
    const initialPaymentData = {
        number: "",
        expirationDate: "",
        cvv: "",
        saveCard: false,
        isValid: false
    }
    const [paymentData, setPaymentData] = React.useState<PaymentData>(initialPaymentData);
    const [paymentDataErros, setPaymentDataErrors] = React.useState<PaymentDataErrors>({ number: "", expirationDate: "", cvv: "" });

    const showPaymentForm = (value: "update" | "confirm" | "none") => {
        clearPaymentData();
        setIsShowPaymentForm(value);
    }

    const clearPaymentData = () => {
        setPaymentData(initialPaymentData);
    }

    const validateForm = (newPaymentData: PaymentData) => {
        const creditCardDataTmp = newPaymentData;
        const errors: PaymentDataErrors = { number: "", expirationDate: "", cvv: "" };
        let currentYear = moment().year()
        let yearExp = currentYear.toString().substring(2, 4);

        const mounthAndYear = creditCardDataTmp.expirationDate && creditCardDataTmp.expirationDate.split("/");
        const cardMonth = mounthAndYear ? mounthAndYear[0] : '';
        const cardYear = mounthAndYear ? mounthAndYear[1] : '';

        if (!creditCardDataTmp.number) {
            if (!creditCardDataTmp.number) errors.number = "El número de tarjeta es requerido";
        } else {
            if (creditCardDataTmp.number.length !== 19) errors.number = "El número de tarjeta no es válido";
        }

        if (!creditCardDataTmp.expirationDate) {
            errors.expirationDate = "La fecha de vencimiento es requerida";
        } else {
            if (creditCardDataTmp.expirationDate.length !== 5) {
                errors.expirationDate = "Fecha de vencimiento inválida";
            }
            if (Number(cardMonth) < 1 || Number(cardMonth) > 12) {
                errors.expirationDate = "El mes de la tarjeta es inválido";
            }
            if (Number(cardYear) < Number(yearExp)) {
                errors.expirationDate = "El año de tarjeta ha expirado";
            }
        }
        if (!creditCardDataTmp.cvv) {
            if (!creditCardDataTmp.cvv || creditCardDataTmp.cvv.length < 3) errors.cvv = "El cvv de tarjeta es requerido";
        } else {
            if (creditCardDataTmp.cvv.length < 3) errors.cvv = "El cvv es no es válido";
        }
        return errors
    }

    const payWithMyCreditCard = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => {
        const errors: PaymentDataErrors = validateForm(newPaymentData);
        if (errors.expirationDate || errors.cvv) {
            setPaymentDataErrors(errors);
        } else {
            if (onClick) {
                const creditCart = { ...newPaymentData, number: cardInfo.tarjetaDecrypted };
                onClick(event, creditCart);
            }
        }
    }

    useEffect(() => {
    }, [paymentDataErros])

    const addPaymentData = (value: string, typeData: "number" | "date" | "cvv") => {
        let valueTmp = value;
        const typeDataTmp = typeData;
        switch (typeDataTmp) {
            case "number":
                var numberRaw = valueTmp.split("-").join("");
                var numberFormated = valueTmp.match(new RegExp('.{1,4}', 'g'))?.join("-");
                if (numberRaw.length <= 16) {
                    if (numberFormated) {
                        setPaymentData({ ...paymentData, number: numberFormated });
                    } else {
                        setPaymentData({ ...paymentData, number: '' });
                    }
                }
                break;
            case "date":
                let expirationDate: string = _.cloneDeep(valueTmp);
                if (expirationDate) {
                    expirationDate = expirationDate.split("/").join("")
                    if (expirationDate.length <= 4) {
                        let cardFormatArray: Array<string> | null = expirationDate.match(/.{1,2}/g);
                        if (cardFormatArray) {
                            let newCardFormat: string = cardFormatArray.join("/");
                            setPaymentData({ ...paymentData, expirationDate: newCardFormat });
                        } else {
                            setPaymentData({ ...paymentData, expirationDate: '' });
                        }
                    }
                } else {
                    setPaymentData({ ...paymentData, expirationDate: "" });
                }
                break;
            case "cvv":
                setPaymentData({ ...paymentData, cvv: valueTmp });
                break;
            default:
                break;
        }
    }

    const handleFocus = (typeData: "number" | "date" | "cvv") => {
        switch (typeData) {
            case "number":
                setPaymentDataErrors({ ...paymentDataErros, number: "" });
                break;
            case "date":
                setPaymentDataErrors({ ...paymentDataErros, expirationDate: "" });
                break;
            case "cvv":
                setPaymentDataErrors({ ...paymentDataErros, cvv: "" });
                break;
            default:
                break;
        }
    }

    const updateMyCreditCard = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, newPaymentData: PaymentData) => {
        const errors: PaymentDataErrors = validateForm(newPaymentData);
        if (errors.number) {
            setPaymentDataErrors(errors);
        } else {
            if (onClick) {
                onClickUpdate && onClickUpdate(event, paymentData)
            }
        }

    }

    return (
        <div className={classes.statusContainer}>
            <img src="../pago-tarjeta.png" className={classes.cardIcon} alt="" />
            <div className={classes.row}>
                <div className={classes.textContainer}>

                    {
                        isShowPaymentForm === "update" ? (
                            <TextFieldCardNumber
                                cardNumberValue={paymentData.number}
                                addPaymentData={addPaymentData}
                                onFocus={handleFocus}
                                cardNumberError={paymentDataErros.number} />
                        ) : (
                            <span className={classes.cardTitle}>{cardInfo.tarjeta}</span>
                        )
                    }
                </div>
                <div className={classes.buttonContainer}>
                    {
                        isShowPaymentForm === "confirm" && (
                            <>
                                <TextFieldCardMMAA
                                    cardMMAAValue={paymentData.expirationDate}
                                    cardMMAAError={paymentDataErros.expirationDate}
                                    addPaymentData={addPaymentData}
                                    onFocus={handleFocus}
                                    otherStyles={{ marginRight: 16, }} />

                                <TextFieldCardCVV
                                    cardCvvValue={paymentData.cvv}
                                    cardCvvError={paymentDataErros.cvv}
                                    addPaymentData={addPaymentData}
                                    onFocus={handleFocus}
                                    otherStyles={{ marginRight: 16 }} />
                            </>
                        )
                    }
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "auto", padding: 16, }}>
                        {
                            isShowPaymentForm === "none" ? (
                                <>
                                    <Tooltip title="Editar tarjeta" aria-label="add">
                                        <FontAwesomeIcon icon={faPen} className={classes.trashIcon} onClick={() => showPaymentForm("update")} />
                                    </Tooltip>
                                    <FontAwesomeIcon icon={faChevronRight} className={classes.trashIcon} onClick={() => showPaymentForm("confirm")} />
                                </>
                            ) : (
                                <>
                                    {
                                        isShowPaymentForm === "confirm" &&
                                        // <Tooltip title="confirmar pago" aria-label="add">
                                        //     <FontAwesomeIcon
                                        //         icon={faCreditCard}
                                        //         className={classes.trashIcon}
                                        //         onClick={(event) => { payWithMyCreditCard(event, paymentData) }} />
                                        // </Tooltip>
                                        <Button type={(paymentData.expirationDate.length === 5) && (paymentData.cvv.length === 3) ? 'primary' : "disabled"} text="Pagar" disabled={(paymentData.expirationDate.length === 5) && (paymentData.cvv.length === 3) ? false : true} size="slim" onClick={(event: any) => { payWithMyCreditCard(event, paymentData) }} />
                                    }
                                    {
                                        isShowPaymentForm === "update" &&
                                        // <Tooltip title="Guardar tarjeta" aria-label="add">
                                        //     <FontAwesomeIcon
                                        //         icon={faSave}
                                        //         className={classes.trashIcon}
                                        //         onClick={(event) => { updateMyCreditCard(event, paymentData) }} />
                                        // </Tooltip>
                                        <Button type="primary" text="Guardar" size="slim" onClick={(event: any) => { updateMyCreditCard(event, paymentData) }} />

                                    }
                                    <Button type="danger" text="Cancelar" size="slim" onClick={() => showPaymentForm("none")} />
                                    {/* <FontAwesomeIcon icon={faTimes} className={classes.trashIcon} onClick={() => showPaymentForm("none")} /> */}
                                </>
                            )
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(CreditCard);
