import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider } from '@material-ui/core';
// import moment from 'moment';

interface IDevice {
    modelo: string,
    serie: string,
    tipo: string
}

interface IDeviceItemList extends WithStyles<typeof styles>{
    device: IDevice,
}

const DeviceItemList = (props: IDeviceItemList) => {
    const { classes, device} = props;
    const { tipo, serie, modelo} = device;
    return (
       <div className={classes.root}>
                <div className={classes.deviceInfo}>
                    <Typography component={'span'} variant="body1">
                        <Box fontWeight={700} color="#2a5179">
                            {tipo}
                        </Box>
                    </Typography>
                    <Typography component={'span'} variant="body2" color="textPrimary">
                        <Box>
                            Serie: {serie}
                        </Box>
                    </Typography>
                    <Typography component={'span'} variant="body2" color="textPrimary">
                        <Box>
                            Modelo: {modelo}
                        </Box>
                    </Typography>
                </div>
            <Divider className={classes.divider}/>   
       </div>
    )
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },

    deviceInfo:{
        display: "flex",
        flexDirection: "column",
    },

    divider:{
        marginTop: 16,
        marginBottom: 16,
    }
   
});

export default withStyles(styles)(DeviceItemList);
