import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

interface IHBOHireProps extends WithStyles<typeof styles> {
    onClick: Function;
}

const PortabilityBanner = (props: IHBOHireProps) => {
    const { classes, onClick } = props;

    return (
        <div className={classes.root}>
            <div className={classes.imageBackground}>
                <div className={classes.innerImage}>
                    <img
                        src="../9-mega-movil-1.png"
                        alt="portability"
                        className={classes.image}
                    />
                </div>
                <div className={classes.buttonContainer}>
                    <Button className={classes.portabilityButton} onClick={() => onClick()}>
                        Haz clic aquí
                    </Button>
                </div>
            </div>
        </div>
    );
}

const styles = ({ breakpoints }: any) => createStyles({
    root: {
        width: "100%",
        height: "400px",
    },
    imageBackground: {
        height: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        backgroundImage: "url(mega-movil-bg.jpg)",
        backgroundSize: "cover",
    },
    buttonContainer: {
        display: "flex",
        width: "100%",
        justifyContent: 'center',
    },
    innerImage: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: "10px"
    },
    image: {
        maxWidth: "100%",
        [breakpoints.down('sm')]: {
            [breakpoints.down('sm')]: {
                height: "300px",
            },
        },
    },
    portabilityButton: {
        backgroundColor: "#fff",
        color: "#164173",
        width: "160px",
        textTransform: "uppercase",
        fontSize: "18px",
        padding: "6px",
        borderRadius: "30px",
        "&:hover": {
            backgroundColor: "#fff",
            cursor: "pointer",
        },
        [breakpoints.down('sm')]: {
            [breakpoints.down('sm')]: {
                width: "150px",
                padding: "4px",
                fontSize: "16px"
            },
        },
    },
});

export default withStyles(styles)(PortabilityBanner);
