import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';

// import Typography from './Typography';

const styles = ({ palette, breakpoints }: any) => createStyles({
    mobileTopUpHeaderContainer: {
        backgroundColor: `#282828`,
        fontFamily: 'Sarabun',
        color: 'white',
        width: '100%',
        display: 'block',
        padding: 16,
    },
    container1: {
        width: '100%',
        display: 'flex'
    },
    div1: {
        /* marginLeft: '15px', */
        position: 'relative',
        width: '70%',
        height: '45px'
    },
    div2: {
        position: 'relative',
        width: '30%',
        display: 'flex',
        height: '45px',
        whiteSpace: 'pre-wrap',
        direction: 'rtl',
        /* marginRight: '15px' */
    },
    textContainer: {
        position: 'relative',
        height: 'fit-content',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        direction: 'ltr'
    },

    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 16,
        backgroundColor: "#282828",
        color: 'white',
    },

    phoneIcon: {
        width: 35,
        height: 40,
        transform: 'rotate(15deg)',
        color: 'white',
    }
});

interface MobileTopUpHeaderProps extends WithStyles<typeof styles> {
    phone: string;
    amount: string;
}

export const MobileTopUpHeader = ({ phone, amount, classes }: MobileTopUpHeaderProps) => {
    return (

        <div className={classes.root}>
            <Typography component={'span'} variant="h5">
                <Box fontWeight={700} mr={1}>Recarga</Box>
            </Typography>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: 8, }}>
                <img src="../smartphone-call.svg" className={classes.phoneIcon} alt="" />
            </div>
            <Typography component={'span'} variant="h5">
                <Box fontWeight={700}>{phone}</Box>
            </Typography>
            <div style={{ marginLeft: "auto" }}>
                <Typography component={'span'} variant="h5">
                    <Box>{amount}</Box>
                </Typography>
            </div>

        </div>


        // <div className={classes.mobileTopUpHeaderContainer}>
        //     <div className={classes.container1}>
        //         <div className={classes.div1}>
        //             <div className={classes.textContainer}>

        //             </div>
        //         </div>
        //         <div className={classes.div2}>
        //             <div className={classes.textContainer}>

        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default withStyles(styles)(MobileTopUpHeader);
