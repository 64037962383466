import * as React from 'react';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export default function RequestConfirmationDialog(props) {

    const handleClose = () => {
        props.onExit();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={props.open}
            PaperProps={{
                style: {
                    backgroundImage: 'linear-gradient(90deg, rgba(214,232,244,1) 0%, rgba(239,246,252,1) 100%)',
                    borderRadius: 8,
                    width: 400
                },
            }}
        >
            <div style={{
                backgroundImage: 'linear-gradient(90deg, rgba(2,126,28,1) 0%, rgba(71,215,57,1) 100%)',
                width: '100%',
                fontSize: 70,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderRadius: 8,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            }}>
                <FontAwesomeIcon icon={faCheckCircle} color="white" solid style={{ marginTop: 20, marginBottom: 20 }} />
            </div>
            <DialogContent>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: 22,
                        color: '#047d1e',
                        fontWeight: 'bold',
                        marginTop: 10
                    }}>
                        Solicitud procesada
                    </Typography>
                    <Typography style={{
                        fontSize: 22,
                        color: '#047d1e',
                        fontWeight: 'bold',
                        marginBottom: 15,
                        marginTop: -8
                    }}>
                        Correctamente
                    </Typography>
                    <Typography style={{
                        fontSize: 14,
                    }}>
                        Estimado Suscriptor.
                    </Typography>
                    <Typography style={{
                        fontSize: 14,
                    }}>
                        Su solicitud de contratación está
                    </Typography>
                    <Typography style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: '#047d1e'
                    }}>
                        EN PROCESO
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{
                            fontSize: 14,
                        }}>
                            y se aplicará en un
                        </Typography>
                        <Typography style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            marginLeft: 4
                        }}>
                            máximo de 24 horas
                        </Typography>
                    </div>
                    <Button style={{
                        height: 35,
                        width: 120,
                        alignSelf: 'center',
                        borderRadius: 20,
                        marginTop: 20,
                        marginBottom: 20,
                        backgroundImage: 'linear-gradient(90deg, rgba(2,126,28,1) 0%, rgba(71,215,57,1) 100%)',
                        color: 'white',
                        fontWeight: 'bold'
                    }}
                        onClick={handleClose}
                    >
                        Aceptar
                    </Button>
                </div>
            </DialogContent>
        </Dialog >
    );
}