import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { TextField, InputAdornment, Theme, Tooltip, Fade } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

interface ITextFieldCardMMAA extends WithStyles<typeof styles> {
    cardMMAAValue: string,
    cardMMAAFormat?: string,
    cardMMAAError?: string,
    otherStyles?: any,
    onKeyDown?: (event: React.KeyboardEvent) => void,
    onFocus?: (typeData: "number" | "date" | "cvv") => void,
    addPaymentData: (value: string, typeData: "number" | "date" | "cvv") => void,
}

const TextFieldCardMMAA = (props: ITextFieldCardMMAA) => {
    const {classes, cardMMAAValue, cardMMAAError, otherStyles, onKeyDown, onFocus, addPaymentData} = props;

    return (
        <div className={classes.root} style={otherStyles}>
             <TextField
                value={cardMMAAValue} 
                placeholder="MM/AA"
                autoComplete="off"
                error={cardMMAAError ? true : false}
                helperText={cardMMAAError ? cardMMAAError : ' '}
                inputProps={{style: { color: "#005ca7" }, maxLength: 5}}
                InputProps={{
                    endAdornment: (
                        <Tooltip
                            className={classes.tooltip} 
                            TransitionComponent={Fade} 
                            TransitionProps={{ timeout: 600 }} 
                            title="Fecha ubicada al frente de tu tarjeta, debajo del número de tarjeta.">
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faQuestionCircle} style={{color: "#cccccc"}} />
                            </InputAdornment>
                        </Tooltip>
                    ),
                }}
                onKeyDown={(event: React.KeyboardEvent) => {onKeyDown && onKeyDown(event)}}
                onChange={(event) => addPaymentData(event.target.value.replace(/[^0-9]/g, ''), 'date')} 
                onFocus={() => {onFocus && onFocus("date")}} 
            />
        </div>
    )
}

const styles = ({ palette, breakpoints }: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    tooltip: {
        backgroundColor: "transparent",
        color: palette.common.black
    }
});

export default withStyles(styles)(TextFieldCardMMAA);
